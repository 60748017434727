import {
  STORIES_ADD,
  STORIES_FETCH_ERROR,
  UN_AUTHORIZED_USER
} from '../constants/actionTypes';

const INITIAL_STATE = {
  stories: [],
    count: 0,
  error: null,
};

const applyAddStories = (state, action) => {
        let stories;
        if(action.deviceType ==='mobile') {
            stories =[...state.stories, ...action.stories];
        }
        else {
          stories = action.stories;
        }
        return ({
            stories,
            count: action.count,
            error: null,
        })
};



const applyFetchErrorStories = (state, action) => ({
  stories: [],
  error: action.error,
});

function storyReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case STORIES_ADD : {
      return applyAddStories(state, action);
    }
    case STORIES_FETCH_ERROR : {
      return applyFetchErrorStories(state, action);
    }
    case UN_AUTHORIZED_USER : {
        return {...state, responseStatus:action.payload}
    }
    default : return state;
  }
}

export default storyReducer;
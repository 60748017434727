import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import * as JWT from 'jwt-decode';

Moment.locale('en')
momentLocalizer()

const getFullDateFromCurrentYear = (monthDate) => {
    const thisYear = (new Date()).getFullYear();
    const start = new Date(`${monthDate}/${  thisYear}`);
    return new Date(start.valueOf());
}
const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

const compressImg = (img) => {
  
    var canvas = document.createElement('canvas');
  
    var width = img.width;
    var height = img.height;
  
    // resize the canvas and draw the image data into it
    canvas.width = width;
    canvas.height = height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, width, height);
    
    //document.appendChild(canvas); // do the actual resized preview
    
    return canvas.toDataURL("image/jpeg",0.2); // get the data from canvas as 70% JPG (can be also PNG, etc.)
  
}


const getToken =() => {
    const storage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return storage ? (storage.accessToken ? storage.accessToken.accessToken :''):'';
}

const getTokenGrp =() => {
    let grp =null;
    const token = getToken();
    if(token) {
        //grp = JWT(token)['grp'].toString().split(' - ').join("_").split(' – ').join("_");
        //grp = JWT(token)['grp'].toString();

    }
}

const getTokenEmail =() => {
    let sub =null;
    const token = getToken();
    if(token) {
        sub = JWT(token)['sub'].toString();
        //grp = JWT(token)['grp'].toString();

    }
    return sub;
}

const getTokenInfo = () => {
    let tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'));
    return tokenStorage && tokenStorage.idToken  && tokenStorage.idToken.claims ? tokenStorage.idToken.claims : {};
}


const capitalizeStr = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

  const multiselectOptions = (dropDownList, selectObj) => {
    const {optionId, optionValue} = selectObj;
    if (dropDownList) {
        const multipleSelectOptions = dropDownList.map((dataOption, index) => {

        const id = (optionId !== null) ? dataOption [optionId] :dataOption .Id;
        const displayValue = dataOption [optionValue];

        return (
            {
              id,
              label: displayValue,
              value: displayValue
            } );
        });
        return multipleSelectOptions;
    } else {
        return '';
    }
  }

const getAccessPermission = (roles,value) => {

    return roles.some(role => role.AccessControlType === value);
}


export {
    getFullDateFromCurrentYear,
    getBase64,
    getToken,
    getTokenGrp,
    getTokenEmail,
    getTokenInfo,
    capitalizeStr,
    multiselectOptions,
    getAccessPermission,
    compressImg
};
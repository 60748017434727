import React, { Component } from "react";
import { connect } from 'react-redux';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {capitalizeStr, multiselectOptions} from "../../utils/utils";

export default class MultiSelectDropdown extends Component {

  componentDidUpdate () {
    // Fix for double tab required on IOS devices. code below ensures click event fires on single tap
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      const $this = this;
      try {
        var buttonElement = document.querySelector(`#${this.props.forEle}__label`).parentElement.querySelector('button');
        if (buttonElement) {
          buttonElement.onclick = function () {
            setTimeout(() => {
              var optionElements = document.querySelectorAll(`div.${$this.props.forEle}-name__option`);
              if (optionElements.length) {
                for (var i=0; i<optionElements.length; i++) {
                  optionElements[i].onmouseover = function() {
                     this.click();
                  }
                }
              }
            }, 500);
          }
        }
      } catch(exc) {}
    }

  }

  render() {
    return (
      <ReactMultiSelectCheckboxes
          id={`${this.props.forEle}__name`}
          classNamePrefix={`${this.props.forEle}-name`}
          className={`${this.props.forEle}__name custom-multiselect-dropdown customized`}
          ref={this.props.reference}
          placeholderButtonLabel={this.props.placeholderText || `Select ${capitalizeStr(this.props.forEle)}`}
          onChange={this.props.handleChange}
          options={multiselectOptions(this.props.dropDownList, this.props.selectObj)}
          isSearchable="true"
          isMulti="true"
      />
    )
  }

}


import React from 'react';
import Popup from "reactjs-popup";
import CreateManageAction from "./CreateManageAction";

const contentStyle = {
};


const PopUpCreateActionTrigger = (triggerAction,mode,question, review,locationName, currentUser,action, actionId, addActionDetails,firstReview,reviewStatus,activePage) => (

  <Popup
    trigger={triggerAction}
    modal
    contentStyle={contentStyle}
    closeOnDocumentClick
    closeOnEscape
    lockScroll
  >
    {close => (
      <div>
        <div className="close__wrapper">
        <div className="popup__close">
          <label onClick={close}><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
        </div>
        </div>
        
         
        <CreateManageAction
          mode={mode}
          question={question}
          review={review}
          actionId={actionId}
          locationName={locationName}
          currentUser={currentUser}
          action={action}
          addActionDetails={addActionDetails}
          close={close}
          activePage = {activePage}
          notFirstReview={!firstReview}
          reviewStatus={reviewStatus}
        />
      </div>
    )}

  </Popup>
);

export {PopUpCreateActionTrigger};

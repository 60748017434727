
import React, { Component }  from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
export class ReviewSuccess extends React.Component {

    handleViewReview = () =>{
        this.props.history.push(
                   
            {
                pathname: this.props.location.state.fromPath.pathname,
                state: { isReadOnlyMode:true }
              }
            );
    }
   
    constructor(props) {
        super(props);
    }

    shareClicked(event){
      let path = this.props.tflType==="tfl9"?'/ReviewSuccess': '/Tfl18ReviewSuccess'
             this.props.history.push(
              {
                  pathname:'/ShareReview',
                  state: { fromPath:  this.props.location.state.fromPath.pathname?this.props.location.state.fromPath.pathname:this.props.location.state.fromPath,
                    reviewType:  this.props.location.state.reviewType,
                    assessorName:  this.props.location.state.assessorName,
                    dealerLocation: this.props.location.state.dealerLocation,
                    dealerEmail: this.props.location.state.dealerEmail,
                    assessmentId: this.props.location.state.assessmentId
                   }
                }
              );
      //this.props.history.push(`/ShareReview`);
  }


    render() {
        return (
          <div className="main-content row justify-content-center">
            <div className="success-content-wrapper align-self-center text-center">
              <img src="../../images/success-big.svg" alt="Success" />
              <h1 className="text-dark-gray">Success!</h1>
              <h6>{this.props.location.state.program}  {this.props.location.state.reviewType} Review Completed.</h6>
              <button className="btn btn-primary m-t-30 m-b-40" onClick={(e) => this.handleViewReview(e)}>View Review</button>
              { this.props.tflType==="tfl9" && <div className="share-review">
                <a href="#">
                  <img
                    className="m-b-10"
                    src="../../images/share.svg"
                    alt="Share"
                    onClick={(e) => this.shareClicked(e)}
                  />
                </a>
                <p className="text-black">Share</p>
              </div>}
            </div>
          </div>
        );

    }


}

export default connect(
  null,
  null
)(ReviewSuccess);

/* const connectedReview= connect(
    null,
    null
)(ReviewSuccess);

export default withRouter(connectedReview); */
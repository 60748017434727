import React, { Component }  from 'react';
import { getToken } from '../../utils/utils';
import { connect } from 'react-redux'
import { fetchUserAccessRoles, setForSecured } from '../../actions/userAccessRoles';

class BlankPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const token = getToken();
    if (token && !this.props.userRoles && !this.props.isFetching) {
      this.props.fetchUserAccessRoles();
    }
    if (this.props.userRoles) {
      this.props.setForSecured();
    }
  }

  componentDidUpdate() {
    if (this.props.userRoles) {
      this.props.setForSecured();
    }
  }

  render() {
    return (
      <div>
        <div className="container-fluid logout-screen h-100">
          <div className="row grid__columns-wrapper h-100">
              <div className="col-12 text-center align-self-center">
                  <div className="login__box position-relative">
                    <div className="login-content ">
                        
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <div>
          <div className="loader">
            <div className="load-bar">
              <div className="bar" />
              <div className="bar" />
              <div className="bar" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => ({
 
  userRoles:state.userAccessRolesState.userAccessRoles.data,
  isFetching:state.userAccessRolesState.isFetching
});
const mapDispatchToProps = dispatch => ({
  fetchUserAccessRoles: ()=>dispatch(fetchUserAccessRoles()),
  setForSecured: ()=> dispatch(setForSecured())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlankPage);
import {call, put} from 'redux-saga/effects';
import {fetchBrandName} from '../api/api_review';
import {fetchBrandNameSuccess,fetchBrandNameError} from '../actions/header'
import {doHideLoader, doShowLoader} from "../actions/loader";

export function* handleFetchBrandName (action) {
    try {
        const result = yield call(fetchBrandName);
        yield put(fetchBrandNameSuccess(result))

    } catch (error) {
        yield put(fetchBrandNameError(error))


    }
}
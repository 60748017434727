import {
  ADD_PROGRAMS_LIST,
  // FETCH_LOCATIONS_LIST,
  FETCH_PROGRAMS_LIST_ERROR  
} from '../constants/actionTypes';

const INITIAL_STATE = {
  programsList: [],
  count: 0,
  error: null,
};

export function programsListReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ADD_PROGRAMS_LIST: {
      return {
          ...state,
          programsList:action.programsList.data.data,
          count :action.programsList.data.data.Count
      }
    }
    case FETCH_PROGRAMS_LIST_ERROR : {
      return {
          ...state,
          programsList: [],
          error: action.error,
          count :0
      }
    }

    default : return state;
  }
}

export default programsListReducer;

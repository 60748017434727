import React from "react";
import { Modal, ModalHeader } from 'reactstrap';
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import {doFetchStartReview} from "../../actions/startReview"
import {doClearReviewData} from "../../actions/review"
import LoaderModal from "../Common/LoaderModal";
import Select from "react-select";

import {doFetchProgramsList} from "../../actions/programsList";
import {getLocationsList, getProgramsList} from '../../selectors/uploadSOP';
import {multiselectOptions} from "../../constants/uploadSOPConstants";
import {fetchLocationList} from '../../actions/locationsList';
import {doFetchScheduleDropDowns} from '../../actions/story';
import { getCurrentUserDetails } from "../../constants/utilityfunctions";
import { getCurrentUser } from '../../selectors/dropdown';
import { getTokenFullName} from "../../utils/utils";
import {doPostAuditLogData} from "../../actions/auditLog";
import Moment from 'moment';

export class StartReviewModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: props.initialModalState,
            formValid: false,
            dealerId: null,
            selectedOption: null,
            selectedReview:null,
            value: null,
            assessmentTemplateID: null,
            programSelectedOptions: null,
            isProgramDisabled: false,
            isLocationDisabled: true,
            isReviewDisabled: true
        };

        this.toggle = this.toggle.bind(this);
        this.resetState = this.resetState.bind(this);
        this.locDataListText = React.createRef();
        this.programRef = React.createRef();
    }

    componentDidMount() {
        this.props.onFetchProgramsList();
    }

    componentDidUpdate(prevProps) {
      if (this.state.dealerId && prevProps.reviewDropDownList !== this.props.reviewDropDownList && this.state.isReviewDisabled) {
        this.setState({isReviewDisabled: false});
        this.setState({mLoadValue : false});
      }
      if (this.state.programSelectedOptions && prevProps.locationsDropDownList !== this.props.locationsDropDownList && this.state.isLocationDisabled) {
        this.setState({isLocationDisabled: false});
        this.setState({mLoadValue : false});
      }
    }

    onSelectDealer = selectedOption => {
        this.setState({dealerId: selectedOption.id, selectedReview:null, isReviewDisabled: true, formValid: false});
        this.setState({selectedOption});
        /** S - DSA 1967 */
        const query = {
          dealer_id:selectedOption.id,
          program_id:this.state.programSelectedOptions.id,
          statusType: "Start Review"
      }
      this.setState({mLoadValue : true});
        this.props.onFetchDropDowns(query);
        /** E - DSA -1967 */
    }

    onSelectReview = (selectedReview) => {
        this.setState({selectedReview,formValid: true});

    }

    handleProgramDropdown = (programSelectedOptions) => {
        const query = {
            is_active:1,
            program_id:programSelectedOptions.id
        }

        this.props.fetchLocationList(query);
        this.setState({mLoadValue : true});
        this.setState({programSelectedOptions,selectedOption:null, isLocationDisabled: true, selectedReview:null, isReviewDisabled: true, formValid: false});
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
        this.resetState();
        this.setState({selectedReview:null, isLocationDisabled: true, isReviewDisabled: true});
    }

    resetState() {
        this.setState({
            formValid: false,
            dealerId: null,
            selectedOption: null,
            value: null,
            assessmentTemplateID: null,
            programSelectedOptions: null,
        });
        let query = {
          is_active:1,
          program_id:''
        }
        this.props.fetchLocationList(query);
        query = {
          dealer_id: '',
          program_id: '',
          statusType: ""
        }
        this.props.onFetchDropDowns(query);
        
        /* this.locDataListText.current.value=''; */
    }

    startReview =()=> { 
        this.props.doFetchStartReview({dealerID:this.state.dealerId,assessmentID:this.state.selectedReview.id,userID:getCurrentUserDetails(),history:this.props.history,
            program: this.state.programSelectedOptions.label, review: this.state.selectedReview.label, location: this.state.selectedOption.label});
       }

    /* function to display the error message */
    errorMessage = (msg) => {
        const errorDetails = (
            <div className="alert alert-danger alert-dismissible" role="alert">
                <button type="button" id="messageButton" className="close" data-dismiss="alert" aria-label="Close"
                        onClick={() => this.hideAlert()}>
                    <span aria-hidden="true">&times;</span>
                </button>
                {msg}
            </div>
        );
        return errorDetails;
    }

    componentWillUnmount() {
      this.props.clearData();
    }

    render() {
        let isError = false;
        if (this.props.startReviewError) {
             isError = true;
        }

        const {isProgramDisabled, isLocationDisabled, isReviewDisabled, formValid} = this.state;
        return (
            <span>
        <button id="start-review__button" className="btn btn-primary" onClick={this.toggle}>Start Review</button>
        <Modal onClosed={this.resetState} isOpen={this.state.modal} toggle={this.toggle}
               className={this.props.className} centered>

          {this.props.startReviewError || isError ? this.errorMessage(`Oops! unable to start the assessment!!!`) : ""}
            <div className="popup__wrapper align-self-center">
            <ModalHeader className="popup__header" toggle={this.toggle}>
              Start Review
              <span className="cst-close" title="Close" onClick={this.toggle}></span>
                </ModalHeader>
            <LoaderModal  mLoad={this.state.mLoadValue}/>
            <div className="popup__body">
            <form>

                <div className="row">
                  <div className="col-12">
                    <label htmlFor="program" id="select-program__label">
                      Program
                        <span className="required">*</span>
                    </label>
                    <Select
                        id="select-program__input" classNamePrefix="select-program"
                        className="form-group dropdown-with-search"
                        placeholder="Select Program"
                        value={this.state.programSelectedOptions}
                         options={multiselectOptions("Select Program", this.props.programsDropDownList, "Id", "Name", null)}
                        isDisabled={isProgramDisabled}
                        onChange={this.handleProgramDropdown}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <label htmlFor="location" id="select-location__label">
                      Location
                        <span className="required">*</span>
                    </label>
                    <Select
                        id="select-location__input" classNamePrefix="select-location"
                        className="form-group"
                        placeholder="Select Location"
                        value={this.state.selectedOption}
                        options={multiselectOptions("Select Location", this.props.locationsDropDownList, "Dealer_ID", "Location_Name", null)}
                        isDisabled={isLocationDisabled}
                        onChange={(e) => this.onSelectDealer(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">
                    <label htmlFor="review" id="select-review__label">
                      Review
                    <span className="required">*</span>
                    </label>
                    <Select
                        id="select-review__input" classNamePrefix="select-review"
                        className="form-group"
                        value={this.state.selectedReview}
                        placeholder= {"Select Review"}
                        options={multiselectOptions("Select Review", this.props.reviewDropDownList, "Assessment_Template_ID", "Assessment_Name", null)}
                        isDisabled={isReviewDisabled}
                        onChange={(e) => this.onSelectReview(e)}
                    />
                  </div>
                </div>

                <div className="popup__button-wrapper">
                  <button id="start-review__button" className="btn btn-primary" type="button" disabled={!formValid ? 'disabled' : null}
                          onClick={this.startReview}>Start Review</button>
                </div>
              </form>
            </div>
          </div>
        </Modal>
      </span>
        );
    }
}


const mapStateToProps = state => ({
    startReviewData: state.startReviewState.startReviewData,
    startReviewError: state.startReviewState.startReviewError,
    locationList: state.locationListState.locationsList,
    locationsDropDownList: getLocationsList(state),
    programsDropDownList: getProgramsList(state),
    reviewDropDownList:state.dropdownState.AssessmentStatusLookup,
    currentUser: getCurrentUser(state.dropdownState),

});

const mapDispatchToProps = dispatch => ({
    doFetchStartReview: (payload) => dispatch(doFetchStartReview(payload)),
    clearData: () => dispatch(doClearReviewData()),
    onFetchProgramsList: query => dispatch(doFetchProgramsList(query)),
    fetchLocationList: query => dispatch(fetchLocationList(query)),
    onFetchDropDowns: (statusType) => dispatch(doFetchScheduleDropDowns(statusType)),
    doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
});

export const StartReviewModalConnect = connect(
    mapStateToProps,
    mapDispatchToProps,
)(StartReviewModal);

export default withRouter(StartReviewModalConnect);
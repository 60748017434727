import React, { Component }  from 'react';

class PageNotFound extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
      document.title = "Page Not Found";
  }



  render() {
    return (
      <div className="container-fluid logout-screen h-100">
        <div className="row grid__columns-wrapper h-100">
            <div className="col-12 text-center align-self-center">
                <div className="login__box position-relative">
                    <div className="login-content ">
                        <p className="text-f-16">Page not Found</p>
                      { /*<p className="text-f-16">You are not authorised to view this page.</p>*/}
                    </div>
                </div>
            </div>
        </div> 
      </div>
   
    )
  }
}

export default PageNotFound;
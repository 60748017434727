import React from 'react';
import {connect} from "react-redux";
import {getFetchError, getPaginationDetails, getReadableStories} from "../../selectors/story";
import {changeStoriesPage} from "../../actions/story";
import { getLoaderModalStatus, getLoaderStatus } from "../../selectors/loader";
import { doShowModalLoader, doHideModalLoader } from '../../actions/loader';


export class LoaderModal extends React.Component{
constructor(props){
    super(props)
}
componentDidMount() {
  if (this.props.loader === 'show') {
    this.props.applyShowLoaderModal();
  } else if (this.props.loader === 'hide') {
    this.props.applyHideLoaderModal();
  }
}
componentDidUpdate() {
  if (this.props.loader === 'show') {
    this.props.applyShowLoaderModal();
  } else if (this.props.loader === 'hide') {
    this.props.applyHideLoaderModal();
  }
}
render(){

    return (
      <div>
        { (this.props.mLoad === true || this.props.loaderModal ==='show') && (
          <div className="loaderModal">
            <div className="load-bar-modal">
              <div className="bar" />
              <div className="bar" />
              <div className="bar" />
            </div>
          </div>
)}
      </div>

    )
}

    
};


const mapStateToProps = state => ({
    loaderModal:getLoaderModalStatus(state),
    loader: getLoaderStatus(state)
});

const mapDispatchToProps = dispatch => ({
  applyShowLoaderModal: () => dispatch(doShowModalLoader()),
  applyHideLoaderModal: () => dispatch(doHideModalLoader())
});

export default connect(
    mapStateToProps, mapDispatchToProps
)(LoaderModal);

import {
  FETCH_LOCATIONS_LIST, FETCH_LOCATIONS_LIST_SUCCESS,
  FETCH_LOCATIONS_LIST_ERROR,FILTER_ACTIVE_LOCATION
} from '../constants/actionTypes';

export const fetchLocationList = status => ({
  type: FETCH_LOCATIONS_LIST,
  status
})

export const FetchLocationsListSuccess = payload => ({
  type: FETCH_LOCATIONS_LIST_SUCCESS,
  payload
})

export const FetchLocationsListError = error => ({
  type: FETCH_LOCATIONS_LIST_ERROR,
  error
})





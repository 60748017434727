import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Moment from 'react-moment';
import 'moment-timezone';
import {Link} from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';
import { PopUpShareReviewTrigger } from '../Review9/PopUpTrigger';
//import TextTruncate from 'react-text-truncate'; // recommend

// import uniqueId from 'react-html-id';
const Story = ({ story, columns, onArchive, sendEmail, rowId, itemMenuStory, onItemMenuOpen }) => {
  const {
      Assessment_ID,
      Dealer_Name,
      Assessment_Template_Name,
      Program,
      Last_Modified_By,
      StartDate,
      EndDate,
      Status,
      Email_Status
  } = story;

  const toggleOptionView = (e, story) => {
    if (e.currentTarget.classList.contains('open')) {
      e.currentTarget.classList.remove('open');
      document.body.classList.remove('itemmenu-open');
    } else {
      let allItemMenus = document.getElementsByClassName('more-option');
      for(let i=0; i < allItemMenus.length; i++ ) {allItemMenus[i].classList.remove('open') }
       document.body.classList.add('itemmenu-open');
       e.currentTarget.classList.add('open')
    }
   
    if (e.currentTarget.classList.contains('open')) { 
      onItemMenuOpen(story.Assessment_ID)
    } else {
      onItemMenuOpen('');
    }
    e.preventDefault();
    e.stopPropagation();
    
  }

  const closeItemMenu= () => {
    let allItemMenus = document.getElementsByClassName('more-option');
    for(let i=0; i < allItemMenus.length; i++ ) {allItemMenus[i].classList.remove('open') }
    document.body.classList.remove('itemmenu-open');
    onItemMenuOpen('');
  }
  const handleClick = (e, storyId) => {
    if (storyId === itemMenuStory) {
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
  }
 

  const statusClass = classNames({
    'action__status': true,
    'action__status--gray': (Status.toUpperCase() === 'Not Started'.toUpperCase()),
    'action__status--blue': (Status.toUpperCase() === 'In-progress'.toUpperCase() || Status === 'InProgress'.toUpperCase() || Status === 'In Progress'.toUpperCase()),
    'action__status--green': (Status.toUpperCase() === 'Completed'.toUpperCase() || Status.toUpperCase() === 'Complete'.toUpperCase()),
    'action__status--yellow': (
        Status.toUpperCase() === 'Pending 1st'.toUpperCase() || 
        Status.toUpperCase() === 'Pending 2nd'.toUpperCase() || 
        Status.toUpperCase() === 'Reopen'.toUpperCase() 
      ),
      'action__status--gray': (Status.toUpperCase() === 'Archive'.toUpperCase()),
    'action__status--red': (Status.toUpperCase() === 'Deleted'.toUpperCase() || Status.toUpperCase() === 'Delete'.toUpperCase())
    });

    const linkReview = {
      pathname: `/review9/${Assessment_ID}`,
      state: { 
        reviewStatus: {Status},
        programName:{Program}      
      }
 }
   
        let reviewstatus;
        if(Status === 'In-progress'){
            reviewstatus = Status.replace(/-/g, " ");
        }else if(Status === 'Completed'){
            reviewstatus = 'Complete';
        }else {
            reviewstatus = Status;

    }

  
    return (
      <Link className="grid__row-link" id={`Schedule_${  Assessment_ID}`} to={linkReview}  state={{ ReviewStatus: "hello" }} onClick= {(e) => {handleClick(e, story.Assessment_ID)}}>
        <div className="grid__row">
                <div className="row grid__columns-wrapper">
                    {Status === "Deleted" &&                        
                    <hr className="hrred" 
                            
                        />}
            <div className="col-md-6 col-lg-3 grid__column grid__dealer-name grid__order-md-1 mar-md-b-10" id={`dealer-name__row${rowId}`}>
              <span className="grid__labels-mobile">Location</span>
              {Dealer_Name}
            </div>
            <div className="col-md-6 col-lg-2 grid__column grid__template-name grid__order-md-3 grid__order-sm-2 mar-md-b-10" id={`assessment-name__row${rowId}`}>
            <span className="grid__labels-mobile">Review</span>
                      
              <TruncateMarkup lines={2} >
                <div title={Assessment_Template_Name}>
                  {Assessment_Template_Name}
                </div>
               </TruncateMarkup>
                                                                         
            </div>
             <div className = "col-md-6 col-lg-1 col-4 grid__column program grid__order-md-2 grid__order-sm-3 mar-md-b-10" id={`program-name__row${rowId}`}>
               <span className = "grid__labels-mobile"> Program </span> {
                 Program
               } </div>
            <div className="col-md-6 col-lg-2 col-8 grid__column last-updated grid__order-md-5 grid__order-sm-4 mar-md-b-10" id={`last-modified-by__row${rowId}`}>
              <span className="grid__labels-mobile">Updated By</span>
              {Last_Modified_By}
            </div>
            <div className="col-md-6 col-lg-2 col-4 grid__column start-date grid__order-md-4" id={`start-date__row${rowId}`}>
              <span className="grid__labels-mobile">Start Date</span>
              {StartDate && (
              <Moment format="DD-MM-YYYY">
                {StartDate}
              </Moment>
)}
            </div>
            
            {/* <div className="col-md-2 col-4 grid__column end-date">
              <span className="grid__labels-mobile">End Date</span>
              { EndDate && (
              <Moment format="DD-MM-YYYY">
                {EndDate}
              </Moment>
)}

            </div> */}
            <div className="col-md-6 col-lg-2 col-8 grid__column status grid__order-md-7 text-md-r position-static">
               <span className={statusClass}>{reviewstatus.toUpperCase()}</span>
               {/* make open class toggle to open dropdown in more-option class */}
               <div className="share-overlay-wrap">
                <span className="more-option" id={`more-option-${Assessment_ID}`} onClick={(e) =>toggleOptionView(e, story)}>
                      <img src="../images/icons-more.svg" />
                </span>
                <div className="more-menu high-z-index">
                    <div className="relative menu-wraper">
                        <ul>
                            {PopUpShareReviewTrigger((<li id="" className="sharereview" >Share Review</li>),
                            story, undefined, closeItemMenu, true)}
                        </ul>
                    </div>
                </div>
                {story.Assessment_ID === itemMenuStory && <div className="item-menu-background" onClick={closeItemMenu}></div>}
               </div>
            </div>
            {/* <div className="start-assessment d-none d-sm-none d-md-block">
                  <button className="btn btn-primary">Start Assessment</button>
              </div> */}
          </div>
        </div>
      </Link>
  );
}

export default connect(
  null,
  null
)(Story);
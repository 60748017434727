import axios from "axios";
import { calculateOneYrFromNov, calculateStartDate, formatDate, formatMomentDate } from "../constants/utilityfunctions";
import { ADD_SOPDOCS_BASE_URL } from '../api/api_uploadSOP';
import { getUploadSOPDocQuery, getFormDataForUploadSOP } from "../selectors/uploadSOP";



export const uploadSOPDocAction = (uploadEvent, selectedLocation,sopdropselect, programselect, selectedFileForUpload, expiry_date) => {
      const url = getUploadSOPDocQuery (ADD_SOPDOCS_BASE_URL, selectedLocation,sopdropselect, programselect, selectedFileForUpload, expiry_date);
      const formData = getFormDataForUploadSOP(selectedFileForUpload);
      


      let result ="";
    // try {
        // reader.onload = event => {
            return axios
          .post(url, formData)
          .then(res => {
            return res;
          })
          .catch(error => {
            console.log(
              `There is an Error in the Upload Document Service${ 
                error.toString()}`
            );
            result = error;
            return error;
        });

  }



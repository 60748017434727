import axios from 'axios';

let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}
export const fetchScoringList = (query, payload) => {
    let modified_by = (payload !== undefined && payload.Modified_By !== undefined && payload.Modified_By !== null) ?
        payload.Modified_By : "";
    let params = query !== undefined && query !== null ? query.postParams : "";
    return axios.post(`${BASE_URL}/ScoreListing?${query.query}`, {
        "FilterBy": params,
        "Modified_By": modified_by
    });

}

export const fetchTFLScoringList = (query, payload) => {
    let modified_by = (payload !== undefined && payload.Modified_By !== undefined && payload.Modified_By !== null) ?
        payload.Modified_By : "";
        let Region = (payload !== undefined && payload.Region !== undefined && payload.Region !== null) ?
        payload.Region : " ";    
        let params = query !== undefined && query !== null ? query.postParams : "";
    return axios.post(`${BASE_URL}/TFLScoreListing?${query.query}`, {
        "FilterBy": params,
        "Modified_By": modified_by,
        "Region": Region
    });

}


export const fetchPMAScoreBreakdown = (query) => {
    let dealer_id, history_id;
    dealer_id = (query !== undefined && query.dealer_id !== undefined && query.dealer_id !== null) ?
        query.dealer_id : "";
    history_id = (query !== undefined && query.history_id !== undefined && query.history_id !== null) ?
        query.history_id : "";
    return axios.get(
        `${BASE_URL}/PMAScoreBreakdown?dealer_id=${dealer_id}&history_id=${history_id}`
    );
}


export const approveScore = payload => {
    let modified_by, history_id;
    modified_by = (payload !== undefined && payload.Modified_By !== undefined && payload.Modified_By !== null) ?
        payload.Modified_By : "";
    history_id = (payload !== undefined && payload.History_Id !== undefined && payload.History_Id !== null) ?
        payload.History_Id : "";
    let requestPayload = {
        "History_Id": history_id,
        "Modified_By": modified_by
    }
    if (requestPayload.History_Id !== "" && requestPayload.Modified_By !== "") {
        return axios.post(`${BASE_URL}/ChangeScoreStatus?status_id=1`, requestPayload);
    }
}

export const resetScore = payload => {
    let modified_by, history_id;
    modified_by = (payload !== undefined && payload.Modified_By !== undefined && payload.Modified_By !== null) ?
        payload.Modified_By : "";
    history_id = (payload !== undefined && payload.History_Id !== undefined && payload.History_Id !== null) ?
        payload.History_Id : "";
    let requestPayload = {
        "History_Id": history_id,
        "Modified_By": modified_by
    }
    if (requestPayload.History_Id !== "" && requestPayload.Modified_By !== "") {
        return axios.post(`${BASE_URL}/ChangeScoreStatus?is_reset=1`, requestPayload);
    }
}

export const fetchScoreOverriedData = (query) => {
    let history_id = (query !== undefined && query !== null) ?
        query : "";
    // history_id = 1;
    return axios.get(
        `${BASE_URL}/PMAOverride?history_id=${history_id}`
    );
}

export const deleteSelectedOverrideFiles = (query) => {
    let PMAHistory_id = query !== undefined && query !== null ? query.PMAHistory_Id : "";
    let Doc_Id = query !== undefined && query !== null ? query.filesToBeDeleted : "";
    return axios.delete(`${BASE_URL}/DeletePMADocument?pmahistory_id=${PMAHistory_id}&pmadoc_id=${Doc_Id}`);
}


export const savePMAOverRideScore = (query) => {
    let requestPayload = query.payload !== undefined && query.payload !== null ? query.payload : "";
    return axios.post(`${BASE_URL}/PMAOverRideScore`, requestPayload);
}
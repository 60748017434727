import {all, call, put, select} from 'redux-saga/effects';
import {
    postQuestionDocument ,
    deleteQuestionsDocument,
    postQuestionRecommendation,
    updateQuestionRecommendation,
    getDealerInfoById,
    postQuestionSopDocument,
    DealerSectionScore,
    TFLScoreListing,
    Score,
    fetchStartReview
} from '../api/api_review';
import {doHideLoader, doShowLoader, doHideModalLoader, doShowModalLoader} from "../actions/loader";
import { doSuccess, fetchDealerInfoByIdSuccess, fetchDealerInfoByIdError, doFetchScoreData, doFetchScoreDataSuccess, doFetchScoreDataError,doFetchSectionScoreData,doFetchSectionScoreDataSuccess,doFetchSectionScoreDataError,doFetchtflscorelistingData,doFetchTflscorelistingDataSuccess,doFetchTflscorelistingDataError} from '../actions/review'
import {postReview, fetchTfl18StoryFromDelearId} from "../api/story";
import { doStartTFL18Success, doStartTFL18Error } from '../actions/tfl18reviews';
import {doFetchStartReviewSuccess,doFetchStartReviewError} from '../actions/startReview';
import Moment from 'moment';
import {doPostAuditLogData} from "../actions/auditLog";

export function* handlePostQuestionsDocument (action) {
    yield put(doShowLoader());
    try {
        const reviewState = yield select((state)=> state.reviewState);
        const [result] = yield all([
            call(postReview, reviewState),
            call(postQuestionDocument, action.payload)
        ]);
         
         yield put(doHideLoader());
         yield put(doSuccess(result))
    } catch (error) {
        yield put(doHideLoader());
    }
}

export function* handleDeleteQuestionsDocument (action) { 
    yield put(doShowLoader());
    try {
        const reviewState = yield select((state)=> state.reviewState);
        yield call(postReview, reviewState);
        const result = yield call(deleteQuestionsDocument, action.payload);
        yield put(doSuccess(result))

        yield put(doHideLoader());
    } catch (error) {
        yield put(doHideLoader());
    }
}
export function* handlePostQuestionsSop (action) {
    yield put(doShowLoader());
    try {
        const reviewState = yield select((state)=> state.reviewState);
        yield call(postReview, reviewState);
        const result = yield call(postQuestionSopDocument, action.payload,action.params);
        yield put(doHideLoader());
        yield put(doSuccess(result))
    } catch (error) {
        yield put(doHideLoader());
    }
}
export function* handlePostQuestionsRecommendation (action) { 
    yield put(doShowLoader());
    try {
        const reviewState = yield select((state)=> state.reviewState);
        yield call(postReview, reviewState);
        const result = yield call(postQuestionRecommendation, action.payload);
        yield put(doSuccess(result))
        yield put(doHideLoader());
    } catch (error) {
        yield put(doHideLoader());
    }
}
export function* handleUpdateQuestionsRecommendation (action) {
    yield put(doShowLoader());
    try {
        const reviewState = yield select((state)=> state.reviewState);
        yield call(postReview, reviewState);
        const result = yield call(updateQuestionRecommendation, action.payload);

        yield put(doSuccess(result))
        yield put(doHideLoader());
    } catch (error) {
        yield put(doHideLoader());
    }
}
/* A generator function to call the api function to fetch tfl18 assesment data from dealer id */
export function* handleFetchTFL18Assessment (action) { 
    yield put(doShowModalLoader());
    try {
        const result = yield call(fetchTfl18StoryFromDelearId, action.payload,action.userDetails);
        yield put(doStartTFL18Success(result))

        yield put(doHideModalLoader());
    } catch (error) {
        yield put(doStartTFL18Error(error));
        yield put(doHideModalLoader());
    }
}

/* get dealer info based on dealer ID */
export function* handleFetchDealerInfoById (action) {
    yield put(doShowLoader());

    try {
        const result = yield call(getDealerInfoById,action.payload);
        yield put(fetchDealerInfoByIdSuccess(result))
        yield put(doHideLoader());

    } catch (error) {
        yield put(fetchDealerInfoByIdError(error))
        yield put(doHideLoader());


    }
}

/* get dealer info based on dealer ID */
export function* handleGetScoreData(action) {
    yield put(doShowLoader());

    try {
        const result = yield call(DealerSectionScore, action.payload);
        yield put(doFetchScoreDataSuccess(result))
        yield put(doHideLoader());

    } catch (error) {
        yield put(doFetchScoreDataError(error))
        yield put(doHideLoader());
    }
}
/* get tflscoreListing */
export function* TFLScoreListingData(action) {
    yield put(doShowLoader());

    try {
        const result = yield call(TFLScoreListing, action.payload);
        yield put(doFetchTflscorelistingDataSuccess(result))
        yield put(doHideLoader());

    } catch (error) {
        yield put(doFetchTflscorelistingDataError(error))
        yield put(doHideLoader());
    }
}
export function* handleGetUpdatecoreData(action) {
    yield put(doShowLoader());

    try {
        const result = yield call(Score, action.payload);
        yield put(doFetchSectionScoreDataSuccess(result))
        yield put(doHideLoader());

    } catch (error) {
        yield put(doFetchSectionScoreDataError(error))
       yield put(doHideLoader());
    }
}

/* startReview */

export function* handleFetchStartReview (action) {

    yield put(doShowModalLoader());
    try {
        if (!action.payload.userID) {
            throw {error: 'user details not available error'};
        }
        const result = yield call(fetchStartReview, action.payload);
        const { history } = action.payload;

        if (result.data.Success) {
            yield put(doHideModalLoader());
            history.push(`/review9/${result.data.Id}`);
            yield put(doFetchStartReviewSuccess(result))
            const payload = {
                LogMessage: action.payload.userID + ' started ' +
                 result.data.Id + ' for ' + action.payload.program + ' ' + action.payload.review
                 + ' for ' + action.payload.location
                + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.',
                AuditLogModule: "Conduct Reviews And Approvals",
                AcionAPISource: "Start Review",
                CreatedBy: action.payload.userID,
                CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
            }
           yield put(doPostAuditLogData(payload));
        }     
        if (result.data.Success==false) {
           alert(result.data.Message)
           yield put(doHideModalLoader());
        }
    } catch (error) {
        yield put(doFetchStartReviewError(error));
        yield put(doHideModalLoader());
    }
}





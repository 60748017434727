import React, { Component } from 'react';
import {connect} from "react-redux/";
import {doFetchStories, doResetSortAndFilter, doSortStories} from "../../actions/story";
import {doShowLoader,doHideLoader} from "../../actions/loader";
import Stories from "./Stories";
import Filter from "../Common/Filter";
import {toggleSidebar} from "../../actions/header";
import {getUnAuthResponse} from "../../selectors/story";
import UnauthorizePage from "../Common/UnauthorizePage"

export class Schedule extends Component {


    componentWillMount(){
        //console.log(this.props.reviewType);
        // Review list being fetched from filters, so commenting below lines
        //this.props.onResetSortAndFilters(this.props.reviewType);

        //this.props.onFetchStories('schedule');

    }

    componentDidMount() {
        document.title = "Reviews";
        //this.props.onResetSortAndFilters('schedule');
        document.body.classList.remove('nav-open');
        document.body.classList.remove('filter-open');
    }

    sendEmail(event, story) {
        this.props.history.push(
           {
               pathname:'/ShareReview',
               state: {
                    reviewType:  story.Assessment_Template_Name,
                    reviewId : story.Assessment_ID,
                    assessorName:  story.Last_Modified_By,
                    dealerLocation: story.Dealer_Name,
                    dealer_ID: story.Dealer_ID,
                    dealerEmail: story.Email,
                    assessmentId: story.Assessment_ID,
                    mine: true,
                    isReadOnlyMode: this.props.review ? this.props.review.ActiveDealer : false,
                    location: this.props.location.state
               }
            });
           event.preventDefault();
    }

    render() {
        return (
            <div>
              {this.props.unauthUser && this.props.unauthUser.StatusCode===401 ? (
                 <UnauthorizePage msg={this.props.unauthUser.Message} />
              ) : (
            <div>
            <Stories sendEmail={this.sendEmail.bind(this)} />
            <Filter
              reviewType="schedule"
              filtersToDisplay={{
                        dealer_list: true,
                        assessment_template_lookup: true,
                        status_lookup: true,
                        last_modified_by: true,
                        start_date: false,
                        end_date: false,
                        program_list: true,
                        sap_dealer_code: false,
                        year: false,
                        investigation_title: false,
                        stage: false,
                        closed_on_date: false,
                        endyear:true,
                    }}
                />
            </div>
            )}
          </div>
        );
    }
}

const mapStateToProps = state => ({
   unauthUser:getUnAuthResponse(state)
});

const mapDispatchToProps = (dispatch) => ({
    onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onFetchStories: reviewType => dispatch(doFetchStories(reviewType)),
   /* onShowLoader: query => dispatch(doShowLoader(query)), */
});



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedule);

import React from 'react';
import "babel-polyfill";
import Header from './Header';
import Navbar from './Navbar';
import Schedule from '../Schedule/Schedule';
import Review from '../Review9/Review';
import { Action } from '../Action/Schedule';
import ManageDealerSOP from "../UploadSOPDocs/ManageDealerSOP";
import UserGuid from '../UserGuid/UserGuid';
import ReviewSuccess from '../Review9/ReviewSuccess';
import ShareReview from '../Review9/ShareReview';
import Faqs from '../FAQs/Faqs';
import Loader from "../Common/Loader";
import PageNotFound from "../Common/PageNotFound"

import '../App.css';
import { Route, Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import Home from "./Home";
import UnAuthCallBack from './UnAuthCallBack';

import TflScore from '../TflScore/TflScore';
import TflScorebreakdown from '../TflScore/TflScore-breakdown';

import TflScoreView from '../TflScore/TflScoreView';

import TflScoreviewbreakdown from '../TflScoreview/TflScoreview-breakdown';
import { getAccessPermission } from "../../utils/utils";
import BlankPage from './BlankPage';
import Investigation from '../Investigation/investigation';
import UnauthorizePage from './UnauthorizePage';

export const Routes = (...props) => {
    const isFetching = props[0].isFetching;
    let isTflAccessForPowerUser = false;
    let isInvestigationAccessForPowerUser = false;
    let isInvestigationAccessForRM = false;
    let isTflSectionScoreAccessForPowerUser = false;
    let isTflSectionScoreAccessForAssessor = false;
    let isTflSectionScoreAccessForRM= false;
    if (!isFetching) {
        isTflAccessForPowerUser = props[0].userRoles ? getAccessPermission(props[0].userRoles.data, 'PowerUser_TFLSAPScores') : false;
        isInvestigationAccessForPowerUser = props[0].userRoles ? getAccessPermission(props[0].userRoles.data, "Investigations_PowerUser_Add") : false;
        isInvestigationAccessForRM = props[0].userRoles ? getAccessPermission(props[0].userRoles.data, "Investigations_RegionalManager_Add") : false;
        isTflSectionScoreAccessForPowerUser = props[0].userRoles ? getAccessPermission(props[0].userRoles.data, 'PowerUser_SectionScore_View') : false;
        isTflSectionScoreAccessForAssessor = props[0].userRoles ? getAccessPermission(props[0].userRoles.data, "Assessor_SectionScore_View") : false;
        isTflSectionScoreAccessForRM = props[0].userRoles ? getAccessPermission(props[0].userRoles.data, "RM_SectionScore_View") : false;

        
    }
    return (<div>
        <Header auth={props[0].auth} history={props[0].history} />
        <main role="main" className="main-wrapper">
            <Navbar />
            <Switch>
                <Route path='/implicit/callback' component={UnAuthCallBack} />}/>
                    <SecureRoute path='/protected' component={Home} />
                <SecureRoute exact path="/" component={Schedule} />
                <SecureRoute path="/home" component={Schedule} />
                <SecureRoute path="/actions" component={Action} />
                <SecureRoute path="/review9/:id" render={props => <Review tflType="tfl9" {...props} />} />

                <SecureRoute path="/UploadSOP" component={ManageDealerSOP} />
                {isTflAccessForPowerUser ?
                    <SecureRoute path="/TflScore" component={TflScore} /> : 
                    <SecureRoute path="/TflScore" component={UnauthorizePage}/>}
                {isTflAccessForPowerUser ?
                    <SecureRoute path="/TflScore-breakdown" component={TflScorebreakdown} render={props => <TflScorebreakdown {...props} />} />
                    : <SecureRoute path="/TflScore-breakdown" component={UnauthorizePage} />}

                {/*{isTflSectionScoreAccessForPowerUser || isTflSectionScoreAccessForAssessor || isTflSectionScoreAccessForRM ?
                    <SecureRoute path="/TflScoreview" component={TflScoreview} /> :
                    <SecureRoute path="/TflScoreview" component={UnauthorizePage} />}
                {isTflSectionScoreAccessForPowerUser || isTflSectionScoreAccessForAssessor || isTflSectionScoreAccessForRM ?
                    <SecureRoute path="/TflScoreview-breakdown" component={TflScoreviewbreakdown} render={props => <TflScoreviewbreakdown {...props} />} />
                    : <SecureRoute path="/TflScoreview-breakdown" component={UnauthorizePage} />}
                    */}

                {isTflSectionScoreAccessForPowerUser || isTflSectionScoreAccessForAssessor || isTflSectionScoreAccessForRM ?
                    <SecureRoute path="/sectionscoreview" component={TflScoreView} /> : 
                    <SecureRoute path="/sectionscoreview" component={UnauthorizePage}/>}
					  {isTflSectionScoreAccessForPowerUser || isTflSectionScoreAccessForAssessor || isTflSectionScoreAccessForRM ?
                    <SecureRoute path="/sectionscoreview-breakdown" component={TflScoreviewbreakdown} render={props => <TflScoreviewbreakdown {...props} />} />
                    : <SecureRoute path="/sectionscoreview-breakdown" component={UnauthorizePage}/>}

                {/* {isTflSectionScoreAccessForPowerUser || isTflSectionScoreAccessForAssessor || isTflSectionScoreAccessForRM ?
                    <SecureRoute path="/sectionscoreview" component={TflScoreview} /> : 
                    <SecureRoute path="/sectionscoreview" component={UnauthorizePage}/>}
					  {isTflSectionScoreAccessForPowerUser || isTflSectionScoreAccessForAssessor || isTflSectionScoreAccessForRM ?
                    <SecureRoute path="/sectionscoreview-breakdown" component={TflScoreviewbreakdown} render={props => <TflScoreviewbreakdown {...props} />} />
                    : <SecureRoute path="/sectionscoreview-breakdown" component={UnauthorizePage}/>} */}

                <SecureRoute path="/ReviewSuccess" render={props => <ReviewSuccess tflType="tfl9" {...props} />} />
                <SecureRoute path="/Tfl18ReviewSuccess" render={props => <ReviewSuccess tflType="tfl18" {...props} />} />
                <SecureRoute path="/ShareReview" component={ShareReview} />
                <SecureRoute path="/Faqs" component={Faqs} render={props => <Faqs {...props} />} />
                {isInvestigationAccessForPowerUser || isInvestigationAccessForRM ?
                    <SecureRoute path="/Investigation" component={Investigation} 
                    render={props => <Investigation {...props}
                     isInvestigationAccessForPowerUser={isInvestigationAccessForPowerUser}
                      isInvestigationAccessForRM={isInvestigationAccessForRM} />} /> 
                    : <SecureRoute path="/Investigation" component={UnauthorizePage} />}
                <SecureRoute path="**" component={PageNotFound} />

            </Switch>
            {/** In case user changes the url manually in the browser and
                 it is an invalid one then below component will be rendered*/}
            {/*<Redirect to="/home"/>*/}
            {/* <Route component={InvalidURL} /> */}
        </main>
        <Loader />
    </div>


    )

}

export const RoutesWithoutPermission = (...props) => {
    return (
        <div>
            <Header auth={props[0].auth} history={props[0].history} />
            <main role="main" className="main-wrapper">
                <Navbar />
                <Switch>
                    <Route path='/implicit/callback' component={UnAuthCallBack} />}/>
                <SecureRoute path='/protected' component={Home} />
                    <SecureRoute exact path="/" component={Schedule} />
                    <SecureRoute path="/home" component={Schedule} />
                    <SecureRoute path="/actions" component={Action} />
                    <SecureRoute path="/UploadSOP" component={ManageDealerSOP} />
                    <SecureRoute path="/Faqs" component={Faqs} render={props => <Faqs {...props} />} />
                    <SecureRoute path="**" component={BlankPage} />
                </Switch>
            </main>
            <Loader />
        </div>
    );
}
export const RoutesWithGuide = (...props) => {
    return (
        <Switch>
            <Route path='/implicit/callback' component={UnAuthCallBack} />
            <SecureRoute exact path="/" component={UserGuid} />
            <SecureRoute exact path="/home" component={UserGuid} />
            <SecureRoute exact path="/actions" component={UserGuid} />
            <SecureRoute exact path="/review9/:id" component={UserGuid} />
            <SecureRoute exact path="/review18/:id" component={UserGuid} />
            <SecureRoute exact path="/tfl8schedule" component={UserGuid} />
            <SecureRoute exact path="/UploadSOP" component={UserGuid} />
            <SecureRoute exact path="/Faqs" component={UserGuid} />
            <SecureRoute exact path="/TflScore" component={UserGuid} />
            <SecureRoute exact path="/TflScoreview" component={UserGuid} />
            <SecureRoute path="/Investigation" component={UserGuid} />
        </Switch>

    );
}

export default Routes;

import {
    SCORE_STORIES_ADD,
  SCORE_STORIES_FETCH_ERROR,
  ADD_SCORE_LIST,
  SCORE_LIST_ERRORS,
  ADD_BREAKDOWN_SCORE_LIST,
  BREAKDOWN_SCORE_LIST_ERRORS,
  ADD_SCOREOVERRIDE_DATA,
  SAVE_SCOREOVERRIDE_RESPONSE,
  DELETE_SCOREOVERRIDE_RESPONSE,
  TFL_SCORE_STORIES_ADD,
  TFL_SCORE_STORIES_FETCH_ERROR,
  ADD_TFL_SCORE_LIST,
  TFL_SCORE_LIST_ERRORS,

} from '../constants/actionTypes';

const INITIAL_STATE = {
    scores: [],
    tflcsores : [],
  count: 0,
  error: null,
  breakDownScoreListData: [],
    scoreListData: [],
    tflscoreListData: [],
  scoreOverrideObj: {}
};

const applyAddScoreStories = (state, action) => {
  const scores = [...action.scores];
 
  return ({
    ...state,
    scores,
    count: action.count,
    error: null,
  })
};



const applyFetchScoreErrorStories = (state, action) => ({
  ...state,
  scores: [],
  error: action.error,
});

const applyAddScoreList = (state, action) => {

   const scoreListData = [ ...action.scoreListData];
  return ({
    ...state,
    scoreListData,
    count: action.count,
    error: null,
  })
};


const applyAddTFLScoreStories = (state, action) => {
    const tflsores = [...action.scores];

    return ({
        ...state,
        tflsores,
        count: action.count,
        error: null,
    })
};



const applyFetchTFLScoreErrorStories = (state, action) => ({
    ...state,
    tflsores: [],
    error: action.error,
});

const applyAddtFLScoreList = (state, action) => {

    const TFLscoreListData = [...action.TFLscoreListData];
    return ({
        ...state,
        TFLscoreListData,
        count: action.count,
        error: null,
    })
};


const applyFetchTFLScoreListErrors = (state, action) => ({
    ...state,
    TFLscoreListData: [],
    error: action.error,
});


const applyAddBreakDownScoreList = (state, action) => {

    const breakDownScoreListData = [ ...action.breakDownScoreListData];
  return ({
    ...state,
    breakDownScoreListData,
    count: action.count,
    error: null,
  })
};

const applyAddScoreOverrideData = (state, action) => {
  const scoreOverrideObj = {...action.scoreOverrideObj};
  return ({
    ...state,
    scoreOverrideObj,
    error: null,
  })
};

const applySaveScoreOverrideResp = (state, action) => {
  let saveResponse = action.saveResponse;
  return ({
    ...state,
    saveResponse,
    error: null,
  })
};

const applyDeleteScoreOverrideResp = (state, action) => {
  let deleteResponse = action.deleteResponse;
  return ({
    ...state,
    deleteResponse,
    error: null,
  })
};

const applyFetchScoreListErrors = (state, action) => ({
  ...state,
  scoreListData: [],
  error: action.error,
});

const applyFetchBreakdownScoreErrors = (state, action) => ({
  ...state,
  breakDownScoreListData: [],
  error: action.error,
});

function scoreReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SCORE_STORIES_ADD: {
      return applyAddScoreStories(state, action);
    }
    case SCORE_STORIES_FETCH_ERROR: {
      return applyFetchScoreErrorStories(state, action);
    }
    case ADD_SCORE_LIST: {
      return applyAddScoreList(state, action);
    }
    case SCORE_LIST_ERRORS: {
      return applyFetchScoreListErrors(state, action);
    }
    case ADD_BREAKDOWN_SCORE_LIST: {
      return applyAddBreakDownScoreList(state, action);
    }
    case BREAKDOWN_SCORE_LIST_ERRORS: {
      return applyFetchBreakdownScoreErrors(state, action);
    }
    case ADD_SCOREOVERRIDE_DATA: {
      return applyAddScoreOverrideData(state, action);
    }
    case SAVE_SCOREOVERRIDE_RESPONSE: {
      return applySaveScoreOverrideResp(state, action);
    }
    case DELETE_SCOREOVERRIDE_RESPONSE: {
      return applyDeleteScoreOverrideResp(state, action);

    }
    default: return state;
  }
}


function tflScoreReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TFL_SCORE_STORIES_ADD: {
            return applyAddTFLScoreStories(state, action);
        }
        case TFL_SCORE_STORIES_FETCH_ERROR: {
            return applyFetchTFLScoreErrorStories(state, action);
        }
        case ADD_TFL_SCORE_LIST: {
            return applyAddtFLScoreList(state, action);
        }
        case TFL_SCORE_LIST_ERRORS: {
            return applyFetchTFLScoreListErrors(state, action);
        }
        //case ADD_BREAKDOWN_SCORE_LIST: {
        //    return applyAddBreakDownScoreList(state, action);
        //}
        //case BREAKDOWN_SCORE_LIST_ERRORS: {
        //    return applyFetchBreakdownScoreErrors(state, action);
        //}
        //case ADD_SCOREOVERRIDE_DATA: {
        //    return applyAddScoreOverrideData(state, action);
        //}
        //case SAVE_SCOREOVERRIDE_RESPONSE: {
        //    return applySaveScoreOverrideResp(state, action);
        //}
        //case DELETE_SCOREOVERRIDE_RESPONSE: {
        //    return applyDeleteScoreOverrideResp(state, action);

        //}
        default: return state;
    }
}

export { scoreReducer, tflScoreReducer };
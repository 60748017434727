import React from 'react';


class DeletePopup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
      
        };
    }



    render() {
      
        return (
            <div className="delete-popup small-popup">
                        <div className="popup__wrapper">
                            <div className="popup__body">
                                <div className="form-row">
                                        <div className="col-12">
                                            <div className="delete-warning">
                                                <img src="./../images/error.svg"/>
                                            </div>
                                            <p className="text-center text-bold m-t-20">Are you sure you want to delete?</p>
                                            <div className="buttons-wrapper text-center m-t-25">
                                                <a href="#" className="m-r-15 text-red" onClick={this.props.onClose}>No</a>
                                                <button value="0" type="button" class="btn btn-gray" onClick={(e)=>{
                                                     setTimeout(() => {
                                                        this.props.onClose()
                                                       }, 1000);
                                                    this.props.deleteDoc(e)}}>Yes</button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                      
                    </div>
            </div>
        );
    }
}



export default DeletePopup;

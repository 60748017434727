import React from 'react';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import 'react-widgets/dist/css/react-widgets.css';
import Moment from 'moment'
import { multiselectOptions } from "../../constants/uploadSOPConstants";
import Select from "react-select";
import { connect } from "react-redux";
import { getCurrentUserDetails } from "../../constants/utilityfunctions"
import { getToken, getTokenInfo } from '../../utils/utils';
import {
    doFetchInvestigationCreateData,
    doPostNewInvestigationData,
    doFetchLookUpStage,
    doFetchLookUpStatus,
    doFetchInvestigationRecords,
    doDeleteInvestigationFiles,
    doPostUpdateInvestigationData
} from "../../actions/Investigations";
import {
    getInvestigationCreateError,
    getInvestigationCreateData,
    fetchNewlySavedInvestigationResp,
    getLookUpStatusData,
    getLookUpStageData,
    getLookUpStatusError,
    getLookUpStageError,
   // fetchDeleteInvestigationResp,
    fetchUpdateInvestigationResp
} from '../../selectors/Investigations';
import { getCurrentUser } from "../../selectors/dropdown";
import { validateUploadedFile } from "../../FormValidators/CreateActionValidator";
import { getBase64 } from "../../utils/utils";
import is from 'is_js';
import { fieldErrorDiv } from "../../constants/uploadSOPConstants";
import { getFullDateFromCurrentYear } from "../../utils/utils";
import LoaderModal from "../Common/LoaderModal";
import {

    doShowModalLoader,
    doHideModalLoader
} from "../../actions/loader";
import { getLocationsList } from '../../selectors/uploadSOP';
import { fetchLocationList } from "../../actions/locationsList";
import { doPostAuditLogData } from "../../actions/auditLog";
import CustomDateTimePicker from '../Common/CustomDateTimePicker';

class InvestigationOverlay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMsg: false,
            selectedLocation: null,
            selectedStage: this.props.investigationObj && this.props.investigationObj.Stage ? this.props.investigationObj.Stage : null,
            selectedStatus: this.props.investigationObj && this.props.investigationObj.Status ? this.props.investigationObj.Status : null,
            titleValue: "",
            summaryValue: this.props.investigationObj && this.props.investigationObj.Summary ? this.props.investigationObj.Summary : '',
            commentValue: "",
            selectedFileForUpload: new Map(),
            fileUploadValid: false,
            createDate: new Date(),
            saveBtnClicked: false,
            successful: false,
            hideTimer: true,
            logMessage: '',
            addFileMessage: ''
        };
    }
   
    componentDidMount() {

        const location_payload = {
            is_active: '1',
            program_id: 1
        }
        this.props.onFetchLocationList(location_payload);
        this.props.onFetchInvestigationCreateData();
        this.props.onFetchLookUpStage();
        this.props.onFetchLookUpStatus();
        this.hideModal();

        if (this.props.investigationType === "Update") {
            let stateObj = {
                id: this.props.investigationObj.Stage_Id,
                label: this.props.investigationObj.Stage,
                value: this.props.investigationObj.Stage,
            }
            let statusObj = {
                id: this.props.investigationObj.Status_Id,
                label: this.props.investigationObj.Status,
                value: this.props.investigationObj.Status,
            }
            let createdOn = this.props.investigationObj.CreatedOn;
            let titleValue = this.props.investigationObj.Title;
            let summaryValue = this.props.investigationObj.Summary ? this.props.investigationObj.Summary : '';
            this.setState({
                selectedStage: stateObj,
                selectedStatus: statusObj,
                createDate: new Date(Moment(createdOn, "YYYY-MM-DD", "DD-MM-YYYY")),
                titleValue: titleValue,
                summaryValue: summaryValue
            })
        }

    }

    componentDidUpdate(newProps) {
        if (this.props.saveResponse !== undefined && this.props.saveResponse !== null && this.props.saveResponse.hasOwnProperty("Success") && this.state.saveBtnClicked) {
            let respObj = this.props.saveResponse;
            if (!respObj.Success) {
                this.setState({
                    showMsg: true,
                    successful: respObj.Success,
                    saveBtnClicked: false,
                }, function () {
                    setTimeout(() => {
                        this.setState({
                            showMsg: false
                        });
                    }, 5000);
                });
            }
        }
        let saveObj = newProps.investigationType === "Create" ? newProps.saveResponse : newProps.saveUpdateResponse;
        if (saveObj !== undefined && saveObj !== null && saveObj.hasOwnProperty("Success") && this.state.saveBtnClicked) {
            this.setState({
                showMsg: true,
                successful: saveObj.Success,
                saveBtnClicked: false
            }, function () {
               if(newProps.investigationType === "Create") {
                const labelName = newProps.investigationType === "Create" ? this.state.selectedLocation.label : this.props.investigationObj.Dealer_Name;
                const currentUser = this.props.currentUser ?
                    this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                       + '(' + this.props.currentUser.employeeNumber + ')' : 'default';   
                  
                   let msg = getCurrentUserDetails() + ' created Investigation ' + saveObj.Investigation_Id + ' for ' + labelName + ' with Title ' + this.state.titleValue +
                    ' and Description ' + this.state.summaryValue + ' as Stage ' +
                    this.state.selectedStage.value + ' and Status ' + this.state.selectedStatus.value + ' and Created On date to ' +
                       Moment(this.state.createDate).format('HH:MM DD MMM YYYY');                   
                let tempMsgForComment = this.state.commentValue ? ' and Comments ' + this.state.commentValue : '';
                let tempMsgForDate = ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
                msg = msg + tempMsgForComment + this.state.addFileMessage + tempMsgForDate;
                const reqPayload = {
                    LogMessage: msg,
                    AuditLogModule: "Investigation",
                    AcionAPISource: "Create Investigation",
                    CreatedBy: getCurrentUserDetails(),
                    CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
                }
                this.props.doPostAuditLogData(reqPayload);
               }
                setTimeout(() => {
                    this.setState({
                        showMsg: false
                    }, function () {
                        if (saveObj.Success) {
                            this.props.onClose();
                            const payload = {
                                "Modified_By": getCurrentUserDetails()
                            }
                           // this.props.onFetchInvestigationRecords('Investigation', payload);
                               //   window.location.reload();
                           }
                       });
               }, 5000);
                });
        }
    }

    hideAlert() {
        this.setState({
            showMsg: false,
        });
    }

    hideModal() {
        setTimeout(() => {
            this.setState({
                hideTimer: false
            });
        }, 5000);
    }

    onPastePress = (e) => {
        e.preventDefault();
    }

    onKeyPress = (e) => {
        var inputValue = e.target.value;
        var keyValue = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (inputValue.length > 0) {
            var regvalue = inputValue.substr((inputValue.length - 1), 1) + keyValue;
            var SpecialCharExists = false;
            var setOfStrings = ['\\*', '--', '?=', ';', '%%', '[^'];
            for (var i = 0; i < setOfStrings.length; i++) {
                if (regvalue.indexOf(setOfStrings[i]) != -1)
                    SpecialCharExists = true;
            }
            if (SpecialCharExists) {
                e.preventDefault();
                return false;
            }

            if (e.target.selectionStart == 0) {
                var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
                if (Specialcharacters.test(keyValue) || e.which === 32) {
                    e.preventDefault();
                    return false;
                }

            }

            return true;
        }
        if (inputValue.length == 0) {
            var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
            if (Specialcharacters.test(keyValue)) {
                e.preventDefault();
                return false;
            }
            if (e.which === 32) {
                e.preventDefault();
                return false;
            }


        }
        return true;
    }
    handleOnSelectLocation(e, dropdown) {
        const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
        switch (dropdown) {
            case "LOCATION": {
                return this.setState({
                    selectedLocation: e
                })
            }
            case "STAGE": {
                if (this.props.investigationType === "Update") {
                    this.setState({
                        logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                            this.props.investigationObj.Dealer_Name + ' with Stage ' + e.label + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
                    });
                }
                return this.setState({
                    selectedStage: e
                })
            }
            case "STATUS": {
                if (this.props.investigationType === "Update") {
                    this.setState({
                        logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                            this.props.investigationObj.Dealer_Name + ' with Status ' + e.label + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
                    });
                }
                return this.setState({
                    selectedStatus: e
                })
            }
            default: return this.setState({
                selectedLocation: null,
                selectedStatus: null,
                selectedStage: null
            });
        }


    }


    handleTitleChange(e) {
        this.setState({
            titleValue: e.target.value
        });
        if (this.props.investigationType === "Update") {
            const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
            this.setState({
                logMessage: getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' with Title ' + e.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
    }

    handleSummaryChange(e) {
        this.setState({
            summaryValue: e.target.value
        });
        if (this.props.investigationType === "Update") {
            const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';

            this.setState({
                logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' with Description ' + e.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
        var getPastedata= e.target.value;
        var splChars = "*|,\":<>[]{}`\';()@&$#%";
        if (getPastedata.length > 0) {
            var targetId = e.target.id;
            if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
                window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");          
                setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
        } 
    }   

    }
    titleCopyPaste(e){
        var getPastedata= e.target.value;
        var splChars = "*|,\":<>[]{}`\';()@&$#%";
        if (getPastedata.length > 0) {
            var targetId = e.target.id;
            if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
                window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
                setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
        } 
    }   
    }

    handleCommentChange(e) {
        this.setState({
            commentValue: e.target.value
        });
        if (this.props.investigationType === "Update") {
            const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
            this.setState({
                logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' with Comments ' + e.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
        var getPastedata= e.target.value;
        var splChars = "*|,\":<>[]{}`\';()@&$#%";
        if (getPastedata.length > 0) {
            var targetId = e.target.id;
            if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
                window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
                setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
        } 
    }    
    }


    handleAddDocBtn(e) {

        e.preventDefault();
        const fileObjectUpload = validateUploadedFile(e);
        const existingMap = this.state.selectedFileForUpload || new Map();
        if (fileObjectUpload !== undefined) {
            const fileBasedata = "";
            getBase64(fileObjectUpload).then((el) => {
                const arr = el.split("base64,")
                const simpleBase64 = arr[1];
                existingMap.set(new String(fileObjectUpload.name), [simpleBase64, fileObjectUpload]);
                this.setState({
                    selectedFileForUpload: existingMap,
                    fileUploadValid: true
                });
            });

        }
        const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
        if (this.props.investigationType === "Update") {
            this.setState({
                logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' with added Evidence ' + e.target.files[0].name + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
        else {
            this.setState({
                addFileMessage: ' and Evidence ' + e.target.files[0].name
            });
        }
    }

    displayDocumentList = (selectedFileForUpload) => {
        const finalList = [];
        if (selectedFileForUpload) {
            let counter = 0;
            for (const obj of selectedFileForUpload) {
                const [fileName, [simpleBase64, fileData]] = obj;
                finalList.push(<li key={fileName.toString()} >
                    <span id="newlyAddedDoc" className="file-name" title={fileName.toString()}>{fileName.toString()}</span>
                    <div className="action-btns">
                        <div className="download-icon" onClick={(e) => this.downloadNewFiles(e, obj, fileName)}>
                            <a href="#">
                                <img src="../images/download.svg" width="16px">
                                </img>
                            </a>
                        </div>
                        <div className="delete-icon">
                            <a href="#">
                                <img src="../images/delete.svg" width="17px" onClick={(e) => this.deleteNewFiles(e, obj, fileName)} ></img>
                            </a>
                        </div>
                    </div>
                </li>
                )
                counter++;
            }
        }
        return finalList;

    }

    downloadNewFiles(e, obj, fileNameSel) {
        const ext = fileNameSel.toString().split('.').pop();

        let fileType = '';
        if (ext == "docx" || ext == "doc" || ext == 'xlsx' || ext == 'txt') {
            fileType = 'document'
        } else {
            fileType = 'photo'
        }

        for (const obj of this.state.selectedFileForUpload) {
            const [fileName, [simpleBase64, fileData]] = obj;
            if (fileType === 'photo') {
                window.open(fileData.BlobURI || window.URL.createObjectURL(fileData));
            } else {
                if (fileName.toString() === fileNameSel.toString()) {
                    if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                        window.navigator.msSaveOrOpenBlob(fileData, fileName.toString());
                    }
                    else {
                        window.URL = window.URL || window.webkitURL;
                        const url = fileData.BlobURI || window.URL.createObjectURL(fileData);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = fileName;
                        if (is.safari()) {
                            a.target = '_blank';
                        }
                        a.click();
                    }
                }
            }
        }
    }

    deleteNewFiles = (e, obj, fileNameSel) => {
        const existingMap = this.state.selectedFileForUpload || new Map();
        for (const obj of this.state.selectedFileForUpload) {
            const [fileName, [simpleBase64, fileData]] = obj;
            if (fileName.toString() === fileNameSel.toString()) {
                existingMap.delete(fileName)
                this.setState({
                    selectedFileForUpload: existingMap,
                    fileUploadValid: true
                });
            }
        }
        if (this.props.investigationType === "Update") {
            console.log(this.props.currentUser)
            const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
            this.setState({
                logMessage: this.state.logMessage + getCurrentUserDetails() + 'updated Investigation' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' deleting Evidence ' + fileNameSel.toString() + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
    }

    downloadOldFiles = (e, fileObj) => {

        const ext = fileObj.InvestigationDocName.split('.').pop();

        let fileType = '';
        if (ext == "docx" || ext == "doc" || ext == 'xlsx' || ext == 'txt') {
            fileType = 'document'
        } else {
            fileType = 'photo'
        }
        if (fileType === 'photo') {
            window.open(fileObj.InvestigationDocBlobUri || window.URL.createObjectURL(fileObj));

        } else {
            window.URL = window.URL || window.webkitURL;
            const url = fileObj.InvestigationDocBlobUri || window.URL.createObjectURL(fileObj);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileObj.InvestigationDocName;
            if (is.safari()) {
                a.target = '_blank';
            }
            a.click();
        }

    }

    deleteOldFiles = (e, fileObj) => {
        let payload = {};
        payload.InvestigationDocumentsId = fileObj.InvestigationDocumentsId;
        payload.InvestigationId = fileObj.InvestigationId;
        this.props.onDeleteInvestigationFiles(payload);
        if (this.props.investigationType === "Update") {
            const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
            this.setState({
                logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' deleting Evidence ' + fileObj.Doc_Name + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
    }

    onSelectCreatedOnDate = (key, e) => {
        const date = Moment(e).format('DD-MM-YYYY');
        this.setState({ createDate: new Date(Moment(e, "DD-MM-YYYY")) }, function () {
        });
        const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : 'default';
        if (this.props.investigationType === "Update") {
            this.setState({
                logMessage: this.state.logMessage + getCurrentUserDetails() + ' updated Investigation ' + this.props.investigationObj.Investigation_Id + ' for ' +
                    this.props.investigationObj.Dealer_Name + ' with Created On date to ' + date + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
    }

    handleStartInvestigation(investigationType) {
        this.btnReview.setAttribute("disabled", "disabled");
        /******Hardcoded payload to be removed later ******/
        let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
            + '(' + this.props.currentUser.employeeNumber + ')' : "";
        const payload =
        {
            "Location": investigationType === "Create" ? this.state.selectedLocation.value : this.props.investigationObj.Dealer_Name,
            "Dealer_Id": investigationType === "Create" ? this.state.selectedLocation.id : this.props.investigationObj.Dealer_Id,
            "Title": this.state.titleValue,
            "Summary": this.state.summaryValue,
            "Status_ID": this.state.selectedStatus.id,
            "Status": this.state.selectedStatus.value,
            "Stage_Id": this.state.selectedStage.id,
            "Stage": this.state.selectedStage.value,
            "CreatedOn": this.state.createDate,
            "Comments": this.state.commentValue,
           // "CreatedBy": currentUser,
            "CreatedBy": getCurrentUserDetails()
        }

        payload.Documents = [];
        for (const obj of this.state.selectedFileForUpload) {
            let tempArr = {};
            const [fileName, [simpleBase64, fileData]] = obj;
            tempArr.Doc_Name = fileName.toString();
            tempArr.data = simpleBase64;
            payload.Documents.push(tempArr);
        }
        let msg = '';
        if (investigationType === "Create") {
            // msg = currentUser + ' created Investigation for ' + this.state.selectedLocation.label + ' with Title ' + this.state.titleValue +
            //     ' and Description ' + this.state.summaryValue + ' as Stage ' +
            //     this.state.selectedStage.value + ' and Status ' + this.state.selectedStatus.value + ' and Created On date to ' +
            //     Moment(this.state.createDate).format('HH:MM DD MMM YYYY');
            // let tempMsgForComment = this.state.commentValue ? ' and Comments ' + this.state.commentValue : '';
            // let tempMsgForDate = ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
            // msg = msg + tempMsgForComment + this.state.addFileMessage + tempMsgForDate;
            this.props.onPostNewInvestigationData(payload);
        } else {
            msg = this.state.logMessage;
            payload.Investigation_Id = this.props.investigationObj.Investigation_Id;
            this.props.onPostUpdateInvestigationData(payload);
            const reqPayload = {
                LogMessage: msg,
                AuditLogModule: "Investigation",
                AcionAPISource: "Create Investigation",
                CreatedBy: getCurrentUserDetails(),
                CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
            }
            this.props.doPostAuditLogData(reqPayload);
        }

        this.setState({
            saveBtnClicked: true
        });

    }

    render() {
        let isStartEnabled = true;
        let overlayTitle, INVESTIGATION_BUTTON, message;
        let locationList = this.props.locationsDropDownList.filter(item => item.Program_Name === 'TFL' && (item.Location_Type === "Principal" || item.Location_Type === "Branch"))

        if (this.props.investigationType === "Create") {
            if ((this.state.selectedLocation !== null && this.state.selectedLocation.value !== "") && this.state.titleValue !== "" && (this.state.selectedStatus !== null && this.state.selectedStatus.value !== "") && (this.state.selectedStage !== null && this.state.selectedStage.value !== "")) {
                isStartEnabled = false;
            }
            overlayTitle = "Create Investigation";
            INVESTIGATION_BUTTON = "Create Investigation";
            message = this.state.successful ? "Investigation created successfully." : "Create investigation failed."


        } else {
            if ((this.props.investigationObj.Dealer_Name !== null && this.props.investigationObj.Dealer_Name !== "") && (this.state.titleValue !== "") && (this.state.selectedStatus !== null && this.state.selectedStatus.value !== "") && (this.state.selectedStage !== null && this.state.selectedStage.value !== "")) {
                isStartEnabled = false;
            }
            overlayTitle = "Update Investigation";
            INVESTIGATION_BUTTON = "Update Investigation";
            message = this.state.successful ? "Investigation updated successfully." : "Update investigation failed."
        }

        let alertClass = this.state.successful ? "alert alert-center alert-success alert-dismissible text-left" : "alert alert-danger alert-dismissible text-left";
        return (
            <div className="investpopup-popup">
                <div className="main-wrapper popup">
                    <div className="main-content row">
                        <div className="popup__wrapper align-self-center">
                            <div className="popup__header" id="headerDiv">
                                <h5>{overlayTitle}</h5>
                            </div>
                            {<LoaderModal mLoad={this.state.hideTimer} />}
                            {this.state.showMsg ?
                                <div className={alertClass} role="alert">
                                    <button type="button" id="messageButton" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.hideAlert()}><span aria-hidden="true">&times; </span></button>
                                    {' '}
                                    {message}
                                    {' '}
                                </div>
                                : null
                            }
                            <div className="popup__body">
                                <div className="form-row">
                                    <div className="col-12 text-right">
                                        <span className="text-italic text-12 text-light-gray"><span className="required">*</span> Mandatory fields</span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <div className="form-group text-left">
                                            <label >Location <span className="required">*</span></label>
                                            {
                                                (this.props.investigationType === "Create") ?

                                                    <Select
                                                        id="createInvestigation-location-list"
                                                        className="dropdown-with-search"
                                                        value={this.state.selectedLocation}
                                                        placeholder="Select Location"
                                                        onChange={(e) => this.handleOnSelectLocation(e, "LOCATION")}
                                                        options={multiselectOptions("Select Location", locationList, "Dealer_ID", "Location_Name", null)}
                                                        isSearchable="true"
                                                    />
                                                    :
                                                    <input className="form-control"
                                                        title={this.props.investigationObj.Dealer_Name}
                                                        defaultValue={this.props.investigationObj.Dealer_Name}
                                                        disabled={true}
                                                    ></input>
                                            }
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="form-group text-left">
                                            <label className="text-left">Title <span className="required">*</span></label>
                                            <input className="form-control placeholder-light"
                                                defaultValue={this.state.titleValue}
                                                placeholder="Title"
                                                maxlength="100"
                                                id="copy_paste_title"
                                                onChange={(e) => this.handleTitleChange(e)}
                                                disabled={this.props.isUpdateDisabled}
                                                onBlur={(e) => this.titleCopyPaste(e)}
                                                onKeyPress={this.onKeyPress}
                                            ></input>

                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="form-group text-left">
                                            <label>Description </label>
                                            <textarea className="form-control"
                                                onBlur={(e) => this.handleSummaryChange(e)}
                                                disabled={this.props.isUpdateDisabled}
                                                maxlength="1000" id="summary_field"
                                                onKeyPress={this.onKeyPress}
                                            >{this.state.summaryValue}</textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <div className="form-group text-left">
                                            <label>Stage<span className="required">*</span></label>
                                            {this.props.lookUpStageObj && this.props.lookUpStageObj.data && this.props.lookUpStageObj.data.length > 0 && (
                                                <Select
                                                    id="createInvestigation-stage-list"
                                                    className="dropdown-with-search"
                                                    value={this.state.selectedStage}
                                                    placeholder="Select Stage"
                                                    onChange={(e) => this.handleOnSelectLocation(e, "STAGE")}
                                                    options={multiselectOptions("Select Stage", this.props.lookUpStageObj.data, "Id", "Name", null)}
                                                    isSearchable="true"
                                                    isDisabled={this.props.isUpdateDisabled}

                                                />
                                            )}
                                        </div>
                                    </div>


                                    <div className="col-6">
                                        <div className="form-group text-left">
                                            <label>Status <span className="required">*</span></label>
                                            {this.props.lookUpStatusObj && this.props.lookUpStatusObj.data && this.props.lookUpStatusObj.data.length > 0 && (
                                                <Select
                                                    id="createInvestigation-status-list"
                                                    className="dropdown-with-search"
                                                    value={this.state.selectedStatus}
                                                    placeholder="Select Status"
                                                    onChange={(e) => this.handleOnSelectLocation(e, "STATUS")}
                                                    options={multiselectOptions("Select Status", this.props.lookUpStatusObj.data, "Id", "Name", null)}
                                                    isSearchable="true"
                                                    isDisabled={this.props.isUpdateDisabled}
                                                />
                                            )}

                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-6">
                                        <div className="form-group text-left" id="createdOn-investigation">
                                            {(this.props.investigationType === "Create") ?
                                                <label>Created On</label> :
                                                <label>Creation Date</label>
                                            }

                                            <CustomDateTimePicker
                                                inputProps={{
                                                    component: props => <input {...props} id="created_on_date__input"
                                                        placeholder="Select year" />
                                                }}
                                                dropUp
                                                time={false}
                                                value={this.state.createDate}
                                                max={new Date()}
                                                min={getFullDateFromCurrentYear("1/1")}
                                                onChange={(e) => this.onSelectCreatedOnDate("CREATION_DATE", e)}
                                                format="DD-MM-YYYY"
                                                disabled={this.props.isUpdateDisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-12">
                                        <div className="photos-document">
                                            <div className="photo-icon">
                                                <img src="images/icons-upload.svg" width="12px"></img>
                                                <span>Evidence</span>
                                            </div>
                                            {!this.props.isUpdateDisabled ?
                                                <div className="add-more">
                                                    <label for="add-more" class="add-more">+ Add </label>
                                                    <input id="add-more" type="file" name="myFile" class="hide"
                                                        onChange={(e) => this.handleAddDocBtn(e)} />
                                                </div>
                                                : null
                                            }
                                        </div>
                                        <div className="document-list">
                                            <div id="uploadfileerror" style={fieldErrorDiv} />
                                            <ul>
                                                {(this.props.investigationType === "Update" && this.props.investigationObj.Documents.length > 0) ?
                                                    (this.props.investigationObj.Documents || []).map((docObj, index) => (
                                                        <li key={docObj.InvestigationDocName} >
                                                            <span id="savedDoc" className="file-name" title={docObj.InvestigationDocName}>{docObj.InvestigationDocName}</span>
                                                            <div className="action-btns">
                                                                <div className="download-icon" onClick={(e) => this.downloadOldFiles(e, docObj)}>
                                                                    <a href="#">
                                                                        <img src="../images/download.svg" width="16px">
                                                                        </img>
                                                                    </a>
                                                                </div>
                                                                {!this.props.isUpdateDisabled ?
                                                                    <div className="delete-icon">
                                                                        <a href="#">
                                                                            <img src="../images/delete.svg" id="delete-img" width="17px" onClick={(e) => this.deleteOldFiles(e, docObj)} ></img>

                                                                        </a>
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </li>
                                                    ))
                                                    : null
                                                }


                                                {this.displayDocumentList(this.state.selectedFileForUpload)}
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                {(this.props.investigationType === "Update" && this.props.investigationObj.Comments.length > 0) ?
                                    <div className="form-row have-comments">
                                        <div className="col-12  ">
                                            <div className="comment-box">
                                                <div className="form-group">
                                                    <label><span className="m-r-10">
                                                        <img src="/images/comment.svg" width="18px" /></span>
                                                        Comments</label>
                                                </div>
                                                <div className="comment-history">
                                                    {(this.props.investigationObj.Comments || []).map((CommentObj, index) => (
                                                        <div className="comment-listing">
                                                            <div className="form-row">
                                                                <div className="col-8 col-md-10">
                                                                    <div class="comment-username">{CommentObj.InvestigationCommentsUserName}</div>
                                                                </div>
                                                                <div className="col-4 col-md-2 text-right">
                                                                    <div class="comment-history-date">{CommentObj.InvestigationCommentsDate}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-row">
                                                                <div className="col-12"><p className="comment-description">{CommentObj.InvestigationComments}</p></div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div> : null}
                                {!this.props.isUpdateDisabled ?
                                    <div className="form-row">
                                        <div className="col-12">
                                            <div className="form-group text-left">
                                                <textarea className="form-control"
                                                    id="comments-section"
                                                    onBlur={(e) => this.handleCommentChange(e)}
                                                    placeholder="Comments"
                                                    onKeyPress={this.onKeyPress}
                                                ></textarea>
                                            </div>
                                        </div>
                                    </div> : null
                                }
                                {!this.props.isUpdateDisabled ?
                                    <div className="form-row">
                                        <div className="col-12 text-center m-b-20">
                                            <button id="investigation__start" class="btn btn-primary"
                                                ref={btnReview  => {
                                                    this.btnReview = btnReview; 
                                                  }}
                                                onClick={() => this.handleStartInvestigation(this.props.investigationType)}
                                                disabled={isStartEnabled}
                                            >
                                                {INVESTIGATION_BUTTON}
                                            </button>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return ({
        errorState: getInvestigationCreateError(state),
        createInvestigationObj: getInvestigationCreateData(state),
        saveResponse: fetchNewlySavedInvestigationResp(state),
        lookUpStatusObj: getLookUpStatusData(state),
        lookUpStageObj: getLookUpStageData(state),
        lookUpStatusError: getLookUpStatusError(state),
        lookUpStageError: getLookUpStageError(state),
        //currentUser: getCurrentUser(state.dropdownState),
        currentUser: getCurrentUserDetails(),

        //deleteResponse: fetchDeleteInvestigationResp(state),
        saveUpdateResponse: fetchUpdateInvestigationResp(state),
        locationsDropDownList: getLocationsList(state),
    }
    )
};
const mapDispatchToProps = (dispatch) => ({
    onFetchInvestigationCreateData: () => dispatch(doFetchInvestigationCreateData()),
    onPostNewInvestigationData: (payload) => dispatch(doPostNewInvestigationData(payload)),
    onFetchLookUpStage: () => dispatch(doFetchLookUpStage()),
    onFetchLookUpStatus: () => dispatch(doFetchLookUpStatus()),
   // onFetchInvestigationRecords: (query, payload) => dispatch(doFetchInvestigationRecords(query, payload)),
    onDeleteInvestigationFiles: (payload) => dispatch(doDeleteInvestigationFiles(payload)),
    onPostUpdateInvestigationData: (payload) => dispatch(doPostUpdateInvestigationData(payload)),
    onFetchLocationList: query => dispatch(fetchLocationList(query)),
    doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvestigationOverlay);

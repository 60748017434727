import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
    doPopulateInvestigationList,
    doFetchInvestigationErrors,
    doFetchInvestigationCreateErrors,
    doPopulateInvestigationCreateData,
    saveNewInvestigationResp,
    doFetchLookUpStatusDataErrors,
    doFetchLookUpStageDataErrors,
    doPopulateLookUpStatusData,
    doPopulateLookUpStageData,
    deleteInvestigationEvidence,
    saveUpdateInvestigationResp

} from '../actions/Investigations';
import {
    fetchInvestigationRecordsList,
    fetchInvestigationCreateData,
    postInvestigationCreateData,
    fetchLookUpStageData,
    fetchLookUpStatusData,
    deleteInvestigationFile,
    postUpdateInvestigation
} from '../api/Investigations';
import { getScheduleListParams } from '../selectors/story';
import { doHideLoader, doShowLoader } from "../actions/loader";
import { doFetchInvestigationRecords } from "../actions/Investigations";
import {doPostAuditLogData} from "../actions/auditLog";
import { doFetchStories } from '../actions/story';

export function* handleFetchInvestigationRecords(action) {
    yield put(doShowLoader());
    const { reviewType, deviceType, payload } = action;
    const filterState = yield select((state) => state.filterState);
    filterState.reviewType = action.reviewType;
    const query = getScheduleListParams(filterState);
    try {
        let result;
        const index = query.postParams.findIndex(val => val.Field === 'Program');
        query.postParams.splice(index, 1);
        result = yield call(fetchInvestigationRecordsList, query, payload);
        yield put(doPopulateInvestigationList(result.data.data, result.data.Count, deviceType));
        yield put(doHideLoader());
    } catch (error) {
        yield put(doFetchInvestigationErrors(error));
        yield put(doHideLoader());
    }
}

export function* handleCreateInvestigationData(action) {
    const { deviceType } = action;
    try {
        // let result;
        // result = yield call(fetchInvestigationCreateData); // uncomment once APIs are Live
        // yield put(doPopulateInvestigationCreateData(result.data, result.Count, deviceType));
    } catch (error) {
        //  yield put(doFetchInvestigationCreateErrors(error));
    }
}

export function* handleStartNewInvestigation(requestPayload) {
    let result;
    try {

        result = yield call(postInvestigationCreateData, requestPayload.payload);
        if (result.status === 200) {
           // yield put(doFetchInvestigationRecords('Investigation'));
            yield put(doFetchStories('investigation'));

        }
        yield put(saveNewInvestigationResp(result.data));
    } catch (error) {
        error = {
            Message: "Create Investigation Failed.",
            Success: false,
        }
        yield put(saveNewInvestigationResp(error));
    }

}

export function* handleLookUpStageData(action) {
    const { deviceType } = action;
    try {
        let result;
        result = yield call(fetchLookUpStageData);
        yield put(doPopulateLookUpStageData(result.data, result.Count, deviceType));
    } catch (error) {
        yield put(doFetchLookUpStageDataErrors(error));
    }
}

export function* handleLookUpStatusData(action) {
    const { deviceType } = action;
    try {
        let result;
        result = yield call(fetchLookUpStatusData);
        yield put(doPopulateLookUpStatusData(result.data, result.Count, deviceType));
    } catch (error) {
        yield put(doFetchLookUpStatusDataErrors(error));
    }
}

export function* handleDeleteInvestigationFile(requestPayload) {
    try {
        let result;
        result = yield call(deleteInvestigationFile, requestPayload.payload);
        yield put(deleteInvestigationEvidence(result.data));
        if (result.status === 200) {
            yield put(doFetchInvestigationRecords('Investigation'));
        }
    } catch (error) {
        console.log(error);;
    }
}

export function* handleUpdateInvestigation(requestPayload) {
    try {
        let result;
        result = yield call(postUpdateInvestigation, requestPayload.payload);
        if (result.status === 200) {
            yield put(doFetchInvestigationRecords('Investigation'));
        }
        yield put(saveUpdateInvestigationResp(result.data));
    } catch (error) {
        error = {
            Message: "Update Investigation Failed.",
            Success: false,
        }
        yield put(saveUpdateInvestigationResp(error));
    }

}
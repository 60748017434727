import React from 'react';
import {connect} from "react-redux";
import {getFetchError, getPaginationDetails, getReadableStories} from "../../selectors/story";
import {changeStoriesPage} from "../../actions/story";
import {getLoaderStatus, getLoaderModalStatus} from "../../selectors/loader";

const Loader = ({loader, loaderModal}) => {
    return (
      <div>
        { loader ==='show' && loaderModal !=='show' && (
          <div className="loader">
            <div className="load-bar">
              <div className="bar" />
              <div className="bar" />
              <div className="bar" />
            </div>
          </div>
)}
      </div>

    )
};



const mapStateToProps = state => ({
    loader: getLoaderStatus(state),
    loaderModal:getLoaderModalStatus(state)
});

export default connect(
    mapStateToProps
)(Loader);

import React ,{ Suspense } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactTooltip from 'react-tooltip';
import { DropdownButton, Dropdown, Image } from "react-bootstrap"
import {getLoaderStatus} from "../../selectors/loader";
import SkeletonsReview from '../Common/Skeletons/review/SkeletonsReview'
import Status from '../Common/Status'
/* import PhoneIcon from '@material-ui/icons/Phone';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import HelpIcon from '@material-ui/icons/Help';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp'; */
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import JssProvider from 'react-jss/lib/JssProvider';
import { createGenerateClassName } from '@material-ui/core/styles';
import _ from 'lodash';
import { Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import ScorePopup from './Scorepopup';
import ResponseProgressBar from '../Common/ResponseProgressBar'
import { doFetchScheduleDropDowns, doFetchStories, doResetSortAndFilter } from "../../actions/story";
import { doFetchReview, doFetchScoreData, doSaveReview, doUpdateReviewState, doValidateReviewState, doCompleteReview, doClearReviewData, fetchDealerInfoById, doClearDocData, doUpdateKPIData, doUpdateCSVReview } from "../../actions/review";
import {
  getFetchError,
  getFilterLabel,
  getPaginationDetails,
  getReadableStories,
  getReview,
  getUnAuthReviewResponse,
  getDealerInfo,
  getReviewNotStartedStatus,
  getReviewValidation,
  getCompleteReviewButtonStatus,
  getUserRole,
  getNumberOfFlags,
  getCurrentStateIntoJSON,
  getCurrentStateIntoExcelJSON,
  getReviewFileName,
  validateKPI
  // getCompleteReviewStatus
} from "../../selectors/story";
import { getSuccessStatus, getCompleteReviewStatus, } from "../../selectors/loader";
import { toggleSidebar } from "../../actions/header";
import { getCurrentUser } from "../../selectors/dropdown";
import ReviewCollapse from "./ReviewCollapse";
import is from 'is_js';
import TruncateMarkup from 'react-truncate-markup';

import ResponseOptions from "../Common/Responseoptions";
import UnauthorizePage from "../Common/UnauthorizePage"
import PageNotFound from '../Common/PageNotFound';
import { PopUpShareReviewTrigger } from './PopUpTrigger';
import { showToast } from '../../actions/toast';
import Moment from 'moment';
import { doPostAuditLogData } from "../../actions/auditLog";
import UploadReviewPopup from './UploadReviewPopup';
import Popup from "reactjs-popup";
import XLSX from "tempa-xlsx";
import { saveAs } from "file-saver";
import { excelSheetFromDataSet, formatWorksheet, formatWorksheetBeforeDownload, strToArrBuffer } from '../../utils/XlsxUtils';


function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
  productionPrefix: '',
});

class Review extends React.Component {

  _exporter;
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      value: 0,
      questionResponse: {},
      showingAlert: false,
      saveType: 'save',
      reviewEnable: true,
      dealer_location: '',
      dealer_email: '',
      dealStatus: true,
      expanded: {},
      selectedResponseOption: {},
      clicked: false,
      revmsg: "",
      flagDetails: {},
      auditLogMsg: "",
      AcionAPISourceName: "",
      
    };
    this.renderTabVal = 0;
    this.headerRef = React.createRef();
    this.successRef = React.createRef();
    this.openUpdatedReview = this.openUpdatedReview.bind(this);
    this.toggleFlag = this.toggleFlag.bind(this);
  }

  handleClickShowAlert(type) {
    let isVisible = type ? true : false;
    this.setState({
      showingAlert: isVisible,
      saveType: type
    });

    /* setTimeout(() => {
         this.setState({
             showingAlert: false
         });
     }, 10000); */
  }

  hideAlert() {
    this.setState({
      showingAlert: false
    });
  }

  componentWillUnmount() {
    this.props.clearData();
  }
  componentDidMount() {
    
    document.title = "Reviews";
    /**S DSA-1697 */
    // this.props.onFetchDropDowns("review");

    /**E DSA-1697 */
    //this.props.onFetchDropDowns("review");
    if (this.props.getFilterStatus === 'Hide Filters') {
      this.props.toggleFilter();
    }
    this.props.onFetchReview(this.props.tflType, this.props.match.params.id);
    document.body.classList.remove('nav-open');
    document.body.classList.remove('filter-open');
    document.body.addEventListener('click', () => {
      document.body.classList.remove('response-open');
    });
    this.setState({ reviewEnable: this.props.reviewEnable });
    window.scrollTo(0, 0);
  }
  toggleClass(e) {
    document.body.classList.toggle('response-open');
    e.preventDefault();
  }
  componentDidUpdate() {
    if (this.props.review && !this.state.dropdownsFetched) {
      const query = {
        dealer_id: null,
        program_id: null,
        statusType: "review"
      }
      this.props.onFetchDropDowns(query);
      this.setState({ dropdownsFetched: true });
    }

    if (this.props.isDocAdded) {
      this.props.onFetchReview(this.props.tflType, this.props.match.params.id);
      this.props.doClearDocData()
    }
    if (this.state.showingAlert && this.props.showSuccess) {
      this.setState({ showingAlert: false });
      this.props.showToast({ show: true, message: this.setDynMsg(this.props.showSuccess), showSuccess: (this.props.showSuccess === 'yes') })
      if (this.successRef && this.successRef.current && this.successRef.current.style) {
        this.successRef.current.style.display = "none";
      }
      if (this.props.showSuccess === 'yes' && !this.state.showShareOverlay &&
        (this.state.saveType === 'approveReview') || (this.state.saveType === 'sendBack')) {
        this.setState({
          shareRevieData: {
            Assessment_Template_Name: this.props.review.Assessment_Name,
            Assessment_ID: this.props.review.Review_ID,
            Last_Modified_By: this.props.review.LastModifiedBy,
            Dealer_Name: this.props.review.Location_Name,
            Dealer_ID: this.props.review.Dealer_ID,
            Email: this.props.review.Email,
            Assessment_ID: this.props.review.Assessment_Id,
            defaultMsg: this.getDefaultShareMessage()
          }
        });
        this.setState({ showShareOverlay: true });
        //this.props.onFetchReview(this.props.tflType, this.props.match.params.id);
      } else if (this.props.showSuccess === 'yes' && (this.state.saveType === 'save' || this.state.saveType === 'submitReview' || this.state.saveType === 'delete')) {
        this.props.onFetchReview(this.props.tflType, this.props.match.params.id);
      }
      else if (this.props.showSuccess === 'yes' && this.state.saveType === 'saveAndClose') {
        this.props.history.push(`/home`);
      }
    }

    if (this.state.dealStatus) {
      if (this.props && this.props.review && this.props.review.Location_Name && this.props.review.Email) {
        this.setState({ dealer_location: this.props.review.Location_Name });
        this.setState({ dealer_email: this.props.review.Email })
        this.setState({ dealStatus: false })
      }
    }
  }

  getDefaultShareMessage = () => {
    let msg = `${this.props.review.LastModifiedBy} has ${this.state.saveType === 'approveReview' ? `approved` : `reopened`} ${this.props.review.Program} ${this.props.review.Assessment_Name} for ${this.props.review.Location_Name}.\n\n`;
    if (this.state.saveType === 'sendBack') {
      msg += 'Please review flagged questions and comments.\n\n';
    }
    return msg;
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggle(event, qId, qResponse, isReadOnlyMode) {
    /*** Audit Log */
    //this.props.review.Review_ID
    const resp = event.value !== "" && event.value !== undefined ? event.value : event.target.innerText;
    let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
      + '(' + this.props.currentUser.employeeNumber + ')' : "";
    let finalMsg = this.state.auditLogMsg;
    let msg = currentUser + ' selected ' + resp + ' for ' + qId +
      ' for ' + this.props.match.params.id + ' on ' + Moment(this.state.createDate).format('HH:MM DD MMM YYYY');
    if (!this.state.auditLogMsg.includes(qId)) {
      finalMsg = finalMsg + msg;
    } else {
      finalMsg = msg;
    }
    this.setState({
      auditLogMsg: finalMsg,
      AcionAPISourceName: "Conduct Review"
    });
    /*** Audit Log */

    if (!isReadOnlyMode) {
      if (!qResponse) {
        qResponse = event.value;
      }

      const questionResponse = { ...this.state.questionResponse };

      questionResponse[qId] = qResponse;
      this.setState({ questionResponse });
      this.props.onUpdateReviewState(qId, qResponse, this.state.flagDetails[qId]);
    }
  }
 
  sendEmail(event) {
    this.setState({
      shareRevieData: {
        Assessment_Template_Name: this.props.review.Assessment_Name,
        Assessment_ID: this.props.review.Review_ID,
        Last_Modified_By: this.props.review.LastModifiedBy,
        Dealer_Name: this.props.review.Location_Name,
        Dealer_ID: this.props.review.Dealer_ID,
        Email: this.props.review.Email,
        Assessment_ID: this.props.review.Assessment_Id,
      }
    });
    this.setState({ showShareOverlay: true });
    event.preventDefault();
  }

  showScoreData(event) {
    const scorePayload = {
      "Program_Id": this.props.review.Program_ID,
      "Dealer_Id": this.props.review.Dealer_ID,
      "IS_ACTIVE": "1",
      "Review_Id": this.props.review.Review_ID,
      "Review_Type": this.props.review.Review_Type,
      "IS_REPORT": "false",
    }
    this.props.doFetchScoreData(scorePayload);
    event.preventDefault();

  }
  getSections = (readOnlyClass, elHide, canAddSOP, locationName, address) => {
    let sections = [];
    let runOnce = true;
    {
      this.props.review.Sections.map((data) => {
        (data.Criterias || []).map((criteria) => {
          const section = (
            <>
              {runOnce && (
                <div class="sectionName">
                  <h1 className="SectionHeading">{data.Section_Name}</h1>
                </div>
              )}
               <div
                          className="tab-pane fade show active"
                          role="tabpanel"
                          aria-labelledby="tab04"
                        >
                          <div className="position-relative long-title">
                            <TruncateMarkup lines={2} >
                              <div className="h4 m-t-15 m-b-15 " title={criteria.Criteria_Name}>
                                {criteria.Criteria_Name}
                              </div>
                            </TruncateMarkup>
                            {criteria.Criteria_Guidance &&
                              <a href="javascript:void(0)">
                                <img
                                  className="questions__image float-right"
                                  key={criteria.Criteria_Id}
                                  onClick={(e) => this.questionClicked(e, criteria.Criteria_GuidanceURL)}
                                  src="../images/criteria.svg"
                                  alt="Criteria"
                                />
                              </a>
                            }
                          </div>
                            


                          <div className="questions">
                            {(criteria.Questions || []).map((question, index) => (
                              <div className="questions__row">
                                <div className="questions__header">
                                  <div className="row">
                                    <div className="col-md-12 col-lg-5 col-xl-5 questions__left-col">
                                      <div className={`questions__count active ${((question.QuestionComment && question.QuestionComment.length > 0) || question.Comments.length > 0) ? "commented" : ""}`}>{index + 1}</div>
                                      <div className="questions__question">
                                        {question.Method_Text}
                                        {question.Method_List.length > 0 && (
                                          <ul>
                                            {(question.Method_List || []).map(list => (
                                              <li>
                                                {list}
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                        {!this.props.hasReviewNotStarted && (question.valid === 'invalid' || question.flagValid === 'invalid') &&
                                          <img className="questions__alert-icon-mobile" src="../images/alert-new.svg" alt="Warning" />
                                        }
                                        {question.Question_Guidance &&
                                          <a href="javascript:void(0)">
                                            <img
                                              className="m-r-15 questions__guidance-icon-mobile"
                                              key={question.Question_Id}
                                              onClick={(e) => this.questionClicked(e, question.Question_GuidanceURL)}
                                              width="20" height="20" src="../images/criteria.svg"
                                              alt="Question"
                                            />
                                          </a>
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-lg-2">
                                      {this.displayKPIData(question, readOnlyClass)}
                                    </div>
                                    <div className={`col-md-12 col-lg-5 col-xl-5 questions__right-col text-right ${readOnlyClass}`}>
                                      {!this.props.hasReviewNotStarted && (question.valid === 'invalid' || question.flagValid === 'invalid') &&
                                        <img className="m-r-15 m-r-20-l questions__alert-icon-desktop" width="20" height="20" src="../images/alert-new.svg" alt="Warning" />
                                      }
                                      {question.Question_Guidance &&
                                        <a href="javascript:void(0)">
                                          <img
                                            className="m-r-15 m-r-20-l questions__guidance-icon-desktop"
                                            width="20" height="20" src="../images/criteria.svg" alt="Question"
                                            key={question.Question_Id}
                                            onClick={(e) => this.questionClicked(e, question.Question_GuidanceURL)}
                                          />
                                        </a>
                                      }                                     
                                      {question.Responses && question.Responses.length > 0 && (
                                        <ResponseOptions
                                          toggle={(e, resp) => this.toggle(e, question.Question_Id, resp, readOnlyClass)}
                                          toggleClass={(e) => this.toggleClass(e)}
                                          questionId={question.Question_Id}
                                          responseOptionsDropDownList={question.Responses}
                                          questionResponse={this.state.questionResponse}
                                          question={question} reviewStatus={this.props.review.Status}
                                          isReadOnlyMode={this.props.review.ActiveDealer}
                                          Assessment_Id ={this.props.review.Assessment_Id}
                                       />)}

                                      {/* Display tootip when review is Not at standard for any question and Action/obervation/photos are updated */}
                                      {((question.Actions.length > 0) || (question.Recommendations.length > 0)
                                        || (question.Documents.length > 0) || (question.Sops.length > 0)) && (

                                          <div className="tooltipicon">
                                            <br />
                                            <img id={question.Question_Id} class="questions__redhelp-button" src="../images/RedHelp.png" data-tip data-for={question.Provided_Question_Id} />
                                            <ReactTooltip id={question.Provided_Question_Id} place="left" effect="solid" getContent={() => this.showTooltip(question)} />

                                          </div>
                                        )}

                                      {((this.props.review.Status === "Pending 1st") || (this.props.review.Status === "Pending 2nd")
                                        || (this.props.review.Status === "Reopen")) &&
                                        <div key={question.Question_Id}
                                          onClick={e => this.toggleFlag(question)}
                                          className={`flag-icon ${question.IsQuestionFlag ? "active-flag" : this.props.review.Status === "Reopen" ? "hide" : ""} ${elHide}`}>
                                          <img className="in-active" src="../images/flag.svg" width="26px" />
                                          <img className="active" src="../images/flag-active.svg" width="26px" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                                
                                <ReviewCollapse questionResponse={question.Status===null?"":question.Status} qDetails={question} qAssessment_Response_ID={!(question.Assessment_Response_ID && question.Assessment_Response_ID!==0)} key={question.Question_Id} review={this.props.review} readOnlyClass={readOnlyClass} elHide={elHide} canAddSOP={canAddSOP} locationDetails={{ locationName, address }}
                                  doPostAuditLogData={this.props.doPostAuditLogData}
                                  currentUser={this.props.currentUser} auditLogMsg={this.state.auditLogMsg} />
                              </div>
                            ))}
                          </div>
                        </div>
              {/* {runOnce && (
                <div class="sectionName">
                  <h1>{data.Section_Name}</h1>
                </div>
              )} */}

              {(runOnce = false)}
            </>
          );
          sections.push(section);
        });
        runOnce = true;
      });
    }

    return sections;
  };

  saveReview(e, type) {

    window.scrollTo(0, 0);
    /** Audit Log */
    const CreatedByVal = this.props.currentUser ?
      this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
      + '(' + this.props.currentUser.employeeNumber + ')' : 'default';

    let updatingReviewMsg = this.getReviewUpdateMsg(e, type);

    const reqPayload = {
      LogMessage: updatingReviewMsg,
      AuditLogModule: "Conduct Reviews And Approvals",
      AcionAPISource: this.state.AcionAPISourceName,
      CreatedBy: CreatedByVal,
      CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
    }

    this.props.doPostAuditLogData(reqPayload);

    /*** Audit Log */
    this.props.onSaveReview(this.state.questionResponse, e.target.value);
    this.handleClickShowAlert(type);
    this.setState({
      auditLogMsg: ""
    });
  }

  /**Audit Log */

  getReviewUpdateMsg(e, type) {
    let ObjStatus = {
      ApprovalStage: this.props.review.isApprovalStage,
      RoleName: this.props.RoleName,
      Status: this.props.review.Status,
      Program: this.props.review.Program,
      AssessmentType: this.props.review.Assessment_Type
    }

    const CreatedByVal = this.props.currentUser ?
      this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
      + '(' + this.props.currentUser.employeeNumber + ')' : 'default';

    let messageVal = this.state.auditLogMsg;
    let status, updateMsg;
    if (type === "approveReview") {

      if (ObjStatus.Status === "Pending 1st") {
        status = "Pending 2nd";
      } else {
        status = "Complete";
      }
    } else if (type === "submitReview") {
      if (ObjStatus.Status === "In-progress") {
        if (!this.props.review.isApprovalStage) {
          status = "Complete";
        } else {
          status = "Pending 1st";
        }
      }
    } else if (type === "sendBack") {
      if (ObjStatus.Status === "Pending 2nd") {
        status = "Pending 1st";
      } else {
        status = "Reopen";
      }
    }
    else if (type === "delete") {
      if (ObjStatus.Status === "In-progress") {
        status = "Deleted";
      }
    }

    else {
      status = ObjStatus.Status;
    }

    const locationName = this.props.review ? this.props.review.Location_Name : '';
    updateMsg = CreatedByVal + " updated " + this.props.match.params.id + " for " + ObjStatus.Program + " for " + locationName + " to " + status + " on " + Moment(new Date()).format('HH:MM DD MMM YYYY') + ".";
    messageVal = messageVal + " " + updateMsg;
    this.setState({
      auditLogMsg: messageVal
    })

    return messageVal;
  }
  /**Audit Log */

  completeReview(e, type) {
    window.scrollTo(0, 0);
    this.props.onCompleteReview(this.state.questionResponse);
    this.handleClickShowAlert(type);
  }

  questionClicked(e, Question_GuidanceURL) {
    // Question_GuidanceURL='http://www.pdf995.com/samples/pdf.pdf';
    if (Question_GuidanceURL) {
      window.open(Question_GuidanceURL);
    }
  }

  handleKPIChange = (event, questionId) => {
    /* Audit log */
    let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
      + '(' + this.props.currentUser.employeeNumber + ')' : "";
    let finalMsg = this.state.auditLogMsg;
    let msg = currentUser + ' entered ' + event.target.value + ' for ' + questionId +
      ' for ' + this.props.match.params.id + ' on ' + Moment(this.state.createDate).format('HH:MM DD MMM YYYY');
    if (!this.state.auditLogMsg.includes(questionId)) {
      finalMsg = finalMsg + msg;
    } else {
      finalMsg = msg;
    }
    this.setState({
      auditLogMsg: finalMsg,
      AcionAPISourceName: "Manual KPIs"
    });
    /****AL */

  }

  validateKPI = (event, question) => {
    if (validateKPI(event.target.value)) {
      this.setState({
        [event.target.id]: event.target.value
      });
      this.props.updateKPIData(question.Question_Id, event.target.value);
    }
  }

  getKpiLabel = kpi => {
    let lbl = kpi ? kpi : "KPI"
    return lbl;
  }
  saveCurrentStateToDownloadExcel = (e) => {
    this.setState({ latestFileName: getReviewFileName(this.props.review) });
    e.stopPropagation();
    e.preventDefault();

    const wb = {
      SheetNames: ["ReviewJSON"],
      Sheets: {}
    };

    // console.log(JSON.stringify(this.props.currentStateExcelJSON));
    const ws = excelSheetFromDataSet(this.props.currentStateExcelJSON);
    wb.Sheets["ReviewJSON"] = formatWorksheetBeforeDownload(ws);
    //  wb.Sheets["ReviewJSON"] = ws;        
    /* generate XLSX file and send to client */
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    saveAs(new Blob([strToArrBuffer(wbout)], { type: "application/octet-stream" }), getReviewFileName(this.props.review) + '.xlsx');
    //   this.openMail();
  }

  saveCurrentStateToExcel = (e) => {
    this.setState({ latestFileName: getReviewFileName(this.props.review) });
    e.stopPropagation();
    e.preventDefault();

    const wb = {
      SheetNames: ["ReviewJSON"],
      Sheets: {}
    };
    const ws = excelSheetFromDataSet(this.props.currentStateJSON);
    wb.Sheets["ReviewJSON"] = formatWorksheet(ws);
    /* generate XLSX file and send to client */
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' });
    saveAs(new Blob([strToArrBuffer(wbout)], { type: "application/octet-stream" }), getReviewFileName(this.props.review) + '.xlsx');

  }

  getBtnType = (ObjBtn) => {
    return <button type='button' className={`btn btn-primary ${ObjBtn.elHide}`} disabled={ObjBtn.isCompleteReviewDisabled} value={ObjBtn.value} onClick={(e) => this.saveReview(e, ObjBtn.type)}>{ObjBtn.lbl}</button>
  }

  getApprovalBtnType = (ObjStatus, elHide, isCompleteReviewDisabled) => {
    if (ObjStatus.Status !== "Completed") {
      let ObjBtn = {
        elHide: elHide,
        isCompleteReviewDisabled: isCompleteReviewDisabled
      }
      let btnAttr = {};
      btnAttr = ObjStatus.Status === 'In-progress' || ObjStatus.Status === 'Reopen' ? btnAttr = Object.assign(btnAttr, { saveType: "submitReview", btnLbl: 'Submit Review' })
        : ObjStatus.Status === 'Pending 1st' || ObjStatus.Status === 'Pending 2nd' ? btnAttr = Object.assign(btnAttr, { saveType: "approveReview", btnLbl: "Approve" })
          : "";
      ObjBtn = Object.assign(ObjBtn, { value: 2, type: btnAttr.saveType, lbl: btnAttr.btnLbl });
      if (["power user", "regional manager", "super admin"].indexOf(ObjStatus.RoleName) > -1) {
        ObjBtn.elHide = "hide";
      }
      if ((ObjStatus.Status === 'Pending 1st' || ObjStatus.Status === 'Pending 2nd') && this.props.questionsFlagged > 0) {
        ObjBtn = Object.assign(ObjBtn, { value: 3, type: 'sendBack', lbl: 'Send Back' });
      }
      return btnAttr ? this.getBtnType(ObjBtn) : ''

    }
  }

  displayKPIData = (question, readOnlyClass) => {
    //question = Object.assign(question,{KPIType:"Manual",KPIMSValue:33})
    let salesUI = (type, value, isEditable) => {
      return value || isEditable ? (
        <>
          <div className="kpi-label">{type}</div>
          {isEditable ? <input id={`kpi${question.Question_Id}`
          } value={question.KPIManualValue || ''}
            className={`kpi-value ${readOnlyClass} kpi-value-max-width40`
            } onChange={(e) => this.validateKPI(e, question)} onBlur={(e) => this.handleKPIChange(e, question.Question_Id)} /> : <div className="kpi-value">{value}</div >}
        </>
      ) : ""
    };

    let kpi = (sales, service) => (
      <>
        <div className="sales-kpi kpi-container">
          {sales}
        </div>
        <div className="service-kpi kpi-container">
          {service}
        </div>
      </>
    )

    let kpiReturns = "";
    if (question.KPIType == undefined
      || question.KPIType == null
      || question.KPIType == "") {
      return "";
    }
    else if (question.KPIType === "VOG") {
      if (question.Auto_KPI_1) {
        if (question.Auto_KPI_2) {
          kpiReturns = kpi(
            salesUI(this.getKpiLabel(question.Auto_KPI_1_Label), question.KPIVOGSaleValue, false),
            salesUI(this.getKpiLabel(question.Auto_KPI_2_Label), question.KPIVOGServiceValue, false)
          )
        }

        else if (!question.Auto_KPI_2) {
          kpiReturns = kpi(
            salesUI(this.getKpiLabel(question.Auto_KPI_1_Label), question.KPIVOGSaleValue, false)
          )
        }
      }
    }

    else if (question.KPIType === "MS") {
      if (question.Auto_KPI_1) {
        kpiReturns = kpi(
          salesUI(this.getKpiLabel(question.Auto_KPI_1_Label), question.KPIMSValue, false)
        )
      }
    }

    else if (question.KPIType === "Manual") {
      kpiReturns = kpi(
        salesUI(this.getKpiLabel(question.Manual_KPI_Label), question.Manual_KPI_Label, true)
      )
    }
    return kpiReturns;
  }

  setDynMsg = (showSuccess) => {
    let revmsg = ''
    if (showSuccess === 'yes') {
      revmsg = this.state.saveType === "save" ||
        this.state.saveType === "saveAndClose" ||
        this.state.saveType === "submitReview" ||
        this.state.saveType === "sendBack" ||
        this.state.saveType === "approveReview" ? "Review saved successfully."
        : "";

      if (revmsg === "" && this.state.saveType === "delete") {
        revmsg = "Review deleted successfully";
      }
    }
    else if (showSuccess === 'no') {
      revmsg = this.state.saveType === "save" ||
        this.state.saveType === "saveAndClose" ||
        this.state.saveType === "submitReview" ||
        this.state.saveType === "sendBack" ||
        this.state.saveType === "approveReview" ? 'Save review failed. Please try again.'
        : "";

    }
    return revmsg;
  }
  // Method created to show tooltip when review for any question contains Action/photo/Recommendations
  showTooltip = (question) => {

    let checkActionPhoto = '';

    if (question.Sops.length > 0) {
      checkActionPhoto = checkActionPhoto.concat('Supporting SOP + ');
    }
    if (question.Documents.length > 0) {
      checkActionPhoto = checkActionPhoto.concat('Photos & Documents + ');
    }
    if (question.Actions.length > 0) {
      checkActionPhoto = checkActionPhoto.concat('Action + ');
    }
    if (question.Recommendations.length > 0) {
      checkActionPhoto = checkActionPhoto.concat('Observation + ');
    }

    if (checkActionPhoto.length > 0) {
      checkActionPhoto = checkActionPhoto.slice(0, -2);
      checkActionPhoto = checkActionPhoto.concat('Exists');
    }

    return checkActionPhoto;

  }
  toggleFlag(quest) {
    if (this.props.review.Status !== "Reopen") {
      const flagDetails = { ...this.state.flagDetails };
      const qId = quest.Question_Id;
      flagDetails[qId] = !(quest.IsQuestionFlag);
      this.setState({ flagDetails });
      this.props.onUpdateReviewState(qId, quest.Status, flagDetails[qId]);
      const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
        + '(' + this.props.currentUser.employeeNumber + ')' : 'default';

      let finalMsg = this.state.auditLogMsg;
      let msg;
      if (quest.IsQuestionFlag) {
        msg = currentUser + ' activated Flag for ' + quest.Question_Id + ' for ' + this.props.review.Assessment_Id
          + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
      }
      else {
        msg = currentUser + ' deactivated Flag for ' + quest.Question_Id + ' for ' + this.props.review.Assessment_Id
          + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
      }

      if (!this.state.auditLogMsg.includes(qId)) {
        finalMsg = finalMsg + msg;
      } else {
        finalMsg = msg;
      }
      this.setState({
        auditLogMsg: finalMsg,
        AcionAPISourceName: "Conduct Review"
      });
    }
  }

  openUpdatedReview() {
    if (this.state.shareRevieData.defaultMsg) {
      this.props.onFetchReview(this.props.tflType, this.props.match.params.id);
    }
    this.setState({ showShareOverlay: false });
  }

  render() {
    let ObjStatus = {
      ApprovalStage: this.props.review.isApprovalStage,
      RoleName: this.props.RoleName,
      Status: this.props.review.Status,
      Program: this.props.review.Program,
      AssessmentType: this.props.review.Assessment_Type
    }
    //document.title = ObjStatus.RoleName +' / '+ ObjStatus.Status;
    const { value } = this.state;
    const readOnlyClass = (
      (this.props.location.state && this.props.location.state.isReadOnlyMode) ||
      (this.props.review && this.props.review.Status === "Completed") ||
      (this.props.review && this.props.review.Status === "Deleted") ||
      (this.props.review && !this.props.review.ActiveDealer) ||
      (ObjStatus.Status === 'Pending 2nd' && ObjStatus.RoleName === 'accessor') ||
      (ObjStatus.Status !== 'In-progress' && ObjStatus.RoleName === 'dealerUser')
    ) ? "disabled-review" : "";
    const canAddSOP = (ObjStatus.Status !== 'Completed' && ObjStatus.RoleName === 'dealerUser')
    //hide buttons before data is fetched
    const elHide = !this.props.review || (this.props.review && this.props.review.Status === "Completed") ||
      (this.props.review && !this.props.review.ActiveDealer) ||
      (ObjStatus.Status === 'Pending 2nd' && ObjStatus.RoleName === 'accessor') ||
      (ObjStatus.Status !== 'In-progress' && ObjStatus.RoleName === 'dealerUser') || (ObjStatus.Status === 'Deleted') ? "hide" : "";

    const locationName = this.props.review.Location ? this.props.review.Location : '';
    const address = this.props.review ? this.props.review.Address_Street + ' ' + this.props.review.Address_Suburb + ' ' + this.props.review.Address_State + ' ' + this.props.review.Address_PostCode : '';
    const isCompleteReviewDisabled = this.props.hasReviewNotStarted ? this.props.hasReviewNotStarted : this.props.reviewEnable
    const reviewStatusValue= this.props.location.state?this.props.location.state.reviewStatus.Status:"none";
    const programNameValue= this.props.location.state?this.props.location.state.programName.Program:"none";
    return (
      
      <div>
        {this.props.unauthUser && this.props.unauthUser.StatusCode === 401 && (
          <UnauthorizePage msg={this.props.unauthUser.Message} />
        ) || (this.props.unauthUser && this.props.unauthUser.StatusCode === 404 && (
          <PageNotFound msg={this.props.unauthUser.Message} />
        )) ||
         this.props.loader==="show" && !this.props.review && (
          <SkeletonsReview reviewStatus= {reviewStatusValue} programName={programNameValue} />
        ) ||
          (
          
            <div className="main-content main-content-conduct-assessment">
              <div className="small-popup-wrap">
                {PopUpShareReviewTrigger((<div className="hide"></div>),
                  this.state.shareRevieData, this.state.showShareOverlay, this.openUpdatedReview)}
              </div>
              <div className={`page-header page-header-conduct-assessment`}>
                <div className="row">
                  <div className={`col-md-5 col-lg-6 col-12 ${readOnlyClass && false}`}>
                    {this.props.review && this.props.review.Assessment_Name &&
                       // <TruncateMarkup lines={3} >
                    <div className='heading-container'>
                      <div className="h2 assessment-title" title={this.props.review.Assessment_Name}>
                        {this.props.review.Assessment_Name}

                      </div>

                      <Status Reviewstatus={this.props.review.Status} />
                    </div>
                    }
                    {this.props.review.Status === 'In-progress' && this.props.RoleName !== 'dealerUser' ?
                      <div className="assessment-link">
                        <a href="" onClick={(e) => this.saveCurrentStateToExcel(e)} title="Download Review">Download Review</a>
                        <Popup
                          trigger={
                            <a href="#" onClick={() => {
                              this.setState({ isOpen: true })

                            }
                            } title="Upload Review">Upload Review</a>
                          }
                          modal
                          closeOnDocumentClick
                          lockScroll
                          open={this.state.isOpen}
                        >
                          {close => (
                            <div>
                              <div title="" className="com-small-container">

                                <div className="com-popup_wrapper small-popup-wrap">
                                  <div className="close__wrapper upload-review-pop">
                                    <div className="popup__close" onClick={close}>
                                      <label><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
                                    </div>
                                  </div>
                                </div>

                                <UploadReviewPopup
                                  onClose={close}
                                  program={this.props.review.Program}
                                  locationName={locationName}
                                  AssessmentType={this.props.review.Assessment_Type}
                                  AssessmentName={this.props.review.Assessment_Name}
                                  review={this.props.review}
                                  excelColumns={this.props.currentStateJSON && this.props.currentStateJSON.length ? this.props.currentStateJSON[0].columns : []}
                                />
                              </div>
                            </div>
                          )}
                        </Popup>

                      </div> : null}
                  </div>
                  <div className="col-md-7 col-lg-6 d-none d-sm-none d-md-block">
                    <div className="show-filters">
                      {((this.props.review.Status === "Completed" && this.props.review.Program === "TFL" && (this.props.review.Assessment_Type === "TFL-9"||this.props.review.Assessment_Type ==="TFL 1~8") && new Date(this.props.review.StartDate).getFullYear() > 2019)
                        &&
                        <>
                          <DropdownButton
                            title="Export"
                            variant='blank'
                            className={`downloadButton  m-r-40 `}>
                           <Dropdown.Item onClick={(e) => this.saveCurrentStateToDownloadExcel(e)}><Image className="downloadImage" src="../images/excel.svg" /> Excel</Dropdown.Item>
                          </DropdownButton>

                          <Popup trigger={
                            this.props.review.Assessment_Type !="TFL 1~8" && <span className="">

                            <button value="0" type="button" onClick={(e) => this.showScoreData(e)} className={`btn  btn-primary  m-r-20`}>Score</button>
                          </span>}
                            modal
                            closeOnDocumentClick
                            lockScroll
                            open={this.state.open}
                          >
                            {close => (
                              <div onClick={(e) => e.preventDefault(e)}>
                                {((this.props.scoreInfo || this.props.scoreInfoerror) &&
                                  <div className="deletepopup-container">

                                    <div className="deletepopup_wrapper small-popup-wrap">

                                      <div className="close__wrapper">
                                        <div className="popup__close" onClick={close}>
                                          <label><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>

                                        </div>
                                      </div>
                                    </div>

                                    <ScorePopup scoreInfoerror={this.props.scoreInfoerror} DealerNamesuburb={this.props.scoreInfo.DealerNamesuburb} tflscore={this.props.scoreInfo.Score} scoreDetailData={this.props.scoreInfo.SectionScore || []} onClose={close} />
                                  </div>)}
                              </div>

                            )}

                          </Popup>
                        </>
                      )}

                      {((this.props.review.Status === "Completed" || (this.props.review.Status === 'Pending 2nd' && this.props.RoleName === 'accessor') ||
                        (this.props.review.Status !== 'In-progress' && this.props.RoleName === 'dealerUser')) && this.props.review.Program !== "TFL" &&
                        <button className={`btn btn-primary share`} onClick={this.sendEmail.bind(this)} >Share</button>
                      )}

                      <button disabled={this.props.hasReviewNotStarted} onClick={e => window.confirm("Are you sure you wish to delete this review?") && this.saveReview(e, 'delete')} value="6" type="button" className={`btn btn-blank m-r-20 ${elHide}`}>Delete</button>
                      <button disabled={this.props.hasReviewNotStarted} onClick={(e) => this.saveReview(e, 'save')} value="0" type="button" className={`btn btn-blank m-r-20 ${elHide}`}>Save</button>
                      <button disabled={this.props.hasReviewNotStarted} onClick={(e) => this.saveReview(e, 'saveAndClose')} value="0" className={`btn btn-blank  m-r-20  ${elHide}`} ref={this.headerRef}>Save & Close</button>
                      {this.getApprovalBtnType(ObjStatus, elHide, isCompleteReviewDisabled)}
                    </div>
                  </div>
                </div>
                <div className="row m-t-10">
                  <div className="col-6">
                    <h6>{locationName}</h6>
                  </div>
                  <div className="col-6">

                  </div>
                  <div className="col-12 col-md-6">
                    <div className="dealership-location">
                      {address}
                    </div>
                  </div>
                  <div className="col-12 col-md-6 text-right text-m-left mar-tn-10">
                    {/* <div className="complete-status">Complete: <span>5/20</span></div>
                    <div className="score">Score: <span>80</span></div> */}
                  </div>
                </div>
              </div>
              <div className="legend-wrapper d-none d-sm-none d-md-block">
                <div className="row grid__columns-wrapper">
                  <div className="col-sm-12">
                    {/* <ul class="legend list-unstyled">
                        <li class="legend__dot legend__yellow">In- Progress</li>
                        <li class="legend__dot legend__green">Completed</li>
                    </ul> */}
                  </div>
                </div>
              </div>
              {
                this.props.review.Sections && (
                  <div>
                    {this.props.review.Program !== "TFL" &&
                    <JssProvider generateClassName={generateClassName}>
                      <AppBar position="static" color="default">
                        <Tabs
                          value={value}
                          onChange={this.handleChange}
                          variant="scrollable" scrollButtons="auto"
                          indicatorColor="primary"
                          textColor="primary"
                        >

                          {(this.props.review.Sections || []).map(section => (
                            <Tab className="truncate" title={section.Section_Name}
                              icon={(!this.props.hasReviewNotStarted && section.valid === 'invalid') ? <img src="../images/alert-new.svg" width="20px" alt="Failure" /> : (this.props.hasReviewNotStarted ? '' : <img src="../../images/green-tick.svg" alt="Success" />)}
                              label={
                                section.Section_Name

                              }
                            />
                          ))}
                        </Tabs>
                      </AppBar>
                    </JssProvider>
                    }
                    <TabContainer>
                    {this.props.review.Program === "TFL"? this.getSections(readOnlyClass, elHide,canAddSOP,locationName,address) :

                      (this.props.review.Sections[value].Criterias || []).map(criteria => (
                        <div
                          className="tab-pane fade show active"
                          role="tabpanel"
                          aria-labelledby="tab04"
                        >
                          <div className="position-relative long-title">
                            <TruncateMarkup lines={2} >
                              <div className="h4 m-t-40 m-b-15 " title={criteria.Criteria_Name}>
                                {criteria.Criteria_Name}
                              </div>
                            </TruncateMarkup>
                            {criteria.Criteria_Guidance &&
                              <a href="javascript:void(0)">
                                <img
                                  className="questions__image float-right"
                                  key={criteria.Criteria_Id}
                                  onClick={(e) => this.questionClicked(e, criteria.Criteria_GuidanceURL)}
                                  src="../images/criteria.svg"
                                  alt="Criteria"
                                />
                              </a>
                            }
                          </div>



                          <div className="questions">
                            {(criteria.Questions || []).map((question, index) => (
                              <div className="questions__row">
                                <div className="questions__header">
                                  <div className="row">
                                    <div className="col-md-12 col-lg-5 col-xl-5 questions__left-col">
                                      <div className={`questions__count active ${((question.QuestionComment && question.QuestionComment.length > 0) || question.Comments.length > 0) ? "commented" : ""}`}>{index + 1}</div>
                                      <div className="questions__question">
                                        {question.Method_Text}
                                        {question.Method_List.length > 0 && (
                                          <ul>
                                            {(question.Method_List || []).map(list => (
                                              <li>
                                                {list}
                                              </li>
                                            ))}
                                          </ul>
                                        )}
                                        {!this.props.hasReviewNotStarted && (question.valid === 'invalid' || question.flagValid === 'invalid') &&
                                          <img className="questions__alert-icon-mobile" src="../images/alert-new.svg" alt="Warning" />
                                        }
                                        {question.Question_Guidance &&
                                          <a href="javascript:void(0)">
                                            <img
                                              className="m-r-15 questions__guidance-icon-mobile"
                                              key={question.Question_Id}
                                              onClick={(e) => this.questionClicked(e, question.Question_GuidanceURL)}
                                              width="20" height="20" src="../images/criteria.svg"
                                              alt="Question"
                                            />
                                          </a>
                                        }
                                      </div>
                                    </div>
                                    <div className="col-md-12 col-lg-2">
                                      {this.displayKPIData(question, readOnlyClass)}
                                    </div>
                                    <div className={`col-md-12 col-lg-5 col-xl-5 questions__right-col text-right ${readOnlyClass}`}>
                                      {!this.props.hasReviewNotStarted && (question.valid === 'invalid' || question.flagValid === 'invalid') &&
                                        <img className="m-r-15 m-r-20-l questions__alert-icon-desktop" width="20" height="20" src="../images/alert-new.svg" alt="Warning" />
                                      }
                                      {question.Question_Guidance &&
                                        <a href="javascript:void(0)">
                                          <img
                                            className="m-r-15 m-r-20-l questions__guidance-icon-desktop"
                                            width="20" height="20" src="../images/criteria.svg" alt="Question"
                                            key={question.Question_Id}
                                            onClick={(e) => this.questionClicked(e, question.Question_GuidanceURL)}
                                          />
                                        </a>
                                      }
                                      {question.Responses && question.Responses.length > 0 && (
                                        <ResponseOptions
                                          toggle={(e, resp) => this.toggle(e, question.Question_Id, resp, readOnlyClass)}
                                          toggleClass={(e) => this.toggleClass(e)}
                                          questionId={question.Question_Id}
                                          responseOptionsDropDownList={question.Responses}
                                          questionResponse={this.state.questionResponse}
                                          question={question} reviewStatus={this.props.review.Status}
                                          isReadOnlyMode={this.props.review.ActiveDealer}
                                          Assessment_Id ={this.props.review.Assessment_Id}
                                       />)}

                                      {/* Display tootip when review is Not at standard for any question and Action/obervation/photos are updated */}
                                      {((question.Actions.length > 0) || (question.Recommendations.length > 0)
                                        || (question.Documents.length > 0) || (question.Sops.length > 0)) && (

                                          <div className="tooltipicon">
                                            <br />
                                            <img id={question.Question_Id} className="questions__redhelp-button" src="../images/RedHelp.png" data-tip data-for={question.Provided_Question_Id} />
                                            <ReactTooltip id={question.Provided_Question_Id} place="left" effect="solid" getContent={() => this.showTooltip(question)} />

                                          </div>
                                        )}

                                      {((this.props.review.Status === "Pending 1st") || (this.props.review.Status === "Pending 2nd")
                                        || (this.props.review.Status === "Reopen")) &&
                                        <div key={question.Question_Id}
                                          onClick={e => this.toggleFlag(question)}
                                          className={`flag-icon ${question.IsQuestionFlag ? "active-flag" : this.props.review.Status === "Reopen" ? "hide" : ""} ${elHide}`}>
                                          <img className="in-active" src="../images/flag.svg" width="26px" />
                                          <img className="active" src="../images/flag-active.svg" width="26px" />
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                                <ReviewCollapse questionResponse={question.Status===null?"":question.Status} qDetails={question} qAssessment_Response_ID={!(question.Assessment_Response_ID && question.Assessment_Response_ID!==0)} key={question.Question_Id} review={this.props.review} readOnlyClass={readOnlyClass} elHide={elHide} canAddSOP={canAddSOP} locationDetails={{ locationName, address }}
                                  doPostAuditLogData={this.props.doPostAuditLogData}
                                  currentUser={this.props.currentUser} auditLogMsg={this.state.auditLogMsg}/>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    { this.props.review.Program === "TFL" &&
                      <ResponseProgressBar  review={this.props.review.Sections} resposeStateValue={this.state.questionResponse}/>}
                    </TabContainer>
                    <div className={`buttons-wrapper text-center d-none d-sm-none m-t-30 d-md-block`}>
                      {/* <button className="btn btn-gray  m-r-10">Back</button> */}
                      
                      {/* {((this.props.review.Status === "Completed" || (this.props.review.Status === 'Pending 2nd' && this.props.RoleName === 'accessor') ||
                        (this.props.review.Status !== 'In-progress' && this.props.RoleName === 'dealerUser')) &&
                        <button className={`btn btn-primary share`} onClick={this.sendEmail.bind(this)} >Share</button>
                      )} */}
                      <button disabled={this.props.hasReviewNotStarted} onClick={e => window.confirm("Are you sure you wish to delete this review?") && this.saveReview(e, 'delete')} value="6" type="button" className={`btn btn-blank m-r-20 ${elHide}`}>Delete</button>
                      <button disabled={this.props.hasReviewNotStarted} onClick={(e) => this.saveReview(e, 'save')} value="0" type="button" className={`btn btn-blank m-r-20 ${elHide}`}>Save Review</button>
                      <button disabled={this.props.hasReviewNotStarted} onClick={(e) => this.saveReview(e, 'saveAndClose')} value="0" type="button" className={`btn btn-blank m-r-20 ${elHide}`}>Save & Close</button>
                      {this.getApprovalBtnType(ObjStatus, elHide, isCompleteReviewDisabled)}
                    </div>
                    <div className={`button-wrapper-mobile text-center clearfix d-block d-sm-block d-md-none`}>
                      <div className="review-buttons-mobile clearfix">
                        <div className={`save-btn clearfix ${elHide}`}>
                          <button disabled={this.props.hasReviewNotStarted} onClick={(e) => this.saveReview(e, 'save')} value="0" type="button" className="btn btn-blank m-r-20 float-left">Save Review</button>
                          <button disabled={this.props.hasReviewNotStarted} onClick={(e) => this.saveReview(e, 'saveAndClose')} value="0" type="button" className="btn btn-blank float-right">Save & Close</button>
                        </div>

                        {(this.props.review.Status === "Completed" && this.props.review.Assessment_Name !== "TFL 1~8" &&
                          <button className={`btn btn-primary share`}>Share</button>
                        )}
                      </div>
                      {this.getApprovalBtnType(ObjStatus, elHide, isCompleteReviewDisabled)}
                    </div>
               
                  </div>
                 
                )
              }

            </div >
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  review: getReview(state),
  currentUser: getCurrentUser(state.dropdownState),
  showSuccess: getSuccessStatus(state),
  hasReviewNotStarted: getReviewNotStartedStatus(state),
  getValidation: getReviewValidation(state),
  getFilterStatus: getFilterLabel(state),
  completedReview: getCompleteReviewStatus(state),
  reviewEnable: getCompleteReviewButtonStatus(state),
  isLoading: state.reviewState.isLoading,
  isDocAdded: state.reviewState.isDocAdded,
  dealersList: state.locationListState.locationsList,
  dealerInfo: state.reviewState.dealerInfo,
  scoreInfo: state.reviewState.tflscoreInfo,
  scoreInfoerror: state.reviewState.tflscoreInfoerror,
  unauthUser: getUnAuthReviewResponse(state),
  RoleName: getUserRole(state),
  questionsFlagged: getNumberOfFlags(state.reviewState),
  currentStateJSON: getCurrentStateIntoJSON(state.reviewState),
  currentStateExcelJSON: getCurrentStateIntoExcelJSON(state.reviewState),
  loader: getLoaderStatus(state),

});
const mapDispatchToProps = (dispatch) => ({
  toggleFilter: () => dispatch(toggleSidebar()),
  onFetchReview: (reviewType, reviewId) => dispatch(doFetchReview(reviewType, reviewId)),
  onSaveReview: (reviewResponse, isSubmit) => dispatch(doSaveReview(reviewResponse, isSubmit)),
  onUpdateReviewState: (qId, qResponse, flagStatus) => dispatch(doUpdateReviewState(qId, qResponse, flagStatus)),
  onValidateReviewState: (qId, qResponse) => dispatch(doValidateReviewState(qId, qResponse)),
  onCompleteReview: (reviewResponse) => dispatch(doCompleteReview(reviewResponse)),
  clearData: () => dispatch(doClearReviewData()),
  doClearDocData: () => dispatch(doClearDocData()),
  updateKPIData: (questionId, value) => dispatch(doUpdateKPIData(questionId, value)),
  onFetchDropDowns: (statusType) => dispatch(doFetchScheduleDropDowns(statusType)),
  showToast: (payload) => dispatch(showToast(payload)),
  doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
  doUpdateCSVReview: (payload) => dispatch(doUpdateCSVReview(payload)),
  doFetchScoreData: (payload) => dispatch(doFetchScoreData(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);

/*export const connectedReview = connect(
    mapStateToProps,
    mapDispatchToProps
)(Review);

export default ReactAI.withTracking(withRouter(connectedReview),"Review9/Review.js");*/

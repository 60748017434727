import { call, put,select } from 'redux-saga/effects';
import { doAddSOPDocs, doFetchErrorSOPDocsList } from '../actions/actionsUploadSOP';
import { doFetchLocationsList, FetchLocationsListSuccess, FetchLocationsListError } from '../actions/locationsList';
import {  doAddProgramsList, doFetchErrorProgramsList } from '../actions/programsList';
import {  doAddSOPNamesList, doFetchErrorSOPNamesList, unAuthorisedSOP } from '../actions/sopNameList';
import { fetchSOPDocsList,fetchProgramList, fetchSOPNamesList,fetchLocationList, uploadSOPDocument } from '../api/api_uploadSOP';

import { getDealerSOPListQuery } from '../selectors/uploadSOP';
import {doHideLoader, doShowLoader} from "../actions/loader";
import {getToken} from "../utils/utils";


function* handleFetchLocationsList (action) {
    if (action.status.program_id) {
        yield put(doShowLoader());
    }

    const token = getToken();
    if(token) {

        const result = yield call(fetchLocationList, action.status);
        try {
            const payload = {result: result, status: action.status};
            yield put(FetchLocationsListSuccess (payload));
            if (action.status.program_id) {
                yield put(doHideLoader());
            }
        } catch (error) {
            yield put(FetchLocationsListError(error));
            if (action.status.program_id) {
                yield put(doHideLoader());
            }
        }
    }

}

function* handleFetchProgramsList (action) {

    try {
        const result = yield call(fetchProgramList);
        if(result.data.StatusCode===200){
            yield put(doAddProgramsList (result));
        }
    
        else if(result.data.StatusCode===401){
            yield put(unAuthorisedSOP(result.data));
        }
      

    } catch (error) {
        yield put(doFetchErrorProgramsList(error));

    }
}

function* handleFetchSOPNamesList (action) {
    // yield put(doShowLoader());
    const {deviceType, sopNamesList} = action;
  try {
    const result = yield call(fetchSOPNamesList,action.sopNamesList);
      yield put(doAddSOPNamesList (result.data.data));
    // yield put(doHideLoader());
  } catch (error) {
        yield put(doFetchErrorSOPNamesList(error));
        // yield put(doHideLoader());
    }
}

function* handleFetchDealerSOPDocsList (action) {
    yield put(doShowLoader());
      const {selectedLocation,sopdropselect} = action;
      const uploadSOPState = yield select((state)=> state.uploadSOPState);
      const query = getDealerSOPListQuery (uploadSOPState, action);
    try {
        const result = yield call(fetchSOPDocsList, query);

        yield put(doAddSOPDocs(selectedLocation, result.data.data, result.data.Count));
    yield put(doHideLoader());
  } catch (error) {
        yield put(doFetchErrorSOPDocsList(error));
        yield put(doHideLoader());
    }
}

function* handleUploadSOPDocument (action) {
    // yield put(doShowLoader());
    const {selectedLocation,sopdropSelect, programSelect,selectedFileForUpload, expiryDate, uploadEvent } = action;
    // const reader = new FileReader();
    // reader.readAsDataURL(selectedFileForUpload);

    const uploadSOPState = yield select((state)=> state.uploadSOPState);
    //const query = getUploadSOPDocQuery (uploadSOPState, action);

  // try {
  //     reader.onload = event => {
  //       let result = yield call(uploadSOPDocument, query);
  //       // yield put(doAddSOPDocs(selectedLocation, result.data.data, result.data["Count"]));
  //       // yield put(doHideLoader());
  //     }
  // } catch (error) {
  //       yield put(doUploadSOPDocumentError(error));
  //       // yield put(doHideLoader());
  //   }
}



export {
  handleFetchLocationsList, handleFetchProgramsList, handleFetchSOPNamesList,
  handleFetchDealerSOPDocsList, handleUploadSOPDocument
};

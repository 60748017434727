const getLoaderStatus = ({ loaderState }) => {
    return loaderState.loader;
};

const getLoaderModalStatus = ({ loaderState }) => {
    return loaderState.loaderModal;
};

const getSuccessStatus = ({ loaderState }) => {
    return loaderState.showSuccess;
};

const getCompleteReviewStatus = ({ loaderState}) => {
    
    return loaderState.showSuccess;
}



export {
  getLoaderStatus,getSuccessStatus, getCompleteReviewStatus,getLoaderModalStatus
};
import React from "react";
import { connect } from 'react-redux';
import { getFilterLabel } from "../../selectors/story";
import { doSortStories } from "../../actions/story";
import { toggleSidebar } from "../../actions/header";

export const TFLScoreStoriesHeader = (props, { onSort, toggleFilter, filterLabel, myForwardedRef }) => {
  return (
    <div ref={myForwardedRef} tabIndex={1} >
      <div className="show-filters">                
        {/* <button className="btn btn-primary start-tfl-btn m-r-20">Start TFL 1-8 Review</button> */}
        <button id="Show_Filters"
          onClick={() => props.toggleFilter()}
          type="button"
          className="btn btn-white"
          data-toggle="button"
          aria-pressed="false"
          autoComplete="off"
        >
          <img src="images/filter-icon.svg" alt="Filters" className="filter-g m-r-10" />
          <img src="images/filter-w.svg" alt="Filters" className="filter-hover m-r-10" />
          <span className="show-filter-text">Filter</span>
        </button>
      </div>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  //onSort: sortBy => dispatch(doSortStories(sortBy, 'action')),
  toggleFilter: () => dispatch(toggleSidebar()),
});

const mapStateToProps = state => ({
  filterLabel: getFilterLabel(state),
});

const ConnectedMyComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TFLScoreStoriesHeader);

export default React.forwardRef((props, ref) =>
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
);
import { array } from "is_js";
import React from "react";
import SkeletonSchedule from "./SkeletonSchedule"


function ReturnSkeleton(){
    return (
        <SkeletonSchedule/>
    )
}

export default function SkeletonsSchedule(params) {
    return(
        [...Array(20)].map((n) =>ReturnSkeleton() )
    )
}
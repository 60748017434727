import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import { doAddStories, doSortStories, doFetchErrorStories, doAddToDropdowns, doShareReviewStatus } from '../actions/story';
import { fetchDealerInfoById, unAuthorisedReview, doAddGroupId, doFetchReviewError } from '../actions/review';
import { unAuthorisedUser } from '../actions/story';
import { FetchLocationsListSuccess, FetchLocationsListError } from '../actions/locationsList';

import {
    fetchActionStories,
    fetchStories,
    fetchTitleLookup,
    fetchTfl18Stories,
    fetchAssessmentStatusLookup,
    fetchStatusLookup,
    fetchLastUpdateByLookup,
    fetchPriorityLookup, fetchTflNineReview, postReview, submitScore,
    completeReview, shareReview
} from '../api/story';
import { fetchScoringList, fetchTFLScoringList } from '../api/TflScores';
import { fetchInvestigationRecordsList } from '../api/Investigations';
import { getScheduleListParams } from '../selectors/story';
import { doHideLoader, doShowLoader } from "../actions/loader";
import { doAddReview } from "../actions/review";
import { getCurrentUser } from "../selectors/dropdown";
import { doAddScoringList } from '../actions/TflScores';
import { getToken } from "../utils/utils";
import { fetchLocationList } from '../api/api_uploadSOP';
import { doPopulateInvestigationList } from '../actions/Investigations';
import { getCurrentUserDetails } from '../constants/utilityfunctions';

function* handleFetchStories(action) {
    yield put(doShowLoader());
    const { reviewType, deviceType } = action;
    const filterState = yield select((state) => state.filterState);
    const query = getScheduleListParams(filterState);
    let usersList = yield select((state) => state.dropdownState);
    // if (usersList && usersList.oktaUsers.length === 0) {
    //     let oktaUsers = yield call(fetchLastUpdateByLookup);
    //     oktaUsers = oktaUsers.data.data;
    //     yield put(doAddToDropdowns(oktaUsers, 'OktaUsers'));
    //     usersList.oktaUsers = oktaUsers;
    // }
    const currentUser = getCurrentUser(usersList);
    try {
        let result;

        if (reviewType === 'tfl18') {
            result = yield call(fetchTfl18Stories, query);

        } else if (reviewType === 'action') {
            query.postParams = query.postParams.filter(val => val.Field !== 'Dealer_Id');
            query.postParams = query.postParams.filter(val => val.Field !== 'Year');
            query.postParams = query.postParams.filter(val => val.Field !== 'Stage');
            query.postParams = query.postParams.filter(val => val.Field !== 'region');

            result = yield call(fetchActionStories, query);
        } else if (reviewType === 'score') {                                    
            const payload = {
                Modified_By: currentUser ?
                    currentUser.firstName + ' ' + currentUser.lastName
                    + ' (' + currentUser.employeeNumber + ')' : 'default'
            }
            
            const index = query.postParams.findIndex(val => val.Field === 'Program');
            if (index > -1) {
                query.postParams.splice(index, 1);
            }

            query.postParams = query.postParams.filter(val => val.Field !== 'Dealer_Id');
            query.postParams = query.postParams.filter(val => val.Field !== 'Stage');
            query.postParams = query.postParams.filter(val => val.Field !== 'actionYear');
            query.postParams = query.postParams.filter(val => val.Field !== 'region');

            result = yield call(fetchScoringList, query, payload);
            for (let i = 0; i < result.data.data.length; i++) {
                result.data.data[i].isChecked = false;
            }
            yield put(doAddScoringList(result.data.data, result.Count, deviceType));
        }
        else if (reviewType === 'TFLscore') {
            query.postParams = query.postParams.filter(val => val.Field !== 'Dealer_Id');
            query.postParams = query.postParams.filter(val => val.Field !== 'Stage');
            query.postParams = query.postParams.filter(val => val.Field !== 'actionYear');
            let region=findRegion(query.postParams)
            const payload = {
                Modified_By: currentUser ?
                    currentUser.firstName + ' ' + currentUser.lastName
                    + ' (' + currentUser.employeeNumber + ')' : 'default',
                Region:region!==""?region:" "
                
                }
            const index = query.postParams.findIndex(val => val.Field === 'Program');
            const RegionIndex = query.postParams.findIndex(val => val.Field === 'region');
                
            if (query.postParams) {
                index > -1 &&  query.postParams.splice(index, 1);
                RegionIndex > -1 && query.postParams.splice(RegionIndex, 1);
            }
            result = yield call(fetchTFLScoringList, query, payload);
            for (let i = 0; i < result.data.data.length; i++) {
                result.data.data[i].isChecked = false;
            }
            yield put(doAddScoringList(result.data.data, result.Count, deviceType));
        }
        else if (reviewType === 'investigation') {            
            query.postParams = query.postParams.filter(val => val.Field !== 'Year');
            query.postParams = query.postParams.filter(val => val.Field !== 'actionYear');
            query.postParams = query.postParams.filter(val => val.Field !== 'Program');
            query.postParams = query.postParams.filter(val => val.Field !== 'Dealer_ID');
            query.postParams = query.postParams.filter(val => val.Field !== 'region');
            result = yield call(fetchInvestigationRecordsList, query);
            yield put(doPopulateInvestigationList(result.data.data, result.data.Count));
            yield put(doAddToDropdowns(result.data, 'InvestigationLookup'));
        }
        else {
            query.postParams = query.postParams.filter(val => val.Field !== 'Dealer_Id');
            query.postParams = query.postParams.filter(val => val.Field !== 'Year');
            query.postParams = query.postParams.filter(val => val.Field !== 'actionYear');            
            query.postParams = query.postParams.filter(val => val.Field !== 'Stage');
            query.postParams = query.postParams.filter(val => val.Field !== 'region');
            result = yield call(fetchStories, query);
        }

        if (result.data.StatusCode === 200) {
            yield put(doAddStories(result.data.data, result.data.Count, deviceType));
        }

        else if (result.data.StatusCode === 401) {
            yield put(unAuthorisedUser(result.data));
        }

        yield put(doHideLoader());
    } catch (error) {
        yield put(doFetchErrorStories(error));
        yield put(doHideLoader());
    }
}
function findRegion(query){
    let arr=[]
    query.map((obj)=>{obj.Field==='region' && obj.SearchText.map((obj)=>{arr.push(obj.label) })})
    arr=arr.toString()
    return arr
}

function* handleSaveReviewById(action) {
    yield put(doShowLoader());
    const isSubmit = action.isSubmit
    const reviewState = yield select((state) => state.reviewState);
    if (reviewState && reviewState.review) {
        reviewState.review.LastModifiedBy = getCurrentUserDetails();
    }
    try {
        if (!reviewState.review.LastModifiedBy) {
            throw ({ error: 'Error fetching current user details' });
        }
        let result;
        result = yield call(postReview, reviewState, isSubmit);
        if (result && result.data.Success) {
            //if (isSubmit === "2" && reviewState.review.Status === 'Pending 2nd') {
            if ((!reviewState.review.isApprovalStage &&  isSubmit === "2") || (reviewState.review.Status === 'Pending 2nd' && reviewState.review.isApprovalStage && isSubmit === "2")) {
                yield call(submitScore, reviewState);
            }
            yield put(doHideLoader(null, 'saveReview'));
        } else {
            yield put(doHideLoader());
        }

    } catch (error) {
        //yield put(doFetchErrorStories(error));
        yield put(doHideLoader());
    }
}

function* handleCompletedReview(action) {
    yield put(doShowLoader());
    const reviewState = yield select((state) => state.reviewState);
    if (reviewState && reviewState.review) {
        reviewState.review.LastModifiedBy = getCurrentUserDetails();
    }
    try {
        let result;
        result = yield call(completeReview, reviewState);
        if (result && result.data.Success) {
            yield call(submitScore, reviewState);
        }
        // yield put(doCompleteReview(result));
        yield put(doHideLoader(null, 'saveReview'));
    } catch (error) {
        //yield put(doFetchErrorStories(error));
        yield put(doHideLoader());
    }

}

function* handleFetchReviewById(action) {
    yield put(doShowLoader());
    const { reviewType, reviewId } = action;
    const usersList = yield select((state) => state.dropdownState);
    // if (usersList && usersList.oktaUsers.length === 0) {
    //     let oktaUsers = yield call(fetchLastUpdateByLookup);
    //     oktaUsers = oktaUsers.data.data;
    //     yield put(doAddToDropdowns(oktaUsers, 'OktaUsers'));
    //     usersList.oktaUsers = oktaUsers;
    // }
    const currentUser = getCurrentUser(usersList);
    try {
        let result;
        if (reviewType === 'tfl9') {
            result = yield call(fetchTflNineReview, reviewId);
        } else {
            result = yield call(fetchTflNineReview, reviewId);
        }
        if (result && result.data.StatusCode === 200) {
            if (action.fetchGroupId) {
                yield put(doAddGroupId(reviewType, result.data.data));
                yield put(doHideLoader());
            } else {
                yield put(doAddReview(reviewType, result.data.data, currentUser));
                yield put(doHideLoader());
                // const dealerId = result.data.data.returnData[0].Dealer_ID;
                // let dealerInfo = yield select((state) => state.reviewState.dealerInfo);
                // if (!dealerInfo || dealerInfo.Dealer_ID !== dealerId) {
                //     let dealersList = yield select((state) => state.locationListState.locationsList);
                //     if (dealersList.length === 0) {
                //         const locationListState = yield call(fetchLocationList, {
                //             is_active: 1,
                //             program_id: ''
                //         });
                //         dealersList = locationListState.data.data;
                //     }
                //     const dealerInfo = dealersList.filter(el => el.Dealer_ID === dealerId);
                //     yield put(fetchDealerInfoById(dealerInfo[0].Sap_Code));
                // }
            }
        }
        else if (result && (result.data.StatusCode === 401 || result.data.StatusCode === 404)) {
            yield put(unAuthorisedReview(result.data));
            yield put(doHideLoader());
        }
    } catch (error) {
        yield put(doFetchReviewError(error));
        yield put(doHideLoader());
    }
}




function* fetchOktaUsers() {
    let oktaUsers = undefined;
    if (sessionStorage.getItem("OktaUsers") === null) {
        //if OktaUser Doesnt Exist in session storage then create session storage
        oktaUsers = yield call(fetchLastUpdateByLookup);
        sessionStorage.setItem('OktaUsers', JSON.stringify(oktaUsers));
    }

    else {
        oktaUsers = JSON.parse(sessionStorage.getItem("OktaUsers"))
    }

    try {
        yield put(doAddToDropdowns(oktaUsers.data.data, 'OktaUsers'));

    } catch (exc) {
        console.log('🚀 Okta group users not fetched');
    }
}

function* handleFetchScheduleDropdowns(action) {
    //const {statusType} = action;
    const { statusType } = action.statusType;// DSA-1697
    let locationListResult;
    let assessmentResult; let statusResult; let lastUpdateByresult; let title; let priority; let creator;
    try {
        yield put(doShowLoader());
        const dropdownsState = yield select((state) => state.dropdownState);
        const { reviewType, deviceType } = action;
        const filterState = yield select((state) => state.filterState);
        const query = getScheduleListParams(filterState);
        /** Status api call is outside if to fetch atction and assessment statuses*/
        if (statusType === 'review') {
            lastUpdateByresult = dropdownsState.oktaUsers;
            if (lastUpdateByresult.length === 0) {
                lastUpdateByresult = yield call(fetchLastUpdateByLookup);
                lastUpdateByresult = lastUpdateByresult.data.data;
                yield put(doAddToDropdowns(lastUpdateByresult, 'OktaUsers'));
            }
            yield put(doAddToDropdowns(lastUpdateByresult, 'LastUpdateByStatusLookup'));
            yield put(doAddToDropdowns(lastUpdateByresult, 'LastConductedByStatusLookup'));
        } else if (statusType === "Start Review") {
            assessmentResult = yield call(fetchAssessmentStatusLookup, action);
            yield put(doAddToDropdowns(assessmentResult.data.data, 'AssessmentStatusLookup'));
        }
        else {
            const token = getToken();
            if (token) {
                if (statusType === 'score' || statusType === 'investigation') {
                    locationListResult = yield call(fetchLocationList, {
                        is_active: 1,
                        program_id: 1
                    });
                }
                else {
                    locationListResult = yield call(fetchLocationList, {
                        is_active: 1,
                        program_id: ''
                    });
                }
                try {
                    const payload = { result: locationListResult, status: { is_active: 1 } };
                    yield put(FetchLocationsListSuccess(payload));

                } catch (error) {
                    yield put(FetchLocationsListError(error));
                }
            }
            statusResult = yield call(fetchStatusLookup, statusType);
            yield put(doAddToDropdowns(statusResult.data.data, 'StatusLookup'));
            /** S- DSA 1697 */
            /** E - DSA 1697 */
            assessmentResult = yield call(fetchAssessmentStatusLookup, action);
            yield put(doAddToDropdowns(assessmentResult.data.data, 'AssessmentStatusLookup'));
          
            lastUpdateByresult = dropdownsState.oktaUsers;
            if (lastUpdateByresult.length === 0) {
                lastUpdateByresult = yield call(fetchLastUpdateByLookup);
                lastUpdateByresult = lastUpdateByresult.data.data;
                yield put(doAddToDropdowns(lastUpdateByresult, 'OktaUsers'));
            }
            yield put(doAddToDropdowns(lastUpdateByresult, 'LastUpdateByStatusLookup'));
            yield put(doAddToDropdowns(lastUpdateByresult, 'LastConductedByStatusLookup'));
            yield put(doAddToDropdowns(lastUpdateByresult, 'AssigneeLookup'));
            yield put(doAddToDropdowns(lastUpdateByresult, 'CreatorLookup'));

            title = yield call(fetchTitleLookup);
            yield put(doAddToDropdowns(title.data.data, 'TitleLookup'));
            // assignee = yield call(fetchAssigneeLookup);
            if (dropdownsState.PriorityLookup.length === 0) {
                priority = yield call(fetchPriorityLookup);
                yield put(doAddToDropdowns(priority.data.data, 'PriorityLookup'));
            }
        }

        if (statusType === 'score') {
            const scoreList = yield call(fetchScoringList, query);
            for (let i = 0; i < scoreList.data.data.length; i++) {
                scoreList.data.data[i].isChecked = false;
            }
            yield put(doAddToDropdowns(scoreList.data, 'ScoringLookup'));
        }
        yield put(doHideLoader());
    } catch (exc) {
        console.log("Error fetching records");
        yield put(doHideLoader());
    }
}

function* handleShareReview(action) {
    yield put(doShowLoader());
    const { reviewResponse } = action;
    const data = yield select((state) => state.reviewState);
    // const query =getScheduleListParams(filterState);

    try {
        let result;
        result = yield call(shareReview, action.reviewData);

        yield put(doShareReviewStatus(result.data.Success));
        yield put(doHideLoader(null, 'shareReview'));
    } catch (error) {
        //yield put(doFetchErrorStories(error));
        yield put(doHideLoader());
    }
}

export {
    handleSaveReviewById, handleFetchReviewById, handleFetchStories,/* handleFetchSortedStories, handleFilterStories, */handleFetchScheduleDropdowns, handleCompletedReview,
    handleShareReview, fetchOktaUsers
};
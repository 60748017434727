import {
  FETCH_LOCATIONS_LIST,
  FETCH_DEALERSOP_DOCS_LIST,
  ADD_SOPDOCS,
  ADD_SOPDOCS_FORMFIELDS,
  SORT_SOPDOCS,
  FETCH_ERROR_SOPDOCS,
  UPLOAD_SOP_DOCUMENT,
  UPLOAD_SOP_DOCUMENT_ERRORS
}
from '../constants/actionTypes';

const doAddSelectedLocation = (selectedFileForUpload, sopdropselect, programselect ) => ({
  type: ADD_SOPDOCS_FORMFIELDS,
    selectedFileForUpload,
    sopdropselect,
    programselect
});


const doAddSOPDocs = (selectedLocation, dealerSopDocsList, count, deviceType) => ({
  type: ADD_SOPDOCS,
    selectedLocation,
    dealerSopDocsList,
    count,
    deviceType
});

const doSortSOPDocs = (sortBy,reviewType) => ({
    type: SORT_SOPDOCS,
    sortBy,
    reviewType
});

const doFetchSOPDocsList = (selectedStatus, selectedLocation,sopdropselect) => ({
  type: FETCH_DEALERSOP_DOCS_LIST,
  selectedStatus,
  selectedLocation,
  sopdropselect
});

const doUploadSOPDocument = (uploadEvent, selectedLocation,sopdropselect, programSelect, selectedFileForUpload, expiryDate ) => ({
  type: UPLOAD_SOP_DOCUMENT,
  uploadEvent,
  selectedLocation,
  sopdropselect,
  programSelect,
  selectedFileForUpload,  
  expiryDate
});

const doUploadSOPDocumentError = error => ({
  type: UPLOAD_SOP_DOCUMENT_ERRORS,
  error,
});

const doFetchErrorSOPDocsList = error => ({
  type: FETCH_ERROR_SOPDOCS,
  error,
});

export {
  doAddSOPDocs,
  doFetchSOPDocsList,
    doSortSOPDocs,
  doFetchErrorSOPDocsList,
  doUploadSOPDocument,
  doUploadSOPDocumentError,
  doAddSelectedLocation
};

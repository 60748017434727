import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { createGenerateClassName } from '@material-ui/core/styles';
import {doResetSortAndFilter, doSortStories} from "../../actions/story";
import {fetchTflBreakdownScoreList} from "../../actions/TflScores";
import {getTflBreakDownScoreList} from '../../selectors/TflScores';
import { doFetchScoreData} from '../../actions/review';
function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: true,
    productionPrefix: '',
});

class TflScorebviewreakdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locationName: this.props.location.state.DealerNamesuburb,
            totalscore: this.props.location.state.totalscore,
        };
        this.renderTabVal = 0;
        this.headerRef = React.createRef();
        this.successRef = React.createRef();
        this.showMsg= false;
    }
   
    componentWillUnmount() {
    }
    componentDidMount() {
       
        const scorePayload = {
            "Program_Id": this.props.location.state.Program_Id,
                "Dealer_Id": this.props.location.state.Dealer_Id,                
                "Review_Id": this.props.location.state.Review_Id,
                "Review_Type": this.props.location.state.Review_Type,
               "IS_REPORT": this.props.location.state.IS_REPORT,
        }    
        this.props.doFetchScoreData(scorePayload);
      
    }
    

    handleBackBtnClick(){
        this.props.history.push('/sectionscoreview');
    }

    render() {
        return (
            <div>
            <div className="main-content error-screen text-center">
            <h2 className="m-t-50 m-b-30">Desktop only screen</h2>
            <a href="/home" className="btn btn-primary">Back to Home</a>
        </div>
            <div className="main-content tfl-score">
                <div>
                    <div className="page-header">
                        <div className="row">
                            <div className="col-12">
                                <h3>TFL Section Scores Breakdown</h3>
                            </div>
                            <div className="col-6">
                                <h6>{this.state.locationName}</h6>
                            </div>
                            <div className="col-6 text-right">
                                <div className="total-score">Total:<b>
                                {this.state.totalscore}
                                </b></div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="grid m-t-30">
                        <div className="grid__labels d-none d-md-none d-lg-block">
                            <div className="row grid__columns-wrapper">
                                <div className="col-lg-4 col-md-4">
                                Section  
                                </div>
                                <div className="col-lg-4 col-md-4">
                                Section ID
                                 
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    Score
                                 
                                </div>
                               
                             

                            </div>
                        </div>
                    </div>

                </div>   {this.props.tflbreakDownScoreListData && this.props.tflbreakDownScoreListData.length === 0 && this.props.loader !== 'show' && (
                    <div className="grid">
                        <div className="grid__row">
                            <div className="grid__columns-wrapper">
                                No Records Found.
                        </div>
                        </div>
                    </div>
                )}
                
                
                <div className="grid">

                {(this.props.tflbreakDownScoreListData || []).map((brkScoreObj, index) => (
                    
                        <div className="grid__row">
                            <div className="row grid__columns-wrapper">
                                <div className="col-lg-4 col-md-4">
                                    <span className="grid__labels-mobile">Section</span>
                                    {brkScoreObj.SectionName}
                                </div>

                                <div className="col-lg-4 col-md-4">
                                    <span className="grid__labels-mobile">SectionId</span>
                                    {brkScoreObj.SectionName2}
                                </div>
                                <div className="col-lg-4 col-md-4">
                                    <span className="grid__labels-mobile">Score</span>
                                    {brkScoreObj.SectionScore}
                                </div>
                              


                            </div>
                        </div>
                    
                     ))}
                </div> 
                <div className="legend-wrapper">
                    <div className="row grid__columns-wrapper">
                        <div className="col-sm-12">
                            <div className="buttons-wrapper text-center m-t-15">
                                <button type="button" class="btn btn-gray " id="back-button"
                                onClick = {()=>this.handleBackBtnClick()}
                                >
                                Back
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>




        );
    }
}

const mapStateToProps = (state) => ({
    scoreInfo: state.reviewState.tflscoreInfo,
    tflscoresuccessMessage:state.reviewState.tflscoresuccessMessage,
    tflbreakDownScoreListData:state.reviewState.tflbreakDownScoreListData,
    scoreInfoerror: state.reviewState.tflscoreInfoerror,
});
const mapDispatchToProps = (dispatch) => ({
    onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onfetchScoreBreakdownList: (reviewType, paramObj) => dispatch(fetchTflBreakdownScoreList(reviewType, paramObj)),
    doFetchScoreData: (payload) => dispatch(doFetchScoreData(payload)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TflScorebviewreakdown);
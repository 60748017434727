import {
  ACTION_ERRORS,
  CREATE_ACTION_DATA,
  FETCH_ACTIONDATA_SUC,
  CLEAR_DATA
} from '../constants/actionTypes';

const INITIAL_STATE = {
  actionId: 0,
  actionsList: [],
  count: 0,
  error: null,
};

/** A function to populated Action response */
const handleCreateActionReponse = (state, action) => {

  return ({
    actionData: action.actionData
  })
};

/** A function to populated Action response */
const handleFetchActionReponse = (state, action) => {

  return ({
    actionDetails: action.actionData
  })
};


const applyCreateActionErrors = (state, action) => {
  return {
    error: action.error
  }
};

export function createUpdateActionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    /*  case CREATE_ACTION : {
        return applyCreateAction(state, action);
      }
      case UPDATE_ACTION : {
        return state;
      } */
    case ACTION_ERRORS:
      {
        return applyCreateActionErrors(state, action);
      }

    case CREATE_ACTION_DATA:
      {
        return handleCreateActionReponse(state, action);
      }

    case FETCH_ACTIONDATA_SUC:
      {
        return handleFetchActionReponse(state, action);
      }

    case CLEAR_DATA:
      {
        state = INITIAL_STATE;
        return state;
      }
    default:
      return state;
  }
}


export default createUpdateActionReducer;
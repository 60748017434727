import React from "react";
import { connect } from 'react-redux';
import {getDropdownOptions} from "../../selectors/dropdown";
import {getPaginationDetails} from "../../selectors/story";

const Select = ({type,label, value, onChange, optionsData}) => {

    const optionsList =optionsData;
    return (
      <div id={`FSelect_${type}`} className="form-group">
        <label htmlFor="template_name" id={`labelFor__${type}`}>{label}</label>
        <select id={`option-list__${type}`} value={value} onChange={(e) => onChange(e)} className={`custom-select ${value ? '' : 'not-selected'}`}>
          {optionsList.default_option}
          {optionsList.data}
        </select>
      </div>
    )
}

const mapStateToProps = (state,ownProps) => ({
        type:ownProps.type,
        label: ownProps.label,
        value: ownProps.value,
        onChange: ownProps.onChange,
        optionsData:getDropdownOptions(state.dropdownState,ownProps.type)
});

const ConnectedMyComponent = connect(
    mapStateToProps
)(Select);

export default React.forwardRef((props, ref) =>
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
);
import React from "react";
import { connect } from 'react-redux';
import {getFilterLabel, getPaginationDetails} from "../../selectors/story";
import {doSortStories} from "../../actions/story";
import {toggleSidebar} from "../../actions/header";

export const StoriesHeader = ({onSort, toggleFilter, filterLabel,paginationData,myForwardedRef }) => (
  <div>
    <div ref={myForwardedRef} tabIndex={1} className="page-header">
      <div className="row grid__columns-wrapper">
        <div className="col-6">
          <h3 id="actions__heading">Actions</h3>
        </div>
        <div className="col-6">
          <div className="show-filters">
            {/* <button className="btn btn-primary start-tfl-btn m-r-20">Start TFL 1-8 Review</button> */}
            <button id="Show_Filters"
              onClick={() => toggleFilter()}
              type="button"
              className="btn btn-white"
              data-toggle="button"
              aria-pressed="false"
              autoComplete="off"
            >
                <img src="images/filter-icon.svg" alt="Filters" className="filter-g m-r-10" />
                <img src="images/filter-w.svg" alt="Filters" className="filter-hover m-r-10" />
                <span className="show-filter-text">Filter</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div className="legend-wrapper d-none d-sm-none d-md-block">
      <div className="row grid__columns-wrapper">
        <div className="col-sm-12">
          <p className="records-count">
            {(paginationData.activePage*20+1)-20}
-
            {(paginationData.totalCount - ((paginationData.activePage*20+1)-20)) >= 20 ? paginationData.activePage*20:paginationData.totalCount}
            {' '}
of 
            {' '}
            {paginationData.totalCount}
            {' '}
records
          </p>
        </div>
        {/* <div className="col-sm-8">
                    <ul className="legend list-unstyled">
                        <li className="legend__dot legend__red">Not started yet</li>
                        <li className="legend__dot legend__yellow">In-Progress</li>
                        <li className="legend__dot legend__green">Completed</li>
                    </ul>
                </div> */}
      </div>
    </div>

    <div className="grid">
      <div className="grid__labels d-none d-md-none d-lg-block">
        <div className="row grid__columns-wrapper">
          <div id="location__sort" onClick={() => onSort('Dealer_Name')} className="col-lg-2 col-md-2 grid__label-sort">
                        Location
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="title__sort" onClick={() => onSort('Title')} className="col-md-2 grid__label-sort">
                        Title
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="review__sort" onClick={() => onSort('Assessment_Template_Name')} className="col-md-2 grid__label-sort">
                        Review Name
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="create__sort"  onClick={() => onSort('AssessmentSubmitDate')} className="col-md-1 grid__label-sort">
                        Created Date 
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="due-date__sort" onClick={() => onSort('Due_Date')} className="col-md-1 grid__label-sort">
                        Due Date
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="priority__sort" onClick={() => onSort('DateClosed')} className="col-md-1 grid__label-sort">
                Date Closed
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="assignee__sort" onClick={() => onSort('Assigned_To')} className="col-md-1 grid__label-sort">
          Created By
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="status__sort" onClick={() => onSort('Status')} className="col-md-1 grid__label-sort">
                        Status
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div id="rating__sort" onClick={() => onSort('Rating')} className="col-md-1 grid__label-sort">
                        Rating
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
const mapDispatchToProps = dispatch => ({
    onSort: sortBy => dispatch(doSortStories(sortBy,'action')),
    toggleFilter: () => dispatch(toggleSidebar()),
});

const mapStateToProps = state => ({
    filterLabel: getFilterLabel(state),
    paginationData: getPaginationDetails(state),
});

const ConnectedMyComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(StoriesHeader);

export default React.forwardRef((props, ref) =>
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
);
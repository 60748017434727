import React, { Component } from 'react';
import { connect } from "react-redux";
import uniqueId from 'react-html-id';
import {
    doFetchStories,
    doFilterStories,
    doResetSortAndFilter,
    doFetchScheduleDropDowns,
    doAddFilter,
    doRemoveFilter
} from "../../actions/story";
import { getLocationsList, getProgramsList } from '../../selectors/uploadSOP';
import { getTflScoreList } from '../../selectors/TflScores';
import { getInvestigationRecordsData } from '../../selectors/Investigations';
import { getFullDateFromCurrentYear } from "../../utils/utils";
import { toggleSidebar } from "../../actions/header";
import {
    ASSESSMENT_TEMPLATE_DROPDOWN,
    STATUS_DROPDOWN,
    LASTUPDATEDBY_DROPDOWN,
    LASTCONDUCTEDBY_DROPDOWN,
    TITLE,
    ACTION_STATUS,
    PRIORITY,
    ASSIGNEE,
    CREATOR,

} from "../../constants/dropdowns";
import Select from './Select';
import 'react-widgets/dist/css/react-widgets.css';
import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';

import MultiSelectDropdown from '../Common/MultiSelectDropdown';
import { dropdownOptions, multiselectOptions } from "../../constants/uploadSOPConstants";
import LocationList from "./LocationList";
import ReviewList from './ReviewList';
import ProgramList from "./ProgramList";
import SapDealerList from "./SapDealerList";
import InvestigationFilter from "./InvestigationFilter";
import { fetchLocationList } from "../../actions/locationsList";
// import { null } from 'is_js';


Moment.locale('en')
momentLocalizer()
export class Filter extends Component {
    initialState = {
        // filterOpen: true
        Dealer_ID: "",
        Assessment_Template_Name: "",
        Last_Modified_By: "",
        Conducted_By: "",
        Program: "",
        Status: "",
        activeStatus: 1,
        StartDate: null,
        selectedLocationList: null,
        selectedReview: null,
        selectedProgram: null,
        selectedSapCode: null,
        statusList: null,
        priorityList: null,
        EndDate: null,
        Last_Updated_On: null,
        Priority: "",
        Assigned_To: "",
        Due_Date: null,
        Title: "",
        CreatedBy: "",
        filters: [],
        isApplyDisabled: true,
        year: '',
        actionYear: '',
        tflScoreYear : '',
        region:null,
        dropdownsFetched: false,
        selectedLocation: null,
        selectedStatus: null,
        selectedTitle: null,
        selectedStage: null,
        ClosedOnYear: null,
        End_Year: '',
        endyearList: [],
        yearList: [],
        actionyearList: [],
        tflScoreYearList : [],
        allYearList: [],
        regionList:[]
    };

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.onSelectDealer = this.onSelectDealer.bind(this);
        this.state = this.initialState;
        this.locDataListText = React.createRef();
        this.locationNameRef = React.createRef();
        this.statusRef = React.createRef();
        this.priorityRef = React.createRef();
        this.endyearRef = React.createRef();
        this.yearRef = React.createRef();
        this.actionyearRef = React.createRef();
        this.tflScoreYearRef = React.createRef();
        this.regionRef=React.createRef();
        this.sapCodeRef = React.createRef();
        this.reviewRef = React.createRef();
        uniqueId.enableUniqueIds(this);
        let editFormatter = Moment().format('DD-MM-YYYY');
    }

    componentWillMount() {
        if (this.props.filterOpenend === 'Hide Filters') {
            this.props.toggleFilter();
        }
        this.getEndYears();
        this.getyears();      
    }

    componentDidMount() {
        /****Update filters value if cache ******/
        if (localStorage.getItem('cacheFilter') !== null) {
            const cacheFilter = JSON.parse(localStorage.getItem('cacheFilter'));
            if (cacheFilter.hasOwnProperty(this.props.reviewType)) {
                const cacheState = cacheFilter[this.props.reviewType];                
                let {
                    StartDate,
                    EndDate,
                    Due_Date,
                    Priority,
                    End_Year,
                    selectedLocationList,
                    statusList,
                    priorityList,
                    endyearList, 
                    yearList,
                    actionyearList,
                    tflScoreYearList,
                    Title,
                    Assessment_Template_Name,
                    selectedSapCode,
                    year,
                    actionYear,
                    tflScoreYear,
                    region,
                    Last_Updated_On,
                    Program,
                    Assigned_To,
                    Status,
                    activeStatus,
                    selectedStage,
                    selectedLocation,
                    Last_Modified_By,
                    selectedReview
                } = cacheState;
                this.setState(cacheState)                
                if (selectedLocationList) {
                    if (this.locationNameRef && this.locationNameRef.current && this.locationNameRef.current.state) {
                        this.locationNameRef.current.state.value = selectedLocationList;
                    }
                }
                
                if (selectedStage) {
                    this.updateFilters('Stage', selectedStage.label);
                }
                

                this.updateFilters('Last_Modified_By', Last_Modified_By);

                this.onSelectLocation("Dealer_Id", selectedLocation ? selectedLocation.id : '');

                if (statusList) {
                    this.statusRef.current.state.value = statusList;
                }

                if (selectedSapCode) {                    
                    this.sapCodeRef.current.state.value = selectedSapCode;
                }
                if(region){
                    this.regionRef.current.state.value = region;
                }
                if (selectedReview) {

                    this.reviewRef.current.state.value = selectedReview;
                }

                if (Priority) {
                    this.priorityRef.current.state.value = priorityList;
                }
                this.updateFilters('Priority', Priority);

                if (this.endyearRef.current !== null) {
                    this.endyearRef.current.state.value = endyearList;
                }

                if (this.actionyearRef.current !== null) {
                    this.actionyearRef.current.state.value = actionyearList;
                }

                if (this.yearRef.current !== null) {                    
                    this.yearRef.current.state.value = yearList;    
                }

                if (this.tflScoreYearRef.current !== null) {
                    this.tflScoreYearRef.current.state.value = tflScoreYearList;                                        
                }

                
                this.updateFilters('End_Year', End_Year);

                this.updateFilters('Title', Title);
                this.updateFilters('Status', Status);
                this.updateFilters('Program', Program);
                this.updateFilters('Assessment_Template_Name', Assessment_Template_Name);
                this.updateFilters('Assigned_To', Assigned_To);


                if (StartDate) {
                    this.onSelectDate('StartDate', this.state.StartDate);
                }
                if (EndDate) {
                    this.onSelectDate('EndDate', this.state.EndDate);

                }
                if (Due_Date) {
                    this.onSelectDate('Due_Date', this.state.Due_Date);

                }
                this.onSelectDealer(selectedLocationList ? selectedLocationList : []);
                
                if (year) {                   
                    this.onSelectYear('Year', year);
                }
                if (actionYear) {                    
                    this.onSelectActionYear('ActionYear', actionYear);
                }
                if (tflScoreYear) {
                    this.onSelectScoreYear('Year', tflScoreYear);                    
                }
                if (region) {
                    this.onSelectRegion('region', region);                    
                }
                if (Last_Updated_On) {
                    this.onSelectYear('Last_Updated_On', new Date(year));
                }
                if (selectedSapCode) {
                    const selectedSAPCodes = selectedSapCode.map((obj) => {
                        return obj.label
                    }).join(',');
                    this.updateFilters('SAP_Dealer_Code', selectedSAPCodes);

                }
                else
                    this.updateFilters('SAP_Dealer_Code', '');
                this.updateFilters('is_Active', activeStatus.toString());
                //this.onApplyFilter();
            } else {
                this.props.onResetSortAndFilters(this.props.reviewType);
                if (this.endyearRef.current !== null) {
                    this.endyearRef.current.state.value = this.initialState.endyearList;
                }
                this.onSelectMultiDropdown("End_Year", this.initialState.endyearList);
                this.props.onFilterStories(this.props.reviewType);
            }
        } else {
            this.props.onResetSortAndFilters(this.props.reviewType);
            if (this.endyearRef.current !== null) {
                this.endyearRef.current.state.value = this.initialState.endyearList;
            }
            this.onSelectMultiDropdown("End_Year", this.initialState.endyearList);
            this.props.onFilterStories(this.props.reviewType);
        }
        this.setState({ filterDropdownsFetched: false });

    }
    componentDidUpdate() {
        if (this.props.filterOpenend === 'Hide Filters' && !this.state.filterDropdownsFetched) {
            const query = {
                dealer_id: null,
                program_id: null,
                statusType: (this.props.reviewType === 'schedule' || this.props.reviewType === 'TFLscore') ? 'assessment' : this.props.reviewType
            }
            this.props.onFetchDropDowns(query);
            this.setState({ filterDropdownsFetched: true });
        }
        var dateInputElements = document.querySelectorAll(".rw-widget-picker > input");
        var dateButtonElements = document.querySelectorAll(".rw-widget-picker > input + .rw-select > button");
        for (let i = 0; i < dateInputElements.length; i++) {
            var inputId = dateInputElements[i].getAttribute("id");
            if (dateButtonElements && dateButtonElements[i]) {
                dateButtonElements[i].setAttribute("id", inputId + '-button');
            }
        }

    }

    getyears = () => {        
        let prepStartYear = 2019, yearList = [], actionyearList = [], tflScoreYearList = [], allYearList = [],
            currentYear = new Date().getFullYear();
        yearList.push({ "id": currentYear.toString(), "label": currentYear.toString(), "value": currentYear.toString() });

        if (this.props.reviewType === 'score') {            

            this.setState({ yearList: yearList, year: '', isApplyDisabled: false });
            this.initialState.yearList = yearList;           
        }

        if (this.props.reviewType === 'action') {
            this.setState({ actionyearList: yearList, year: '', actionYear: '', isApplyDisabled: false });
            this.initialState.actionyearList = actionyearList;
        }

        if (this.props.reviewType === 'TFLscore') {
            this.setState({ tflScoreYearList: yearList, year: '', tflScoreYear: '', isApplyDisabled: false });
            this.initialState.tflScoreYearList = tflScoreYearList;
        }

        allYearList.push({ "Year": currentYear.toString(), "YearLabel": currentYear.toString() })
        if (prepStartYear !== currentYear) {
            for (let i = prepStartYear; i < currentYear; i++) {
                allYearList.push({ "Year": i.toString(), "YearLabel": i.toString() })
            }
            allYearList.sort(function (a, b) {
                return b.Year - a.Year
            })
        }

        this.setState({ allYearList: allYearList });
        this.initialState.allYearList = allYearList;

    }
    
    getEndYears = () => {
        let prepStartYear = 2019, endyearList = [], allYearList = [],
            currentYear = new Date().getFullYear();
        endyearList.push({ "id": currentYear.toString(), "label": currentYear.toString(), "value": currentYear.toString() });

        if (this.props.reviewType === 'schedule') {
            this.setState({ endyearList: endyearList, End_Year: currentYear.toString(), isApplyDisabled: false });
            this.initialState.endyearList = endyearList;
            this.initialState.End_Year = currentYear.toString();
        }

        allYearList.push({ "Year": currentYear.toString(), "YearLabel": currentYear.toString() })
        if (prepStartYear !== currentYear) {
            for (let i = prepStartYear; i < currentYear; i++) {
                allYearList.push({ "Year": i.toString(), "YearLabel": i.toString() })
            }
            allYearList.sort(function (a, b) {
                return b.Year - a.Year
            })
        }

        this.setState({ allYearList: allYearList });
        this.initialState.allYearList = allYearList;

    }

    updateFilters = (key, val, val2 = null) => {        
        this.setState(prevState => ({
            ...prevState, [key]: (val2) || val
        }), () => {
            if (val === '') {
                this.props.onRemoveFilter({ "Field": key });
            } else {
                this.props.onAddFilter({
                    "Field": key,
                    "SearchText": val
                }, this.props.reviewType);
                if (key === 'is_Active') {
                    this.props.onFilterStories(this.props.reviewType);
                    // this.saveToCacheMemory(this.props.reviewType, this.state)
                    // this.saveToCacheMemoryForOtherReviewType(this.props.reviewType, this.state);
                }
            }
        });
    }

    onApplyFilter = () => {
        this.props.onFilterStories(this.props.reviewType);
        this.saveToCacheMemory(this.props.reviewType, this.state);
        this.saveToCacheMemoryForOtherReviewType(this.props.reviewType, this.state);
        this.toggle();
    }

    saveToCacheMemory = (key, state) => {
        let cacheFilter = '';
        if (localStorage.getItem('cacheFilter') !== null) {
            cacheFilter = JSON.parse(localStorage.getItem('cacheFilter'));
            cacheFilter[key] = state;

        } else {
            cacheFilter = {
                [key]: state
            }

        }
        localStorage.setItem('cacheFilter', JSON.stringify(cacheFilter))
    }

    saveToCacheMemoryForOtherReviewType = (key, state) => {
        let cacheFilter = '';
        const reviewnames = [{ name: 'schedule' }, { name: 'action' }, { name: 'investigation' }, { name: 'score' }, { name: 'TFLscore' }]
        var Notexistsreview = reviewnames.filter(val => val.name !== key);

        if (localStorage.getItem('cacheFilter') !== null && Object.keys(JSON.parse(localStorage.getItem('cacheFilter'))).length > 0) {
            cacheFilter = JSON.parse(localStorage.getItem('cacheFilter'));
            var cacheState = { ...state };
            Notexistsreview.forEach(function (item) {
                if (item.name === "schedule") {
                    delete cacheFilter["schedule"];
                    cacheState = {
                        Assessment_Template_Name: "",
                        Assigned_To: "",
                        ClosedOnYear: null,
                        Conducted_By: "",
                        CreatedBy: "",
                        Dealer_ID: state.Dealer_ID,
                        Due_Date: null,
                        EndDate: null,
                        End_Year: "",
                        Last_Modified_By: "",
                        Last_Updated_On: null,
                        Priority: "",
                        Program: state.Program,
                        StartDate: null,
                        Status: "",
                        Title: "",
                        activeStatus: state.activeStatus,
                        allYearList: [],
                        dropdownsFetched: false,
                        endyearList: [],
                        yearList: [],
                        actionyearList: [],
                        tflScoreYearList : [],
                        filterDropdownsFetched: true,
                        filters: [],
                        isApplyDisabled: false,
                        priorityList: null,
                        selectedLocation: null,
                        selectedLocationList: state.selectedLocationList,
                        selectedProgram: state.selectedProgram,
                        selectedReview: null,
                        selectedSapCode: null,
                        selectedStage: null,
                        selectedStatus: null,
                        selectedTitle: null,
                        statusList: null,
                        year: null,
                        actionYear: null,
                        tflScoreYear:null,
                    }

                    cacheFilter["schedule"] = cacheState;
                }

                if (item.name === "action") {
                    delete cacheFilter["action"];
                    cacheState = {
                        Assessment_Template_Name: "",
                        Assigned_To: "",
                        ClosedOnYear: null,
                        Conducted_By: "",
                        CreatedBy: "",
                        Dealer_ID: state.Dealer_ID,
                        Due_Date: null,
                        EndDate: null,
                        End_Year: "",
                        Last_Modified_By: "",
                        Last_Updated_On: null,
                        Priority: "",
                        Program: state.Program,
                        StartDate: null,
                        Status: "",
                        Title: "",
                        activeStatus: state.activeStatus,
                        allYearList: state.allYearList,
                        dropdownsFetched: false,
                        endyearList: [],
                        yearList: [],
                        actionyearList: [],
                        tflScoreYearList : [],
                        filterDropdownsFetched: true,
                        filters: [],
                        isApplyDisabled: false,
                        priorityList: null,
                        selectedLocation: null,
                        selectedLocationList: state.selectedLocationList,
                        selectedProgram: state.selectedProgram,
                        selectedReview: null,
                        selectedSapCode: null,
                        selectedStage: null,
                        selectedStatus: null,
                        selectedTitle: null,
                        statusList: null,
                        year: null,
                        actionYear: null,
                        tflScoreYear: null,
                    }
                    cacheFilter["action"] = cacheState;
                }

                if (item.name === "investigation") {
                    delete cacheFilter["investigation"];
                    cacheState = {
                        Assessment_Template_Name: "",
                        Assigned_To: "",
                        ClosedOnYear: null,
                        Conducted_By: "",
                        CreatedBy: "",
                        Dealer_Id: state.Dealer_ID,
                        Due_Date: null,
                        EndDate: null,
                        End_Year: "",
                        Last_Modified_By: "",
                        Last_Updated_On: null,
                        Priority: "",
                        Program: "",
                        StartDate: null,
                        Status: "",
                        Title: "",
                        activeStatus: state.activeStatus,
                        allYearList: [],
                        dropdownsFetched: false,
                        endyearList: [],
                        yearList: [],
                        actionyearList: [],
                        tflScoreYearList : [],
                        filterDropdownsFetched: true,
                        filters: [],
                        isApplyDisabled: false,
                        priorityList: null,
                        selectedLocation: state.selectedLocation,
                        selectedLocationList: state.selectedLocationList,
                        selectedProgram: null,
                        selectedReview: null,
                        selectedSapCode: null,
                        selectedStage: null,
                        selectedStatus: null,
                        selectedTitle: null,
                        statusList: null,
                        year: null,
                        actionYear: null,
                        tflScoreYear: null,
                    }
                    cacheFilter["investigation"] = cacheState;
                }

                if (item.name === "score") {
                    delete cacheFilter["score"];
                    cacheState = {
                        Assessment_Template_Name: "",
                        Assigned_To: "",
                        ClosedOnYear: null,
                        Conducted_By: "",
                        CreatedBy: "",
                        Dealer_ID: state.Dealer_ID,
                        Dealer_Id: null,
                        Due_Date: null,
                        EndDate: null,
                        End_Year: "",
                        Last_Modified_By: "",
                        Last_Updated_On: null,
                        Priority: "",
                        Program: "",
                        StartDate: null,
                        Status: "",
                        Title: "",
                        activeStatus: state.activeStatus,
                        allYearList: state.allYearList,
                        dropdownsFetched: false,
                        endyearList: [],
                        yearList: [],
                        actionyearList: [],
                        tflScoreYearList: [],
                        filterDropdownsFetched: true,
                        filters: [],
                        isApplyDisabled: false,
                        priorityList: null,
                        selectedLocation: null,
                        selectedLocationList: state.selectedLocationList,
                        selectedProgram: null,
                        selectedReview: null,
                        selectedSapCode: null,
                        selectedStage: null,
                        selectedStatus: null,
                        selectedTitle: null,
                        statusList: null,
                        year: state.year,
                        actionYear: null,
                        tflScoreYear: null,
                    }
                    cacheFilter["score"] = cacheState;
                }

                if (item.name === "TFLscore") {
                    delete cacheFilter["TFLscore"];
                    cacheState = {
                        Assessment_Template_Name: "",
                        Assigned_To: "",
                        ClosedOnYear: null,
                        Conducted_By: "",
                        CreatedBy: "",
                        Dealer_ID: state.Dealer_ID,
                        Dealer_Id: null,
                        Due_Date: null,
                        EndDate: null,
                        End_Year: "",
                        Last_Modified_By: "",
                        Last_Updated_On: null,
                        Priority: "",
                        Program: "",
                        StartDate: null,
                        Status: "",
                        Title: "",
                        activeStatus: state.activeStatus,
                        allYearList: state.allYearList,
                        dropdownsFetched: false,
                        endyearList: [],
                        yearList: [],
                        actionyearList: [],
                        tflScoreYearList: state.tflScoreYearList,
                        filterDropdownsFetched: true,
                        filters: [],
                        isApplyDisabled: false,
                        priorityList: null,
                        selectedLocation: null,
                        selectedLocationList: state.selectedLocationList,
                        selectedProgram: null,
                        selectedReview: null,
                        selectedSapCode: null,
                        selectedStage: null,
                        selectedStatus: null,
                        selectedTitle: null,
                        statusList: null,
                        year: null,
                        actionYear: null,
                        tflScoreYear : state.tflScoreYear,
                        region:state.region,
                        
                    }
                    cacheFilter["TFLscore"] = cacheState;
                }

            });
        };
        localStorage.setItem('cacheFilter', JSON.stringify(cacheFilter))

    }

    removeKeyFromCache = (key) => {
        if (localStorage.getItem('cacheFilter') !== null) {
            const cacheFilter = JSON.parse(localStorage.getItem('cacheFilter'));
            //delete cacheFilter[key];
            delete cacheFilter["schedule"];
            delete cacheFilter["action"];
            delete cacheFilter["investigation"];
            delete cacheFilter["score"];
            delete cacheFilter["TFLscore"];
            localStorage.setItem('cacheFilter', JSON.stringify(cacheFilter))

        }
        //this.props.onFilterStories(this.props.reviewType);



    }

    onSelectDropDown = (key, event) => {
        this.setState({ isApplyDisabled: false });
        this.updateFilters(key, event.target.value);
    }

    onSelectMultiDropdown = (key, selected) => {        
        this.setState({ isApplyDisabled: false });        
        if (key === 'Status') {
            this.setState({ statusList: selected })
        }
        else if (key === 'End_Year') {
            this.setState({ endyearList: selected })
        }
        else if (key === 'Year') {            
            let selectedString = selected.map((obj) => {
                return obj.id
            }).join(',');
            this.setState({ yearList: selected })
            this.setState({ year: selectedString })
        }
        else if (key === 'TFLScoreYear') {            
            key = "Year";
            let selectedString = selected.map((obj) => {
                return obj.id
            }).join(',');
            this.setState({ tflScoreYearList: selected })
            this.setState({ tflScoreYear: selectedString })
        }      
        else if (key === 'actionYear') {            
            let selectedString = selected.map((obj) => {
                return obj.id
            }).join(',');
            this.setState({ actionyearList: selected })            
            this.setState({ actionYear: selectedString })
        }
        else {
            this.setState({ priorityList: selected })

        }

        if (selected.length > 0) {
            let selectedString = selected.map((obj) => {
                return obj.id
            }).join(',');
            this.updateFilters(key, selectedString);
        } else {
            this.updateFilters(key, '');
        }        
    }

    onSelectStatus = (e, status) => {
        
        const payload = {
            is_active: status,
            program_id: ''
        }
        // this.setState({ isApplyDisabled: false });
        // this.onResetForm();
        this.setState({ activeStatus: status });
        this.props.onFetchLocationList(payload);
        this.resetMultiDropdowns();
        this.updateFilters("is_Active", status.toString());
        this.setState({
            selectedLocationList: null, selectedSapCode: null, selectedProgram: null, selectedReview: null,
            selectedLocation: null, selectedStatus: null, Last_Modified_By: '', StartDate: null, EndDate: null,
            Priority: null, Due_Date: null, CreatedBy: '', Assigned_To: '', Title: '', selectedTitle: null, ClosedOnYear: null
        });
    }

    onSelectReview = selectedReview => {
        const selectedReviewLabel = selectedReview.map((obj) => {
            return obj.label
        }).join(',');
        this.setState({ selectedReview });
        this.setState({ isApplyDisabled: false });
        this.updateFilters("Assessment_Template_Name", selectedReviewLabel);
    }


    onSelectSapCode = selectedSapCode => {
        const selectedSAPCodes = selectedSapCode.map((obj) => {
            return obj.label
        }).join(',');
        this.setState({ selectedSapCode });
        this.setState({ isApplyDisabled: false });
        this.updateFilters("SAP_Dealer_Code", selectedSAPCodes);
    }

    onSelectProgram = selectedProgram => {
        this.setState({ selectedProgram });
        this.setState({ isApplyDisabled: false });
        const payload = {
            is_active: this.state.activeStatus,
            program_id: selectedProgram.id
        }
        this.props.onFetchLocationList(payload);
        this.resetMultiDropdowns();
        this.updateFilters("Program", selectedProgram.label);
    }

    onSelectDealer = selectedOption => {
        var lastselectedlocation = '';

        const selectedLocationObjs = selectedOption.map((obj) => {
            lastselectedlocation = obj;
            return obj.id
        }).join(',');

        this.setState({ selectedLocationList: selectedOption, isApplyDisabled: false });
        this.setState({ selectedLocation: lastselectedlocation, isApplyDisabled: false });
        if (this.props.reviewType != "investigation")
            this.updateFilters("Dealer_ID", selectedLocationObjs);
        else
            this.updateFilters("Dealer_Id", lastselectedlocation.id);
    }


    onSelectDate = (key, e) => {
        const date = Moment(e).format('DD-MM-YYYY');
        this.setState({ isApplyDisabled: false });
        this.updateFilters(key, date, new Date(Moment(date, "DD-MM-YYYY")));
    }

    onSelectYear = (key, e) => {
        this.setState({ isApplyDisabled: false });
        this.setState({ year: e });
        this.updateFilters(key, e);
    }
    onSelectRegion=(key, e)=>{
        this.setState({ isApplyDisabled: false });
        this.setState({ region: e });
        this.updateFilters(key,e);      
    }

    onSelectScoreYear = (key, e) => {            
        this.setState({ isApplyDisabled: false });
        this.setState({ tflScoreYear: e });
        this.updateFilters(key, e);        
    }

    onSelectActionYear = (key, e) => {              
        this.setState({ isApplyDisabled: false });
        this.setState({ actionYear: e});
        this.updateFilters(key, e);
    }

    onSelectClosedOnYear = (key, e) => {
        const date = Moment(e).format('DD-MM-YYYY');
        this.setState({ isApplyDisabled: false });
        this.setState({ ClosedOnYear: new Date(Moment(date, "DD-MM-YYYY")) });
        this.updateFilters(key, date, new Date(Moment(date, "DD-MM-YYYY")));
    }

    onSelectStage = selectedStage => {
        this.setState({ selectedStage });
        this.setState({ isApplyDisabled: false });
        this.updateFilters("Stage", selectedStage.label);
    }


    onSelectLocation = selectedLocation => {
        var selectedLocationarr = [];
        selectedLocationarr.push(selectedLocation)
        this.setState({ selectedLocation: selectedLocation });
        this.setState({ selectedLocationList: [] });
        this.setState({ isApplyDisabled: false });
        this.setState({ selectedLocationList: selectedLocationarr });
        this.updateFilters("Dealer_Id", selectedLocation.id);
    }

    onSelectInvestigationStatus = selectedStatus => {
        this.setState({ selectedStatus });
        this.setState({ isApplyDisabled: false });
        const status = selectedStatus.label.toLowerCase()
            .split(' ')
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ');
        this.updateFilters("Status", status);
    }

    onSelectInvestigationTitle = selectedTitle => {
        this.setState({ selectedTitle });
        this.setState({ isApplyDisabled: false });
        this.updateFilters("Title", selectedTitle.label);
    }

    resetAutoSuggestlabel = () => {
        if (this.props.filtersToDisplay.dealer_list) {
            this.locDataListText.current.value = '';
        }
    }

    resetMultiDropdowns = () => {
        if (this.locationNameRef && this.locationNameRef.current && this.locationNameRef.current.state) {
            this.locationNameRef.current.state.value = '';
        }
        if (this.statusRef && this.statusRef.current && this.statusRef.current.state) {
            this.statusRef.current.state.value = '';
        }
        if (this.props.filtersToDisplay.priority) {
            this.priorityRef.current.state.value = '';
        }
        if (this.props.filtersToDisplay.assessment_template_lookup) {
            this.reviewRef.current.state.value = '';
        }
        if (this.props.filtersToDisplay.endyear) {
            this.endyearRef.current.state.value = this.initialState.endyearList;
        }
        if (this.props.filtersToDisplay.year) {
            this.yearRef.current.state.value = '';// this.initialState.yearList
        }
        if (this.props.filtersToDisplay.actionProgramyear) {
            this.actionyearRef.current.state.value = '';// this.initialState.yearList
        }
        if (this.props.filtersToDisplay.TFLScoreyear) {
            this.tflScoreYearRef.current.state.value = '';// this.initialState.yearList
        }
        if (this.sapCodeRef && this.sapCodeRef.current && this.sapCodeRef.current.state) {
            this.sapCodeRef.current.state.value = '';
        }
        if (this.regionRef && this.regionRef.current && this.regionRef.current.state) {
            this.regionRef.current.state.value = '';
        }
        this.updateFilters('Dealer_ID', '');
        this.updateFilters('Status', '');
        this.updateFilters('Priority', '');
        this.updateFilters('End_Year', this.initialState.End_Year);


    }

    onResetForm = () => {
        //this.resetAutoSuggestlabel();
        this.setState({ selectedLocationList: null });
        this.setState({ selectedSapCode: null });
        this.setState({ region:null });
        this.setState({ activeStatus: 1 });
        this.resetMultiDropdowns();
        this.setState(prevState => (this.initialState), () => {
            this.props.onResetSortAndFilters(this.props.reviewType);
            this.props.onFetchStories(this.props.reviewType);
        });
        const payload = {
            is_active: 1,
            program_id: ''
        }
        this.removeKeyFromCache(this.props.reviewType)
        this.props.onFetchLocationList(payload);

    }

    toggle = event => {
        if (this.props.filterOpenend === 'Hide Filters') {
            this.props.toggleFilter();
        }
    }

    render() {
        let locationListArr = this.props.locationsDropDownList;
        let locationList;
        if (this.props.reviewType === 'score') {
            locationList = this.props.locationsDropDownList.filter(item => item.Program_Name === 'TFL' && item.Location_Type === "Principal")
            locationListArr = this.props.scoreListData;
        } else if (this.props.reviewType === 'investigation') {
            locationList = this.props.locationsDropDownList.filter(item => item.Program_Name === 'TFL' && (item.Location_Type === "Principal" || item.Location_Type === "Branch"))
            locationListArr = this.props.investigationList
        }
        else {
            locationListArr = this.props.locationsDropDownList;
        }
        return (
            <div>
                <div className="overlay" onClick={(e) => this.toggle(e)}></div>
                <div className="filter-panel">
                    <div id="Filter_Toggle" className="filter-wrapper">
                        <div className="filter-panel__header">
                            <h6>Filters</h6>
                            <span onClick={(e) => this.toggle(e)} id="filter-panel__close" className="filter-panel__close" />
                        </div>
                        <div className="filter-panel__form-wrapper">
                            <form action="">

                                <div className="location-status">
                                    <div className="form-group text-left">
                                        <div><label className="m-b-10" htmlFor="location-status">Location Status</label>
                                        </div>
                                        <label className="custom-radio">
                                            <input type="radio" checked={this.state.activeStatus !== 0} name="radio"
                                                id="status__active-radio" onChange={(e) => this.onSelectStatus(e, 1)} />
                                            <span className="custom-checkmark" />
                                            Active
                                    </label>
                                        <label className="custom-radio">
                                            <input type="radio" name="radio" id="status__inactive-radio"
                                                checked={this.state.activeStatus !== 1}
                                                onChange={(e) => this.onSelectStatus(e, 0)} />
                                            <span className="custom-checkmark" />
                                            Inactive
                                    </label>
                                    </div>
                                </div>


                                {this.props.filtersToDisplay.program_list && (
                                    <ProgramList
                                        selectedOption={this.state.selectedProgram}
                                        onSelectProgram={(e) => this.onSelectProgram(e)}
                                        programsDropDownList={this.props.programsDropDownList}
                                    />

                                )}


                                {this.props.filtersToDisplay.dealer_list && (this.props.reviewType !== 'investigation' && this.props.reviewType !== 'score') && (

                                    <LocationList
                                        selectedOption={this.state.selectedLocationList}
                                        onSelectDealer={(e) => this.onSelectDealer(e)}
                                        locationsDropDownList={locationListArr}
                                        reference={this.locationNameRef}
                                        reviewType={this.props.reviewType}
                                    />


                                )}

                                {this.props.filtersToDisplay.dealer_list && this.props.reviewType === 'score' && (

                                    <LocationList
                                        selectedOption={this.state.selectedLocationList}
                                        onSelectDealer={(e) => this.onSelectDealer(e)}
                                        locationsDropDownList={locationList}
                                        reference={this.locationNameRef}
                                        reviewType={this.props.reviewType}
                                    />


                                )}

                                {this.props.filtersToDisplay.region  && (
                                    <div id="regionList"
                                    className={`form-group custom-multiselect ${this.state.region && this.state.region.length > 0? '' : 'not-selected'}`}>
                                    <label htmlFor="region" id="region__label">Region</label>
                                    <MultiSelectDropdown
                                        selectedOption={this.state.region}
                                        forEle="region"
                                        handleChange={(e) => this.onSelectRegion('region',e)}
                                        dropDownList={this.props.regionList}
                                        value={this.state.region}
                                        reference={this.regionRef}
                                        placeholderText="Select Region"
                                        selectObj={{ optionId: "region", optionValue: "regionlabel" }}
                                    />
                                </div>
                                )}
                                
                                {this.props.filtersToDisplay.dealer_list && this.props.reviewType === 'investigation' && (

                                    <InvestigationFilter
                                        selectedOption={this.state.selectedLocation}
                                        onSelectLocation={(e) => this.onSelectLocation(e)}
                                        locationList={locationList}
                                        reviewType={this.props.reviewType}
                                        type={'location'}
                                    />


                                )}

                                {this.props.filtersToDisplay.sap_dealer_code && this.props.scoreListData && (
                                    <SapDealerList
                                        selectedOption={this.state.selectedSapCode}
                                        onSelectSapCode={(e) => this.onSelectSapCode(e)}
                                        sapDealerList={locationListArr}
                                        reference={this.sapCodeRef}
                                        type={this.props.reviewType}
                                    />

                                )}

                                {this.props.filtersToDisplay.title && (
                                    <Select
                                        type="TitleLookup"
                                        label={TITLE.label}
                                        value={this.state.Title}
                                        onChange={(e) => this.onSelectDropDown("Title", e)}
                                    />
                                )}

                                {this.props.filtersToDisplay.investigation_title && (
                                    <InvestigationFilter
                                        selectedOption={this.state.selectedTitle}
                                        onSelectTitle={(e) => this.onSelectInvestigationTitle(e)}
                                        titleList={locationListArr}
                                        reviewType={this.props.reviewType}
                                        type={'title'}
                                    />
                                )}
                                {this.props.filtersToDisplay.closed_on_date && (
                                    <div id="FDate_Closed_Year" className="form-group">
                                        <label htmlFor="closed_on_year" id="year__label">Closed on year</label>
                                        <DateTimePicker
                                            inputProps={{
                                                component: props => <input {...props} id="closed_on_date__input"
                                                    placeholder="Select year" />
                                            }}
                                            editFormat={this.editFormatter}
                                            dropUp
                                            time={false}
                                            value={this.state.ClosedOnYear}
                                            max={new Date()}
                                            min={getFullDateFromCurrentYear("1/1")}
                                            onChange={(e) => this.onSelectClosedOnYear("ClosedOn", e)}
                                            format="DD-MM-YYYY"
                                        />
                                    </div>
                                )}
                                {this.props.filtersToDisplay.stage && (
                                    <InvestigationFilter
                                        selectedOption={this.state.selectedStage}
                                        onSelectStage={(e) => this.onSelectStage(e)}
                                        stageList={this.props.stageList}
                                        reviewType={this.props.reviewType}
                                        type={'stage'}
                                    />
                                )}

                                {this.props.filtersToDisplay.assessment_template_lookup && (
                                    <ReviewList
                                        value={this.state.selectedReview}
                                        reviewDropDownList={this.props.reviewDropDownList}
                                        onChange={(e) => this.onSelectReview(e)}
                                        reference={this.reviewRef}
                                    />
                                )}
                                {this.props.filtersToDisplay.last_conducted_by && (
                                    <Select
                                        type="LastConductedByLookup"
                                        label={LASTCONDUCTEDBY_DROPDOWN.label}
                                        value={this.state.Conducted_By}
                                        onChange={(e) => this.onSelectDropDown("Conducted_By", e)}
                                    />
                                )}

                                {this.props.filtersToDisplay.year &&   (
                                    <div id="Year"
                                        className={`form-group custom-multiselect ${this.state.year && this.state.year.length > 0 ? '' : 'not-selected'}`}>
                                        <label htmlFor="year" id="year__label">Year</label>
                                        <MultiSelectDropdown
                                            forEle="Year"
                                            handleChange={(e) => this.onSelectMultiDropdown("Year", e)}
                                            dropDownList={this.state.allYearList}
                                            value={this.state.yearList}
                                            reference={this.yearRef}
                                            placeholderText="Select Year"
                                            selectObj={{ optionId: "Year", optionValue: "YearLabel" }}
                                        />
                                    </div>
                                )}

                                {this.props.filtersToDisplay.TFLScoreyear && (
                                    <div id="TFLScoreYear"
                                        className={`form-group custom-multiselect ${this.state.tflScoreYear && this.state.tflScoreYear.length > 0 ? '' : 'not-selected'}`}>
                                        <label htmlFor="year" id="year__label">Year</label>
                                        <MultiSelectDropdown
                                            forEle="TFLScoreYear"
                                            handleChange={(e) => this.onSelectMultiDropdown("TFLScoreYear", e)}
                                            dropDownList={this.state.allYearList}
                                            value={this.state.tFLScoreyearList}
                                            reference={this.tflScoreYearRef}
                                            placeholderText="Select Year"
                                            selectObj={{ optionId: "Year", optionValue: "YearLabel" }}
                                        />
                                    </div>
                                )}


                                {this.props.filtersToDisplay.status_lookup && this.props.reviewType !== 'investigation' && (
                                    /* <Select
                                     type="StatusLookup"
                                     label={STATUS_DROPDOWN.label}
                                     value={this.state.Status}
                                     onChange={(e) => this.onSelectDropDown("Status",e)}
                                     />  */
                                    <div id="FSelect_StatusLookup"
                                        className={`form-group custom-multiselect ${this.state.Status && this.state.Status.length > 0 ? '' : 'not-selected'}`}>
                                        <label htmlFor="template_name" id="status__label">{STATUS_DROPDOWN.label}</label>
                                        <MultiSelectDropdown
                                            forEle="status"
                                            handleChange={(e) => this.onSelectMultiDropdown("Status", e)}
                                            dropDownList={this.props.statusList}
                                            value={this.state.Status}
                                            reference={this.statusRef}
                                            selectObj={{ optionId: "Name", optionValue: "Description" }}
                                        />
                                    </div>
                                )}

                                {this.props.filtersToDisplay.status_lookup && this.props.reviewType === 'investigation' && (

                                    <InvestigationFilter
                                        selectedOption={this.state.selectedStatus}
                                        onSelectStatus={(e) => this.onSelectInvestigationStatus(e)}
                                        dropDownList={this.props.statusList}
                                        reviewType={this.props.reviewType}
                                        type={'status'}
                                    />


                                )}


                                {this.props.filtersToDisplay.due_date && (
                                    <div id="FDate_DueDate" className="form-group">
                                        <label htmlFor="end_date" id="due-date__label">Due Date</label>
                                        <DateTimePicker
                                            inputProps={{
                                                component: props => <input {...props} id="due-date__input" readOnly
                                                    placeholder="DD-MM-YYYY" />
                                            }}
                                            dropUp
                                            time={false}
                                            value={this.state.Due_Date}
                                            // min={getFullDateFromCurrentYear("1/1")}
                                            // max={getFullDateFromCurrentYear("12/31")}
                                            onChange={(e) => this.onSelectDate("Due_Date", e)}
                                            format="DD-MM-YYYY"
                                        />
                                    </div>
                                )}

                                {this.props.filtersToDisplay.priority && (
                                    /* <Select
                                    type="PriorityLookup"
                                    label={PRIORITY.label}
                                    value={this.state.Priority}
                                    onChange={(e) => this.onSelectDropDown("Priority",e)}
                                    /> */
                                    <div id="FSelect_PriorityLookup"
                                        className={`form-group custom-multiselect ${this.state.Priority && this.state.Priority.length > 0 ? '' : 'not-selected'}`}>
                                        <label htmlFor="template_name" id="priority__label">{PRIORITY.label}</label>
                                        <MultiSelectDropdown
                                            forEle="priority"
                                            handleChange={(e) => this.onSelectMultiDropdown("Priority", e)}
                                            dropDownList={this.props.priorityList}
                                            value={this.state.Priority}
                                            reference={this.priorityRef}
                                            selectObj={{ optionId: "Name", optionValue: "Description" }}
                                        />
                                    </div>

                                )}


                                {this.props.filtersToDisplay.assignee && (
                                    <Select
                                        type="AssigneeLookup"
                                        label={ASSIGNEE.label}
                                        value={this.state.Assigned_To}
                                        onChange={(e) => this.onSelectDropDown("Assigned_To", e)}
                                    />
                                )}
                                {this.props.filtersToDisplay.creator && (
                                    <Select
                                        type="CreatorLookup"
                                        label={CREATOR.label}
                                        value={this.state.CreatedBy}
                                        onChange={(e) => this.onSelectDropDown("CreatedBy", e)}
                                    />
                                )}

                                {this.props.filtersToDisplay.actionProgramyear && (
                                    <div id="ActionYear"
                                        className={`form-group custom-multiselect ${this.state.actionYear && this.state.actionYear.length > 0 ? '' : 'not-selected'}`}>
                                        <label htmlFor="year" id="year__label">Program Year</label>
                                        <MultiSelectDropdown
                                            forEle="ActionYear"
                                            handleChange={(e) => this.onSelectMultiDropdown("actionYear", e)}
                                            dropDownList={this.state.allYearList}
                                            value={this.state.actionyearList}
                                            reference={this.actionyearRef}
                                            placeholderText="Select Year"
                                            selectObj={{ optionId: "Year", optionValue: "YearLabel" }}
                                        />
                                    </div>
                                )}

                                {this.props.filtersToDisplay.last_updated_on_date && (
                                    <div id="FDate_Last_Updated_On" className="form-group">
                                        <label htmlFor="end_date" id="last-updated-on__label">Last Updated On</label>
                                        <DateTimePicker
                                            inputProps={{
                                                component: props => <input {...props} id="last-updated-on__input" readOnly
                                                    placeholder="DD-MM-YYYY" />
                                            }}
                                            dropUp
                                            time={false}
                                            value={this.state.Last_Updated_On}
                                            // min={getFullDateFromCurrentYear("1/1")}
                                            // max={getFullDateFromCurrentYear("12/31")}
                                            onChange={(e) => this.onSelectDate("Last_Updated_On", e)}
                                            format="DD-MM-YYYY"
                                        />
                                    </div>
                                )}
                                {this.props.filtersToDisplay.last_modified_by && (
                                    <Select
                                        type="LastUpdateByLookup"
                                        label={LASTUPDATEDBY_DROPDOWN.label}
                                        value={this.state.Last_Modified_By}
                                        onChange={(e) => this.onSelectDropDown("Last_Modified_By", e)}
                                    />
                                )}
                                {this.props.filtersToDisplay.start_date && (
                                    <div id="FDate_StartDate" className="form-group">
                                        <label htmlFor="start_date" id="start-date__label">Start Date</label>
                                        <DateTimePicker
                                            inputProps={{
                                                component: props => <input {...props} id="start-date__input" readOnly
                                                    placeholder="DD-MM-YYYY" />
                                            }}
                                            dropUp
                                            time={false}
                                            value={this.state.StartDate}
                                            // min={getFullDateFromCurrentYear("1/1")}
                                            // max={getFullDateFromCurrentYear("12/31")}
                                            onChange={(e) => this.onSelectDate("StartDate", e)}
                                            format="DD-MM-YYYY"
                                        />
                                    </div>
                                )}
                                {this.props.filtersToDisplay.end_date && (
                                    <div id="FDate_EndDate" className="form-group">
                                        <label htmlFor="end_date" id="end-date__label">End Date</label>
                                        <DateTimePicker
                                            inputProps={{
                                                component: props => <input {...props} id="end-date__input" readOnly
                                                    placeholder="DD-MM-YYYY" />
                                            }}
                                            dropUp
                                            time={false}
                                            value={this.state.EndDate}
                                            // min={getFullDateFromCurrentYear("1/1")}
                                            // max={getFullDateFromCurrentYear("12/31")}
                                            onChange={(e) => this.onSelectDate("EndDate", e)}
                                            format="DD-MM-YYYY"
                                        />
                                    </div>
                                )}
                                {this.props.filtersToDisplay.endyear && (
                                    <div id="End_Year"
                                        className={`form-group custom-multiselect ${this.state.End_Year && this.state.End_Year.length > 0 ? '' : 'not-selected'}`}>
                                        <label htmlFor="template_name" id="endyear__label">End Date Year</label>
                                        <MultiSelectDropdown
                                            forEle="End_Date_Year"
                                            handleChange={(e) => this.onSelectMultiDropdown("End_Year", e)}
                                            dropDownList={this.state.allYearList}
                                            value={this.state.endyearList}
                                            reference={this.endyearRef}
                                            placeholderText="Select End Date Year"
                                            selectObj={{ optionId: "Year", optionValue: "YearLabel" }}
                                        />
                                    </div>
                                )}
                                <div className="form-group text-right p-t-5">
                                    <a id="Filter_Apply_Filters"
                                        className={this.state.isApplyDisabled ? "grid__label-sort filter-panel__disabled-btn m-r-10"
                                            : "grid__label-sort filter-panel__active-btn m-r-10"}
                                        onClick={(e) => this.onApplyFilter(e)}>Apply</a>
                                    <a id="Filter_Reset_Filters" className="grid__label-sort filter-panel__reset-btn"
                                        onClick={(e) => this.onResetForm(e)}>Clear</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    locationsDropDownList: getLocationsList(state),
    programsDropDownList: getProgramsList(state),
    reviewDropDownList: state.dropdownState.AssessmentStatusLookup,
    statusList: state.dropdownState.StatusLookup,
    priorityList: state.dropdownState.PriorityLookup,
    investigationList: state.dropdownState.InvestigationLookup,
    scoreListData: state.dropdownState.ScoringLookup,
    filterOpenend: state.headerState.isNavOpen
});

const mapDispatchToProps = dispatch => ({
    toggleFilter: () => dispatch(toggleSidebar()),
    onFilterStories: (reviewType) => dispatch(doFilterStories(reviewType)),
    onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onFetchStories: (reviewType) => dispatch(doFetchStories(reviewType)),
    onFetchDropDowns: (statusType) => dispatch(doFetchScheduleDropDowns(statusType)),
    onFetchLocationList: query => dispatch(fetchLocationList(query)),
    onRemoveFilter: obj => dispatch(doRemoveFilter(obj)),
    onAddFilter: (state, reviewType) => dispatch(doAddFilter(state, reviewType))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Filter);

import {
  ADD_SOPNAMES_LIST,
  // FETCH_LOCATIONS_LIST,
  FETCH_SOPNAMES_LIST_ERROR
} from '../constants/actionTypes';

const INITIAL_STATE = {
  sopNamesList: [],
  count: 0,
  error: null,
};

const addSOPNamesList = (state, action) => {
        const sopNamesList = action.sopNamesList;
        return ({
            sopNamesList,
            count: action.count,
            error: null,
        })
};


const fetchSOPNamesListError = (state, action) => ({
  sopNamesList: [],
  error: action.error,
  count :0
});


export function sopNamesListReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    // case FETCH_LOCATIONS_LIST : {
    //   return fetchLocationsList(state, action);
    // }
    case ADD_SOPNAMES_LIST: {
      return addSOPNamesList (state, action);
    }
    case FETCH_SOPNAMES_LIST_ERROR : {
      return fetchSOPNamesListError (state, action);
    }

    default : return state;
  }
}

export default sopNamesListReducer;

import { SHOW_LOADER,HIDE_LOADER,SHOW_LOADER_MODAL,HIDE_LOADER_MODAL} from '../constants/actionTypes';

const INITIAL_STATE = {
loader: 'hide',
showSuccess:'no'
};

const applyShowLoader = (state, action) => {
   return {
        loader:'show'
    }
}


const applyHideLoader = (state, action) => {
    let showSuccess ='no';
    if(action.loaderAction ==='saveReview') {
        showSuccess='yes';
    }
    return {
        loader: 'hide',
        showSuccess
    };
}

const applyShowLoaderModal = (state, action) => {
   return {
        loaderModal:'show'
    }
}


const applyHideLoaderModal = (state, action) => {
   
    return {
        loaderModal: 'hide',
       
    };
}

function loaderReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SHOW_LOADER : {
            return applyShowLoader(state, action);
        }
        case HIDE_LOADER : {
            return applyHideLoader(state, action);
        }

        case SHOW_LOADER_MODAL : {
            return applyShowLoaderModal(state, action);
        }
        case HIDE_LOADER_MODAL : {
            return applyHideLoaderModal(state, action);
        }
        default : return state;
    }
}

export default loaderReducer;
import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
export class Logout extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        localStorage.removeItem('userType');
    }

    render() {
        return (
            <div>
                <div className="header">
                    <img
                        className="header__logo-image"
                        src="../images/logo.png"
                        alt="Toyota"
                    />
                </div>
                <div className="container-fluid logout-screen h-100">
                    <div className="row grid__columns-wrapper h-100">
                        <div className="col-12 text-center align-self-center">
                            <div className="login__box position-relative">
                                <div className="login-content ">
                                    <p className="text-f-16">You are currently logged out of PREP.<br/>
                                    Click Login to login to PREP.</p>
                                    <button id="login__button" className="btn btn-primary login__button" onClick={()=>this.props.history.push('/login')}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Logout);

import { getTokenInfo } from "../utils/utils";

function calculateOneYrFromNov(){
    const now = new Date();
    const oneYr = new Date();
    oneYr.setYear(now.getFullYear() + 1);

    return formatDate(oneYr);
}

function calculateStartDate() {
    const now = new Date();
    return now;
}
async function createFileObject(url, fileName){
    const response = await fetch(url);
    const data = await response.blob();
    const metadata = {
    };
    const file = new File([data], fileName, metadata);
    return file;
}

function formatDate(date) {
    const monthNames = [
        "January", "February", "March",
        "April", "May", "June", "July",
        "August", "September", "October",
        "November", "December"
    ];
    date.setDate(date.getDate() - 1);
    let day = date.getDate();
    day = day > 10 ? day: `0${ day}`;
    let monthIndex = date.getMonth();
    monthIndex = monthIndex > 10 ? (monthIndex+1) : `0${  monthIndex+1}`;
    const year = date.getFullYear();


    return `${year  }-${  monthIndex  }-${  day}` ;
}

function formatMomentDate (date) {
    
    const newDate = new Date (date);
    let day = newDate.getDate();
    day = day >= 10 ? day: `0${ day}`;

    let monthIndex = newDate.getMonth();
    monthIndex = monthIndex >= 10 ? (monthIndex+1) : `0${  monthIndex+1}`;
    const year = newDate.getFullYear();

    // alert (year + '-' + monthIndex + '-' + day);
    return `${day  }-${  monthIndex  }-${  year}`;
}

function getStartDate() {
    const startDate = calculateStartDate();
    return startDate;
}

function getStartExpiryDate() {
    const startDate = calculateStartDate();
    return startDate;
}

function getEndExpiryDate () {
    const endDate = calculateOneYrFromNov();
    const thisYear = (new Date()).getFullYear();
    const start = new Date(`12/31/${  thisYear}`);
    return new Date(endDate);
}

function wordWrap(str, maxWidth) {
    const newLineStr = "\n";
    let done = false;
    let res = '';
    let found = false;
    do {
        found = false;
        // Inserts new line at first whitespace of the line
        for (let i = maxWidth - 1; i >= 0; i--) {
            if (testWhite(str.charAt(i))) {
                res += [str.slice(0, i), newLineStr].join('');
                str = str.slice(i + 1);
                found = true;
                break;
            }
        }
        // Inserts new line at maxWidth position, the word is too long to wrap
        if (!found) {
            res += [str.slice(0, maxWidth), newLineStr].join('');
            str = str.slice(maxWidth);
        }

        if (str.length < maxWidth)
            done = true;
    } while (!done);

    return res + str;
}

function createDateAsUTC(date) {
    date = new Date(date);
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
}

function testWhite(x) {
    const white = new RegExp(/^\s$/);
    return white.test(x.charAt(0));
};

/* const statusMap =  new Map([
 [1 , "TO DO"],
 [2 , "In-progress"],
 [3 , "Complete"],
 [4 , "Overdue"]
 ]); */


const statusMap =  new Map([
    [4 , "Not-Started"],
    [5 , "In-progress"],
    [6 , "Complete"],
    [3 , "Overdue"]
]);

const getCurrentUserDetails = () =>{
   /* const employeeNumber = currentUser && currentUser.employeeNumber?` (${currentUser.employeeNumber})`:``;
   return  currentUser ?`${currentUser.firstName} ${currentUser.lastName}${employeeNumber}`:``;
    */
    const claims = getTokenInfo();
    if (localStorage.getItem('userType') === 'Dealer Use') {
        return claims.SSAM ? claims.SSAM : '';
    } else {
        return (claims.name && claims.K_Number_ID) ?`${claims.name} (${claims.K_Number_ID})`:``;
    }
   
}

const getUserDetails = (user) => {
        return user && user.employeeNumber ? `${user.firstName} ${user.lastName} (${user.employeeNumber})` :
        user && user.SsamAccountName ? user.SsamAccountName : `${user.firstName} ${user.lastName}`;    
}

export { calculateOneYrFromNov, calculateStartDate, formatDate, getStartDate,
    formatMomentDate, createDateAsUTC, wordWrap, getStartExpiryDate, getEndExpiryDate,createFileObject,statusMap,getCurrentUserDetails, getUserDetails};

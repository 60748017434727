import {
  STORIES_ADD,
  STORIES_FETCH,
  STORIES_FETCH_ERROR,
  STORIES_SORT,
  STORIES_FILTER,
  STORIES_PAGE_CHANGE,
  RESET_SORT_FILTER,
  FETCH_TFL18_ASSESSMENT_DATA,
  FETCH_TFL18_ASSESSMENT_ERROR,
  FETCH_TFL18_ASSESSMENT
} from '../constants/actionTypes';

const doAddStories = (stories, count) => ({
  type: STORIES_ADD,
  stories,
  count
});

const doFilterStories = obj => ({
  type: STORIES_FILTER,
  obj,
});

const doResetSortAndFilter = () => ({
  type: RESET_SORT_FILTER,
});

const doSortStories = sortBy => ({
  type: STORIES_SORT,
  sortBy,
});

const changeStoriesPage = pageNo => ({
  type: STORIES_PAGE_CHANGE,
  pageNo,
});

const doFetchStories = query => ({
  type: STORIES_FETCH,
  query,
});

const doFetchErrorStories = error => ({
  type: STORIES_FETCH_ERROR,
  error,
});

/** An action to fetch tfl18 assessment details from dealer id */
const doStartTFL18 = (payload,userDetails) => ({
  type: FETCH_TFL18_ASSESSMENT,
  payload,
  userDetails,
});

/** An action to send across the received tfl18 assessment data */
const doStartTFL18Success = (payload) => ({
  type: FETCH_TFL18_ASSESSMENT_DATA,
  payload
});

/** An action to send across the received tfl18 assessment error */
const doStartTFL18Error = (error) => ({
  type: FETCH_TFL18_ASSESSMENT_ERROR,
  error
});

export {
  doAddStories,
  doFetchStories,
  doSortStories,
  doFilterStories,
  doFetchErrorStories,
  changeStoriesPage,
  doResetSortAndFilter,
  doStartTFL18,
  doStartTFL18Success,
  doStartTFL18Error
};
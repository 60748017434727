import React from 'react';
import "babel-polyfill";
import './App.css';
import Routes, {RoutesWithGuide, RoutesWithoutPermission} from './Common/Routes';
import {Route, Switch, withRouter} from 'react-router-dom';

import { Security, Auth} from '@okta/okta-react';
import Toast from './Common/toastMsg';
import Logout from "./Common/Logout";
import Login from "./Common/Login";
import {connect} from "react-redux";


import {createBrowserHistory} from 'history';

const history = createBrowserHistory();
let auth;
if (localStorage.getItem('userType') === 'TMCA Use') {
    auth = new Auth({
        history,
        issuer: `${process.env[`REACT_APP_OKTA_ISSUER_${window.location.hostname}`]}`,
        client_id: `${process.env[`REACT_APP_OKTA_CLIENTID_${window.location.hostname}`]}`,
        redirect_uri: window.location.origin + '/implicit/callback',
    });
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    auth = new Auth({
        history,
        issuer: `${process.env[`REACT_APP_OKTA_ISSUER_Dealer_${window.location.hostname}`]}`,
        client_id: `${process.env[`REACT_APP_OKTA_CLIENTID_Dealer_${window.location.hostname}`]}`,
        redirect_uri: window.location.origin + '/implicit/callback',
    });
}


//import reactAppInsights from 'react-appinsights';//
export class App extends React.Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const isUserFirstVisit = localStorage.getItem('isUserFirstVisit');
        if (!auth) {
            return (<Switch>
                    <Route path="/logout"  render={(props) => <Logout {...props}/>}/>
                    <Route path="*" component={Login}/>
                </Switch>)
        }
        if (!isUserFirstVisit) {
            return <Security auth={auth}>
                <RoutesWithGuide {...this.props}/>
            </Security>
        }
        if(this.props.forSecured){
            return (
                <div>
                    <Toast />
                    <Security auth={auth} >
                        <Switch>
                            <Route path="/logout"  render={(props) => <Logout {...props}  auth={auth}/>}/>
                            <Route path="/login"  component={Login}/>
                            <Route path="/" render={(props) => <Routes {...this.props}  auth={auth}/>}/>
                        </Switch>
                    </Security>
                </div>)
        } else {
            return (
                <div>
                    <Toast />
                    <Security auth={auth}>
                            <RoutesWithoutPermission {...this.props} auth={auth}/>
                    </Security>
                </div>)
        }        
    }
}

//export default reactAppInsights.withTracking(App);
const mapStateToProps = state => ({
    forSecured: state.userAccessRolesState.forSecured,
    userRoles: state.userAccessRolesState.forSecured ? state.userAccessRolesState.userAccessRoles.data : undefined
});

export default  withRouter(connect(
    mapStateToProps,
    null,
)(App));

import React from "react";
import Select from "react-select";
import _ from 'lodash'
import {multiselectOptions} from "../../constants/uploadSOPConstants";
import ReactTooltip from 'react-tooltip';


const ResponseOptions = (props) => {
    const selectedOption = _.find(props.responseOptionsDropDownList, (obj) => {
        return obj.Response === props.question.Status;
    });
    let selectedInSelect = '';
    if (selectedOption) {
        selectedInSelect = {
            id: selectedOption.Response,
            label: selectedOption.Response,
            value: selectedOption.Response
        }
    }
    const showDropdownOptions = !(props.reviewStatus === 'In-progress' || props.reviewStatus === 'Completed' || props.reviewStatus === 'Deleted') || props.responseOptionsDropDownList.length > 2;
    
    const conditionForButtonDisable =
      props.question.Actions.length > 0 &&
      props.question.Actions[0].Action_Status !== "complete" &&
      props.Assessment_Id > props.question.Actions[0].Action_Assessment_Id;

    const readOnlyClass = conditionForButtonDisable ? "disabled-review" : "";

    return (
      <React.Fragment>
        {(!showDropdownOptions &&
          (props.responseOptionsDropDownList || []).map((option) => (
            //to add tooltip
            <div
              className={`responseButton m-r-10`}
              data-tip data-for={conditionForButtonDisable ? "registerTip" : ""}
            >
              {/* to disable response button*/}
              <span className={`responseButton ${readOnlyClass}`}>
                <button
                  id={option.Response}
                  type="button"
                  className={`btn btn-blank at-standard-btn m-r-10 ${
                    props.question.Status === option.Response &&
                    (props.question.Status === "At Standard" ||
                      props.question.Status === "Pass")
                      ? `AS active`
                      : props.question.Status === option.Response &&
                        (props.question.Status === "Not At Standard" ||
                          props.question.Status === "Fail")
                      ? `NS active`
                      : ""
                  }`}
                  onClick={(e) => props.toggle(e, option.Response)}
                >
                  {option.Response}
                </button>
              </span>
              <ReactTooltip id="registerTip" place="top" effect="solid">
                Open actions must be closed before the assessment result can be
                changed
              </ReactTooltip>
            </div>
          ))) ||
          (props.responseOptionsDropDownList.length > 0 && (
            <div
              onClick={(e) => props.toggleClass(e)}
              className={
                selectedInSelect
                  ? "response-dropdown response-dropdown__selected"
                  : "response-dropdown"
              }
            >
              <Select
                id={props.questionId}
                className="dropdown-with-search"
                onChange={(e) => props.toggle(e)}
                placeholder="Select response"
                value={selectedInSelect}
                options={multiselectOptions(
                  "Select response",
                  props.responseOptionsDropDownList,
                  "Response",
                  "Response",
                  null
                )}
              />
            </div>
          ))}
      </React.Fragment>
    );
}

export default ResponseOptions;
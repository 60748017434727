import {
  FETCH_INVESTIGATION_RECORDS,
  INVESTIGATION_LIST_ERRORS,
  POPULATE_INVESTIGATION_LIST,
  FETCH_INVESTIGATION_CREATE_DATA,
  POPULATE_INVESTIGATION_CREATE_DATA,
  INVESTIGATION_CREATE_DATA_ERRORS,
  POST_NEW_INVESTIGATION_DATA,
  SAVE_NEW_INVESTIGATION_RESPONSE,
  FETCH_LOOKUP_STAGE_DATA,
  FETCH_LOOKUP_STATUS_DATA,
  LOOKUP_STATUS_ERRORS,
  LOOKUP_STAGE_ERRORS,
  POPULATE_LOOKUP_STATUS_DATA,
  POPULATE_LOOKUP_STAGE_DATA,
  DELETE_INVESTIGATION_FILES,
  DELETE_INVESTIGATION_RESPONSE,
  POST_UPDATE_INVESTIGATION_DATA,
  SAVE_UPDATE_INVESTIGATION_RESPONSE
} from '../constants/actionTypes';

export const doFetchInvestigationRecords = (query, payload) => ({
  type: FETCH_INVESTIGATION_RECORDS,
  query,
  payload
});

export const doPopulateInvestigationList = (investigationList, count, deviceType) => ({
  type: POPULATE_INVESTIGATION_LIST,
  investigationList,
  count,
  deviceType
});


export const doFetchInvestigationErrors = error => ({
  type: INVESTIGATION_LIST_ERRORS,
  error,
});


export const doFetchInvestigationCreateData = () => ({
  type: FETCH_INVESTIGATION_CREATE_DATA,
});

export const doPopulateInvestigationCreateData = (createInvestigationObj, count, deviceType) => ({
  type: POPULATE_INVESTIGATION_CREATE_DATA,
  createInvestigationObj,
  count,
  deviceType
});


export const doFetchInvestigationCreateErrors = error => ({
  type: INVESTIGATION_CREATE_DATA_ERRORS,
  error,
});

export const doPostNewInvestigationData = payload => ({
  type: POST_NEW_INVESTIGATION_DATA,
  payload
});

export const saveNewInvestigationResp = saveResponse => ({
  type: SAVE_NEW_INVESTIGATION_RESPONSE,
  saveResponse,
});

export const doFetchLookUpStage = () => ({
  type: FETCH_LOOKUP_STAGE_DATA,
});

export const doFetchLookUpStatus = () => ({
  type: FETCH_LOOKUP_STATUS_DATA,
});

export const doFetchLookUpStatusDataErrors = error => ({
  type: LOOKUP_STATUS_ERRORS,
  error,
});

export const doFetchLookUpStageDataErrors = error => ({
  type: LOOKUP_STAGE_ERRORS,
  error,
});

export const doPopulateLookUpStatusData = (lookUpStatusObj, count, deviceType) => ({
  type: POPULATE_LOOKUP_STATUS_DATA,
  lookUpStatusObj,
  count,
  deviceType
});

export const doPopulateLookUpStageData = (lookUpStageObj, count, deviceType) => ({
  type: POPULATE_LOOKUP_STAGE_DATA,
  lookUpStageObj,
  count,
  deviceType
});

export const doDeleteInvestigationFiles = (payload) => ({
  type: DELETE_INVESTIGATION_FILES,
  payload
});

export const deleteInvestigationEvidence = (deleteResponse) => ({
  type: DELETE_INVESTIGATION_RESPONSE,
  deleteResponse,
});

export const doPostUpdateInvestigationData = payload => ({
  type: POST_UPDATE_INVESTIGATION_DATA,
  payload
});

export const saveUpdateInvestigationResp = saveUpdateResponse => ({
  type: SAVE_UPDATE_INVESTIGATION_RESPONSE,
  saveUpdateResponse,
});
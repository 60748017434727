import {
    ADD_SINGLE_REVIEW,
    FETCH_SINGLE_REVIEW,
    SAVE_SINGLE_REVIEW,
    UPDATE_SINGLE_REVIEW,
    VALIDATE_SINGLE_REVIEW,
    ADD_ACTION_DETAILS,
    Do_COMPLETE_REVIEW,
    ADD_QUESTION_DOCUMENT,
    DO_SUCCESS,
    DELETE_DOCUMENT,
    DO_SHARE_REVIEW,
    DO_SHARE_REVIEW_STATUS,
    ADD_RECOMMENDATION,
    UPDATE_RECOMMENDATION,
    FETCH_DEALER_INFO_BY_ID,
    UN_AUTHORIZED_REVIEW,
    FETCH_DEALER_INFO_BY_ID_SUCCESS,
    FETCH_DEALER_INFO_BY_ID_ERROR,
    CLEAR_DOC_DATA,
    POPULATE_KPI_DATA,
    ADD_QUESTION_SOP,
    UPDATE_COMMENT,
    ADD_GROUP_ID,
    REVIEW_FETCH_ERROR,
    CLEAR_REVIEW_DATA,
    UPDATE_CSV_REVIEW,
    SCORE_DATA,
    SCORE_DATA_SUCCESS,
    SCORE_DATA_ERROR,
    TFLSCORELISTING_DATA,
    TFLSCORELISTING_SUCCESS,
    TFLSCORELISTING_ERROR,
    UPDATESCORE_DATA,
    UPDATESCORE_DATA_SUCCESS,
    UPDATESCORE_DATA_ERROR,
    DO_CLEAR_CSV_ERR

} from '../constants/actionTypes';

const doFetchReview = (reviewType, reviewId, fetchGroupId) => ({
    type: FETCH_SINGLE_REVIEW,
    reviewType,
    reviewId,
    fetchGroupId
});

const doFetchReviewError = error => ({
    type: REVIEW_FETCH_ERROR,
    error
});

const doAddReview = (reviewType,review, currentUser) => ({
    type: ADD_SINGLE_REVIEW,
    review,
    reviewType,
    currentUser
});

const doAddGroupId = (reviewType, review) => ({
    type: ADD_GROUP_ID,
    review,
    reviewType
});

const doSaveReview = (reviewResponse, isSubmit) => ({
    type: SAVE_SINGLE_REVIEW,
    reviewResponse,
    isSubmit
});

const doUpdateReviewState = (qId, qResponse,flagStatus) => ({
    type: UPDATE_SINGLE_REVIEW,
    qId,
    qResponse,
    flagStatus
});

const updateQuestionComment = (qId, commentText) => ({
    type: UPDATE_COMMENT,
    qId,
    commentText
});

const doValidateReviewState = (qId, qResponse) => ({
    type: VALIDATE_SINGLE_REVIEW,
    qId,
    qResponse
});

const doAddActionDetails = (actionDetails, qId,reviewType) => ({
    type: ADD_ACTION_DETAILS,
    actionDetails,
    qId,
    reviewType
});

const doShareReview = (reviewData) => ({
    type: DO_SHARE_REVIEW,
    reviewData

});
const doShareReviewStatus = (payload) => ({
    type: DO_SHARE_REVIEW_STATUS,
    payload
})

 

 const doCompleteReview =(payload) => ({
    type: Do_COMPLETE_REVIEW,
    payload

})


const doAddQuestionDocument = (payload) => ({
    type: ADD_QUESTION_DOCUMENT,
    payload
});

const doAddQuestionSop = (payload,params,data) => ({
    type: ADD_QUESTION_SOP,
    payload,
    params,
    data
});
const doSuccess = (response) =>({
    type:DO_SUCCESS,
    response
})

const doDeleteDocument = (payload) =>({
    type:DELETE_DOCUMENT,
    payload
})

const doAddQuestionRecommendation = (payload) => ({
    type: ADD_RECOMMENDATION,
    payload
})
const doUpdateQuestionRecommendation = (payload) => ({
    type: UPDATE_RECOMMENDATION,
    payload
})
const fetchDealerInfoById = (payload) => ({
    type: FETCH_DEALER_INFO_BY_ID,
    payload
})

const unAuthorisedReview = (payload) => ({
    type: UN_AUTHORIZED_REVIEW,
    payload
})

const fetchDealerInfoByIdSuccess = (payload) => ({
    type: FETCH_DEALER_INFO_BY_ID_SUCCESS,
    payload
})
const fetchDealerInfoByIdError = (payload) => ({
    type: FETCH_DEALER_INFO_BY_ID_ERROR,
    payload
})
const doFetchScoreData = (payload) => ({
    type: SCORE_DATA,
    payload
})
const doFetchScoreDataSuccess = (payload) => ({
    type: SCORE_DATA_SUCCESS,
    payload
})
const doFetchScoreDataError = (payload) => ({
    type: SCORE_DATA_ERROR,
    payload
})
const doFetchtflscorelistingData = (payload) => ({
    type: TFLSCORELISTING_DATA,
    payload
})
const doFetchTflscorelistingDataSuccess = (payload) => ({
    type: TFLSCORELISTING_SUCCESS,
    payload
})
const doFetchTflscorelistingDataError = (payload) => ({
    type: TFLSCORELISTING_ERROR,
    payload
})
const doFetchSectionScoreData = (payload) => ({
    type: UPDATESCORE_DATA,
    payload
})
const doFetchSectionScoreDataSuccess = (payload) => ({
    type: UPDATESCORE_DATA_SUCCESS,
    payload
})
const doFetchSectionScoreDataError = (payload) => ({
    type: UPDATESCORE_DATA_ERROR,
    payload
})
const doClearReviewData = () =>({
    type: CLEAR_REVIEW_DATA   
  });

const doClearDocData = () =>({
    type: CLEAR_DOC_DATA
});

const doUpdateKPIData = (questionId,kpiValue) =>({
    type: POPULATE_KPI_DATA,
    questionId,
    kpiValue
});

const doUpdateCSVReview = (CSVData) =>({
    type: UPDATE_CSV_REVIEW,
    CSVData
});

const doClearCSVErrors = () => ({
    type: DO_CLEAR_CSV_ERR
});

export {
    doFetchReview,doAddReview, doSaveReview, doUpdateReviewState, doValidateReviewState, doAddActionDetails,doAddQuestionDocument,doCompleteReview,doShareReview,
    doSuccess,
    doDeleteDocument,
    doAddQuestionRecommendation,
    fetchDealerInfoById,
    unAuthorisedReview,
    fetchDealerInfoByIdSuccess,
    fetchDealerInfoByIdError,
    doClearReviewData,
    doUpdateQuestionRecommendation,
    doClearDocData,
    doUpdateKPIData,
    doAddQuestionSop,
    doShareReviewStatus,
    updateQuestionComment,
    doAddGroupId,
    doFetchReviewError,
    doUpdateCSVReview,
    doClearCSVErrors,
    doFetchScoreData,
    doFetchScoreDataSuccess,
    doFetchScoreDataError,
    doFetchtflscorelistingData,
    doFetchTflscorelistingDataSuccess,
    doFetchTflscorelistingDataError,
    doFetchSectionScoreData,
    doFetchSectionScoreDataSuccess,
    doFetchSectionScoreDataError
};
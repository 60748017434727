import React from 'react';
import { getBase64, compressImg } from "../../utils/utils";
import is from 'is_js';
import Popup from "reactjs-popup";
import DeletePopup from './deletepopup';
import Moment from 'moment';
import { connect } from 'react-redux';
import { AiFillFileAdd } from 'react-icons/ai';
import { doUpdateQuestionRecommendation, doAddQuestionRecommendation } from "../../actions/review";
var multipleblobUri = [];
var assessmentResponseDocID=[];
var multipleDocName=[];
class AddDoc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: false,
      length: 0,
      open:false,
      auditLogMsg: "",
    }


  }
  static getDerivedStateFromProps(props, state) {
    if (state.length !== props.qDetails.Documents.length) {
      return {
        length: props.qDetails.Documents.length,
        showLoader: false,
        open:false

      }
    }
  }

  addPhoto(e) {
    let file = e.target.files[0];
    e.target.value = null;

    const ext = file.name.split('.').pop();

    let fileType = '';
    if (ext == "pdf" || ext == "docx" || ext == "doc" || ext == 'xlsx' || ext == 'txt') {
      fileType = 'document'
    } else {
      fileType = 'photo'
    }
    const payload = [{
      "Assessment_Response_ID": this.props.qDetails.Assessment_Response_ID,
      "Assessment_ID": this.props.review.Assessment_Id,
      "Assessment_Response_Doc_ID": '',
      "Question_Template_ID": this.props.qDetails.Question_Template_ID,
      "Document_Name": file.name,
      "Expiry_Date": new Date(),
      "Doc_Type": fileType,
      "data": ''
    }]
    if( ( /image/i ).test( file.type ) ) {
      var $this = this;
      var reader = new FileReader();
      reader.readAsArrayBuffer(file);
    
      reader.onload = function (event) {
        // blob stuff
        var blob = new Blob([event.target.result]); // create blob...
        window.URL = window.URL || window.webkitURL;
        var blobURL = window.URL.createObjectURL(blob); // and get it's URL
        
        // helper Image object
        var image = new Image();
        image.src = blobURL;
        //preview.appendChild(image); // preview commented out, I am using the canvas instead
        image.onload = function() {
          // have to wait till it's loaded
          var file = compressImg(image); // send it to canvas
          payload[0].data = file.split(',')[1];
          $this.setState({ showLoader: true });
          $this.props.addQuestionDocuments(payload);
        }
      };
    } else {
      getBase64(file).then((el) => {
        payload[0].data = el.split(',')[1];
        this.setState({ showLoader: true })
        this.props.addQuestionDocuments(payload)
      });
      }


      // Part of Reccomandation data save logic
      // const Recommendations = this.props.qDetails.Recommendations.length > 0 ? this.props.qDetails.Recommendations : [];

      // if (Recommendations.length > 0) {
      //     let Recommendationpayload = {
      //         "Question_Template_ID": this.props.qDetails.Question_Template_ID,
      //         "Recommendation": this.props.qDetails.Recommendations[0].Recommendation,
      //         "Assessment_ID": this.props.review.Assessment_Id,
      //         "User_ID": "default",
      //         "LastModifiedBy": this.props.review.LastModifiedBy,
      //         "LastModifiedOn": "2019-01-07T16:32:13.277",
      //         "CreatedBy": this.props.review.CreatedBy,
      //         "CreatedOn": "2019-01-07T16:32:13.277",
      //         "Assessment_Response_ID": this.props.qDetails.Assessment_Response_ID,
      //         "Recommendation_Date": new Date()
      //     }
      //     if (this.props.qDetails.Recommendations[0].Recommendation.length > 0 && this.props.qDetails.Recommendations[0].Question_Recommendation_ID == 0) {
      //         this.props.doAddQuestionRecommendation(Recommendationpayload);
      //     }
      //     else {
      //         Recommendationpayload.Question_Recommendation_ID = this.props.qDetails.Question_Recommendation_ID;
      //         this.props.doUpdateQuestionRecommendation(Recommendationpayload);

      //     }
      // }
      //end

     /** Audit Log */
        let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
            + '(' + this.props.currentUser.employeeNumber + ')' : "";
      let finalMsg = this.state.auditLogMsg;
      let msg = currentUser + ' added ' + file.name + ' for ' + this.props.qDetails.Question_Template_ID + ' for ' + this.props.review.Assessment_Id + ' on ' +  Moment(new Date()).format('HH:MM DD MMM YYYY');

      if(!this.state.auditLogMsg.includes(this.props.qDetails.Question_Template_ID)){
        finalMsg = finalMsg + msg;
      }else{
        finalMsg = msg;
      }
      const reqPayload = {
        LogMessage: msg,
        AuditLogModule: "Conduct Reviews And Approvals",
        AcionAPISource: "Upload Photos & Documents",
        CreatedBy: currentUser,
        CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
    }
      this.props.doPostAuditLogData(reqPayload);
      this.setState({
        auditLogMsg: finalMsg,
      });
        /** Audit Log */
    e.preventDefault();
  }

  deleteMultipleQuestionDoc(e) { 
    var deleteMultipleId=assessmentResponseDocID.toString();
    var docName = multipleDocName.toString();
    const payload = {
      "Assessment_ID": this.props.review.Assessment_Id,
      "Question_Template_ID": this.props.qDetails.Question_Template_ID,
      "DeleteBy": [{ "Field": "DeleteBy", "DeleteId": deleteMultipleId }],
    }
    this.setState({ showLoader: true })
    document.body.style.removeProperty('overflow');

     /** Audit Log */
        let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
            + '(' + this.props.currentUser.employeeNumber + ')' : "";
      
      let msg = currentUser + ' deleted ' + docName + ' for ' + this.props.qDetails.Question_Template_ID + ' for ' + this.props.review.Assessment_Id + ' on ' +  Moment(new Date()).format('HH:MM DD MMM YYYY');
      const reqPayload = {
        LogMessage: msg,
        AuditLogModule: "Conduct Reviews And Approvals",
        AcionAPISource: "Upload Photos & Documents",
        CreatedBy: currentUser,
        CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
    }
      this.props.doPostAuditLogData(reqPayload);
        /** Audit Log */

      /** Auto save observatiom */

      // Part of Reccomandation data save logic
      const Recommendations = this.props.qDetails.Recommendations.length > 0 ? this.props.qDetails.Recommendations : [];

      if (Recommendations.length > 0) {
          let Recommendationpayload = {
              "Question_Template_ID": this.props.qDetails.Question_Template_ID,
              "Recommendation": this.props.qDetails.Recommendations[0].Recommendation,
              "Assessment_ID": this.props.review.Assessment_Id,
              "User_ID": "default",
              "LastModifiedBy": this.props.review.LastModifiedBy,
              "LastModifiedOn": "2019-01-07T16:32:13.277",
              "CreatedBy": this.props.review.CreatedBy,
              "CreatedOn": "2019-01-07T16:32:13.277",
              "Assessment_Response_ID": this.props.qDetails.Assessment_Response_ID,
              "Recommendation_Date": new Date()
          }
          if (this.props.qDetails.Recommendations[0].Recommendation.length > 0 && this.props.qDetails.Recommendations[0].Question_Recommendation_ID == 0) {
              this.props.doAddQuestionRecommendation(Recommendationpayload);
          }
          else {
              Recommendationpayload.Question_Recommendation_ID = this.props.qDetails.Question_Recommendation_ID;
              this.props.doUpdateQuestionRecommendation(Recommendationpayload);

          }
      }
      //end

      /** Auti save observation */

    this.props.deleteQuestionDoc(payload);
    this.setState({ isMultiSelection: false });
    var checkboxes = document.getElementsByName(this.props.qDetails.Question_Template_ID);
    var i=0;
    for (i=0; i<checkboxes.length; i++) {
       checkboxes[i].checked = false;
  }
    e.preventDefault();
  }

  /* openBlobURI = (event, blobURL) => {
    event.preventDefault();
    event.stopPropagation();
    if (blobURL.indexOf('.png') > -1 || blobURL.indexOf('.jpeg') > -1 || blobURL.indexOf('.jpg') > -1 || blobURL.indexOf('.svg') > -1 
    || blobURL.indexOf('.gif') > -1 || blobURL.indexOf('.pdf') > -1) {
      fetch(blobURL, {
        method: 'GET'
        
      }).then (function (blobData) {
        blobData = blobData.slice(0, blobData.size, "image/jpeg");
        window.open(window.URL.createObjectURL(blobData));
      }).then(function (json) {
      }).catch(function (error) {});
    }

  } */

  showLoader() {
    return this.state.showLoader ? (
      <div className="lds-ring-loader ">
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-overlay" />
      </div>
    ) : null;
  }
  isChekboxSelected(e, index,el) {
    var checkboxes = document.getElementsByName(this.props.qDetails.Question_Template_ID);
    var temp = [];
    var tempassessmentResponseDocID =[];
    var tempmultipleDocName=[];
    for (var idx in checkboxes) {
      if (checkboxes[idx].checked == true) {
        temp.push(checkboxes[idx].value);
        tempassessmentResponseDocID.push(checkboxes[idx].getAttribute('assessment_res_docid'));  
        tempmultipleDocName.push(checkboxes[idx].getAttribute('doc_name'));  
      }
  }
  multipleblobUri=temp;
  assessmentResponseDocID=tempassessmentResponseDocID;
  multipleDocName = tempmultipleDocName;
    if (temp.length > 0) {
      this.setState({ isMultiSelection: true });
    } else {
      this.setState({ isMultiSelection: false });
    }
  }

  multipleDownlaod(e){
     for( var n = 0; n <multipleblobUri.length; n++ )
       {     
        var download = multipleblobUri[n];      
        let iframe = document.createElement('iframe');
        iframe.style.visibility = 'collapse';
        document.body.append(iframe);
        iframe.contentDocument.write(
            `<form action="${download.replace(/\"/g, '"')}" method="GET"></form>`
        );
        iframe.contentDocument.forms[0].submit();
        setTimeout(() => iframe.remove(), 2000);
       }
     }

  render() {
    const documents = this.props.qDetails.Documents ? this.props.qDetails.Documents : [];
    return [

      <div key={this.props.uniqueKey} id={this.props.uniqueKey} className="col-xl-4 col-md-6 questions__documents-wrapper questions__photo-documents">
        <div className="docs-header add-photo-header clearfix">
        <img className="docs-icon" width="22px" src="../images/icons-camera.svg" alt="Add Photo / Document" />
              <span className="docs-header-text">Photos &amp; Documents</span>
          <label htmlFor={`upload-photo-${this.props.qDetails.Question_Template_ID}-${this.props.uniqueKey}`} className={`add-more-link${this.props.qAssessment_Response_ID?"-disabled":""} add-more-link_photo ${this.props.elHide}`} href=""><AiFillFileAdd /> Add </label>
          <input disabled ={this.props.qAssessment_Response_ID} id={`upload-photo-${this.props.qDetails.Question_Template_ID}-${this.props.uniqueKey}`} type="file" name="myFile" className="addMore-doc" ref={this.fileInput} onChange={(e) => this.addPhoto(e)} />
        </div>
        <div>
          {this.state.isMultiSelection ? (
             <div className="textAlignCenter" style={{textAlign: 'center',paddingTop: '10px'}}>
              <div className="delete-icon">
                 <a className="enabled-review" onClick={this.multipleDownlaod}>
                  <img
                    src="../images/download.svg"
                    alt="Download" width="16px"
                  />
                </a>
                            {((this.props.review.Status != "Completed") &&
                <Popup trigger={<span className="">  <img src="../images/delete.svg" alt="Delete" width="16px" /></span>}
                  modal
                  closeOnDocumentClick
                  lockScroll
                  open={this.state.open}
                >
                  {close => (
                    <div onClick={(e) => e.preventDefault(e)}>
                      <div className="deletepopup-container">
                        <div className="deletepopup_wrapper small-popup-wrap">
                          <div className="close__wrapper">
                            <div className="popup__close" onClick={close}>
                              <label><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
                            </div>
                          </div>
                        </div>

                        <DeletePopup onClose={close} deleteDoc={(e) => this.deleteMultipleQuestionDoc(e)} />
                      </div>
                    </div>

                  )}
                                </Popup>
 
                 )}​​​​​

          
            </div> </div>) : null}
        </div>
        <div className="document-list">
          {documents.length === 0 ? <div className="text-center text-light-gray">
            <div className="text-center m-b-15"><img src="../images/f-image.svg" /></div>
            No uploaded evidence.
              </div> :
            <ul>
              {documents && documents.map((el, index) => {
                return (

                  <li className={el.Doc_Type === 'document' ?  'doc-icon' :'image-icon'  } key={index}>
                    <span title={el.Doc_Name} className="file-name">{el.Doc_Name}</span>
                    <div className="action-btns">
                    <div class="check-icon">
                                <label className="enabled-review">
                          <input  type="checkbox" 
                            defaultChecked = {false}
                            name={this.props.qDetails.Question_Template_ID}
                            value={el.BlobURI}
                            doc_name={el.Doc_Name}
                            assessment_res_docid={el.Assessment_Response_Doc_ID}
                            id={'checkbox' +index}
                            onChange={(e) => this.isChekboxSelected(e, index,el)} />
                        </label></div>
                    </div>
                  </li>
                )
              })}
            </ul>
          }
        </div>
        {this.showLoader()}
      </div>


    ]
  }
}

const mapDispatchToProps = dispatch => ({
    doAddQuestionRecommendation: (payload) => dispatch(doAddQuestionRecommendation(payload)),
    doUpdateQuestionRecommendation: (payload) => dispatch(doUpdateQuestionRecommendation(payload)),

});

export default connect(undefined, mapDispatchToProps)(AddDoc);

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/final/index.css';
import registerServiceWorker from './registerServiceWorker';
import { Router, Route, Link } from "react-router-dom";

import ScrollToTop from 'react-router-scroll-top'
/**Added to track the application in Azure*/
import ReactAI from 'react-appinsights';
import {createBrowserHistory } from 'history'

const history = createBrowserHistory()
//ReactAI.init({instrumentationKey: process.env.REACT_APP_INSTRUMENT_KEY}, history);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <ScrollToTop>
        <App dispatch={store.dispatch} store={store}/>
      </ScrollToTop>
    </Router>
  </Provider>,
    document.getElementById('root')
);

registerServiceWorker();

/**
 * Created by mohdshaban on 25/04/19.
 */

import {
    FETCH_START_REVIEW,
    FETCH_START_REVIEW_SUCCESS,
    FETCH_START_REVIEW_ERROR

} from '../constants/actionTypes';


export const doFetchStartReview = (payload) => ({
    type: FETCH_START_REVIEW,
    payload
})

export const doFetchStartReviewSuccess = (payload) => ({
    type: FETCH_START_REVIEW_SUCCESS,
    payload
})

export const doFetchStartReviewError = (payload) => ({
    type: FETCH_START_REVIEW_ERROR,
    payload
})


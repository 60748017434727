import {
  POST_AUDIT_LOG_DATA,
  FETCH_AUDIT_LOG_SUCCESS,
  FETCH_AUDIT_LOG_ERROR
} from '../constants/actionTypes';

export const doPostAuditLogData = payload => ({
  type: POST_AUDIT_LOG_DATA,
  payload
});

export const doFetchAuditLogError = (payload) => ({
    type: FETCH_AUDIT_LOG_ERROR,
    payload
})

export const doFetchAuditLogSuccess = (payload) => ({
    type: FETCH_AUDIT_LOG_SUCCESS,
    payload
})
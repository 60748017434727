/**
 * Created by mohdshaban on 22/06/19.
 */
import axios from 'axios';
let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}

export const fetchUserAccessRoles = () =>
        axios.post(`${BASE_URL}/GetUserAccessControlPermission`);

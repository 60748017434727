import React from 'react';
import axios from 'axios';

axios.defaults.headers.common = {
    "Ocp-Apim-Subscription-Key": `${process.env[`REACT_APP_APIM_KEY_${window.location.hostname}`]}`
};
let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}

export const postAuditLog = (payload) =>
    axios.post(`${BASE_URL}/SaveAuditLog`, payload);




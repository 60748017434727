import React from "react";
import { connect } from 'react-redux';
import { getFilterLabel } from "../../selectors/story";
import { doSortStories } from "../../actions/story";
import { toggleSidebar } from "../../actions/header";
import Popup from "reactjs-popup";
import InvestigationOverlay from "./InvestigationOverlay";
export const StoriesHeader = (props, { onSort, toggleFilter, filterLabel, myForwardedRef }) => {
  return (
    <div ref={myForwardedRef} tabIndex={1} >
      <div className="show-filters">
        <span className="m-r-20">
          <Popup
            trigger={
              <button id="investigation__button" class="btn btn-primary"> Create Investigation</button>
            }
            modal
            closeOnDocumentClick
            lockScroll
          >
            {close => (
              <div>
                <div className="close__wrapper">
                  <div className="popup__close" onClick={close}>
                    <label><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
                  </div>
                </div>
                <InvestigationOverlay
                  investigationList={props.investigationList} onClose={close} investigationType={"Create"}
                  isInvestigationAccessForPowerUser={props.isInvestigationAccessForPowerUser}
                  isInvestigationAccessForRM={props.isInvestigationAccessForRM}
                  isUpdateDisabled = {false}
                />
              </div>
            )}
          </Popup>

        </span>
        <button id="Show_Filters"
          onClick={() => props.toggleFilter()}
          type="button"
          className="btn btn-white"
          data-toggle="button"
          aria-pressed="false"
          autoComplete="off"
        >
          <img src="images/filter-icon.svg" alt="Filters" className="filter-g m-r-10" />
          <img src="images/filter-w.svg" alt="Filters" className="filter-hover m-r-10" />
          <span className="show-filter-text">Filter</span>
        </button>
      </div>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  //onSort: sortBy => dispatch(doSortStories(sortBy, 'action')),
  toggleFilter: () => dispatch(toggleSidebar()),
});

const mapStateToProps = state => ({
  filterLabel: getFilterLabel(state),
});

const ConnectedMyComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoriesHeader);

export default React.forwardRef((props, ref) =>
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
);
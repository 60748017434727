import React from "react";
import Select from "react-select";
import  { multiselectOptions} from "../../constants/uploadSOPConstants";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MultiSelectDropdown from "./MultiSelectDropdown";
const ReviewListSchedule = (props) => {
    let  selectObj = {
        optionId: "Assessment_Template_ID",
        optionValue: "Assessment_Name"
    }
    return (
        <React.Fragment>
            <div className={`form-group custom-multiselect ${props.value && props.value.length > 0 ? '' : 'not-selected'}`}>
            <label htmlFor="review" id="review__label">
                Review Name
            </label>
          {/* code for single select ReviewName  */}
            {/* <select
                id="review-input" classNamePrefix='review' className={`form-group custom-multiselect ${props.value && props.value.length > 0 ? '' : 'not-selected'}`}
                value={props.value}
                placeholder="Select Review Name"
                onChange={(e) => props.onChange(e)}
                options={multiselectOptions("Select Review", props.reviewDropDownList, "Assessment_Template_ID", "Assessment_Name", "Assessment_Name")}
                isSearchable="true"

            /> */}
            <MultiSelectDropdown
                forEle="review"
                handleChange={(e) =>props.onChange(e)}
                dropDownList={props.reviewDropDownList}
                value={props.value} selectObj={selectObj}
                placeholderText="Select Review Name"
                reference={props.reference}
            />
            </div>
        </React.Fragment>


    )
}

export default ReviewListSchedule;
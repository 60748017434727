import {
    STORIES_FILTER, STORIES_SORT, STORIES_PAGE_CHANGE,RESET_SORT_FILTER, ADD_FILTER, REMOVE_FILTER
} from '../constants/actionTypes';

const INITIAL_STATE = {
    filters: [],
    sortBy: {
        sortField: '',
        sortOrder: 'desc'
    },
    activePage: 1,
    reviewType:'tfl18'
};


const applyResetSortAndFilters = (state, action) => {
    return ({
            ...INITIAL_STATE,
            reviewType:action.reviewType
    });
}

const addFilterStories = (state, action) => {
    const tmpFilterState = state.filters.filter(obj =>{
        return obj.Field !== action.obj.Field
    });
    const filters = (action.obj.SearchText && action.obj.SearchText !=="" ?[...tmpFilterState,action.obj]:[...tmpFilterState])
    return ({
        ...state, filters, activePage: 1,sortBy: {
            sortedField: null,
            sortOrder: "desc"
        },
    })
};

const applyFilterStories = (state, action) => {
    return ({
        ...state, activePage: 1,sortBy: {
            sortedField: null,
            sortOrder: "desc"
        },
    })
};

const removeFilter = (state, action) => {
    const filters = state.filters.filter(obj =>{
        return obj.Field !== action.obj.Field
    });
    return ({
        ...state, filters, activePage: 1,sortBy: {
            sortedField: null,
            sortOrder: "desc"
        },
    })
};

const applySortStories = (state, action) => ({
    ...state, sortBy: {
        sortedField: action.sortBy,
        sortOrder: (state.sortBy.sortOrder ==='desc'?'asc':'desc')
    },
    activePage: 1,
    reviewType: action.reviewType
});

const applyStoriesChangePage = (state, action) => ({
    ...state, activePage:action.pageNo
});

export function filterReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case STORIES_FILTER : {
            return applyFilterStories(state, action);
        }
        case STORIES_SORT : {
            return applySortStories(state, action);
        }
        case STORIES_PAGE_CHANGE : {
            return applyStoriesChangePage(state, action);
        }
        case RESET_SORT_FILTER : {
            return applyResetSortAndFilters(state, action);
        }
        case REMOVE_FILTER : {
            return removeFilter(state, action);
        }
        case ADD_FILTER : {
             return addFilterStories(state, action);
        }
        default : return state;
    }
}

export default filterReducer;
import React from "react";
import Select from "react-select";
import  { multiselectOptions} from "../../constants/uploadSOPConstants";
import MultiSelectDropdown from "./MultiSelectDropdown";

const SapDealerList = (props) => {
    let selectObj;
    if(props.type === 'score') {
        selectObj = {
            optionId: "SAP_Dealer_Code",
            optionValue: "SAP_Dealer_Code"
        }
    } else {
    selectObj = {
        optionId: "Sap_Code",
        optionValue: "Sap_Code"
    }
}
    return (
        <div className={`form-group custom-multiselect ${props.selectedOption && props.selectedOption.length > 0 ? '' : 'not-selected'}`}>
            <label htmlFor="sap-dealer-code" id="sap-dealer-code__label">
                SAP Dealer Code
            </label>
            
            <MultiSelectDropdown
                forEle="sap-dealer-code"
                handleChange={props.onSelectSapCode}
                dropDownList={props.sapDealerList}
                value={props.selectedOption} selectObj={selectObj}
                placeholderText="Select SAP Code"
                reference={props.reference}
            />
        </div>


    )
}

export default SapDealerList;
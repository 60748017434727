import React from 'react';

class Comments extends React.Component {
    constructor(props) {
        super(props);
        this.state = { }
    }

    render() {
        const comments = this.props.qDetails.Comments ? this.props.qDetails.Comments : [];
        return [
           <div>
            {comments.length > 0 &&
            <div className="form-group">
            <label><span className="m-r-10"><img src="/images/comment.svg" width="18px"/></span>Comments</label>
            </div>
             }
            <div className="questions__comment-history">
                {comments && comments.map((el, index) => {
                return (
                    <div className="comment-listing">
                        <div className="row">
                            <div className="col-8 col-md-10">
                                <div className="comment-username">
                                    {el.QuesCommentsUserName}
                                </div>
                            </div>
                            <div className="col-4 col-md-2 text-right">
                                <div className="comment-history-date">
                                    {el.QuesCommentsDate}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-md-11 ">
                                <p className="comment-description">{el.QuestionComments}</p>
                            </div>
                        </div>
                    </div>
                
                    )
                })}
            </div>
          </div>
       ]
    }
}

export default Comments;

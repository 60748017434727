import React, { Component }  from 'react';
import {ImplicitCallback} from '@okta/okta-react';

class UnAuthCallBack extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ''
    }
  }

  componentDidMount() {
    setTimeout(() => {
      if (document.getElementById("auth-error-block") && document.getElementById("auth-error-block").querySelector('p')) {
        let linebreak = document.createElement('br');
        var textNode = document.createTextNode("Please raise an access request through MyISD.");
        document.getElementById("auth-error-block").querySelector('p').appendChild(linebreak);
        document.getElementById("auth-error-block").querySelector('p').appendChild(textNode);
      }
      //document.getElementById("auth-error-block").querySelector('p');
    }, 1000);
  }



  render() {
    return (
      <div className="container-fluid logout-screen h-100">
        <div className="row grid__columns-wrapper h-100">
            <div className="col-12 text-center align-self-center">
                <div className="login__box position-relative">
                    <div className="login-content " id="auth-error-block">
                      <ImplicitCallback />
                    </div>
                </div>
            </div>
        </div>
      </div>)
  }
}

export default UnAuthCallBack;
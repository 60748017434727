import selectedLocation from "../constants/uploadSOPConstants";

function validateUploadSOPForm (locationsDropDownList, sopNameSelectList,programSelectedOptions) {
  // let locationValue = document.getElementById("location_input").value;
  let locationValid = false;
  const locationCheck = isLocationValid(locationsDropDownList);
   if (locationCheck !== undefined){
     locationValid = true;
   }
  const sopNameValid = isSopNameValid(sopNameSelectList);
  const programValid = isProgramValid(programSelectedOptions);
  // let fileuploadValid = isSopNameValid(event);
  // var locationId=document.querySelector('#location option[value="Turnbull Toyota"]');
  return validateForm (sopNameValid, programValid, locationValid);

}

 function validateUploadedFile ( event ) {
  const validationFieldID = event.target;
  if(validationFieldID.type === "file" && validationFieldID.files[0] != null) {

      let fileName = validationFieldID.files[0].name;
      fileName = fileName.slice (0,20);
      const fileSize = validationFieldID.files[0].size;
      const fileType = validationFieldID.files[0].type;

      const infoArea = document.getElementById( 'UploadFileName' );
      infoArea.textContent = `  ${  fileName}`;

      if (fileSize < 10485760) {
            // this.setState({selectedFileForUpload: event.target.files[0]});

            var errArea = document.getElementById( 'uploadfileerror' );
            errArea.textContent = "";
            return event.target.files[0];
      } 
        var errArea = document.getElementById( 'uploadfileerror' );
        errArea.textContent = "** file size must not exceed 10 MB";
        return undefined;
      
    }
}

function isProgramValid (programSelectedOptions) {
  const programValue = programSelectedOptions
  const programValid = validateField("program", programValue);
  return programValid;
}

// function isLocationValid (locationsDropDownList, locationVal) {
//   let locationValue = document.getElementById("location_input").value;
//   let locationValid =   validateField("location_input", locationValue);
//   if (locationValid)
//     return selectedLocation(locationsDropDownList, locationValue);
//   // return locationValid;
// }

function isLocationValid (selectedLocationOptions) {
  // let locationValue = document.getElementById("location_input").value;
  const locationValid =   validateField("location_input", selectedLocationOptions);
  return locationValid;
  // return locationValid;
}

function isSopNameValid (sopNameSelectList){
  // let sop_nameValue = document.getElementById("sop_name").value;
  // let sopNameValid = validateField("sop_name", sop_nameValue);
  const sopNameValid = validateField("sop_name", sopNameSelectList);
  return sopNameValid;
}

function isFileUploadNameValid (){
  const fileUploadValue = document.getElementById("browse_document").value;
  const fileuploadValid=validateField("browse_document", fileUploadValue);
  return fileuploadValid;
}

function validateField(fieldName, value) {

    if (value !== null && value !== undefined) {

        switch(fieldName) {
          case 'sop_name':
            return value.length  > 0;
            // return value.length > 0; // for multi-select SOP
          case 'location_input':
            return value.id  > 0;
            case 'program':
                return value.id  > 0;
          default:
            return value.length > 0;
        }
      }

}

  function validateForm(sopNameValid, programValid,locationValid) {

    return sopNameValid && programValid && locationValid;
  }

  export { validateUploadedFile, validateUploadSOPForm, isLocationValid, isSopNameValid };

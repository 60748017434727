import React from 'react';
import XLSX from 'xlsx';
import Popup from "reactjs-popup"
import { doUpdateCSVReview, doClearCSVErrors } from '../../actions/review';
import { connect } from "react-redux";
import { showToast } from '../../actions/toast';
import _ from 'lodash';

class UploadReviewPopup extends React.Component {
    constructor(props) {
        super(props);
        this.csvRef = React.createRef();
    }

    state = {
        showAlert: false,
        csvErrors: '',
        showConfirmationPopup: false,
        fileName: '',
        file: '',
        CSVArray: '',
        validateCSV: false,
        showSuccess: false
    }

    componentDidUpdate(prevProps) {
        if (prevProps.CsvError !== this.props.CsvError) {
            this.setState({csvError: this.props.CsvError});
        }
        if (this.state.csvError === '' && !this.state.showSuccess) {
            this.successMessage();
            this.props.showToast({show:true ,message: "The file has been uploaded successfully.", showSuccess: true });
        } else if (this.state.csvError && !this.state.showAlert && this.state.validateCSV) {
            this.setState({
                showAlert: true,
                file: '',
                fileName: '',
                csvErrors: this.props.CsvError
            })
        }
        
    }

    addCSV(e) {
        const file = e.target.files[0];
        const ext = file.name.split('.').pop();
        e.target.value = null;
        this.setState({
            showAlert: false,
            valaidateCSV: true,
            csvErrors: ''
        })
        if (ext !== 'csv') {
            this.setState({
                showAlert: true,
                valaidateCSV: false,
                fileName: '',
                csvErrors: 'The uploaded file is not a .CSV file. Please save the Excel spreadsheet as a .CSV file and upload it again.'
            })

            document.getElementById("browse_document").value = "";
            return;
        }
        const reader = new FileReader();
        this.setState({ fileName: file.name })
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const workbook = XLSX.read(bstr, { type: 'binary' });

            const first_worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const aoa = XLSX.utils.sheet_to_json(first_worksheet, { header: 1, raw: false });
            const cols = [];
            for (let i = 0; i < aoa[0].length; ++i) cols[i] = { field: aoa[0][i] };
            this.validateHeader(cols);
                /* generate rest of the data */
            const CSVArray = [];
            for (let r = 2; r < aoa.length; ++r) {
                CSVArray[r - 1] = {};
                for (let i = 0; i < aoa[r].length; ++i) {
                    //if (aoa[r][i] == null) continue;
                    CSVArray[r - 1][aoa[0][i]] = aoa[r][i] || '';
                }
            }
            this.setState({ CSVArray });
            this.valaidateCSV(this.props.review, CSVArray);                            
        }
        reader.readAsBinaryString(file);
        e.preventDefault();
    }

    validateHeader(cols) {
        const excelColumns = this.props.excelColumns;
        let i = 0;
        let validateHeader = true;
        _.each(excelColumns, (column) => {
            if (column.title !== cols[i].field) {
                this.setState({
                    csvErrors: `The column name at row '1', column '${String.fromCharCode(i + 65)}' is expected to be ${column.title}. Actual value is ${cols[i].field}.`
                })
                validateHeader = false;
                return false;
            }
            i++;
        });
        this.setState({ validateHeader });
    }

    valaidateCSV(review, CSVArray) {
        const { Program, Assessment_Name, Sap_Code } = review;
        let programlineNo = '';
        let validateCSV = CSVArray.every((el, key) => {
            if (el.Program === Program && el.Review === Assessment_Name && el['SAP Location Code'] === Sap_Code) {
                return true;
            } else {
                programlineNo = key + 2;
                if ((!el.Program || !el.Review || !el['SAP Location Code']) && this.state.csvErrors) {
                    this.setState({
                        showAlert: true,
                        file: '',
                        fileName: ''
                    })
                } else {
                    this.setState({
                        showAlert: true,
                        file: '',
                        fileName: '',
                        csvErrors: `The Program or Location or Review in row '${programlineNo}' of the uploaded file is different to that of the current review.`
                    })
                }

                return false;
            }
        });
        if (validateCSV && !this.state.validateHeader) {
            validateCSV = false;
            this.setState({
                showAlert: true,
                file: '',
                fileName: ''
            })
        }
        if (validateCSV) {
            this.setState({showAlert: false, csvError: null});
            this.props.clearErrors();
        }
        this.setState({ validateCSV })

    }


    hideAlert() {
        this.setState({
            showAlert: false,
            showSuccess: false
        });
    }
    errorMessage = () => {
        const errorDetails = (
            <div className="alert alert-danger alert-dismissible" role="alert">
                <button type="button" id="messageButton1" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.hideAlert()}>
                    <span aria-hidden="true">&times;</span>
                </button>
                {this.state.csvErrors}
            </div>
        );
        return errorDetails;
    }

    successMessage() {
        this.props.onClose();
    }

    cancelUplaoding = () => {
        this.setState({
            showAlert: false,
            showConfirmationPopup: false
        });
    }

    uploadCSV = () => {
        this.props.doUploadCSV(this.state.CSVArray);        
    }
    
    closeModal=()=>{
        this.setState({
            showConfirmationPopup: false
        });
    }

    render() {

        return (
            <div className="upload-review-popup small-popup">
                <div className="popup__wrapper">
                    <div className="popup__header modal-header">
                        <h5 className="modal-title">Upload Review<span className="cst-close" title="Close"></span></h5></div>
                    {this.state.showAlert ? this.errorMessage() : null}
                    
                    <div className="popup__body">
                        <form>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="program" id="select-program__label">Program</label>
                                    <p>{this.props.program}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="program" id="select-program__label">Location</label>
                                    <p>{this.props.locationName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="program" id="select-program__label">Review</label>
                                    <p>{this.props.AssessmentName}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="file-upload">
                                        <input type="file" ref={this.csvRef} className="form-control inputfile" id="browse_document" onChange={(e) => this.addCSV(e)}></input>
                                        <label className="" htmlFor="browse_document" id="UploadFileName">{this.state.fileName}</label>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12 text-center m-t-20 m-b-5">
                                    <button id="upload-review__button" className="btn btn-primary" type="button" disabled={!this.state.validateCSV} onClick={() => { this.setState({ showConfirmationPopup: true }) }}>Upload Review</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
                <Popup trigger={<span />}
                    modal
                    closeOnDocumentClick
                    lockScroll
                    open={this.state.showConfirmationPopup}
                   onClose={this.closeModal}
                >


                    <div >
                        <div className="deletepopup_wrapper small-popup-wrap">
                            <div className="close__wrapper">
                                <div className="popup__close popup__close-comfirmation" onClick={this.closeModal}>
                                    <label><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
                                </div>
                            </div>
                        </div>
                        <div className="deletepopup-container">
                            <div className="delete-popup small-popup">
                                <div className="popup__wrapper">
                                    <div className="popup__body">
                                        <div className="form-row">
                                            <div className="col-12">
                                                <div className="delete-warning">
                                                    <img src="./../images/error.svg" />
                                                </div>
                                                <p className="text-center text-bold m-t-20">Are you sure that you want to continue uploading this file? It will overwrite the existing 'In Progress' review in the system.</p>
                                                <div className="buttons-wrapper text-center m-t-25">
                                                    <a href="#" className="m-r-15 text-red" onClick={() => this.cancelUplaoding()}>No</a>
                                                    <button value="0" type="button" id='yesbtn' className="btn btn-gray yesbtn" onClick={(e) => {
                                                        this.uploadCSV()
                                                        this.closeModal()
                                                    }}>Yes</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </Popup>

            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    doUploadCSV: (payload) => dispatch(doUpdateCSVReview(payload)),
    clearErrors: () => dispatch(doClearCSVErrors()),
    showToast: (payload) => dispatch(showToast(payload)),
});

const mapStateToProps = (state) => ({
    CsvError: state.reviewState.error
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UploadReviewPopup);

import React from "react";
import Select from "react-select";
import  { multiselectOptions} from "../../constants/uploadSOPConstants";

const InvestigationFilter = (props) => {

    return (
        <React.Fragment>
            { props.locationList && props.type === 'location' && (
                <label htmlFor="investigation-location-list" id="investigation-location-list-label">
                    Location (All Tabs)
                </label>
            ) }
            { props.locationList && props.type === 'location' && (
                <Select
                    id="investigation-location-list" classNamePrefix='investigation' className="form-group dropdown-with-search"
                    value={props.selectedOption}
                    placeholder="Select Location"
                    onChange={(e) => props.onSelectLocation(e) }
                    options={multiselectOptions("Select Location", props.locationList, "Dealer_ID", "Location_Name", null) }
                    isSearchable="true"

                    />
            ) }

            
            { props.dropDownList && props.type === 'status' && (
                <label htmlFor="investigation-status-list" id="investigation-status-list-label">
                    Status
                </label>
            ) }
            { props.dropDownList && props.type === 'status' && (
                <Select
                    id="investigation-status-listt" classNamePrefix='status' className="form-group dropdown-with-search"
                    value={props.selectedOption}
                    placeholder="Select Status"
                    onChange={(e) => props.onSelectStatus(e) }
                    options={multiselectOptions("Select Status", props.dropDownList, "Name", "Description", null) }
                    isSearchable="true"

                    />
            ) }

            { props.titleList && props.type === 'title' && (
                <label htmlFor="investigation-title-list" id="investigation-title-list-label">
                    Title
                </label>
            ) }
            { props.titleList && props.type === 'title' && (
                <Select
                    id="investigation-title-listt" classNamePrefix='title' className="form-group dropdown-with-search"
                    value={props.selectedOption}
                    placeholder="Select Title"
                    onChange={(e) => props.onSelectTitle(e) }
                    options={multiselectOptions("Select Title", props.titleList, "Title", "Title", null) }
                    isSearchable="true"

                    />
            ) }

            { props.stageList && props.type === 'stage' && (
                <label htmlFor="investigation-stage-list" id="investigation-stage-list-label">
                    Stage
                </label>
            ) }
            { props.stageList && props.type === 'stage' && (
                <Select
                    id="investigation-stage-listt" classNamePrefix='stage' className="form-group dropdown-with-search"
                    value={props.selectedOption}
                    placeholder="Select Stage"
                    onChange={(e) => props.onSelectStage(e) }
                    options={multiselectOptions("Select Stage", props.stageList, "Name", "Description", null) }
                    isSearchable="true"

                    />
            ) }
        </React.Fragment>


    )
}

export default InvestigationFilter;
import _ from 'lodash'
import {
    ADD_SINGLE_REVIEW,
    STORIES_ADD,
    REVIEW_FETCH_ERROR,
    UPDATE_SINGLE_REVIEW,
    VALIDATE_SINGLE_REVIEW,
    ADD_ACTION_DETAILS,
    Do_COMPLETE_REVIEW,
    DO_SUCCESS,
    CLEAR_DOC_DATA,
    ADD_QUESTION_SOP,
    DELETE_DOCUMENT,ADD_QUESTION_DOCUMENT, DO_SHARE_REVIEW, DO_SHARE_REVIEW_STATUS,ADD_RECOMMENDATION,UPDATE_RECOMMENDATION,
    FETCH_TFL18_ASSESSMENT_ERROR, FETCH_TFL18_ASSESSMENT_DATA,FETCH_DEALER_INFO_BY_ID,UN_AUTHORIZED_REVIEW, FETCH_DEALER_INFO_BY_ID_SUCCESS,FETCH_DEALER_INFO_BY_ID_ERROR,CLEAR_REVIEW_DATA,
    POPULATE_KPI_DATA,
    UPDATE_COMMENT,
    ADD_GROUP_ID,
    UPDATE_CSV_REVIEW,
    DO_CLEAR_CSV_ERR,
    SCORE_DATA,
    SCORE_DATA_SUCCESS,
    SCORE_DATA_ERROR,
    UPDATESCORE_DATA,
    UPDATESCORE_DATA_SUCCESS,
    UPDATESCORE_DATA_ERROR,
    TFLSCORELISTING_DATA,
    TFLSCORELISTING_SUCCESS,
    TFLSCORELISTING_ERROR
} from '../constants/actionTypes';

import { getCurrentUserDetails } from '../constants/utilityfunctions';
import { validateKPI } from '../selectors/story';


const INITIAL_STATE = {
    review: '',
    questionResponse: {},
    validate: {},
    completedReviewStatus: {},
    isDocAdded:false,
    isLoading:false,
    shareReviewStatus: {},
    dealerInfo: '',
    tflscoreInfo: '',
    tflscoreInfoerror: '',
    sectionscoreInfo: '',
    sectionscoreInfoerror: '',
    tflscorelistingInfo:'',
    tflscoreListData:[],
    tflscorelistingerror:'',
    tflbreakDownScoreListData: [],
    isAddingSop:false,
    
};
 
const applyAddReview = (state, action) => {
    let review; const questionResponse={}; const validate={}; const tmpAppValidation=true; let tmpSectionValidation =true; let tmpCriteriaValidation=true; let tmpQuesValidation=true;
        review = action.review.returnData[0];
        review.groupId = action.review.groupid;
        review.valid ='valid';
        //const reviewStatus = review.Status;
        review.LastModifiedBy = getCurrentUserDetails();
        _.each(review.Sections, function(value, key,obj) {
            tmpSectionValidation =true;
            obj[key].valid ='valid';
            _.each(value.Criterias, function(value, key,obj) {
                tmpCriteriaValidation =true;
                obj[key].valid='valid';
                let isAnyQuestionAns = false;
                _.each(value.Questions, function(value, key,obj) {
                    tmpQuesValidation =true;
                    obj[key].valid='valid';

                     //for KPI question
                    if(obj[key].KPIType !== undefined || obj[key].KPIType !==""){
                        if(obj[key].Status===undefined || obj[key].Status ==='') {
                            obj[key].Status = obj[key].KPIProposedValue
                        } 
                        /** There was a production issue reported that for the reviews in-progress status shall not capture 
                         * the user responses rather it should show KPIProposedValue on UI. Below commented code shall be used for
                         * the fix. 24/04/2019 * /
                        /*else if (obj[key].KPIType !== "Manual" && reviewStatus === "In-progress"){
                         obj[key].Status = obj[key].KPIProposedValue
                    }*/
                    }   
                    questionResponse[obj[key].Question_Id] =obj[key].Status;
                    if(obj[key].Status && obj[key].Status !=='') {
                        const isFail  = obj[key].Responses.filter(el=>el.Response === obj[key].Status);
                        if(isFail[0].Response_Pass_Fail === "Fail" && obj[key].Actions.length ===0) {
                            obj[key].valid='invalid';
                            tmpCriteriaValidation =false;
                            tmpSectionValidation =false;
                        }
                        isAnyQuestionAns = true;
                    } else if (obj[key].Mandatory_Question) {
                        obj[key].valid='invalid';
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                    }
                    
                    const methodList =  value.Method_Text.split("--");
                    obj[key].Method_List =[];
                    obj[key].Question_List =[];
                    if(methodList.length > 1) {
                        obj[key].Method_Text = methodList[0];
                        for (let i=1;i <methodList.length;i++) {
                            obj[key].Method_List.push(methodList[i]);
                        }
                    }
                    if(value.Question_Text) {
                        const qList =  value.Question_Text.split("--");
                        if(qList.length > 1) {
                            obj[key].Question_Text = qList[0];
                            for (let i=1;i <qList.length;i++) {
                                obj[key].Question_List.push(qList[i]);
                            }
                        }
                    }
                    if (review.Status === 'Pending 1st' || review.status === 'Pending 2nd') {
                        if(obj[key].IsQuestionFlag && (!obj[key].QuestionComment || !obj[key].QuestionComment.length)) {
                            obj[key].flagValid = 'invalid';
                            tmpSectionValidation = false;
                        }
                    }
                    //KPI checks

              /*  if(obj[key].KPIType === "Manual" 
                &&  obj[key].KPIManualValue === ""){ 
                    obj[key].valid='invalid';
                    tmpCriteriaValidation =false;
                    tmpSectionValidation =false;
            }*/

                });
                if (isAnyQuestionAns) {
                    _.each(value.Questions, function(value, key,obj) {
                        if(!obj[key].Status) {
                            obj[key].valid='invalid';
                            tmpCriteriaValidation =false;
                            tmpSectionValidation =false;
                        }
                    });
                }
                if(!tmpCriteriaValidation) {
                    obj[key].valid='invalid';
                }
            });
            if(!tmpSectionValidation) {
                obj[key].valid='invalid';
            }
        });
    return ({
        ...state,
        review,
        questionResponse,
        validate
    })
};

const getQuestionResponse = (ques) => {
    let quesKeys = Object.keys(ques);
    let resp = '', col = '';   
    quesKeys.forEach((quesKey) => {
        if (ques[quesKey + '_Value']) {
            if ((ques[quesKey + '_Value'].toLowerCase() === 'y' || ques[quesKey + '_Value'].toLowerCase() === 'yes')) {
                if (resp) {
                    resp = 'invalid';
                    col = quesKey + '_Value';
                } else {
                    resp = ques[quesKey];
                }
            } else if ((ques[quesKey + '_Value'].toLowerCase() !== 'n' && ques[quesKey + '_Value'].toLowerCase() !== 'no')) {
                resp = 'invalid';
                col = quesKey + '_Value';
            }
        }
        
    })
    return {resp, col};
}


const applyCSVData = (state, action) => {
    let review; const questionResponse={}; const validate={}; let tmpSectionValidation =true; let tmpCriteriaValidation=true; let tmpQuesValidation=true;
        let questionResponses = {};
        try {
            action.CSVData.forEach((csvObj, key) => {
                let resp = getQuestionResponse(csvObj);
                if (resp.resp === 'invalid') {
                    let error = `The response in row '${key+2}' and column '${resp.col}' is invalid.\nIt must be 'Y' or 'Yes' or 'N' or 'No' or blank, and there should only be 1 'Y' or 1 'Yes' for each question.`
                    throw error;
                } else if (csvObj.KPI_Type === 'Manual' && csvObj.KPI_Value_1 && !validateKPI(csvObj.KPI_Value_1)) {
                    let error = `The response in row '${key+2}' and column 'KPI_Value_1' is invalid.\n`
                    throw error;
                } else {
                    questionResponses[csvObj.Question_ID] = {
                        'Provided_Question_Id': csvObj.Question_ID,
                        'Status': resp.resp,
                        'KPI_Type': csvObj.KPI_Type,
                        'KPI_Value': csvObj.KPI_Value_1
                    };
                }
                
              });
            review = state.review;
            review.valid ='valid';
            //const reviewStatus = review.Status;
            review.LastModifiedBy = getCurrentUserDetails();
            _.each(review.Sections, function(value, key,obj) {
                tmpSectionValidation =true;
                obj[key].valid ='valid';
                _.each(value.Criterias, function(value, key,obj) {
                    tmpCriteriaValidation =true;
                    obj[key].valid='valid';
                    let isAnyQuestionAns = false;
                    _.each(value.Questions, function(value, key,obj) {
                        tmpQuesValidation =true;
                        obj[key].valid='valid';
                        if (questionResponses[obj[key]['Provided_Question_Id']]) {
                            obj[key].Status = questionResponses[obj[key]['Provided_Question_Id']].Status;
                            
                            if (questionResponses[obj[key]['Provided_Question_Id']].KPI_Type === 'Manual') {
                                obj[key].KPIManualValue = questionResponses[obj[key]['Provided_Question_Id']].KPI_Value || '';
                            }
                        }
                         //for KPI question
                        questionResponse[obj[key].Question_Id] =obj[key].Status;
                        if(obj[key].Status && obj[key].Status !=='') {
                            const isFail  = obj[key].Responses.filter(el=>el.Response === obj[key].Status);
                            if(isFail[0].Response_Pass_Fail === "Fail" && obj[key].Actions.length ===0) {
                                obj[key].valid='invalid';
                                tmpCriteriaValidation =false;
                                tmpSectionValidation =false;
                            }
                            isAnyQuestionAns = true;
                        } else if (obj[key].Mandatory_Question) {
                            obj[key].valid='invalid';
                            tmpCriteriaValidation =false;
                            tmpSectionValidation =false;
                        }
                        
                        const methodList =  value.Method_Text.split("--");
                        obj[key].Method_List =[];
                        obj[key].Question_List =[];
                        if(methodList.length > 1) {
                            obj[key].Method_Text = methodList[0];
                            for (let i=1;i <methodList.length;i++) {
                                obj[key].Method_List.push(methodList[i]);
                            }
                        }
                        if(value.Question_Text) {
                            const qList =  value.Question_Text.split("--");
                            if(qList.length > 1) {
                                obj[key].Question_Text = qList[0];
                                for (let i=1;i <qList.length;i++) {
                                    obj[key].Question_List.push(qList[i]);
                                }
                            }
                        }
                        
                    });
                    if (isAnyQuestionAns) {
                        _.each(value.Questions, function(value, key,obj) {
                            if(!obj[key].Status) {
                                obj[key].valid='invalid';
                                tmpCriteriaValidation =false;
                                tmpSectionValidation =false;
                            }
                        });
                    }
                    if(!tmpCriteriaValidation) {
                        obj[key].valid='invalid';
                    }
                });
                if(!tmpSectionValidation) {
                    obj[key].valid='invalid';
                }
            });
        } catch (error) {
            return ({
                ...state,
                error
            })
        }
        
    return ({
        ...state,
        review,
        questionResponse,
        validate,
        error: ''
    })
}

const applyAddActionDetails = (state, action) => {
    const {qId,actionDetails} = action;
    const review = state.review; const tmpAppValidation=true; let tmpSectionValidation =true; let tmpCriteriaValidation=true; let tmpQuesValidation=true;
    const questionResponse = state.questionResponse;
    _.each(review.Sections, function(value, key,obj) {
        tmpSectionValidation =true;
        obj[key].valid ='valid';
        _.each(value.Criterias, function(value, key,obj) {
            tmpCriteriaValidation =true;
            obj[key].valid ='valid';
            let isQuestionAnswered = false;
            _.each(value.Questions, function(value, key,obj) {
                tmpQuesValidation =true;
                obj[key].valid ='valid';
                if(obj[key].Question_Id === qId) {
                    obj[key].Actions = actionDetails;
                }
                if(obj[key].Status && obj[key].Status !=='') {
                    const isFail  = obj[key].Responses.filter(el=>el.Response === obj[key].Status);
                    questionResponse[obj[key].Question_Id] =obj[key].Status;
                    if(isFail[0].Response_Pass_Fail === "Fail" && obj[key].Actions.length ===0) {
                        obj[key].valid='invalid';
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                    }
                    isQuestionAnswered = true;
                } else if (obj[key].Mandatory_Question) {
                    obj[key].valid='invalid';
                    tmpCriteriaValidation =false;
                    tmpSectionValidation =false;
                }
                if (review.Status === 'Pending 1st' || review.status === 'Pending 2nd') {
                    if(obj[key].IsQuestionFlag && (!obj[key].QuestionComment || !obj[key].QuestionComment.length)) {
                        obj[key].flagValid = 'invalid';
                        tmpSectionValidation = false;
                    }
                }
                //KPI checks

               /* if(obj[key].KPIType === "Manual"
                    &&  obj[key].KPIManualValue === ""){ 
                        obj[key].valid='invalid';
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                }*/
            });
            if (isQuestionAnswered) {
                _.each(value.Questions, function(value, key,obj) {
                    if(!obj[key].Status) {
                        obj[key].valid='invalid';
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                    }
                });
            }
            if(!tmpCriteriaValidation) {
                obj[key].valid='invalid';
            }
        });
        if(!tmpSectionValidation) {
            obj[key].valid='invalid';
        }
    });
    return ({
        ...state,
        review,
        questionResponse

    })

}
const applyUpdateReview = (state, action) => {
    const {qId,qResponse, flagStatus} = action;
    const review = state.review; const tmpAppValidation=true; let tmpSectionValidation =true; let tmpCriteriaValidation=true;
    const questionResponse = state.questionResponse;
    questionResponse[qId] =qResponse;
    _.each(review.Sections, function(section, key,obj) {
        tmpSectionValidation =true;
        obj[key].valid ='valid';
        _.each(section.Criterias, function(criteria, key,obj) {
            tmpCriteriaValidation =true;
            obj[key].valid ='valid';
            let isQuestionAnswered = false;
            _.each(criteria.Questions, function(value, key,obj) {
                obj[key].valid ='valid';
                obj[key].flagValid = 'valid';
                if(obj[key].Question_Id === qId) {
                    obj[key].Status = qResponse;
                }
                if(obj[key].Status && obj[key].Status !=='') {
                    const isFail  = obj[key].Responses.filter(el=>el.Response === obj[key].Status);
                    questionResponse[obj[key].Question_Id] =obj[key].Status;
                    if(isFail[0].Response_Pass_Fail === "Fail" && obj[key].Actions.length ===0) {
                        obj[key].valid='invalid';
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                    }
                    isQuestionAnswered = true;
                } else if (obj[key].Mandatory_Question) {
                    obj[key].valid='invalid';
                    tmpCriteriaValidation =false;
                    tmpSectionValidation =false;
                }
                if (review.Status === 'Pending 1st' || review.Status === 'Pending 2nd') {
                    if(obj[key].Question_Id === qId) {
                        obj[key].IsQuestionFlag = flagStatus;
                    }
                    if(obj[key].IsQuestionFlag && (!obj[key].QuestionComment || !obj[key].QuestionComment.length)) {
                        obj[key].flagValid = 'invalid';
                        tmpSectionValidation = false;
                    }
                }
               
            });
            if (isQuestionAnswered) {
                _.each(criteria.Questions, function(value, key,obj) {
                    if(!obj[key].Status) {
                        obj[key].valid='invalid';
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                    }
                });
            }
            if(!tmpCriteriaValidation) {
                obj[key].valid='invalid';
            }
        });
        if(!tmpSectionValidation) {
            obj[key].valid='invalid';
        }
    });
    return ({
        ...state,
        review,
        questionResponse

    })
};
const applyUpdateComment = (state, action) => {
    const {qId, commentText} = action;
    const review = state.review;
    _.each(review.Sections, function(section, key,obj) {
        _.each(section.Criterias, function(criteria, key,obj) {
            let question = _.find(criteria.Questions, (ques) => {
                return ques.Question_Id === qId;
            });
            if (question) {
                question.QuestionComment = commentText;
                if (question.IsQuestionFlag) {
                    return applyUpdateReview({...state, review}, {
                        qId: question.Question_Id, qResponse: question.Status, flagStatus: true});
                }
                return ({
                    ...state,
                    review
                });
            }
        });
    });
    return ({
        ...state,
        review
    });
}

const applyKPIDetails = (state, action) => {
    const {questionId,kpiValue} = action;
    const review = state.review; 
    const questionResponse = state.questionResponse;

    let tmpSectionValidation =true; let tmpCriteriaValidation=true;
    let isAnyQuestionInvalid = false;
    _.each(review.Sections, function(value, key,obj) {
        //tmpSectionValidation = obj[key].valid==='valid'?true:false;
        _.each(value.Criterias, function(value, key,obj) {
           // tmpCriteriaValidation = obj[key].valid='valid'?true:false;
            _.each(value.Questions, function(value, key,obj) {
                if(obj[key].Question_Id === questionId) {
                    obj[key].KPIManualValue = kpiValue;
                   /* if(obj[key].KPIManualValue===""){
                        obj[key].valid = "invalid"
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                    }else{
                        if(obj[key].Status ==="" || obj[key].Status ==null ){
						obj[key].valid = "invalid"
                        tmpCriteriaValidation =false;
                        tmpSectionValidation =false;
                        } else {
                           obj[key].valid='valid';
                            if(obj[key].Status === 'Not At Standard' && obj[key].Actions.length ===0) {
                                obj[key].valid='invalid';
                                tmpCriteriaValidation =false;
                                tmpSectionValidation =false;
                            }

                        }
                    }
                */} 
            });
           /*
            _.each(value.Questions, function(value, key,obj) {
                if( obj[key].valid == "invalid"){
                    isAnyQuestionInvalid=true;
					   }
            });
            
            if(!isAnyQuestionInvalid) {
                tmpCriteriaValidation = true;
                tmpSectionValidation = true;
               
            }else{
                tmpCriteriaValidation = false;
                tmpSectionValidation = false;
				 
			 }
            if(!tmpCriteriaValidation) {
                obj[key].valid='invalid';
            }else{
                obj[key].valid='valid';
            }*/
        });
      /*  if(!tmpSectionValidation) {
            obj[key].valid='invalid';
        }else{
            obj[key].valid='valid';
        }*/
        
    });
    return ({
        ...state,
        review,
        questionResponse

    })

}


export function reviewReducer(state = INITIAL_STATE, action) {
    
    switch(action.type) {
        
        case ADD_SINGLE_REVIEW : {
            
            return applyAddReview(state, action);
        }
        case UPDATE_SINGLE_REVIEW : {
            
            return applyUpdateReview(state, action);
        }
        case UPDATE_COMMENT : {
            return applyUpdateComment(state, action)
        }
        case CLEAR_REVIEW_DATA : {
            return INITIAL_STATE;
        } 
        case ADD_ACTION_DETAILS : {
            //if(reviewType=="TFL1-8") return applyAddActionDetailsTfl18(state, action);
            return applyAddActionDetails(state, action);
        }
        case Do_COMPLETE_REVIEW : {
           
            return  {...state, completedReviewStatus: action.payload}
        }
        case DO_SHARE_REVIEW : {
            return {...state, shareReviewStatus: action.Status}
        }

        case DO_SHARE_REVIEW_STATUS: {
            return {...state, shareReviewStatus: action.status}
        }
        case ADD_QUESTION_DOCUMENT : {
            
            return {...state,isDocAdded:false};
        }        
        case ADD_QUESTION_SOP : {

            return {...state,isDocAdded:false,isLoading:action.data};
        }
        case DO_SUCCESS : {

            return {...state,isDocAdded:true,isLoading:false};
        }
        case DELETE_DOCUMENT : {

            return {...state,isDocAdded:false};
        }
        case ADD_RECOMMENDATION : {
            return {...state,isDocAdded:false,isLoading:action.payload.Recommendation};
        }
        case UPDATE_RECOMMENDATION : {
            return {...state,isDocAdded:false,isLoading:action.payload.Recommendation};
        }
        case FETCH_TFL18_ASSESSMENT_DATA : {
            return {...state,tfl18Data:action.payload};
        }
        case FETCH_TFL18_ASSESSMENT_ERROR : {
            return {...state,tfl18Error:action.error};
        }

        case FETCH_DEALER_INFO_BY_ID : {
            return {...state};
        }
        case UN_AUTHORIZED_REVIEW : {
            return {...state, reviewResponseStatus:action.payload}
        }
        case FETCH_DEALER_INFO_BY_ID_SUCCESS : {
            const dealerInfo = action.payload.data.data;
            return {...state,dealerInfo};
        }
        case FETCH_DEALER_INFO_BY_ID_ERROR : {
            const error = action.payload
            return {...state,error};
        }
        case CLEAR_DOC_DATA : {
            return {...state,isDocAdded:false};
        }
        case POPULATE_KPI_DATA : {
            return applyKPIDetails(state, action);
        }
        case CLEAR_REVIEW_DATA: {
          state = INITIAL_STATE;
          return state;
        }
        case ADD_GROUP_ID: {
            const review = action.review.returnData[0];
            review.groupId = action.review.groupid;
            return {...state, review};
        }
        case REVIEW_FETCH_ERROR : {
            const error = action.payload
            return {...state,error};
        }

        case UPDATE_CSV_REVIEW : {
            return applyCSVData(state, action);
        }
        case DO_CLEAR_CSV_ERR : {
            const error = null;
            return ({...state, error});
        }
        case SCORE_DATA: {            
            return { ...state };
        }
        case SCORE_DATA_SUCCESS: {
            const tflscoreInfo = action.payload.data.data;
            const tflbreakDownScoreListData = action.payload.data.data.SectionScore;
            const tflscoresuccessMessage = action.payload.data.Message;
            return { ...state, tflscoreInfo, tflscoresuccessMessage,tflbreakDownScoreListData };
        }
        case SCORE_DATA_ERROR: {
            const tflscoreInfoerror = action.payload;
            return { ...state, tflscoreInfoerror,tflbreakDownScoreListData: [] };
        }
        case UPDATESCORE_DATA: {            
            return { ...state };
        }
        case UPDATESCORE_DATA_SUCCESS: {
            const sectionscoreInfo = action.payload.data.data;
            return { ...state, sectionscoreInfo };
        }
        case UPDATESCORE_DATA_ERROR: {
            const sectionscoreInfoerror = action.payload;
            return { ...state, sectionscoreInfoerror };
        }
        case TFLSCORELISTING_DATA: {            
            return { ...state };
        }
        case TFLSCORELISTING_SUCCESS: {
            const tflscorelistingInfo = action.payload.data.data;
            const tflscoreListData = action.payload.data.data;
            return { ...state, tflscorelistingInfo, tflscoreListData};
        }
        case TFLSCORELISTING_ERROR: {
            const tflscorelistingerror = action.error;
            const tflscoreListData = [];
            return { ...state, tflscorelistingerror,tflscoreListData};
        }

        //case SCORE_DATA: {
        //    return { ...state };
        //}
        //case SCORE_DATA_SUCCESS: {
        //    const scoreInfo = action.payload.data;
        //    return { ...state, scoreInfo };
        //}
        //case SCORE_DATA_ERROR: {
        //    const error = action.payload;
        //    return { ...state, error };
        //}​​​​
        default : return state;
    }
}

export default reviewReducer;
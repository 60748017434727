import { SET_USER_TYPE } from "../constants/actionTypes";

const setUserType = userType => ({
  type: SET_USER_TYPE,
  userType,
});

export {
  setUserType
};

import React, { Component } from 'react';
import { withAuth } from '@okta/okta-react';

export default withAuth(class Home extends Component {
    constructor(props) {
        super(props);
        this.state = { authenticated: null };
        this.checkAuthentication = this.checkAuthentication.bind(this);
        this.checkAuthentication();
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.data=null;
    }

    async checkAuthentication() {
        const authenticated = await this.props.auth.isAuthenticated();
        if (authenticated !== this.state.authenticated) {
            this.setState({ authenticated });
        }
    }

    async componentDidMount() {
        try {
            const BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
            const response = await fetch(`${BASE_URL}/api/LookupAssessmentStatus`, {
                headers: {
                    Authorization: 'Bearer ' + await this.props.auth.getAccessToken()
                }
            });
            this.data = await response.json();
        } catch (err) {
            // handle error as needed
        }
    }

    componentDidUpdate() {
        this.checkAuthentication();
    }

    async login() {
        // Redirect to '/' after login
        this.props.auth.login('/');
    }

    async logout() {
        // Redirect to '/' after logout
        this.props.auth.logout('/');
    }

    render() {
        if (this.state.authenticated === null) return null;
        return this.state.authenticated ?
            <div className="main-content row"><div className="popup__button-wrapper">
                <button className="btn btn-primary" type="button" onClick={this.logout}>Logout</button>
            </div>{this.data}</div> :
            <div className="main-content row"><button className="btn btn-primary" type="button" onClick={this.login}>Login</button></div>;
    }
});
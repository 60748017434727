import React from "react";
import { connect } from 'react-redux'
import {hideToast} from '../../actions/toast';

class ToastMsg extends React.Component {
    constructor(props){
        super(props);
        this.timer = ''

    }


    componentDidUpdate(){
        this.timer = setTimeout(()=>{
            this.props.hideToast({show:false,message:''});
            clearInterval(this.timer);
        },5000)
    }

    componentWillUnmount(){
        clearInterval(this.timer);

    }

    close (e) {
        this.props.hideToast({show:false,message:''});
        clearInterval(this.timer);

        e.preventDefault();
    };

    render() {
        if(this.props.toast.show) {
            return (
                <div className="row">
                    <div ref={this.successRef}
                         className={`alert ${this.props.toast.showSuccess ? `alert-success`: `alert-danger`} alert-dismissible  alert-message-assessment`} role="alert" >
                        <img className="alert__icon m-r-15" src={`${this.props.toast.showSuccess ? "../images/success.svg" : "../images/alert.svg"}`} alt="Success"/>
                        {
                         this.props.toast.message
                        }
                        <a className="alert__close-icon" href="#"><img src="../images/close.svg" alt="Close"  className="toastC" onClick={(e)=>this.close(e)}/></a>
                    </div>
                </div>
            )
        }
        return null


    }
}
const mapStateToProps = (state) => ({
    toast: state.toastState.toast
});
const mapDispatchToProps = dispatch => ({
    hideToast:(data) => dispatch(hideToast(data)),

});


const connected = connect(
    mapStateToProps,
    mapDispatchToProps
)(ToastMsg);
export default connected;

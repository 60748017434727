import {
  call,
  put,fork,join,
  select
} from 'redux-saga/effects';
import * as mockassignTo from "../constants/assignto_list";
import {
  doFetchAssignToList,
  doAddAssignToList,
  doFetchErrorAssignToList
} from '../actions/assignToList';
import {
  doFetchActionDataByIdSuc,
  doCreateAction,
  doUpdateAction,
  doCreateUpdateActionError,
  doFetchErrorActionDataById,
  doCreateActionWithData
} from "../actions/actionsCreateUpdateAction";
import {
  fetchActionDataById,
  postAction,
  postUpdateAction
} from '../api/api_action';
import {
  getActionIdQuery,
  createActionSelector,
  getUpdateActionIdQuery
} from "../selectors/createAction";
import {
  doHideLoader,
  doShowLoader,
  doShowModalLoader,
  doHideModalLoader
} from "../actions/loader";
import {doSuccess} from '../actions/review'
import {
  fetchAssigneeLookup,
  postReview
} from "../api/story";
import {
  submitScore
} from '../api/story';


import Moment from 'moment';
import {doPostAuditLogData} from "../actions/auditLog";

function* handleFetchAssignToList(action) {
 yield put(doShowModalLoader());

  try {
    const assigneeList = yield call(fetchAssigneeLookup);
    yield put(doAddAssignToList(assigneeList));
    yield put(doHideModalLoader());
  } catch (error) {
    yield put(doFetchErrorAssignToList(error));
    yield put(doHideModalLoader());
  }
}


/* Generator to call CreateAction API */
function* handleCreateAction(action) {

  yield put(doShowModalLoader());
  
   const createActionData = createActionSelector(action);
   let actionResponse = "";
 
   try {
    //capture assesment
     const reviewState = yield select((state)=> state.reviewState);
         yield call(postReview, reviewState);
     actionResponse = yield call(postAction, createActionData);
     console.log('actionResponse' , actionResponse);
    // if (actionResponse.data.Success) {     
       yield put(doCreateActionWithData(actionResponse));
       let CreatedOn = Moment(createActionData.creation_date).format('HH:MM DD MMM YYYY');      
       let due_Date = Moment(`${createActionData.Due_Date}`).format('HH:MM DD MMM YYYY');
       let documentList =   createActionData.Documents.map((obj) => {
             return obj.Document_Name
         }).join(',');
       let msg = `${createActionData.CreatedBy} added  Action ${actionResponse.data.Id} to ${createActionData.Assessment_ID} with Title ${createActionData.Title} and Description ${createActionData.Description} and ActionPlan ${createActionData.ActionPlan}
       with Due date ${due_Date} and Priority ${createActionData.Priority} Assigned to ${createActionData.Assigned_To} with Comments ${createActionData.Comments} and Evidence ${documentList} on ${CreatedOn}.`
       const payload = {
         LogMessage: msg,
         AuditLogModule: "Create Action",
         AcionAPISource: "AddQuestion",
         CreatedBy: createActionData.CreatedBy,
         CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
       }
       yield put(doPostAuditLogData(payload));     
       //assesmentById
      //  yield put(doSuccess(actionResponse))
   //  }
   } catch (error) {
     yield put(doCreateUpdateActionError(error));
     yield put(doHideModalLoader());
   }
   yield put(doHideModalLoader());
 }
 
 /* Generator to call Update Action API */
 function* handleUpdateAction(action) {
  yield put(doShowModalLoader());
   const createActionData = createActionSelector(action);
   let actionResponse = "";
   const actionIdQuery = getUpdateActionIdQuery(action);
   const scoreRefreshPayload = {"review":
     {
     "Dealer_ID": action.formData.scoreUpdatePayload.Dealer_ID,
     "Program_ID": action.formData.scoreUpdatePayload.Program_ID,
     "Review_ID": action.formData.scoreUpdatePayload.Review_ID,
     "Review_Type": action.formData.scoreUpdatePayload.Review_Type
     }
   }
   try {
    var currentLocation = ""+window.location;
    let CheckActionTab=currentLocation.search("actions")
    //To check if current URL is Action/Review
    if(CheckActionTab===-1){ 
      // capture assesment
      const reviewState = yield select((state)=> state.reviewState);
      yield call(postReview, reviewState);
    }
     actionResponse = yield call(postUpdateAction, createActionData,actionIdQuery);     
     if (actionResponse && actionResponse.data.Success) {     
       if((action.formData.previousStatus !== "Complete") && (action.formData.checkCompletestatus =="true"))
          {
            yield call(submitScore, scoreRefreshPayload);
          }     
      }
     yield put(doCreateActionWithData(actionResponse));
     //assesmentById
    //  yield put(doSuccess(actionResponse))
   } catch (error) {
     yield put(doCreateUpdateActionError(error));
   }
   yield put(doHideModalLoader());
 }


function* handleFetchActionDataById(action) { 
  const actionIdQuery = getActionIdQuery(action);
  try {
  //  yield put(doShowModalLoader());
    const result = yield call(fetchActionDataById, actionIdQuery);
    yield put(doFetchActionDataByIdSuc(result.data.data));
    // yield put(doHideModalLoader());
    return result;
  } catch (error) {
    yield put(doFetchErrorActionDataById(error));
  // yield put(doHideModalLoader());
  }
 // yield put(doHideModalLoader());
}

/* function *handleFetchActionDataByIdBlocking(action) {
  
  const firstTask = yield fork(handleFetchActionDataById, action)
  const payload = yield join(firstTask)
  if(payload && payload.data && payload.data.data)
    yield put(doFetchActionDataByIdSuc(payload.data.data));
  yield put(doHideModalLoader());
}
*/
export {
  handleFetchAssignToList,
  handleFetchActionDataById,
  handleCreateAction,
  
  handleUpdateAction
};
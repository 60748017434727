
import React from "react";
import SkeletonInProgress from "./SkeletonInProgress"
import SkeletonDeleted from "./SkeletonDeleted"
import SkeletonCompleted from "./SkeletonCompleted"
export default function SkeletonsReview(props) {
  return (

  <>
      {props.reviewStatus === 'In-progress' || props.reviewStatus === "none" ||props.reviewStatus === "Pending 1st" || props.reviewStatus === "Pending 2nd" ||  props.reviewStatus === "Reopen" ? ( <SkeletonInProgress reviewStatus={props.reviewStatus} programName={props.programName} />)
      :props.reviewStatus==='Deleted'?( <SkeletonDeleted reviewStatus={props.reviewStatus} programName={props.programName} />)
      :props.reviewStatus==='Completed'?( <SkeletonCompleted reviewStatus={props.reviewStatus} programName={props.programName}/>):""
      }
    </>

  )
}
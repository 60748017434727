import React, {Component,setState} from 'react';
import { connect } from 'react-redux';
import uniqueId from 'react-html-id';
import {
    getReadableStories,
    getFetchError, getPaginationDetails,
} from '../../selectors/story';
import Skeleton from '../Common/Skeletons/Schedule/SkeletonsSchedule'
import Story from './Story';
import StoriesHeader from './StoriesHeader';
import SchedulePagination from "./SchedulePagination";
import {changeStoriesPage, doSortStories} from "../../actions/story";
import {toggleSidebar} from "../../actions/header";
import {getLoaderStatus} from "../../selectors/loader";
import SkeletonsSchedule from '../Common/Skeletons/Schedule/SkeletonsSchedule';

const COLUMNS = {
  title: {
    label: 'Title',
    width: '40%',
  },
  author: {
    label: 'Author',
    width: '30%',
  },
  comments: {
    label: 'Comments',
    width: '10%',
  },
  points: {
    label: 'Points',
    width: '10%',
  },
  archive: {
    width: '10%',
  },
};

export class Stories extends Component {
   /* componentDidUpdate() {
        this.focusDiv();
    } */
    constructor(props) {
        super(props);
        this.headerRef = React.createRef();
        this.focusDiv = this.focusDiv.bind(this);
        this.onItemMenuOpen = this.onItemMenuOpen.bind(this);
        this.state = {
          stories:[],
          
        }
        uniqueId.enableUniqueIds(this);
    }
  
   
    onItemMenuOpen = (storyId) => {
      this.setState({itemMenuStory: storyId});
    }

    focusDiv() {
        window.scrollTo(0, 0);
    }
    static getDerivedStateFromProps(nextProps, prevState){ 
        return {
           stories: nextProps.stories
        };
    }

    render() {
        return (
          <div className="main-content">
            <StoriesHeader ref={this.headerRef} />
            <div id={this.getUniqueId("Schedule_List")} className="grid">
              {this.props.loader === "show" && (<SkeletonsSchedule />)}

              {
                (
                  (this.state.stories || []).map((story, index) => (

                    <Story
                      key={story.Assessment_ID}
                      story={story} rowId={index}
                      columns={COLUMNS} sendEmail={this.props.sendEmail} itemMenuStory={this.state.itemMenuStory} onItemMenuOpen={this.onItemMenuOpen}
                    />
                  )))}
            

            </div>
            {this.state.stories.length === 0 && this.props.loader !=='show' && (
              <div id={this.getUniqueId("Schedule_No_Result_Found")} className="grid">
                <div className="grid__row">
                  <div className="grid__columns-wrapper">
                            No Records Found.
                  </div>
                </div>
              </div>
)}

            {this.props.paginationData.totalCount > 20 && (
              <div className="legend-wrapper d-none d-sm-none d-md-block">
                <div className="row grid__columns-wrapper">
                  <div className="col-sm-12">
                    <p className="records-count">
                      {(this.props.paginationData.activePage * 20 + 1) - 20}
-
                      {(this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) >= 20 ? this.props.paginationData.activePage * 20 : this.props.paginationData.totalCount}
                      {' '}
of 
                      {' '}
                      {this.props.paginationData.totalCount}
                      {' '}
records
                    </p>
                  </div>
                  {/* <div className="col-sm-8">
                    <ul className="legend list-unstyled">
                      <li className="legend__dot legend__red">Not Started</li>
                      <li className="legend__dot legend__yellow">In-Progress</li>
                      <li className="legend__dot legend__green">Completed</li>
                    </ul>
                  </div> */}
                </div>
                <SchedulePagination onSelect={() => this.focusDiv()} />
              </div>
)}
            {this.props.paginationData.totalCount > 20 && ((this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) > 20) && (
              <div className="text-center d-block d-sm-block d-md-none">
                <button id={this.getUniqueId("Schedule_View_More")} onClick={() => this.props.onPageChange((this.props.paginationData.activePage+1))} className="btn btn-gray">
View
                        more
                </button>
              </div>
)}

          </div>

        );
    }
}

const mapStateToProps = state => ({
  stories: getReadableStories(state),
    paginationData: getPaginationDetails(state),
    loader: getLoaderStatus(state),
  error: getFetchError(state),
});

const mapDispatchToProps = dispatch => ({
    onPageChange : id => {
        dispatch(changeStoriesPage(id,'schedule','mobile'));
    }
});

export default connect(
  mapStateToProps,
    mapDispatchToProps
)(Stories);
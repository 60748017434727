import { takeLatest, all } from 'redux-saga/effects';
import {
    STORIES_FETCH,
    STORIES_SORT,
    STORIES_FILTER,
    STORIES_PAGE_CHANGE,
    SCHEDULE_DROPDOWNS_FETCH,
    UPLOAD_SOP_DOCUMENT,
    FETCH_DEALERSOP_DOCS_LIST,
    FETCH_LOCATIONS_LIST,
    FETCH_PROGRAMS_LIST,
    FETCH_SOPNAMES_LIST,
    FETCH_ASSIGNTO_LIST,
    CREATE_ACTION,
    FETCH_ACTIONDATA,
    FETCH_SINGLE_REVIEW,
    SAVE_SINGLE_REVIEW,
    UPDATE_ACTION,
    ADD_QUESTION_DOCUMENT,
    DELETE_DOCUMENT,
    Do_COMPLETE_REVIEW,
    DO_SHARE_REVIEW,
    ADD_RECOMMENDATION,
    UPDATE_RECOMMENDATION,
    FETCH_TFL18_ASSESSMENT,
    FETCH_DEALER_INFO_BY_ID,
    ADD_QUESTION_SOP,
    FETCH_START_REVIEW,
    FETCH_BRAND_NAME,
    SCORE_STORIES_FETCH,
    FETCH_BREAKDOWN_SCORE_LIST,
    FETCH_SCORE_LIST,
    FETCH_TFL_SCORE_LIST,
    APPROVE_STATUS,
    RESET_STATUS,
    FETCH_SCOREOVERRIDE_DATA,
    FETCH_USER_ACCESS_ROLES,
    DELETE_OVERRIDE_FILES,
    FETCH_OKTA_USERS,
    SAVE_OVERRIDE_DATA,
    FETCH_INVESTIGATION_RECORDS,
    FETCH_INVESTIGATION_CREATE_DATA,
    POST_NEW_INVESTIGATION_DATA,
    FETCH_LOOKUP_STAGE_DATA,
    FETCH_LOOKUP_STATUS_DATA,
    DELETE_INVESTIGATION_FILES,
    POST_UPDATE_INVESTIGATION_DATA,
    POST_AUDIT_LOG_DATA,
    SCORE_DATA,
    TFLSCORELISTING_DATA,
    UPDATESCORE_DATA
} from '../constants/actionTypes';

import { handleFetchStories, handleFetchScheduleDropdowns, handleFetchReviewById, handleSaveReviewById, handleCompletedReview, handleShareReview, fetchOktaUsers } from './story';
import {
    handlefetchScoringList,
    hanldefetchPMAScoreBreakdown,
    handleResetStatus,
    handleApproveStatus,
    handleFetchScoreOverrideData,
    handleDeleteScoreOverrideFiles,
    handleSaveScoreOverrideData,
    handlefetchTFLScoringList
} from './TflScores';
import {
    handleFetchInvestigationRecords,
    handleCreateInvestigationData,
    handleStartNewInvestigation,
    handleLookUpStageData,
    handleLookUpStatusData,
    handleDeleteInvestigationFile,
    handleUpdateInvestigation
} from './Investigations';
import {
    handleFetchLocationsList,
    handleFetchProgramsList,
    handleFetchSOPNamesList,
    handleFetchDealerSOPDocsList,
    handleUploadSOPDocument
} from "./uploadSOP";
import {
    handleFetchAssignToList,
    handleCreateAction,
    handleFetchActionDataById,
    handleFetchActionDataByIdBlocking,
    handleUpdateAction
} from "./createAction";

import {
    handlePostQuestionsDocument,
    handleDeleteQuestionsDocument,
    handlePostQuestionsRecommendation,
    handleUpdateQuestionsRecommendation,
    handleFetchTFL18Assessment,
    handleFetchDealerInfoById,
    handlePostQuestionsSop,
    handleFetchStartReview,
    handleGetScoreData,
    handleGetUpdatecoreData,
    TFLScoreListingData,
} from './reviewSaga'

import { handleFetchBrandName } from './header';
import { handleFetchUserAccessRoles } from './userRolesAccess';
import { handleAuditLogData } from './auditLog';


function* watchAll() {
    yield all([
        takeLatest(POST_UPDATE_INVESTIGATION_DATA, handleUpdateInvestigation),
        takeLatest(DELETE_INVESTIGATION_FILES, handleDeleteInvestigationFile),
        takeLatest(FETCH_LOOKUP_STAGE_DATA, handleLookUpStageData),
        takeLatest(FETCH_LOOKUP_STATUS_DATA, handleLookUpStatusData),
        takeLatest(POST_NEW_INVESTIGATION_DATA, handleStartNewInvestigation),
        takeLatest(FETCH_INVESTIGATION_CREATE_DATA, handleCreateInvestigationData),
        takeLatest(FETCH_INVESTIGATION_RECORDS, handleFetchInvestigationRecords),
        takeLatest(SAVE_OVERRIDE_DATA, handleSaveScoreOverrideData),
        takeLatest(DELETE_OVERRIDE_FILES, handleDeleteScoreOverrideFiles),
        takeLatest(FETCH_SCOREOVERRIDE_DATA, handleFetchScoreOverrideData),
        takeLatest(FETCH_SCORE_LIST, handlefetchScoringList),
        takeLatest(FETCH_TFL_SCORE_LIST, handlefetchTFLScoringList),
        takeLatest(FETCH_BREAKDOWN_SCORE_LIST, hanldefetchPMAScoreBreakdown),
        //takeLatest(SCORE_STORIES_FETCH, handleFetchScoreStories),
        takeLatest(STORIES_FETCH, handleFetchStories),
        takeLatest(STORIES_SORT, handleFetchStories),
        takeLatest(STORIES_FILTER, handleFetchStories),
        takeLatest(STORIES_PAGE_CHANGE, handleFetchStories),
        takeLatest(SCHEDULE_DROPDOWNS_FETCH, handleFetchScheduleDropdowns),
        takeLatest(FETCH_LOCATIONS_LIST, handleFetchLocationsList),
        takeLatest(FETCH_PROGRAMS_LIST, handleFetchProgramsList),
        takeLatest(FETCH_SOPNAMES_LIST, handleFetchSOPNamesList),
        takeLatest(FETCH_DEALERSOP_DOCS_LIST, handleFetchDealerSOPDocsList),
        takeLatest(UPLOAD_SOP_DOCUMENT, handleUploadSOPDocument),
        takeLatest(FETCH_ASSIGNTO_LIST, handleFetchAssignToList),
        // takeEvery(FETCH_ACTIONDATA,handleFetchActionDataById),
        takeLatest(FETCH_ACTIONDATA, handleFetchActionDataById),
        takeLatest(FETCH_SINGLE_REVIEW, handleFetchReviewById),
        takeLatest(SAVE_SINGLE_REVIEW, handleSaveReviewById),
        takeLatest(CREATE_ACTION, handleCreateAction),
        takeLatest(UPDATE_ACTION, handleUpdateAction),
        takeLatest(ADD_QUESTION_DOCUMENT, handlePostQuestionsDocument),
        takeLatest(DELETE_DOCUMENT, handleDeleteQuestionsDocument),
        takeLatest(ADD_QUESTION_SOP, handlePostQuestionsSop),
        takeLatest(Do_COMPLETE_REVIEW, handleCompletedReview),
        takeLatest(DO_SHARE_REVIEW, handleShareReview),
        takeLatest(ADD_RECOMMENDATION, handlePostQuestionsRecommendation),
        takeLatest(UPDATE_RECOMMENDATION, handleUpdateQuestionsRecommendation),
        takeLatest(FETCH_TFL18_ASSESSMENT, handleFetchTFL18Assessment),
        takeLatest(FETCH_START_REVIEW, handleFetchStartReview),
        takeLatest(FETCH_DEALER_INFO_BY_ID, handleFetchDealerInfoById),
        takeLatest(FETCH_BRAND_NAME, handleFetchBrandName),
        takeLatest(RESET_STATUS, handleResetStatus),
        takeLatest(APPROVE_STATUS, handleApproveStatus),
        takeLatest(FETCH_USER_ACCESS_ROLES, handleFetchUserAccessRoles),
        takeLatest(FETCH_OKTA_USERS, fetchOktaUsers),
        takeLatest(SCORE_DATA, handleGetScoreData),
        takeLatest(TFLSCORELISTING_DATA, TFLScoreListingData),
        takeLatest(UPDATESCORE_DATA, handleGetUpdatecoreData),

        takeLatest(POST_AUDIT_LOG_DATA, handleAuditLogData)
    ])
}

export default watchAll;

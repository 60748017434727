import { combineReducers } from 'redux';
import headerReducer from './header';
import storyReducer from './story';
import archiveReducer from './archive';
import filterReducer from './filter';
import loaderReducer from "./loader";
import dropdownsReducer from "./dropdowns";
import uploadSOPReducer from "./reduceUploadSOP";
import locationListReducer from "./reduceLocations";
import programsListReducer from "./reducePrograms";
import sopNamesListReducer from "./reduceSOPNames";
import assignToListReducer from "./reduceAssignToList";
import createUpdateActionReducer from "./reduceActions";
import reviewReducer from "./review";
import toastReducer from './toast';
import startReviewReducer from './startReview';
import { tflScoreReducer, scoreReducer } from './TflScores';
import userAccessRolesReducer from './userAccessRoles';
import userTypeReducer from './reduceUserType';
import investigationsReducer from './Investigations';
import auditLogReducer from './auditLog';

const rootReducer = combineReducers({
  headerState: headerReducer,
  storyState: storyReducer,
  archiveState: archiveReducer,
  filterState: filterReducer,
  loaderState: loaderReducer,
  dropdownState: dropdownsReducer,
  uploadSOPState: uploadSOPReducer,
  locationListState: locationListReducer,
  programsListState: programsListReducer,
  sopNamesListState: sopNamesListReducer,
  assignToListState: assignToListReducer,
  createUpdateActionState: createUpdateActionReducer,
  reviewState: reviewReducer,
  toastState: toastReducer,
  startReviewState: startReviewReducer,
  scoreState: scoreReducer,
  tflscoreState: tflScoreReducer,
  userAccessRolesState:userAccessRolesReducer,
  investigationState: investigationsReducer,
  userTypes: userTypeReducer,
  auditLogState: auditLogReducer

});

export default rootReducer;

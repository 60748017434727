import {
  ADD_ASSIGNTO_LIST,
  FETCH_ASSIGNTO_LIST_ERROR
} from '../constants/actionTypes';

const INITIAL_STATE = {
  assignToList: [],
  count: 0,
  error: null,
};

const addAssignToList = (state, action) => {
        const assignToList = action.assignToList;
        return ({
            assignToList,
            count: action.count,
            error: null,
        })
};


const fetchAssignToListError = (state, action) => ({
  assignToList: [],
  error: action.error,
  count :0
});


export function assignToListReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    // case FETCH_LOCATIONS_LIST : {
    //   return fetchLocationsList(state, action);
    // }
    case ADD_ASSIGNTO_LIST: {
      return addAssignToList (state, action);
    }
    case FETCH_ASSIGNTO_LIST_ERROR : {
      return fetchAssignToListError (state, action);
    }
    default : return state;
  }
}

export default assignToListReducer;

import {HIDE_LOADER, SHOW_LOADER , HIDE_LOADER_MODAL, SHOW_LOADER_MODAL} from '../constants/actionTypes';

const doShowLoader = (id=null,loaderAction=null) => ({
    type: SHOW_LOADER,
    id,
    loaderAction,
});

const doHideLoader = (id, loaderAction=null) => ({
    type: HIDE_LOADER,
    id,
    loaderAction
});

const doShowModalLoader = (id=null,loaderAction=null) => ({
    type: SHOW_LOADER_MODAL,
    id,
    loaderAction,
});

const doHideModalLoader = (id, loaderAction=null) => ({
    type: HIDE_LOADER_MODAL,
    id,
    loaderAction
});

export {
    doShowLoader,doHideLoader,doShowModalLoader,doHideModalLoader
};
import _ from 'lodash';
import Moment from 'moment';
import is from 'is_js';
import React from 'react';
// import Moment from 'react-moment';

const isNotArchived = archivedIds => story =>
  archivedIds.indexOf(story.objectID) === -1;

const getReadableStories = ({ storyState }) => {
    return storyState.stories;
};

const getFilterLabel = ({ headerState }) =>
    headerState.isNavOpen;

const getReview = ({ reviewState }) =>
    reviewState.review;

const getUnAuthResponse = ({ storyState }) =>
   storyState.responseStatus; 

const getUnAuthReviewResponse = ({ reviewState }) =>
   reviewState.reviewResponseStatus; 

const getFetchError = ({ storyState }) =>
  storyState.error;

const getPaginationDetails = ({ storyState, filterState }) => {
  return ({
      totalCount: storyState.count,
      activePage: filterState.activePage
  })
}



const getScheduleListParams = (filterState, action) => {    
    let query = '';    
    if (filterState.reviewType !== 'score' && filterState.reviewType !== 'TFLscore') {
        const start = (filterState.activePage * 20 + 1) - 20;
        const end = (start + 20) - 1;
        query = `start=${start}&end=${end}`
    };
    if (filterState.sortBy.sortedField) {
        const orderBy = filterState.sortBy.sortOrder;
        const sortField = filterState.sortBy.sortedField;
        query += `&orderby=${orderBy}&field=${sortField}`;
    }
    return ({
        query,
        postParams: filterState.filters
    })
}

const getReviewNotStartedStatus = ({ reviewState }) => {
    let state = false;
    if(Object.keys(reviewState.questionResponse).length === 0) {
        state =true;
    } else {
        let quesResponse = _.find(reviewState.questionResponse, function(response) {
            return response !== null;
        })
        if (!quesResponse) {
            state = true;
        }
    }
    return state;
}
function test(section) {
    return (section.valid === "invalid");
}


const getCompleteReviewButtonStatus = ({ reviewState}) => {
    const state = reviewState.review && reviewState.review.Sections && reviewState.review.Sections.find(test);
    return state;
}

const getNumberOfFlags = (reviewState) => {
    let numberOfFlags = 0;
    _.each(reviewState.review.Sections, (section) => {
        _.each(section.Criterias, (criteria) => {
            _.each(criteria.Questions, (question) => {
                if (question.IsQuestionFlag) {
                    numberOfFlags++;
                }
            });
        });
    });
    return numberOfFlags;
}

const getReviewValidation = ({ reviewState }) => {
    return reviewState;
}

const getQdetails = ({ reviewState },qId) => {
    const review = reviewState.review; let qDetails;
    _.each(review.Sections, function(value, key,obj) {
        _.each(value.Criterias, function(value, key,obj) {
            _.each(value.Questions, function(value, key,obj) {
                if(obj[key].Question_Id === qId) {
                    qDetails = obj[key];
                }
            });
        });
    });
    return qDetails;
}

const getQStatus = (qDetails) => {
    let qStatus;
    if(qDetails.Status) {
        const isFail  = qDetails.Responses.filter(el=>el.Response === qDetails.Status);
        qStatus = isFail[0].Response_Pass_Fail;
    }
            
    return qStatus;
}

const getQAction = (qDetails) => {
    
    return qDetails.Actions;
}

const getUserRole = (state) => {
    let isAccessor, isPowerUser, isRM, isDealer;
    try {
        const userRoles = state.userAccessRolesState.userAccessRoles.data.data;
        const ProgramId = state.reviewState.review.Program_ID;
        isAccessor = userRoles.find(role => (role.AccessControlType === 'ConductReview_Assessor_Sop_Add' && role.ProgramId === ProgramId));
        isPowerUser = userRoles.find(role => (role.AccessControlType === 'ConductReview_PowerUser_Sop_Add' && role.ProgramId === ProgramId));
        isRM = userRoles.find(role => (role.AccessControlType === 'ConductReview_RM_Sop_Add' && role.ProgramId === ProgramId));
        isDealer = userRoles.find(role => (role.AccessControlType === 'ConductReview_DealerUser_Sop_Add' || role.AccessControlType === 'ConductReview_DealerPrincipal_Sop_Add'));
    } catch (exc) {
        console.log('Complete data not fetched yet');
    }
    return (isPowerUser ? 'powerUser' : (isRM ? 'regionalManager' : (isAccessor ? 'accessor' : 
    isDealer? 'dealerUser': '')));
}

const createFirstRowJSON = (columns) => {
    let row = [];
    _.each(columns, (column) => {
        let value = '';
        switch (column.title) {
            case 'SAP Location Code':
                value = 'eg. 36168 for Sci-Fleet Toyota - Albion';
                break;
            case 'Program':
                value = 'eg. TFL';
                break;
            case 'Review':
                value = 'eg. TFL-9';
                break;
            case 'Review_Type':
                value = 'eg. Full/Basic';
                break;
            case 'KPI_Type':
                value = '"Auto", "Manual" or "N/A"';
                break;
            case 'KPI_Value_1':
                value = 'Input value for Manual KPI';
                break;
            case 'Mandatory_Question':
                value = '"On" OR "Off"\nOn = Mandatory\nOff = Optional';
                break;
            case 'Response_1_Value':
                value = "Specify 'Y' to choose this response";
                break;
            default:
                value = '';
        }
        if (column.title.indexOf('Response_') > -1 && column.title.indexOf('_Value') > -1) {
            value = value === '' ? "OR specify 'Y' to choose this response" : value;
        }
        row.push(value);
    })
    return row;
}
const getCurrentStateIntoJSON = (reviewState) => {
    let stateJSONArray = [];
    const review = reviewState.review;
    let maxKPIs = 0;
    let maxResponses = 2;
    _.each(review.Sections, function(section) {
        _.each(section.Criterias, function(criteria) {            
            _.each(criteria.Questions, function(question) {
                let rowJSON = {
                    'SAP Location Code': review.Sap_Code,
                    'Program': review.Program,
                    'Review': review.Assessment_Name,
                    'Review_Type': review.Review_Type,
                    'Section_ID': section.Provided_Section_Id,
                    'Section': section.Section_Name,
                    'Criteria_ID': criteria.Provided_Criteria_Id,
                    'Criteria': criteria.Criteria_Name,
                    'Question_ID': question.Provided_Question_Id,
                    'Question': question.Method_Text,
                    'KPI_Type': question.KPIType ? (question.KPIType === 'Manual' ? 'Manual' : 'Auto') : 'N/A',
                    'Mandatory_Question': question.Mandatory_Question ? 'On' : 'Off' ,
                }
                if (question.KPIType) {
                    if (question.KPIType === "Manual") {
                        rowJSON[`KPI_Label_1`] = question.Manual_KPI_Label || "KPI";
                        rowJSON[`KPI_Value_1`] = question.KPIManualValue;
                        maxKPIs = maxKPIs > 0 ? maxKPIs : 1;
                    } else {
                        if (question.Auto_KPI_1) {
                            rowJSON[`KPI_Label_1`] = question.Auto_KPI_1_Label || "KPI";
                            if (question.KPIType === "VOG") {
                                rowJSON[`KPI_Value_1`] = question.KPIVOGSaleValue;
                            } else if (question.KPIType === "MS") {
                                rowJSON[`KPI_Value_1`] = question.KPIMSValue;
                            }
                            maxKPIs = maxKPIs > 0 ? maxKPIs : 1;
                            if(question.Auto_KPI_2 && question.KPIVOGServiceValue) {
                                rowJSON[`KPI_Label_2`] = question.Auto_KPI_2_Label || "KPI";
                                rowJSON[`KPI_Value_2`] = question.KPIVOGServiceValue;
                                maxKPIs = maxKPIs > 1 ? maxKPIs : 2;
                            }
                        }
                    }
                }
                _.each(question.Responses, function(response, key) {
                    rowJSON[`Response_${key + 1}`] = response.Response;
                    if (question.Status === response.Response) {
                        rowJSON[`Response_${key + 1}_Value`] = 'Y';
                    } else {
                        rowJSON[`Response_${key + 1}_Value`] = '';
                    }
                });
                maxResponses = maxResponses > question.Responses.length ? maxResponses : question.Responses.length;
                stateJSONArray.push(rowJSON);
            });
            
        });
    });
    return buildMultiDataSet(stateJSONArray, maxKPIs, maxResponses);;
}
const getCurrentStateIntoExcelJSON = (reviewState) => {
    let stateJSONArray = [];
    const review = reviewState.review;
    // console.log("TCL: getCurrentStateIntoExcelJSON -> reviewState.review", reviewState.review)
    
    let maxKPIs = 0;
    let maxResponses = 2;
    _.each(review.Sections, function (section) {
        _.each(section.Criterias, function (criteria) {
            _.each(criteria.Questions, function (question) {
                let rowJSON = {
                    'Location Name': review.Location,                  
                    'Section': section.Section_Name,                    
                    'Criteria': criteria.Criteria_Name,
                    'Question_ID': question.Provided_Question_Id,
                    'Question': question.Method_Text, 
                    // 'Observations':question.Recommendations.length>0?question.Recommendations[0].Recommendation:"",
                    'Action_Title':question.Actions.length>0?question.Actions[0].Title:"",
                    'Observations':question.Actions.length>0 && question.Actions[0].Description!=""?question.Actions[0].Description:"",
                    'Due_Date':question.Actions.length>0?Moment(question.Actions[0].Due_Date).format('DD-MM-YYYY'):"",
                    'Action_Plan':question.Actions.length>0 && question.Actions[0].ActionPlan!=""?question.Actions[0].ActionPlan:""
                }        
                      
                _.each(question.Responses, function (response, key) {
                    rowJSON[`Response_${key + 1}`] = response.Response;
                    if (question.Status === response.Response) {
                        //rowJSON[`Response_${key + 1}_Value`] = 'Y';
                        rowJSON[`Response_Value`] = question.Status;
                   } 
                });
                maxResponses = maxResponses > question.Responses.length ? maxResponses : question.Responses.length;
                stateJSONArray.push(rowJSON);
            });

        });
    });
    return buildMultiExcelDataSet(stateJSONArray, maxResponses);;
}

const buildMultiDataSet = (JSONArray, maxKPIs, maxResponses) => {
    let columns = [
        {title: 'SAP Location Code', width: {wch: 20}},
        {title: 'Program', width: {wch: 10}},
        {title: 'Review', width: {wch: 20}},
        {title: 'Review_Type', width: {wch: 15}},
        {title: 'Section_ID', width: {wch: 20}},
        {title: 'Section', width: {wch: 20}},
        {title: 'Criteria_ID', width: {wch: 20}},
        {title: 'Criteria', width: {wch: 20}},
        {title: 'Question_ID', width: {wch: 10}},
        {title: 'Question', width: {wch: 90}},
        {title: 'KPI_Type', width: {wch: 10}},
    ];
    for (let i = 1; i <= maxKPIs; i++) {
        columns.push({title: `KPI_Label_${i}`, width: {wch: 10}});
        columns.push({title: `KPI_Value_${i}`, width: {wch: 10}});
    }
    columns.push({title: 'Mandatory_Question', width : {wch: 20}});
    for (let i = 1; i <= maxResponses; i++) {
        columns.push({title: `Response_${i}`, width: {wch: 15}});
        columns.push({title: `Response_${i}_Value`, width: {wch: 20}});
    }
    let data = [];
    data.push(createFirstRowJSON(columns));
    _.each(JSONArray, (stateJSON) => {
        let row = [];
        _.each(columns, (column) => {
            row.push(stateJSON[column.title] || '');
        })
        data.push(row);
    })
    return [{
        columns, data
    }];
}
const buildMultiExcelDataSet = (JSONArray, maxResponses) => {
    let columns = [
       { title: 'Location Name', width: { wch: 40 } },       
       {title: 'Section', width: { wch: 20 } },        
       { title: 'Criteria', width: { wch: 20 } },
       { title: 'Question_ID', width: { wch: 10 } },
       { title: 'Question', width: { wch: 90 } },    
       { title: `Response_Value`, width: { wch: 20 } },
       { title: 'Action_Title', width: { wch: 25 } },       
       { title: 'Observations', width: { wch: 25 } },       
       { title: 'Action_Plan', width: { wch: 25 } },       
       { title: 'Due_Date', width: { wch: 10 } },       
    ];        
   
    //for (let i = 1; i <= maxResponses; i++  ) {        
      
    //}
    let data = [];
  //  data.push(createFirstRowJSON(columns));
    _.each(JSONArray, (stateJSON) => {
        let row = [];
        _.each(columns, (column) => {            
            row.push(stateJSON[column.title] || '');            
        })
        data.push(row);
    })
    return [{
        columns, data
    }];
}

const getReviewFileName = (review) => {
    if (review) {
        const currentTime = Moment().format('YYYYMMDD_HHmmss');
        const fileName = `${review.Program}_${review.Location}_${review.Assessment_Name}_${currentTime}`;
        return fileName.replace(/ /g, '_');
    }
}
const validateKPI = (KPIValue) => {
    let regEx = /^(?!.{0-9})([1-9]\d{0,3}|0)(\.\d{1,2})?$/;
    if (((is.number(Number(KPIValue)) && regEx.test(Number(KPIValue))) ||
    KPIValue === "-") && !KPIValue.includes(" ")) {
        return true;
    } else {
        return false;
    }
}

export {
    getReadableStories,
    getFilterLabel,
    getFetchError,
    getPaginationDetails,
    getScheduleListParams,
    getReview,
    getUnAuthResponse,
    getUnAuthReviewResponse,
    getReviewNotStartedStatus,
    getReviewValidation,
    getQdetails,
    getQStatus,
    getQAction,
    getCompleteReviewButtonStatus,
    getUserRole,
    getNumberOfFlags,
    getCurrentStateIntoJSON,
    getCurrentStateIntoExcelJSON,
    getReviewFileName,
    validateKPI
};
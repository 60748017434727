import React from "react";
import Select from "react-select";

import { multiselectOptions } from "../../constants/uploadSOPConstants";


const ProgramList = (props) => {
    return (
        <React.Fragment>
        <div className="form-group">
            <label htmlFor="program" id="program__label">Program</label>
            {props.programsDropDownList && props.programsDropDownList.length > 0 && props.programsDropDownList.length <= 1 && (
                <div className="form-group" onClick={(e) => props.onSelectProgram(props.programsDropDownList[0])}>
                    <input type="text" id="program-text" className="form-control disabled-event" value={props.programsDropDownList[0].Name} />

                </div>
            ) || (
                <Select
                    id="program_input" classNamePrefix='program' className="form-group dropdown-with-search"
                    value={props.selectedOption}
                    placeholder="Select Program"
                    onChange={(e) => props.onSelectProgram(e)}
                    options={multiselectOptions("Select Program", props.programsDropDownList, "Id", "Name", null)}  isSearchable="true"
                />
            )}
        </div>
        </React.Fragment>
    )
}

export default ProgramList;
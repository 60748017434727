import React from 'react';
import { ListGroupItem, Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@mui/material/Tooltip';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Moment from 'react-moment';
import classNames from "classnames";
import { doAddActionDetails, doAddQuestionDocument, doDeleteDocument, doAddQuestionRecommendation, doUpdateQuestionRecommendation, doAddQuestionSop, updateQuestionComment, doSaveReview } from "../../actions/review";
import { getQdetails, getQStatus, getQAction } from "../../selectors/story";
import AddDoc from './AddDoc';
import SupportingSop from './SupportingSop';
import Recommendation from './Recommendation';
import { PopUpCreateActionTrigger } from "../Action/PopUpTrigger";
import Comments from './Comments';
import { doPostAuditLogData } from "../../actions/auditLog";
import { getCurrentUser } from "../../selectors/dropdown";
import moment from 'moment';

function TabContainer(props) {
  return (
    <Typography component="div">
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
});

class ReviewCollapse extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false, value: 0, showActionModal: false };
    this.commentRef = React.createRef();

  }

  componentDidUpdate(prevProps) {
    if (this.props.qAction.length === 0 && this.props.qStatus === 'Fail') {
      if (this.props.qStatus !== prevProps.qStatus) {
        this.setState({ collapse: true, value: 2 });
      }
    }

    if (this.props.qDetails.Comments.length !== prevProps.qDetails.Comments.length) {
      this.commentRef.current.value = '';
    }

    if (this.props.loaderState === 'hide' && this.state.commentSaving) {
      this.setState({ commentSaving: false });
    }

  }

  handleFocusOut = (e) => {
    var getPastedata = e.target.value;
    var splChars = "*|,\":<>[]{}`\';()@&$#%";
    if (getPastedata.length > 0) {
      if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
        window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
        setTimeout(
          function () {
            this.commentRef.current.focus();
          }
            .bind(this),
          100
        );
      }
    }

    this.props.updateQuestionComment(this.props.qDetails.Question_Id, e.target.value);
  }

  onKeyPress = (e) => {
    var inputValue = e.target.value;
    var keyValue = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (inputValue.length > 0) {
      var regvalue = inputValue.substr((inputValue.length - 1), 1) + keyValue;
      var SpecialCharExists = false;
      var setOfStrings = ['\\*', '--', '?=', ';', '%%', '[^'];
      for (var i = 0; i < setOfStrings.length; i++) {
        if (regvalue.indexOf(setOfStrings[i]) != -1)
          SpecialCharExists = true;
      }
      if (SpecialCharExists) {
        e.preventDefault();
        return false;
      }

      if (e.target.selectionStart == 0) {
        var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        if (Specialcharacters.test(keyValue) || e.which === 32) {
          e.preventDefault();
          return false;
        }
      }

      return true;
    }

    if (inputValue.length == 0) {
      var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
      if (Specialcharacters.test(keyValue)) {
        e.preventDefault();
        return false;
      }
      if (e.which === 32) {
        e.preventDefault();
        return false;
      }
    }
    return true;
  }


  handleChange = (event, value) => {
    this.setState({ value });
  };

  toggle(e) {
    this.setState((prevState) => {
      return { collapse: !prevState.collapse };
    })
    e.preventDefault();
  }

  addActionDetails = (actionDetails, qId) => {
    this.props.addActionDetails(actionDetails, qId, this.props.review && this.props.review.Assessment_Type);
  }

  addQuestionDocuments = (data) => {
    this.props.addQuestionDoc(data)
  }

  deleteQuestionDoc = (data) => {
    this.props.deleteQuestionDoc(data)
  }

  saveComment = () => {
    if (this.commentRef.current.value) {
      this.setState({ commentSaving: true });
      this.props.onSaveComment();
    }
    /** Audit Log */
    let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
      + '(' + this.props.currentUser.employeeNumber + ')' : "";

    let msg = this.props.auditLogMsg + currentUser + ' added Comment for ' + this.props.qDetails.Question_Template_ID + ' for ' + this.props.review.Assessment_Id + ' on ' + moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
    const reqPayload = {
      LogMessage: msg,
      AuditLogModule: "Conduct Reviews And Approvals",
      AcionAPISource: "Add Comment",
      CreatedBy: currentUser,
      CreatedOn: moment(new Date()).format('HH:MM DD MMM YYYY')
    }
    this.props.doPostAuditLogData(reqPayload);
  }

  showCommentLoader() {
    return this.state.commentSaving ? (
      <div className="lds-ring-loader for-comment">
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-overlay" />
      </div>
    ) : null;
  }
  render() {
    const { value } = this.state;
    const mode = this.props.qAction[0] && this.props.qAction[0].Action_ID ? "CreateAction" : "UpdateAction";
    const actionId = this.props.qAction[0] && this.props.qAction[0].Action_ID ? this.props.qAction[0].Action_ID : null;
    const currentUser = "K11069";
    const location = this.props.locationDetails && this.props.locationDetails.locationName || "";

    const triggerCreate = (<span><button disabled={this.props.qAssessment_Response_ID|| this.props.review && this.props.review.Program==="TFL" && this.props.questionResponse===""} className={`btn btn-primary action-review-btn ${this.props.elHide}`}>Create Action</button></span>);
    const actionClass = classNames({
      'col-xl-4 col-md-6 questions__documents-wrapper': true,
      'questions__action': (this.props.qAction.length === 0 && this.props.qStatus === 'Fail'),
      'questions__action-required': (this.props.qAction.length === 0 && this.props.qStatus === 'Fail'),

    });
    const actionClassMob = classNames({
      'questions__documents-wrapper': true,
      'questions__action': (this.props.qAction.length === 0 && this.props.qStatus === 'Fail'),
      'questions__action-required': (this.props.qAction.length === 0 && this.props.qStatus === 'Fail'),


    });
    const test= this.props.questionResponse
    const actionWidget = (
      
  
      <Tooltip title={ this.props.review && this.props.review.Program==="TFL" && this.props.questionResponse===""  ?"select a response to create an action":this.props.review && this.props.review.Program==="TFL" && this.props.qAction.length > 0 && window.location.href.indexOf("review9") > -1 ? "Please go to the ‘Actions’ tab to close this Action.":""} followCursor={true}>

       <div className={`${actionClass} ${this.props.qAction.length > 0 ? 'clickable' : ''}`}>
        <div className='tooltip-action'>
       <div className="docs-header action-header">
        <img className="docs-icon" width="22px" src="../images/icons-actions.svg" alt="Action" />
        <span className="docs-header-text"> Action</span>
       
        {this.props.qAction.length > 0 ? '' :
          PopUpCreateActionTrigger(triggerCreate,
            "CreateAction",
            
            this.props.qDetails, this.props.review, location, currentUser,
            this.props.qAction.length > 0 ? this.props.qAction[0] : {},
            this.props.qAction.length > 0 ? this.props.qAction[0].Question_Action_ID : null,
            this.addActionDetails,
            ((this.props.qAction.length > 0 ? this.props.qAction[0].Action_Assessment_Id: null)=== (this.props.review?this.props.review.Assessment_Id:null)),
            this.props.reviewStatus)
        }
      </div>
      {this.props.qAction.length > 0 && (
        <div className="docs-content">
          <p className="word-break">{this.props.qAction[0].Title}</p>
          <div className="docs-header action-details">
          { this.props.review && this.props.review.Program!=="TFL" &&( 
            <div className="row">
                <div className="col-4">Priority</div>
                <div className="col-8 text-black">{this.props.qAction[0].Priority}</div>
              </div>)
              } 
            <div className="row">
              <div className="col-4">Due date</div>
              <div className="col-8 text-black">
                <Moment format="DD-MM-YYYY">
                  {this.props.qAction[0].Due_Date}
                </Moment>

              </div>
            </div>
          </div>
        </div>
      )}
      <div className="lds-ring-loader hide">
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-ring" />
        <div className="lds-overlay" />
      </div>
      </div>
    </div>
  </Tooltip>
 
 
    
    )
    return (

      <div>
        <a
          className={`questions__toggle-button ${this.state.collapse ? "" : "collapsed"}`}
          href="#question1-detail"
          id="question1-detail"
          onClick={(e) => this.toggle(e)}
        />
        <Collapse isOpen={this.state.collapse} className="questions__detail" key={this.props.key}>

          {/* {this.props.review.Status === "In-progress" && !(this.props.qAssessment_Response_ID) && this.props.qDetails.Actions.length > 0 && (this.props.review.Assessment_Id > this.props.qDetails.Actions[0].Action_Assessment_Id) &&
            (<div className="user-prompted-review-section" >
              <h6 className="ReviewCollapseError">Kindly close open actions from previous review</h6>
            </div>)} */}

          {this.props.qAssessment_Response_ID &&
            (<div className="Error-for-unsaved-review" >
              <h6 className="ReviewCollapseError">This review needs to be saved before SOP/Documents/Observations/Action can be added. Actions and Scores will not update on TFL Reporting until the review is submitted.</h6>
            </div>)}

          <div className={`questions__documents questions__documents-desktop d-none d-md-block ${this.props.readOnlyClass}`}>
            <div className="row">
              <React.Fragment>
                <SupportingSop
                  qAssessment_Response_ID={this.props.qAssessment_Response_ID}
                  qDetails={this.props.qDetails}
                  review={this.props.review}
                  postQuestionSopDocument={this.props.postQuestionSopDocument}
                  isLoading={this.props.isLoading}
                  elHide={this.props.elHide} canAddSOP={this.props.canAddSOP}
                  doPostAuditLogData={this.props.doPostAuditLogData}
                  currentUser={this.props.currentUser}
                />
              </React.Fragment>

              <React.Fragment>
                <AddDoc
                  qAssessment_Response_ID={this.props.qAssessment_Response_ID}
                  qDetails={this.props.qDetails}
                  review={this.props.review}
                  uniqueKey={`add-doc-widget-tab__${value}`}
                  addQuestionDocuments={this.addQuestionDocuments}
                  deleteQuestionDoc={this.deleteQuestionDoc}
                  isLoading={this.props.isLoading}
                  key={this.props.key}
                  elHide={this.props.elHide}
                  doPostAuditLogData={this.props.doPostAuditLogData}
                  currentUser={this.props.currentUser}
                  {...this.props}
                />
              </React.Fragment>


              {/* <React.Fragment>
                <Recommendation
                  qAssessment_Response_ID={this.props.qAssessment_Response_ID}
                  qDetails={this.props.qDetails}
                  review={this.props.review}
                  postRecommendation={this.props.postRecommendation}
                  updateRecommendation={this.props.updateRecommendation}
                  elHide={this.props.elHide}
                  doPostAuditLogData={this.props.doPostAuditLogData}
                  isLoading={this.props.isLoading}
                  currentUser={this.props.currentUser}
                  auditLogMsg={this.props.auditLogMsg}
                />
              </React.Fragment> */}
              <React.Fragment>
                {this.props.qAction.length > 0 ?
                  PopUpCreateActionTrigger(actionWidget,
                    this.props.qAction.length > 0 ? "UpdateAction" : "CreateAction",
                    this.props.qDetails, this.props.review, location, currentUser,
                    this.props.qAction.length > 0 ? this.props.qAction[0] : {},
                    this.props.qAction.length > 0 ? this.props.qAction[0].Question_Action_ID : null,
                    this.addActionDetails,
                    ((this.props.qAction.length > 0 ? this.props.qAction[0].Action_Assessment_Id: null)=== (this.props.review?this.props.review.Assessment_Id:null)),
                    this.props.reviewStatus) :
                  actionWidget}
              </React.Fragment>
            </div>
          </div>
          <div className="questions__documents questions__documents-mobile d-block d-sm-block d-md-none ">
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={this.handleChange}
                scrollButtons="off"
                variant="fullWidth"
              >
                <Tab icon={(
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 30 30"
                    width="26" className="custom-svg"
                  >
                    <defs>
                      <path
                        id="asop"
                        d="M29.47 16.408a.53.53 0 01.53.53v9.116a3.75 3.75 0 01-3.746 3.746H3.746A3.75 3.75 0 010 26.054v-9.116a.53.53 0 111.061 0v9.116a2.688 2.688 0 002.685 2.685h22.508a2.688 2.688 0 002.685-2.685v-9.116a.53.53 0 01.53-.53zM6.103 9.977a.53.53 0 11-.75-.75l8.589-8.59a1.496 1.496 0 012.114 0l8.59 8.59a.53.53 0 01-.751.75L15.53 1.612v19.57a.53.53 0 11-1.062 0V1.612L6.104 9.976z"
                      />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <mask id="bsop" fill="#fff">
                        <use xlinkHref="#asop" />
                      </mask>
                      <use fill="#000" fillRule="nonzero" xlinkHref="#asop" />
                      <g fill="#000" mask="url(#bsop)">
                        <path d="M0 0h30v30H0z" />
                      </g>
                    </g>
                  </svg>
                )}
                />
                <Tab icon={(
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 26 26"
                    width="26px" className="custom-svg"
                  >
                    <defs>
                      <path
                        id="aphoto"
                        d="M13 7.852a6.32 6.32 0 016.312 6.312A6.32 6.32 0 0113 20.476a6.32 6.32 0 01-6.312-6.312A6.32 6.32 0 0113 7.852zm0 11.704a5.398 5.398 0 005.392-5.392A5.398 5.398 0 0013 8.772a5.398 5.398 0 00-5.392 5.392A5.398 5.398 0 0013 19.556zm9.196-13.655A3.808 3.808 0 0126 9.705v10.869a3.809 3.809 0 01-3.804 3.804H3.804A3.808 3.808 0 010 20.574V9.705a3.808 3.808 0 013.804-3.804h1.448c.54 0 1.174-.446 1.357-.954l.497-1.384C7.421 2.686 8.397 2 9.33 2h7.342c.931 0 1.907.686 2.223 1.563l.497 1.384c.183.508.817.954 1.358.954h1.447zm2.884 14.673V9.705a2.887 2.887 0 00-2.884-2.884H20.75c-.932 0-1.908-.686-2.223-1.562l-.498-1.384c-.183-.51-.817-.955-1.357-.955H9.329c-.54 0-1.175.446-1.357.955l-.498 1.383c-.315.877-1.291 1.563-2.223 1.563H3.804A2.887 2.887 0 00.92 9.705v10.869a2.888 2.888 0 002.884 2.884h18.392a2.888 2.888 0 002.884-2.884z"
                      />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <mask id="bphoto" fill="#fff">
                        <use xlinkHref="#aphoto" />
                      </mask>
                      <use fill="#000" fillRule="nonzero" xlinkHref="#aphoto" />
                      <g fill="#000" mask="url(#bphoto)">
                        <path d="M0 0h26v26H0z" />
                      </g>
                    </g>
                  </svg>
                )}
                />
                <Tab icon={(
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 30 30"
                    width="26px" className="custom-svg"
                  >
                    <defs>
                      <path
                        id="aaction"
                        d="M27.5 26a1.5 1.5 0 011.5 1.5v2a.5.5 0 01-.5.5h-26a.5.5 0 01-.5-.5v-2A1.5 1.5 0 013.5 26H4v-7.5C4 12.149 9.149 7 15.5 7S27 12.149 27 18.5V26h.5zM5 18.5V26h21v-7.5C26 12.7 21.3 8 15.5 8S5 12.7 5 18.5zM28 29v-1.5a.5.5 0 00-.5-.5h-24a.5.5 0 00-.5.5V29h25zM15 0h1v5h-1V0zM2.065 5.772l.707-.707L6.308 8.6l-.708.707-3.535-3.535zM24.692 8.6l3.536-3.536.707.707L25.4 9.308l-.708-.707z"
                      />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <mask id="baction" fill="#fff">
                        <use xlinkHref="#aaction" />
                      </mask>
                      <use fill="#000" fillRule="nonzero" xlinkHref="#aaction" />
                      <g fill="" mask="url(#baction)">
                        <path d="M0 0h30v30H0z" />
                      </g>
                    </g>
                  </svg>
                )}
                />
                <Tab icon={(
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    viewBox="0 0 26 26"
                    width="26px" className="custom-svg"
                  >
                    <defs>
                      <path
                        id="aobservation"
                        d="M21.228 24.803a.431.431 0 110 .864H5.094a.431.431 0 110-.864h16.134zM5.276 22.165s-.246.085-.364.085a.668.668 0 01-.387-.12c-.21-.147-.315-.4-.29-.694l.27-3.12c.057-.648.393-1.575.767-2.108L14.9 2.458l.354.247-.354-.247a2.788 2.788 0 013.879-.684 2.788 2.788 0 01.683 3.878l-9.628 13.75c-.373.534-1.129 1.168-1.72 1.442l-2.838 1.32zm10.33-19.212L13.81 5.518l3.148 2.205 1.797-2.565a1.925 1.925 0 00-.472-2.677 1.924 1.924 0 00-2.677.472zm-2.29 3.272l-6.359 9.08 3.149 2.205 6.358-9.08-3.149-2.205zM5.364 18.392l-.251 2.896 2.636-1.226c.45-.21 1.093-.748 1.377-1.154l.484-.691-3.149-2.205-.483.691c-.285.406-.571 1.195-.614 1.689z"
                      />
                    </defs>
                    <g fill="none" fillRule="evenodd">
                      <mask id="bobservation" fill="#fff">
                        <use xlinkHref="#aobservation" />
                      </mask>
                      <use fill="#000" fillRule="nonzero" xlinkHref="#aobservation" />
                      <g fill="#000" mask="url(#bobservation)">
                        <path d="M-2.167-2.7h32v32h-32z" />
                      </g>
                    </g>
                  </svg>
                )}
                />
              </Tabs>
            </AppBar>
            <div className={`row ${this.props.readOnlyClass}`}>
              <div className="col-xl-3 col-md-6">
                {value === 0 && (
                  <TabContainer>
                    <SupportingSop
                      qAssessment_Response_ID={this.props.qAssessment_Response_ID}
                      qDetails={this.props.qDetails}
                      review={this.props.review}
                      postQuestionSopDocument={this.props.postQuestionSopDocument}
                      isLoading={this.props.isLoading}
                      elHide={this.props.elHide}
                      doPostAuditLogData={this.props.doPostAuditLogData}
                      currentUser={this.props.currentUser}
                    />
                  </TabContainer>
                )}
              </div>
              <div className="col-xl-3 col-md-6">
                {value === 1 && (
                  <TabContainer>
                    <AddDoc uniqueKey={`add-doc-widget-tab-mobile__${value}`}
                      qAssessment_Response_ID={this.props.qAssessment_Response_ID}
                      qDetails={this.props.qDetails}
                      addQuestionDocuments={this.addQuestionDocuments}
                      deleteQuestionDoc={this.deleteQuestionDoc}
                      review={this.props.review}
                      isLoading={this.props.isLoading}
                      elHide={this.props.elHide}
                      {...this.props}
                    />
                  </TabContainer>
                )}
              </div>
              <div className="col-xl-3 col-md-6">
                {value === 3 && (
                  <TabContainer>
                    <Recommendation
                      qDetails={this.props.qDetails}
                      review={this.props.review}
                      postRecommendation={this.props.postRecommendation}
                      isLoading={this.props.isLoading}
                      updateRecommendation={this.props.updateRecommendation}
                      elHide={this.props.elHide}
                      doPostAuditLogData={this.props.doPostAuditLogData}
                      currentUser={this.props.currentUser}
                    />
                  </TabContainer>
                )}
              </div>
              <div className="col-xl-3 col-md-6">
                {value === 2 && (
                  <TabContainer>
                    {this.props.qAction.length > 0 ?
                      PopUpCreateActionTrigger(actionWidget,
                        this.props.qAction.length > 0 ? "UpdateAction" : "CreateAction",
                        this.props.qDetails, this.props.review, location, currentUser,
                        this.props.qAction.length > 0 ? this.props.qAction[0] : {},
                        this.props.qAction.length > 0 ? this.props.qAction[0].Question_Action_ID : null,
                        this.addActionDetails,
                        ((this.props.qAction.length > 0 ? this.props.qAction[0].Action_Assessment_Id: null)=== (this.props.review?this.props.review.Assessment_Id:null)),
                        this.props.reviewStatus) :
                      actionWidget}
                  </TabContainer>
                )}
              </div>

            </div>
          </div>
          {(this.props.reviewStatus === 'Pending 1st' || this.props.reviewStatus === 'Pending 2nd' || this.props.reviewStatus === 'Reopen')
            && (
              <div className={`questions__comment-box ${this.props.elHide}`}>
                <Comments
                  qDetails={this.props.qDetails}
                  review={this.props.review}
                  postRecommendation={this.props.postRecommendation}
                  isLoading={this.props.isLoading}
                  updateRecommendation={this.props.updateRecommendation}
                />
                <div className="form-group"><label for="comment-description" id="">Comments
                  {this.props.qDetails.flagValid === 'invalid' &&
                    <span class="required">*</span>
                  }
                </label>
                  <div className="row">
                    <div className="col-md-6 p-r-0">
                      <textarea id="comment-description" className={this.props.qDetails.flagValid === 'invalid' ? 'form-control border border-danger' : 'form-control'} ref={this.commentRef}
                        name="comment-description" placeholder="Text" maxlength="500" defaultValue={this.props.qDetails.QuestionComment} onKeyPress={this.onKeyPress} onBlur={(e) => this.handleFocusOut(e)}></textarea>
                    </div>
                    <div className="col-md-6 m-text-center" >
                      <button id="submit-btn" class="btn btn-gray m-t-5" type="button" onClick={(e) => this.saveComment()}>Save</button>
                    </div>
                    {this.showCommentLoader()}
                  </div>
                </div>
              </div>
            )}

        </Collapse>
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => ({
  //qDetails: getQdetails(state, ownProps.qDetails.Question_Id),
  qStatus: getQStatus(ownProps.qDetails),
  qAction: getQAction(ownProps.qDetails),
  isLoading: state.reviewState.isLoading,
  reviewStatus: state.reviewState.review.Status,
  loaderState: state.loaderState.loader,
  currentUser: getCurrentUser(state.dropdownState),
});

const mapDispatchToProps = (dispatch) => ({
  addActionDetails: (actionDetails, qId, reviewType) => dispatch(doAddActionDetails(actionDetails, qId, reviewType)),
  addQuestionDoc: (documents) => dispatch(doAddQuestionDocument(documents)),
  deleteQuestionDoc: (documents) => dispatch(doDeleteDocument(documents)),
  postRecommendation: (recomendation) => dispatch(doAddQuestionRecommendation(recomendation)),
  updateRecommendation: (recomendation) => dispatch(doUpdateQuestionRecommendation(recomendation)),
  postQuestionSopDocument: (payload, params, data) => dispatch(doAddQuestionSop(payload, params, data)),
  updateQuestionComment: (qId, commentText) => dispatch(updateQuestionComment(qId, commentText)),
  onSaveComment: () => dispatch(doSaveReview(undefined, 0)),
  doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
});

const connectedReview = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewCollapse);
export default withRouter(connectedReview);
import {
  FETCH_DEALERSOP_DOCS_LIST,
  ADD_SOPDOCS,
  ADD_SOPDOCS_FORMFIELDS,
  SORT_SOPDOCS,
  FETCH_ERROR_SOPDOCS,
  UPLOAD_SOP_DOCUMENT_ERRORS,
  UN_AUTHORIZED_UPLOAD_SOP
} from '../constants/actionTypes';

const INITIAL_STATE = {
  uploadFormFields: {
      selectedLocation: null,
      sopdropselect: null,
      programdropselect:null,
      selectedFileForUpload: ""
  },
  sortBy: {
      sortField: 'sop_name',
      sortOrder: 'desc'
  },
  dealerSopDocsList: [],
  count: 0,
  error: null,
};

const applyAddSOPDocs = (state, action) => {
        let sopDocList;
        sopDocList = action.dealerSopDocsList;

        return ({
            dealerSopDocsList: sopDocList,
            count: action.count,
            uploadFormFields : {selectedLocation : action.selectedLocation},
            error: null,
        })
};

const applyAddSOPDocsFormFields = (state,action) => {
  return ({
    uploadFormFields : {selectedFileForUpload : action.selectedFileForUpload},
    uploadFormFields : {sopdropselect : action.sopdropselect},
    uploadFormFields : {programdropselect : action.programselect},
  })
}


const applySortSOPDocsList = (state, action) => ({
    dealerSopDocsList: action.dealerSopDocsList,
    error: null,
});

const fetchSOPDocsList = (state, action) => ({
  dealerSopDocsList: action.dealerSopDocsList
});

const applyFetchErrorSOPDocs = (state, action) => ({
  dealerSopDocsList: [],
  error: action.error,
});

const applyUploadSopDocumentErrors = (state, action) => ({
  dealerSopDocsList: [],
  error: action.error,
});

const applySortSOPDocs = (state, action) => ({
    dealerSopDocsList: action.dealerSopDocsList
});

export function uploadSOPReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case ADD_SOPDOCS : {
      return applyAddSOPDocs(state, action);
    }
    // case FETCH_DEALERSOP_DOCS_LIST : {
    //   return fetchSOPDocsList(state, action);
    // }
    case FETCH_ERROR_SOPDOCS : {
      return applyFetchErrorSOPDocs(state, action);
    }
    case SORT_SOPDOCS : {
      return applySortSOPDocs(state, action);
    }
    case ADD_SOPDOCS_FORMFIELDS : {
      return applyAddSOPDocsFormFields (state,action);
    }

    // case UPLOAD_SOP_DOCUMENT : {
    //   return applyUploadSopDocument (state,action);
    // }
    case UPLOAD_SOP_DOCUMENT_ERRORS : {
      return applyUploadSopDocumentErrors (state,action);
    }
    case UN_AUTHORIZED_UPLOAD_SOP : {
      return {...state, sopResponseStatus:action.payload}
    }

    default : return state;
  }
}

export default uploadSOPReducer;

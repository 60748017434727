export const MOCK_ASSIGNTO_LIST = [
  {
    id: 1,
    assignTo: "Assignee 1",
    selected:false
  },
  {
    id: 2,
    assignTo: "Assignee 2",
    selected:false
  },
  {
    id: 3,
    assignTo: "Assignee 3",
    selected:false
  }
]

import {
    INVESTIGATION_LIST_ERRORS,
    POPULATE_INVESTIGATION_LIST,
    POPULATE_INVESTIGATION_CREATE_DATA,
    INVESTIGATION_CREATE_DATA_ERRORS,
    SAVE_NEW_INVESTIGATION_RESPONSE,
    POPULATE_LOOKUP_STATUS_DATA,
    POPULATE_LOOKUP_STAGE_DATA,
    LOOKUP_STATUS_ERRORS,
    LOOKUP_STAGE_ERRORS,
    DELETE_INVESTIGATION_RESPONSE,
    SAVE_UPDATE_INVESTIGATION_RESPONSE,
    
} from '../constants/actionTypes';

const INITIAL_STATE = {
    investigationList: [],
    investigationListCount: 0,
    error: null,
    createInvestigationObj: {},
    lookUpStatusObj: {},
    lookUpStageObj: {},
    deleteResponse:{},
    saveUpdateResponse:{}

};

const applyAddInvestigationRecords = (state, action) => {
    const investigationList = [...action.investigationList];
    return ({
        ...state,
        investigationList,
        investigationListCount: action.count,
        error: null,
    })
};


const applyInvestigationErrors = (state, action) => ({
    ...state,
    investigationList: [],
    error: action.error,
});

const addCreateInvestigationData = (state, action) => {
    let createInvestigationObj;
    if (action.deviceType === 'mobile') {
        createInvestigationObj = [...state.createInvestigationObj, ...action.createInvestigationObj];
    }
    else {
        createInvestigationObj = action.createInvestigationObj;
    }
    return ({
        ...state,
        createInvestigationObj,
        count: action.count,
        error: null,
    })
};

const addCreateInvestigationErrors = (state, action) => ({
    ...state,
    createInvestigationObj: {},
    error: action.error,
});

const applySaveInvestigationResp = (state, action) => {
    let saveResponse = action.saveResponse;
    return ({
        ...state,
        saveResponse,
        error: null,
    })
};


const applyUpdateInvestigation = (state, action) => {
    let saveUpdateResponse = action.saveUpdateResponse;
    return ({
        ...state,
        saveUpdateResponse,
        error: null,
    })
};

const applyLookUpStatusData = (state, action) => {
    let lookUpStatusObj;
    if (action.deviceType === 'mobile') {
        lookUpStatusObj = [...state.lookUpStatusObj, ...action.lookUpStatusObj];
    }
    else {
        lookUpStatusObj = action.lookUpStatusObj;
    }
    return ({
        ...state,
        lookUpStatusObj,
        count: action.count,
        error: null,
    })
};

const applyLookUpStageData = (state, action) => {
    let lookUpStageObj;
    if (action.deviceType === 'mobile') {
        lookUpStageObj = [...state.lookUpStageObj, ...action.lookUpStageObj];
    }
    else {
        lookUpStageObj = action.lookUpStageObj;
    }
    return ({
        ...state,
        lookUpStageObj,
        count: action.count,
        error: null,
    })
};

const applyLookUpStatusError = (state, action) => ({
    ...state,
    lookUpStatusObj: {},
    error: action.error,
});

const applyLookUpStageError = (state, action) => ({
    ...state,
    lookUpStageObj: {},
    error: action.error,
});

const applyDeleteInvestigation = (state, action) => {
    let deleteResponse = action.deleteResponse;
    return ({
      ...state,
      deleteResponse,
      error: null,
    })
  };


function investigationsReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case POPULATE_INVESTIGATION_LIST: {
            return applyAddInvestigationRecords(state, action);
        }
        case INVESTIGATION_LIST_ERRORS: {
            return applyInvestigationErrors(state, action);
        }
        case POPULATE_INVESTIGATION_CREATE_DATA: {
            return addCreateInvestigationData(state, action);
        }
        case INVESTIGATION_CREATE_DATA_ERRORS: {
            return addCreateInvestigationErrors(state, action);
        }
        case SAVE_NEW_INVESTIGATION_RESPONSE: {
            return applySaveInvestigationResp(state, action);
        }
        case POPULATE_LOOKUP_STATUS_DATA: {
            return applyLookUpStatusData(state, action);
        }
        case POPULATE_LOOKUP_STAGE_DATA: {
            return applyLookUpStageData(state, action);
        }
        case LOOKUP_STATUS_ERRORS: {
            return applyLookUpStatusError(state, action);
        }
        case LOOKUP_STAGE_ERRORS: {
            return applyLookUpStageError(state, action);
        }
        case DELETE_INVESTIGATION_RESPONSE:{
            return applyDeleteInvestigation(state, action);
        }
        case SAVE_UPDATE_INVESTIGATION_RESPONSE:{
            return applyUpdateInvestigation(state, action);
        }
        default: return state;
    }
}

export default investigationsReducer;
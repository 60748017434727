import React, { Component } from 'react';
import { connect } from "react-redux";
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import { getStartDate, getCurrentUserDetails } from "../../constants/utilityfunctions";
import 'react-widgets/dist/css/react-widgets.css';
import { validateCreateActionForm, isAssignToValid,isDescriptionValid, isTitleValid, isPriorityValid, isStatusValid, validateUploadedFile } from "../../FormValidators/CreateActionValidator";
import { fieldErrorDiv, dropdownOptionsAction } from "../../constants/uploadSOPConstants";
import { getAssignToList, getActionsList, getActionsSubmitStatus } from '../../selectors/createAction';
import { doCreateAction, doCreateUpdateActionError, doFetchActionDataById, doUpdateAction, doClearData } from "../../actions/actionsCreateUpdateAction";
import { doFetchAssignToList } from "../../actions/assignToList";
import { doUpdateQuestionRecommendation, doAddQuestionRecommendation,doCompleteReview,doFetchSectionScoreData} from "../../actions/review";
import { doFetchScheduleDropDowns } from "../../actions/story";
import { getBase64, compressImg } from '../../utils/utils';
import LoaderModal from "../Common/LoaderModal";

import {

  doShowModalLoader,
  doHideModalLoader
} from "../../actions/loader";

import is from 'is_js';
import { getCurrentUser } from '../../selectors/dropdown';
import { doPostAuditLogData } from "../../actions/auditLog";

import Moment from 'moment'
import momentLocalizer from 'react-widgets-moment';

Moment.locale('en')

class CreateManageAction extends Component {

  constructor(props) {
    super(props);

    // Form data
    this.state = { updateFlag: false, creation_date: "", due_date: "", selectedLocation: "", assign_to: "", notes: "", title: "", description: "", submitError: "", status: "5", ActionPlan: "" };

    // For Form Validation
    this.state = {
      formValid: "", assignToValid: "",
      question: this.props.question,
      review: this.props.review,
      createdBy: this.props.currentUser,
      lastModifiedBy: this.props.currentUser,
      action: this.props.action,
      actionId: this.props.actionId,
      userType: localStorage.getItem('userType') === 'Dealer Use' ? 'Dealer' : 'TMCA',
      addFileMessage: '',
      filesList: [],
      logMessage: "",
      Program_ID: '',
      Question_ID: '',
      Question_Template_ID:  '',
      Action_ID:  '',
      Assessment_Template_ID:  '',
      Assessment_ID: '',      
     // title: (this.props.mode !== "UpdateAction" && this.state.status === undefined) this.props.question.Provided_Question_Id,
      Question_Action_ID: '',
      Dealer_ID: '',
      scoreUpdatePayload : {},
      checkCompletestatus: "false",
      previousStatus:'',
      checkApiresponse:"false",
    };

    }

  componentDidMount() {
   
    const due_date = getStartDate();
    const creation_date = getStartDate();
    if (this.props.mode !== "UpdateAction") this.setState({ due_date, creation_date });
    // this.setState({ showAlert: false });
    // this.props.onFetchAssignToList();      
      if (this.props.mode !== "UpdateAction") this.setState({ title: this.props.question.Provided_Question_Id + ' ' + this.props.question.TFLQuestionStandard });
    if (this.props.mode === "UpdateAction") {
      this.props.fetchActionDetails(this.props.actionId);
      // this.populateActionDetails();
    }
    if (this.props.mode !== "UpdateAction" && this.state.status === undefined) this.setState({ status: "5" })
    if (this.props.mode === "UpdateAction") this.setState({ formValid: validateCreateActionForm() });
    
  }

  componentWillUnmount() {
    const qActionId = this.props.mode === "UpdateAction" ? this.state.action.Question_Action_ID : this.props.submitStatus.Id
    if (this.props.submitStatus.Success) {
        this.props.addActionDetails([{
        "Action_ID": 1,
        "Question_Action_ID": qActionId,
        "Title": this.state.title,
        "Priority": this.state.priority,
        "Due_Date": this.state.due_date
      }], this.props.question && this.props.question.Question_Id, this.props.activePage);
     
    }
    this.props.clearData([]);

  }
  static getDerivedStateFromProps(props, state) {
    const simpleBase64 = "";
    if (!state.updateFlag) {
      const DocList = new Map();
      if (props.mode === "UpdateAction" && props.actionDetails) {
        props.actionDetails.Documents && props.actionDetails.Documents.map(
          (file) => {
            /* let fileData = fetch(file.BlobURI, {
               method: "GET", // *GET, POST, PUT, DELETE, etc.
               mode: "cors", // no-cors, cors, *same-origin
               headers: {
                 "Access-Control-Request-Headers": "*",
                 "Access-Control-Request-Method": "*"
               }})
             .then(function(data) {
               return data;
             })
               let fileObj = createFileObject(fileData, file.Document_Name);
   
   
               getBase64(fileObj).then((el) => {
                 let arr = el.split("base64,")
         
                 let simpleBase64 = arr[1];
                  */
            DocList.set(new String(file.Document_Name), [
              simpleBase64, file])
            //  });




          });
        return {
          updateFlag: true,
          creation_date: state.creation_date || props.actionDetails.CreatedOn,
          due_date: state.due_date || props.actionDetails.Due_Date,
          DateClosed: state.DateClosed || props.actionDetails.DateClosed,
          assign_to: state.assign_to || props.actionDetails.Assigned_To,
          priority: state.priority || props.actionDetails.Priority,
          notes_area: state.notes_area || props.actionDetails.Notes,
          title: state.title || props.actionDetails.Title,
          description: state.description || props.actionDetails.Description,
          ActionPlan: state.ActionPlan || props.actionDetails.ActionPlan,
          // submitError: "",
          status: state.status || props.actionDetails.Status_ID,
          selectedFileForUpload: state.selectedFileForUpload || DocList,
          createdBy: props.actionDetails.CreatedBy,
          lastModifiedBy: props.currentUser, // current user
          formValid: true,

          userType: props.actionDetails.UserType || 'TMCA',
          showAlert: props.submitError != null || (props.submitStatus.Success !== undefined && !props.submitStatus.Success),
          Program_ID: props.actionDetails && props.actionDetails.Program_ID ? props.actionDetails.Program_ID : '',
          Question_ID: props.actionDetails && props.actionDetails.Question_ID ? props.actionDetails.Question_ID : '',
          Question_Template_ID: props.actionDetails && props.actionDetails.Question_Template_ID ? props.actionDetails.Question_Template_ID : '',
          Action_ID: props.actionDetails && props.actionDetails.Action_ID ? props.actionDetails.Action_ID : '',
          Assessment_Template_ID: props.actionDetails && props.actionDetails.Assessment_Template_ID ? props.actionDetails.Assessment_Template_ID : '',
          Assessment_ID: props.actionDetails && props.actionDetails.Assessment_ID ? props.actionDetails.Assessment_ID : '',
          Question_Action_ID: props.actionDetails && props.actionDetails.Question_Action_ID ? props.actionDetails.Question_Action_ID : '',
          Dealer_ID: props.actionDetails && props.actionDetails.Dealer_ID ? props.actionDetails.Dealer_ID : '',

        }

      } else if((props.review && props.review.Program==="TFL")|| (props.actionDetails && props.actionDetails.Program==="TFL")) {
        return {
          assign_to: (props.review && props.review.Program==="TFL")|| (props.actionDetails && props.actionDetails.Program==="TFL")?props.currentUser:'',
          priority: 'Low'
        };
      }
      else {
        return {};
      }
    } else {
      return {
        showAlert: props.submitError != null || (props.submitStatus.Success !== undefined && !props.submitStatus.Success),
      }
    }

  }

  hideAlert() {
    this.setState({
      showAlert: false,
    });
    }

    handleAssignToDropdown = (event) => {
        if (isAssignToValid()) {
            this.setState({ assign_to: event.target.value, assignToValid: true });
            this.setState({ formValid: validateCreateActionForm() });

            if (this.props.mode === "CreateAction") {
                this.setState({
                    createdBy: this.props.currentUser,
                    logMessage: this.state.logMessage + ' Created by ' + this.state.lastModifiedBy + ' Create Action ' + ' for ' +
                        this.props.question.Method_Text + '.'
                });
            }

            if (this.props.mode === "UpdateAction") {

                this.setState({
                    logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' +  this.props.actionDetails && this.props.actionDetails.Action_ID + ' for ' +
                        this.props.actionDetails.Question_ID + ' for ' + this.props.actionDetails.Question_Template_ID + ' with Assignee ' + event.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
                });
            }
        }
    }
    fetchTitleValue = (event) => {
        if (this.state.actionId != null) {
            this.setState({
                logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' + this.props.actionDetails.Action_ID + ' for ' +
                    this.props.actionDetails.Question_ID + ' for ' + this.props.actionDetails.Question_Template_ID + ' with Title ' + event.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });

        }
    var getPastedata= event.target.value;
    var splChars = "*|,\":<>[]{}`\';()@&$#%";
      if (getPastedata.length > 0) {
          var targetId = event.target.id;
        if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
            window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
            setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
    } 
}   
  }

  fetchDescriptionValue = (event) => {
    ;
      if (this.state.actionId != null) {
          this.setState({
              logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' + this.props.actionDetails.Action_ID + ' for ' +
                  this.props.actionDetails.Question_ID + ' for ' + this.props.actionDetails.Question_Template_ID + ' with Description ' + event.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
          });
      }
    var getPastedata= event.target.value;
    var splChars = "*|,\":<>[]{}`\';()@&$#%";
      if (getPastedata.length > 0) {
          var targetId = event.target.id;
        if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
            window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
            setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
    } 
}   
    }
    fetchActionPlan = (event) => {
      ;
        if (this.state.actionId != null) {
            this.setState({
                logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' + this.props.actionDetails.Action_ID + ' for ' +
                    this.props.actionDetails.Question_ID + ' for ' + this.props.actionDetails.Question_Template_ID + ' with ActionPlan ' + event.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
            });
        }
      var getPastedata= event.target.value;
      var splChars = "*|,\":<>[]{}`\';()@&$#%";
        if (getPastedata.length > 0) {
            var targetId = event.target.id;
          if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
              window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
              setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
      } 
  }   
      }

    editTtitle = (event) => {
        this.setState({ title: event.target.value });
    }


  handleTitleField = (event) => {
    const errTitleArea = document.getElementById('titleError');

    if (isTitleValid()) {
      this.setState({ title: event.target.value });
      this.setState({ formValid: validateCreateActionForm() });
      errTitleArea.textContent = "";

    } else { this.setState({ formValid: false }); }
  }

  handleDescField = (event) => {
    this.setState({ description: event.target.value });

    }
  
    handleActionPlan = (event) => {
      if (isDescriptionValid()) {
        this.setState({ ActionPlan: event.target.value});
        this.setState({ formValid: validateCreateActionForm() });
      }
    } 
    
    onPastePress = (e) => {
        e.preventDefault();        
    }

    onKeyPress = (e) => {
        var inputValue = e.target.value;
        var keyValue = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (inputValue.length > 0) {
            var regvalue = inputValue.substr((inputValue.length - 1), 1) + keyValue;
            var SpecialCharExists = false;
            var setOfStrings = ['\\*', '--', '?=', ';', '%%', '[^'];
            for (var i = 0; i < setOfStrings.length; i++) {
                if (regvalue.indexOf(setOfStrings[i]) != -1)
                    SpecialCharExists = true;
            }
            if (SpecialCharExists) {
                e.preventDefault();
                return false;
            }
            if (e.target.selectionStart == 0) {
                var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
                if (Specialcharacters.test(keyValue) || e.which === 32) {
                    e.preventDefault();
                    return false;
                }
            }

            return true;
        }
        if (inputValue.length == 0) {
            var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
            if (Specialcharacters.test(keyValue)) {
                e.preventDefault();
                return false;
            }
            if (e.which === 32) {
                e.preventDefault();
                return false;
            }
        }
        return true;
    }


  handleChangeForPriority = (event) => {
    event.persist();
    const errPrirotyArea = document.getElementById('priorityError');
    if (isPriorityValid()) {
      this.setState({ priority: event.target && event.target.value });
      this.setState({ formValid: validateCreateActionForm() });
      errPrirotyArea.textContent = "";

    let actionID = this.props.actionDetails && this.props.actionDetails.Action_ID ? this.props.actionDetails.Action_ID : '';
    let questionID = this.props.actionDetails && this.props.actionDetails.Question_ID ? this.props.actionDetails.Question_ID : '';
    let questionTemplateID = this.props.actionDetails && this.props.actionDetails.Question_Template_ID ? this.props.actionDetails.Question_Template_ID : ''; 
      this.setState({
        logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' + actionID + ' for ' +
          questionID + ' for ' + questionTemplateID + ' with Priority ' + event.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
      });
    } else { errPrirotyArea.textContent = "** Select priority "; this.setState({ formValid: false }); }
  }

  handleChangeForStatus = (event) => {
    if(event.target.value==='6'){
     this.handlecompleteDate()
    }
    event.persist();
    const errStatusArea = document.getElementById('statusError');
    if (isStatusValid()) {
      document.getElementById(event.target.id).checked = true;
      this.setState((state, props) => ({
        status: event.target && event.target.value,

      }));
      this.setState({ formValid: validateCreateActionForm() });
      errStatusArea.textContent = "";
    } else { errStatusArea.textContent = "** Select status "; this.setState({ formValid: false }); }


  }
  handlecompleteDate=()=>{
    this.setState({
      DateClosed: getStartDate()
    })
  }

  /* A funtion to populate form values in state */
  handleChange(event) {
    const input = event.target.value;
    const name = event.target.id;
    this.setState({
      [name]: input
    });
  }

  handleNotesArea(event) {
    this.setState({ notes: event.target.value });

      if (this.state.actionId != null) {
          this.setState({
              logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' + this.props.actionDetails.Action_ID + ' for ' +
                  this.props.actionDetails.Question_ID + ' for ' + this.props.actionDetails.Question_Template_ID + ' with Comments ' + event.target.value + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
          });
      }
    var getPastedata= event.target.value;
    var splChars = "*|,\":<>[]{}`\';()@&$#%";
      if (getPastedata.length > 0) {
          var targetId = event.target.id;
        if (getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)) {
            window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
            setTimeout(function () { document.getElementById(targetId).focus(); }, 100);
    } 
}   
  }

  onSubmitAction = event => {
    
    event.preventDefault();
    if(this.props.mode === "UpdateAction"){
      if(this.state.status == '6'){
        this.state.checkCompletestatus="true";
      }
      this.state.previousStatus=this.props.actionDetails.Status;
      this.state.scoreUpdatePayload =   {
        "Dealer_ID": this.props.actionDetails.Dealer_ID,
        "Program_ID": this.props.actionDetails.Program_ID,
        "Review_ID": this.props.actionDetails.Review_ID,
        "Review_Type": this.props.actionDetails.Review_Type
    }
    }
    const headerDiv = document.getElementById('headerDiv');
    if (headerDiv) headerDiv.scrollIntoView();
    this.props.mode === "UpdateAction" ? this.props.callUpdateAction(this.props.actionId, this.state) : this.props.callCreateAction(this.state, this.state.createdBy);
    if (this.props.mode === "UpdateAction") {
        const getData = this.getAuditLogData();
        if (this.props.actionDetails.Status === "Complete") {
            this.props.question.Status = this.props.question.Responses[0].Response;
        }
      this.props.doPostAuditLogData(getData);  
    }
    this.setState({
      saveBtnClicked: true,
    });
      // if (this.props.question != null) {
      //     const Recommendations = this.props.question.Recommendations.length > 0 ? this.props.question.Recommendations : [];
      //     if (Recommendations.length > 0) {
      //         let Recommendationpayload = {
      //             "Question_Template_ID": this.props.question.Question_Template_ID,
      //             "Recommendation": this.props.question.Recommendations[0].Recommendation,
      //             "Assessment_ID": this.props.review.Assessment_Id,
      //             "User_ID": "default",
      //             "LastModifiedBy": this.props.review.LastModifiedBy,
      //             "LastModifiedOn": "2019-01-07T16:32:13.277",
      //             "CreatedBy": this.props.review.CreatedBy,
      //             "CreatedOn": "2019-01-07T16:32:13.277",
      //             "Assessment_Response_ID": this.props.question.Assessment_Response_ID,
      //             "Recommendation_Date": new Date()
      //         }
      //         if (this.props.question.Recommendations[0].Recommendation.length > 0 && this.props.question.Recommendations[0].Question_Recommendation_ID == 0) {
      //             this.props.doAddQuestionRecommendation(Recommendationpayload);
      //         }
      //         else {
      //             Recommendationpayload.Question_Recommendation_ID = this.props.question.Question_Recommendation_ID;
      //             this.props.doUpdateQuestionRecommendation(Recommendationpayload);

      //         }
             
              
             
      //     }
         
      //     //end
      // }   
    
      
      
      
    
      
        
  }

  getAuditLogData = () => {
    const payLoad = {
      AuditLogModule: "Action",
      AcionAPISource: "Update Action",
      CreatedBy: this.state.lastModifiedBy,
      CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
    }


    let updatedmsg = this.state.logMessage;
    if (this.state.addFileMessage !== "") {

      updatedmsg = updatedmsg + this.state.lastModifiedBy + ' updated Action ' + this.props.actionDetails.Action_ID + ' for ' +
        this.props.actionDetails.Question_ID + ' for ' + this.props.actionDetails.Question_Template_ID + ' with Evidence ' + this.state.addFileMessage + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'

    }
    this.setState({
      logMessage: updatedmsg
    }, function () {

      payLoad.LogMessage = this.state.logMessage
    });

    return payLoad;
  }
 
  handleStatusDueDate(due_date) {
   
    let currentdate = new Date();
    currentdate=Date.parse(currentdate);
    let dueDate= Date.parse(due_date)

    if(dueDate>currentdate){
      this.setState({ status:'4' });
    }else if(dueDate<currentdate){
      this.setState({ status:'3' });
    }
  }

  handleDueDateChange(due_date) {
   
    this.handleStatusDueDate(due_date)
    this.setState({ due_date });
    let actionID = this.props.actionDetails && this.props.actionDetails.Action_ID ? this.props.actionDetails.Action_ID : '';
    let questionID = this.props.actionDetails && this.props.actionDetails.Question_ID ? this.props.actionDetails.Question_ID : '';
    let questionTemplateID = this.props.actionDetails && this.props.actionDetails.Question_Template_ID ? this.props.actionDetails.Question_Template_ID : '';
    this.setState({
      logMessage: this.state.logMessage + this.state.lastModifiedBy + ' updated Action ' + actionID + ' for ' +
        questionID + ' for ' + questionTemplateID + ' with Due Date ' + due_date + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.'
    });
  }

  /* function to display the error message */
  errorMessage = (msg) => {
    const errorDetails = (
      <div className="alert alert-danger alert-dismissible" role="alert">
        <button type="button" id="messageButton" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.hideAlert()}>
          <span aria-hidden="true">&times;</span>
        </button>
        {msg}
      </div>
    );


    return errorDetails;
  }

  errorSubmitMessage = () => {
    if (this.state.showAlert && (this.props.submitError != null || (this.props.submitStatus.Success !== undefined && !this.props.submitStatus.Success))) {
      return this.errorMessage(`${this.props.mode === "UpdateAction" ? "Update" : "Create"} action failed.`);
    }
   }

  /* function to display the success message */
  successMessage() {
    const successMsg = (
      <div className="alert alert-success alert-dismissible" role="alert">
        <button type="button" id="messageButton" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.hideAlert()}><span aria-hidden="true">&times;</span></button>
        Action
        {' '}
        {this.props.mode === "UpdateAction" ? "updated" : "created"}
        {' '}
        successfully.
      </div>
    );
   
    

  }

  autoClose = () => {

    if (this.props.submitStatus.Success) {
      setTimeout(() => {
        this.props.close()
      }, 1000);
    }
  }

  handleUploadLink(e) {
    e.preventDefault()
    document.getElementById("uploadDocument").click();
  }
 
  handleFileUpload = (event) => {
    event.preventDefault();
    // event.persist();
    const fileObjectUpload = validateUploadedFile(event);
    const existingMap = this.state.selectedFileForUpload || new Map();
    if (fileObjectUpload !== undefined) {
      const fileBasedata = "";
      if( ( /image/i ).test( fileObjectUpload.type ) ) {
        var $this = this;
        var reader = new FileReader();
        reader.readAsArrayBuffer(fileObjectUpload);
      
        reader.onload = function (event) {
          // blob stuff
          var blob = new Blob([event.target.result]); // create blob...
          window.URL = window.URL || window.webkitURL;
          var blobURL = window.URL.createObjectURL(blob); // and get it's URL
          
          // helper Image object
          var image = new Image();
          image.src = blobURL;
          //preview.appendChild(image); // preview commented out, I am using the canvas instead
          image.onload = function() {
            // have to wait till it's loaded
            var file = compressImg(image); // send it to canvas
            const arr = file.split("base64,")

            const simpleBase64 = arr[1];

            // if(!existingMap.has(fileObjectUpload.name)) {
            existingMap.set(new String(fileObjectUpload.name), [simpleBase64, fileObjectUpload]);
            let input = document.getElementById('uploadDocument');
            input.value = null;
            $this.setState({
              selectedFileForUpload: existingMap,
              fileUploadValid: true
            });
          }
        };
      } else {
        getBase64(fileObjectUpload).then((el) => {
          const arr = el.split("base64,")
  
          const simpleBase64 = arr[1];
  
          // if(!existingMap.has(fileObjectUpload.name)) {
          existingMap.set(new String(fileObjectUpload.name), [simpleBase64, fileObjectUpload]);
          let input = document.getElementById('uploadDocument');
          input.value = null;
          this.setState({
            selectedFileForUpload: existingMap,
            fileUploadValid: true
          });  
        });
      }

    }

    this.state.filesList.push(event.target.files[0].name);
    this.setState({
      addFileMessage: this.state.filesList.join(', '),
    });

    // event.persist();
  }

  handleFileDelete = (event, fileName) => {
    event.preventDefault();

    const existingMap = this.state.selectedFileForUpload;
    if (existingMap && existingMap.has(fileName)) {
      existingMap.delete(fileName);
      this.setState({
        selectedFileForUpload: existingMap,
        fileUploadValid: true
      });
    }
    // event.preventDefault();
  }
  isIE = () => {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }

  findStatusDisable = (status) => {
    let result = false
    let actionStatus = this.props.actionDetails && this.props.actionDetails.Status

    switch (status) {

      case 'inprogress':
        if (actionStatus == "In Progress" && this.state.status == '3') {
          result = true;
        }
        else if (actionStatus == 'Complete') {
          result = true;
        } else if (actionStatus == 'Overdue' && this.state.status=='3') {
          result = true;
        }else if(this.state.status=='4'||this.state.status=='5'){
          result = false;
        }
        else if(this.state.status=='3'){
          result = true;
        }
        break;

      case 'complete':
        if (actionStatus == 'Complete') {
          result = false;
        } else if (window.location.href.indexOf("review9") > -1 && actionStatus != 'Complete') {
          result = true;
        }
        break;

      case 'overdue':
        if (actionStatus == 'Overdue' || this.state.status == '3') {
          result = false
        } else if(this.state.status == '4') {
          result = true
        }
        else{result = true}
    }

    return result
  }

  handleFileDownload = (event, fileName, fileData) => {
    event.preventDefault();
    const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (this.props.mode === 'UpdateAction' && isIOS) {
      this.onSubmitAction(event);

    }
    const existingMap = this.state.selectedFileForUpload;
    if (existingMap && existingMap.has(fileName)) {
      /** code to download a file */

      if (window.navigator && window.navigator.msSaveOrOpenBlob && !fileData.BlobURI) { // for IE
        window.navigator.msSaveOrOpenBlob(fileData, fileName.toString());
      } else {

        window.URL = window.URL || window.webkitURL;
        const url = fileData.BlobURI || window.URL.createObjectURL(fileData);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        if (is.safari() || this.isIE()) {
          a.target = '_blank';
        }
        a.click();
      }


    }
  }
  manageForIOSDevice = (fileName, fileData, counter) => {
    let elem = '';
    const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
    if (isIOS) {
      if (this.props.mode === 'CreateAction' || !fileData.BlobURI) {
        elem = '';
      } else {
        elem = <a href="#" className="m-r-20" id={`download-file-${counter}__action-link"`} download={fileName} onClick={(event) => { this.handleFileDownload(event, fileName, fileData) }}><img src="./../images/icon-download.svg" alt="Download" /></a>
      }
    } else {
      elem = <a href="#" className="m-r-20" id={`download-file-${counter}__action-link"`} download={fileName} onClick={(event) => { this.handleFileDownload(event, fileName, fileData) }}><img src="./../images/icon-download.svg" alt="Download" /></a>

    }
    return elem
  }
  displayDocumentList = (selectedFileForUpload) => {
    const finalList = [];
    if (selectedFileForUpload) {
      let counter = 0;
      for (const obj of selectedFileForUpload) {
        const [fileName, [simpleBase64, fileData]] = obj;
        finalList.push(<li key={fileName.toString()}>
          <div className="filename" title={fileName.toString()}>{fileName.toString()}</div>
          <div className="document-actions float-right">
            {this.manageForIOSDevice(fileName, fileData, counter)}
            <a href="#" id={`delete-file-${counter}__action-link"`} onClick={(event) => { this.handleFileDelete(event, fileName) }}><img src="./../images/icon-delete.svg" alt="Delete" /></a>
          </div>
        </li>)

        counter++;
      }
    }
    return finalList;

  }
  canBeSubmitted = () => {
    //check ActionPlan and description
    let checkfield = this.state.ActionPlan && this.state.ActionPlan.length > 0 && this.state.description && this.state.description.length > 0
    let result = (this.state.ActionPlan && this.state.ActionPlan.length > 0 && this.state.description && this.state.description.length > 0) ? false : true
    
    //if its not first review for same type and location so enable update
    // if (this.props.notFirstReview && this.props.reviewStatus !== 'Completed') {
    //   result = false
    // }
     if (this.props.reviewStatus === 'Completed'||this.props.actionDetails && this.props.actionDetails.Status==='Complete') {
      result = true
    }
    //if its action page and action status is complete
    else if (window.location.href.indexOf("review9") === -1 && this.state.status == "6") {
      result = (checkfield && this.state.notes_area && this.state.notes_area.length > 0 ? false : true)
    }
    //if its review page and action status is compelte
    else if (window.location.href.indexOf("review9") > -1 && this.state.status == "6") {
      result = true
    }
 
    return result
  }

  render() {
    const assignToOptions = dropdownOptionsAction(this.props.assignToDropDownList, "id", "assignTo", null);
    const actionDet = this.props.actionDetails;
    const mLoadValue = this.props.mode === "UpdateAction" && (this.state.title === undefined || this.state.title === null);
    const isReadOnlyMode = (this.props.actionDetails && this.props.actionDetails.ActiveDealer) || (this.props.review && this.props.review.ActiveDealer);
    const cannotComplete = (this.props.actionDetails && localStorage.getItem('userType') === 'Dealer Use' && this.props.actionDetails.UserType !== 'Dealer');
    const checkTFL= (this.props.review && this.props.review.Program==='TFL')||(this.props.actionDetails && this.props.actionDetails.Program==='TFL')

    const EnableUpdateAction =  checkTFL && this.canBeSubmitted();
    const checkPathName= this.props.actionDetails && this.props.actionDetails.Program==='TFL' && window.location.href.indexOf("review9") > -1
    const disableActionForCompleteAssesment= this.props.actionDetails && this.props.actionDetails.Program==='TFL' && this.props.actionDetails && this.props.actionDetails.AssessmentStatus==='Completed' && window.location.href.indexOf("review9") > -1
    const disableActionForCompleteAssesmentexceptions= this.props.actionDetails && this.props.actionDetails.Program==='TFL' && this.props.actionDetails && this.props.actionDetails.AssessmentStatus==='Completed' 
    // const diableUpdateActionButtonTFL=this.props.actionDetails && this.props.actionDetails.Program==='TFL' && this.props.actionDetails.AssessmentStatus==='Completed' && window.location.href.indexOf("review9") > -1
    const disableNote=checkTFL && (this.props.mode === 'CreateAction' || window.location.href.indexOf("review9") > -1 || this.state.status !=="6")
    // const disableUpdateNote= checkTFL &&  this.state.status =="6"?this.state.notes_area && this.state.notes_area.length>0?false:true:''
    // const disableUpdateButton= this.props.notFirstReview? false: (disableUpdateNote || !(isEnabled && this.state.formValid && isReadOnlyMode)||diableUpdateActionButtonTFL || this.props.actionDetails && this.props.actionDetails.Status==="Complete")
    const NotFirstReview=  checkTFL && this.props.notFirstReview && this.props.reviewStatus==='Completed' ;
    const disableField = this.props.notFirstReview?NotFirstReview :disableActionForCompleteAssesment
    const disableDueDate= this.props.notFirstReview?NotFirstReview :(!isReadOnlyMode || disableActionForCompleteAssesmentexceptions?true:false)
    return (


      <main role="main" className="main-wrapper popup">
        <div className="main-content row">
          <div className="popup__wrapper popup__wrapper-action align-self-center">
            <div className="popup__header" id="headerDiv">
              <h5>{this.props.mode === "UpdateAction" ? `Update Action` : `Create Action`}</h5>
            </div>
            {<LoaderModal mLoad={mLoadValue} />}
            {this.successMessage()}
            {this.errorSubmitMessage()}
            {this.autoClose()}
            {this.props.AssigneeFetchError !== undefined && this.props.AssigneeFetchError !== null ?
              this.errorMessage("Oops!! unable to fetch assignees.") : null}
            <div className="popup__body">
              <p className="text-small">Question</p>
              <p className="text-black text-medium" id="question">
                {(this.props.question && this.props.question.Method_Text) || (actionDet && actionDet.Method_Text)}

                {this.props.question &&
                  this.props.question.Method_List.length > 0 && (
                    <ul>
                      {(this.props.question.Method_List || []).map(list => (
                        <li>
                          {list}
                        </li>))}
                    </ul>
                  )}

              </p>
              <br />
              <form>

                <div className="form-row">
                  <div className="form-group col-md-7">
                    <label htmlFor="location" id="location__action-label">Location</label>
                    <p className="text-black text-medium" id="location__action-text">{this.props.locationName || (actionDet && actionDet.Dealer_Name)}</p>
                  </div>
                  <div className="form-group col-md-5">
                    <label htmlFor="creator" id="creator__action-label">Creator</label>
                    <p className="text-black text-medium" id="creator__action-text">{this.props.mode === "UpdateAction" ? this.state.createdBy : this.props.currentUser}</p>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="title" id="title__action-label">
                    Title
                    <span className="required">*</span>
                  </label>
                  <span className="float-right text-italic text-11 text-light-gray">
                    {(this.state.title && this.state.title.length) || 0}
                    /100 characters
                  </span>

                                <input  disabled id="title"  tabIndex="1" className={isReadOnlyMode ? "form-control" : "form-control disabled-review"} type="text" name="title" placeholder={this.state.title} maxLength="100" onKeyPress={this.onKeyPress} onChange={(e) => { this.handleChange(e); this.handleTitleField(e); this.editTtitle(e)  }} value={(this.props.question && this.props.question.Method_Text) || (actionDet && actionDet.Method_Text)}
                    onBlur={(e) => this.fetchTitleValue(e)} />
                  <div id="titleError" style={fieldErrorDiv} />
                </div>
                <div className="form-group">
                  <label htmlFor="description" id="description__action-label">Observation</label>
                  <span className="required">*</span>
                  <span className="float-right text-italic text-11 text-light-gray">
                    {(this.state.description && this.state.description.length) || 0}
                    /1000 characters
                  </span>
                  <textarea disabled={disableField} id="description" tabIndex="2" className={isReadOnlyMode ? "form-control" : "form-control disabled-review"} name="description" placeholder="Action Observation" maxLength="1000"  onKeyPress={this.onKeyPress} onChange={(e) => { this.handleChange(e); this.handleDescField(e); }} value={this.state.description} onBlur={(e) => this.fetchDescriptionValue(e)} />
                  <div id="descriptionError" style={fieldErrorDiv} />
                </div>
                
                {/* // action plan */}
                <div className="form-group">
                  <label htmlFor="Action_plan" id="ActionPlan__action-label">Action Plan</label>
                  <span className="required">*</span>
                  <span className="float-right text-italic text-11 text-light-gray">
                    {(this.state.ActionPlan && this.state.ActionPlan.length) || 0}
                    /1000 characters
                  </span>
                  <textarea disabled={disableField} id="ActionPlan" tabIndex="2" className={isReadOnlyMode ? "form-control" : "form-control disabled-review"} name="ActionPlan" placeholder="Action Plan " maxLength="1000"  onKeyPress={this.onKeyPress} onChange={(e) => { this.handleChange(e); this.handleActionPlan(e); }} value={this.state.ActionPlan} onBlur={(e) => this.fetchActionPlan(e)} />
                  
                  <div id="descriptionError" style={fieldErrorDiv} />
                </div>

                <div className="form-group">
                  <label htmlFor="assign_to" id="assign-to__action-label">
                    {checkTFL?'Created By':'Assignee' }
                    <span className="required">*</span>
                  </label>
                  <select className={isReadOnlyMode ? "custom-select" : "custom-select disabled-review"} tabIndex="3" name="assign_to" id="assign_to" onChange={(eve) => this.handleAssignToDropdown(eve, this.props.locationsDropDownList)} defaultValue={checkTFL && this.props.mode === "UpdateAction" ? this.state.createdBy:''} value={this.state.assign_to} disabled={checkTFL}>
                    <option id="assignTo-" value="" disabled>{checkTFL?this.state.createdBy:'Select Assignee'}</option>
                    {assignToOptions}
                  </select>
                </div>

              { !checkTFL &&  (
              <div className="form-group">
                  <label id="priority__action-label">
                    Priority
                    <span className="required">*</span>
                  </label>
                  <div id="priority" className={isReadOnlyMode ? "priority-buttons-wrapper clearfix" : "priority-buttons-wrapper clearfix disabled-review"} onChange={(e) => this.handleChangeForPriority(e)}>
                    <div className="form-check">
                      <input className="form-check-input first" type="radio" name="priority" id="priority1" value="High" onChange={(e) =>  this.handleChangeForPriority(e)} checked={this.state.priority === "High"} disabled={disableActionForCompleteAssesmentexceptions && !(this.state.priority === "High")}/>
                      <label tabIndex="4" className="form-check-label" htmlFor="priority1">High</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="priority" id="priority2" value="Medium" onChange={(e) =>   this.handleChangeForPriority(e)} checked={this.state.priority === "Medium"}  disabled={disableActionForCompleteAssesmentexceptions && !(this.state.priority === "Medium")}/>
                      <label tabIndex="5" className="form-check-label" htmlFor="priority2">Medium</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input last" type="radio" name="priority" id="priority3" value="Low" onChange={(e) =>   this.handleChangeForPriority(e)} checked={this.state.priority === "Low"} disabled={disableActionForCompleteAssesmentexceptions && !(this.state.priority === "Low")} />
                      <label tabIndex="6" className="form-check-label" htmlFor="priority3">Low</label>
                    </div>
                  </div>
                  <div id="priorityError" style={fieldErrorDiv} />
                </div>
                  )
               } 
                <div className="form-group">
                  <label id="status__action-label" >
                    Status
                    <span className="required">*</span>
                  </label>
                  <div id="status" className={`status-buttons-wrapper clearfix ${isReadOnlyMode ? "" : "disabled-review"} ${cannotComplete ? "hide-complete" : ""} `} onChange={(e) => this.handleChangeForStatus(e)}>
                                    {/* <div className="form-check">
                      <input className="form-check-input first" type="radio" name="status" id="status1" value="4" onChange={(e) => this.handleChangeForStatus(e)} checked={this.state.status == "4"} />
                      <label tabIndex="7" className="form-check-label" htmlFor="status1">Not Started</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input second" type="radio" name="status" id="status2" value="5" onChange={(e) => this.handleChangeForStatus(e)} checked={this.state.status == "5"} disabled={this.props.mode === "CreateAction"} />
                      <label tabIndex="8" className="form-check-label" htmlFor="status2">In Progress</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input third" type="radio" name="status" id="status3" value="6" onChange={(e) => this.handleChangeForStatus(e)} checked={this.state.status == "6"} disabled={this.props.mode === "CreateAction"} />
                      <label tabIndex="9" className="form-check-label completeStatus" htmlFor="status3">Complete</label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input last" type="radio" name="status" id="status4" value="3" checked={this.state.status == "3"} disabled={this.props.mode === "CreateAction"} />
                      <label tabIndex="10" className="form-check-label" htmlFor="status4">Overdue</label>
                    </div> */}
                                    <div className="form-status-check">
                                        <input className="form-check-input first" type="radio" name="status" id="status1" value="5" onChange={(e) => this.handleChangeForStatus(e)} checked={this.state.status == "5" || this.state.status == "4"} disabled={ this.findStatusDisable('inprogress')}/>
                                        <label tabIndex="7" className="form-check-label" htmlFor="status1">In Progress</label>
                                        {/* this.props.mode==="UpdateAction" && this.props.actionDetails && this.props.actionDetails.Program==='TFL' && !(enableStatusthis.state.status == "5" ||this.state.status == "4"|| this.state.status == "6")  */}
                                    </div>
                                    {/* <div className="form-check">
                      <input className="form-check-input second" type="radio" name="status" id="status2" value="5" onChange={(e) => this.handleChangeForStatus(e)} checked={this.state.status == "5"} disabled={this.props.mode === "CreateAction"} />
                      <label tabIndex="8" className="form-check-label" htmlFor="status2">In Progress</label>
                    </div> */}
                                    <div className="form-status-check">
                                      <input className="form-check-input third" type="radio" name="status" id="status2" value="6" onChange={(e) => this.handleChangeForStatus(e)} checked={this.state.status == "6"} disabled={ this.findStatusDisable('complete') } />
                                      {/* disabled={(this.props.mode === "CreateAction") ||this.props.actionDetails && this.props.actionDetails.Program==='TFL' && (!(this.state.status == "6") && checkPathName) */}
                                      <label title={checkPathName?"Please go to the ‘Actions’ tab to close this Acton.":""} tabIndex="9" className="form-check-label completeStatus" htmlFor="status2">Complete</label>
                                    </div>
                                    <div className="form-status-check">
                                        <input className="form-check-input last" type="radio" name="status" id="status3" value="3" checked={this.state.status == "3"} disabled={this.findStatusDisable('overdue')} />
                                        {/* this.props.mode === "CreateAction" ||this.props.actionDetails && this.props.actionDetails.Program==='TFL' && !(this.state.status == "3") */}
                                        <label tabIndex="10" className="form-check-label" htmlFor="status3">Overdue</label>
                                    </div>

                  </div>
                  <div id="statusError" style={fieldErrorDiv} />
                </div>
                <div className="form-row">
                  <div className="form-group col-6 creation-date">
                    <label htmlFor="creation_date" id="creation-date__action-label">Creation Date</label>
                    <DateTimePicker tabIndex="11"
                      inputProps={{
                        component: props => <input id="creation-date__action-input" {...props} readOnly />
                      }}
                      onChange={creation_date => this.setState({ creation_date: new Date(Moment(creation_date, "DD-MM-YYYY")) })}
                      dropDown
                      disabled
                      // min={getStartDate()}
                      time={false}
                      format="DD-MM-YYYY"
                      value={new Date(this.state.creation_date)}
                    />
                  </div>
                  <div className="form-group col-6 due-date" >
                    <label htmlFor="due_date" id="due-date__action-label">
                      Due Date
                      <span className="required">*</span>
                    </label>
                    <DateTimePicker tabIndex="12"
                      disabled={disableDueDate}
                      id="due_date"
                      inputProps={{
                        component: props => <input id="due-date__action-input" {...props} readOnly />
                      }}
                      onChange={due_date => this.handleDueDateChange(due_date)}
                      dropDown
                      // min={getStartDate()}
                      time={false}
                      format="DD-MM-YYYY"
                      value={new Date(this.state.due_date)}
                     
                    />
                  </div>
                </div>
                <div className={isReadOnlyMode ? "evidence-upload" : "evidence-upload disabled-review"}>
                  <p className="text-black" id="closing-Comment-Evidence">{checkTFL?'Action Item Closing Comment and Evidence':'Evidence and Notes'}</p>
                  <input tabIndex="13"
                    type="file"
                    id="uploadDocument"
                    encType="multipart/form-data"
                    onChange={this.handleFileUpload.bind(this)}
                    /* onClick={(event)=> {event.target.value = null}} */
                    hidden
                  />
                  <p className="m-b-20">
                    <a className="upload-document-link" id="upload__action-link" href="#" onClick={this.handleUploadLink.bind(this)}>
                      <img className="m-r-10" src="./../images/upload.svg" alt="Upload" />
                      Upload Supporting Documents
                    </a>
                    {this.state.selectedFileForUpload && this.state.selectedFileForUpload.size > 0 &&
                      <a className="add-more-link" href="#" id="upload-more__action-link" onClick={this.handleUploadLink.bind(this)}>+ Add more</a>}
                  </p>
                  <div id="uploadfileerror" style={fieldErrorDiv} />
                  <ul className="list-unstyled uploaded-documents-list">
                    {this.displayDocumentList(this.state.selectedFileForUpload)}
                  </ul>
                  <div className="form-group m-b-0 relative p-t-25">
                  {/* disableActionForCompleteAssesment */}
                  {checkTFL && <label htmlFor="description" id="closeAction__action-label">What was done to close the action item</label>}
                  {checkTFL && this.state.status == "6"  && <span className="required">*</span>}
                 <textarea className="textarea-noresize form-control" id="notes_area" disabled={disableNote} maxLength="1000" placeholder={checkTFL?"":'Notes'} value={this.state.notes_area}  onKeyPress={this.onKeyPress} onChange={(e) => { this.handleChange(e) }} onBlur={(e) => this.handleNotesArea(e)} />
                    <span className="text-limit-evidence float-right text-italic text-11 text-light-gray">
                      {(this.state.notes_area && this.state.notes_area.length) || 0}
                      /1000 characters
                    </span>
                    <div id="notesError" style={fieldErrorDiv} />
                  </div>
                </div>
                <label>
                  <span className="required">*</span>
                  {' '}
                  Mandatory fields
                </label>
           { checkTFL && this.props.actionDetails && this.props.actionDetails.Status_ID===6 &&   
           (<div className="form-group col-6 " >
                    <label htmlFor="DateClosed" id="DateClosed__action-label">
                      This Action was marked complete on
                    </label>
                    <DateTimePicker tabIndex="12"
                      disabled={true}
                      id="due_date"
                      inputProps={{
                        component: props => <input id="due-date__action-input" {...props} readOnly />
                      }}
                     
                     
                    
                      time={false}
                      format="DD-MM-YYYY"
                      value={new Date(this.state.DateClosed!==null?this.state.DateClosed:'')}
                     
                    />
                  </div>)}
                <div className="popup__button-wrapper">
                  <button id="submit-btn" className="btn btn-primary" type="button" disabled={ EnableUpdateAction} onClick={this.onSubmitAction}>{this.props.mode === "UpdateAction" ? `Update Action` : `Create Action`}</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  assignToDropDownList: getAssignToList(state),
  AssigneeFetchError: state.assignToListState.error,
  actionsList: getActionsList(state),
  submitStatus: getActionsSubmitStatus(state),
  submitError: state.createUpdateActionState.error,
  actionDetails: state.createUpdateActionState.actionDetails ?
    state.createUpdateActionState.actionDetails.returnData : null,// getFetchActionData (state),
    currentUser: getCurrentUserDetails(),
});

const mapDispatchToProps = dispatch => ({
  onFetchAssignToList: query => dispatch(doFetchAssignToList(query)),
  callCreateAction: (formData) => dispatch(doCreateAction(formData)),
  callUpdateAction: (actionId, formData) => dispatch(doUpdateAction(actionId, formData)),
  callCreateUpdateActionError: error => dispatch(doCreateUpdateActionError(error)),
  onFetchDropDowns: (reviewType) => dispatch(doFetchScheduleDropDowns(reviewType)),
  fetchActionDetails: (actionId) => dispatch(doFetchActionDataById(actionId)),
  clearData: (dataList) => dispatch(doClearData(dataList)),
  showModalLoader: () => dispatch(doShowModalLoader()),
  hideModalLoader: () => dispatch(doHideModalLoader()),
  doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
  doAddQuestionRecommendation: (payload) => dispatch(doAddQuestionRecommendation(payload)),
  doUpdateQuestionRecommendation: (payload) => dispatch(doUpdateQuestionRecommendation(payload)),    
  onCompleteReviewStatus: (payload) => dispatch(doCompleteReview(payload)),
  doFetchUpdateScoreData: (payload) => dispatch(doFetchSectionScoreData(payload)),

});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateManageAction);

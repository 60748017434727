import {
    FETCH_START_REVIEW,
    FETCH_START_REVIEW_SUCCESS,
    FETCH_START_REVIEW_ERROR
} from '../constants/actionTypes';

const INITIAL_STATE = {
    startReviewData:'',
    startReviewError:''
}

function startReviewReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_START_REVIEW : {
            return {...state}
        }
        case FETCH_START_REVIEW_SUCCESS : {
            return {...state,startReviewData:action.payload}
        }

        case FETCH_START_REVIEW_ERROR : {
            return {...state,startReviewError:action.payload.error}
        }

        default : return state;
    }
}

export default startReviewReducer;
/**
 * Created by mohdshaban on 22/06/19.
 */

import {
    FETCH_USER_ACCESS_ROLES,
    FETCH_USER_ACCESS_ROLES_SUCCESS,
    FETCH_USER_ACCESS_ROLES_ERROR,
    SET_SECURED
} from '../constants/actionTypes';

export  const fetchUserAccessRoles = () => ({
    type: FETCH_USER_ACCESS_ROLES
})

export  const fetchUserAccessRolesSuccess = (payload) => ({
    type: FETCH_USER_ACCESS_ROLES_SUCCESS,
    payload
})

export const setForSecured = () => ({
    type: SET_SECURED
})

export  const fetchUserAccessRolesError = (payload) => ({
    type: FETCH_USER_ACCESS_ROLES_ERROR,
    payload
})


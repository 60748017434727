import axios  from 'axios';
import {showToast} from '../actions/toast';
import { getToken } from '../utils/utils';

export default {
    setupInterceptors: (store) => {

        // Add a response interceptor
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
              } else {
                  store.dispatch(showToast({show:true ,message: "Some data failed to load. Please refresh your browser." }));
                // handle error
              }

            return Promise.reject(error);
        });
        axios.defaults.headers.common = {
            "Ocp-Apim-Subscription-Key": `${process.env[`REACT_APP_APIM_KEY_${window.location.hostname}`]}`
        };
        axios.interceptors.request.use(
            config => {
                const token = getToken();
                
                if (token) {
                    if (config.url.indexOf('okta') > -1) {
                        config.headers.Authorization = `SSWS ${process.env[`REACT_APP_OKTA_APIKEY_${window.location.hostname}`]}`;
        
                    } else {
                        config.headers.Authorization = `Bearer ${token}`;
                        
                    }
        
                }
        
                return config;
            },
            error => Promise.reject(error)
        );

    }
};
import _ from 'lodash';

export const getReadableScores = ({ scoreState }) => {
    return scoreState.scores;
};

export const getFetchScoreError = ({ scoreState }) => {
    return scoreState.error;
}

export const getScorePaginationDetails = ({ scoreState, filterState }) => {
    return ({
        totalCount: scoreState.count,
        activePage: filterState.activePage
    })
}

export const getTflScoreList = ({ scoreState }) => {
    return scoreState.scoreListData;
};

export const getTflSapScoreList = ({ tflscoreState }) => {
    return tflscoreState.tflscoreListData;
};

export const getTflBreakDownScoreList = ({ scoreState }) => {
    return scoreState.breakDownScoreListData;
};

export const getScoreOverrideResponse = ({ scoreState }) => {
    return scoreState.scoreOverrideObj;
};

export const fetchSavedScoreOverrideResp = ({ scoreState }) => {
    return scoreState.saveResponse;
};

export const fetchDeleteScoreOverrideResp = ({ scoreState }) => {
    return scoreState.deleteResponse;
};

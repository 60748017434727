import _ from 'lodash';

export const getInvestigationListError = ({ investigationState }) => {
    return investigationState.error;
}

export const getInvestigationRecordsData = ({ investigationState }) => {
    return investigationState.investigationList;
};

export const getInvestigationRecordsCount = ({ investigationState }) => {
    return investigationState.investigationListCount;
};

export const getPaginationDetails = ({ investigationState, filterState }) => {
    return ({
        totalCount: investigationState.investigationListCount,
        activePage: filterState.activePage
    })
}

export const getInvestigationCreateError = ({ investigationState }) => {
    return investigationState.error;
}

export const getInvestigationCreateData = ({ investigationState }) => {
    return investigationState.createInvestigationObj;
};

export const fetchNewlySavedInvestigationResp = ({ investigationState }) => {
    return investigationState.saveResponse;
};

export const getLookUpStatusData = ({ investigationState }) => {
    return investigationState.lookUpStatusObj;
};

export const getLookUpStageData = ({ investigationState }) => {
    return investigationState.lookUpStageObj;
};

export const getLookUpStatusError = ({ investigationState }) => {
    return investigationState.error;
};

export const getLookUpStageError = ({ investigationState }) => {
    return investigationState.error;
};

export const fetchDeleteInvestigationResp = ({ investigationState }) => {
    return investigationState.deleteResponse;
};

export const fetchUpdateInvestigationResp = ({ investigationState }) => {
    return investigationState.saveUpdateResponse;
};


import {
    SHOW_TOAST,
    HIDE_TOAST
} from '../constants/actionTypes';

const INITIAL_STATE = {
    toast :{
        show:false,
        message:'',
        showSuccess: false
    }
}

function toastReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SHOW_TOAST : {
            const toast = action.payload
            return {...state,toast}
        }
        case HIDE_TOAST : {
            const toast = action.payload
            return {...state,toast}
        }

        default : return state;
    }
}

export default toastReducer;
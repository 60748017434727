import {
  SCORE_STORIES_ADD,
  SCORE_STORIES_FETCH,
  SCORE_STORIES_FETCH_ERROR,
  ADD_SCORE_LIST,
    FETCH_SCORE_LIST,
  SCORE_LIST_ERRORS,
  BREAKDOWN_SCORE_LIST_ERRORS,
  FETCH_BREAKDOWN_SCORE_LIST,
    ADD_BREAKDOWN_SCORE_LIST,

    TFL_SCORE_STORIES_ADD,
    TFL_SCORE_STORIES_FETCH,
    TFL_SCORE_STORIES_FETCH_ERROR,
    FETCH_TFL_SCORE_LIST,
    TFL_SCORE_LIST_ERRORS,
    ADD_TFL_SCORE_LIST,

  APPROVE_STATUS,
  RESET_STATUS,
  FETCH_SCOREOVERRIDE_DATA,
  ADD_SCOREOVERRIDE_DATA,
  DELETE_OVERRIDE_FILES,
  SAVE_OVERRIDE_DATA,
  SAVE_SCOREOVERRIDE_RESPONSE,
  DELETE_SCOREOVERRIDE_RESPONSE
} from '../constants/actionTypes';


//export const doAddTFLScoreStories = (scores, count, deviceType) => ({
//    type: SCORE_STORIES_ADD,
//    scores,
//    count,
//    deviceType
//});

//export const doFetchTFLScoreStories = reviewType => ({
//    type: TFL_SCORE_STORIES_FETCH,
//    reviewType,
//});

export const doFetchErrorTFLScoreStories = error => ({
    type: TFL_SCORE_STORIES_FETCH_ERROR,
    error,
});

export const doAddTFLScoringList = (scoreListData, count, deviceType) => ({
    type: ADD_TFL_SCORE_LIST,
    scoreListData,
    count,
    deviceType
});

export const doFetchScoreTFLList = (reviewType, payload) => ({
    type: FETCH_TFL_SCORE_LIST,
    reviewType,
    payload
});

export const doFetchTFLScoringErrors = error => ({
    type: TFL_SCORE_LIST_ERRORS,
    error,
});


export const doAddScoreStories = (scores, count, deviceType) => ({
  type: SCORE_STORIES_ADD,
  scores,
  count,
  deviceType
});

export const doAddTFLScoreStories = (scores, count, deviceType) => ({
    type: TFL_SCORE_STORIES_ADD,
    scores,
    count,
    deviceType
});

export const doFetchScoreStories = reviewType => ({
  type: SCORE_STORIES_FETCH,
  reviewType,
});

export const doFetchTFLScoreStories = reviewType => ({
    type: TFL_SCORE_STORIES_FETCH,
    reviewType,
});

export const doFetchErrorScoreStories = error => ({
  type: SCORE_STORIES_FETCH_ERROR,
  error,
});


export const doFetchTFLErrorScoreStories = error => ({
    type: TFL_SCORE_STORIES_FETCH_ERROR,
    error,
});

export const doAddScoringList = (scoreListData, count, deviceType) => ({
  type: ADD_SCORE_LIST,
  scoreListData,
  count,
  deviceType
});

export const doFetchScoreList = (reviewType, payload) => ({
  type: FETCH_SCORE_LIST,
  reviewType,
  payload
});

export const doFetchScoringErrors = error => ({
  type: SCORE_LIST_ERRORS,
  error,
});

export const fetchTflBreakdownScoreList = (reviewType, paramObj) => ({
  type: FETCH_BREAKDOWN_SCORE_LIST,
  reviewType,
  paramObj
});
export const doAddTflBreakdownScoreList = (breakDownScoreListData, count, deviceType) => ({
  type: ADD_BREAKDOWN_SCORE_LIST,
  breakDownScoreListData,
  count,
  deviceType
});

export const doFetchBreakdownScoreErrors = error => ({
  type: BREAKDOWN_SCORE_LIST_ERRORS,
  error,
});

export const doApproveStatus = (payload) => ({
  type: APPROVE_STATUS,
  payload

})
export const doResetStatus = (payload) => ({
  type: RESET_STATUS,
  payload

})

export const doFetchScoreOverrideData = query => ({
  type: FETCH_SCOREOVERRIDE_DATA,
  query,
});

export const doAddScoreOverriedData = (scoreOverrideObj) => ({
  type: ADD_SCOREOVERRIDE_DATA,
  scoreOverrideObj,
});

export const doDeleteOverrideFiles = payload => ({
  type: DELETE_OVERRIDE_FILES,
  payload
})

export const doSaveOverrideData = payload => ({
  type: SAVE_OVERRIDE_DATA,
  payload
})

export const saveScoreOverrideResp = (saveResponse) => ({
  type: SAVE_SCOREOVERRIDE_RESPONSE,
  saveResponse,
})

export const deleteRespScoreOverride = (deleteResponse) => ({
  type: DELETE_SCOREOVERRIDE_RESPONSE,
  deleteResponse,
})
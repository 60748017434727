import {
    debug
} from "util";
import {
    statusMap,
    getUserDetails,
    createDateAsUTC
} from "../constants/utilityfunctions";

const getAssignToList = ({
    dropdownState, createUpdateActionState, reviewState
}) => {

        let newList = [];
        const groupId = createUpdateActionState.actionDetails ? createUpdateActionState.actionDetails.groupid : 
         (reviewState.review ? reviewState.review.groupId : '');
        const dealerCode = createUpdateActionState.actionDetails && createUpdateActionState.actionDetails.returnData ? parseInt(createUpdateActionState.actionDetails.returnData.Dealer_Code) : 
        reviewState.review ? reviewState.review.Dealer_Code : '';
        const sapCode = createUpdateActionState.actionDetails && createUpdateActionState.actionDetails.returnData ? parseInt(createUpdateActionState.actionDetails.returnData.Sap_Code) : 
        reviewState.review ? reviewState.review.Sap_Code : '';
        dropdownState.oktaUsers && dropdownState.oktaUsers.map((currentUser) => {
            if (currentUser.groupIds.indexOf(groupId) !== -1 || (parseInt(currentUser.SAPCode) === parseInt(dealerCode)) || (parseInt(currentUser.SAPCode) === parseInt(sapCode))) {
                newList.push({
                    "Id": getUserDetails(currentUser),
                    "Name": getUserDetails(currentUser),
                    "Email": currentUser.email
                })
            }
        });
        return newList;

}

const getActionsList = ({
    createUpdateActionState
}) => {
    const actionsList = createUpdateActionState.actionsList;
    return actionsList;
}

const getActionIdQuery = (action) => {
    const actionId = action.actionId;
    const query = `action_id=${actionId}`;
    return query;
}

const getUpdateActionIdQuery = (action) => {
    const actionId = action.actionId;
    const query = `/${actionId}`;
    return query;
}

const getActionsSubmitStatus = (state) => {

    if (!(state && state.createUpdateActionState && state.createUpdateActionState.actionData && state.createUpdateActionState.actionData.data)) {
        return "";
    }
    return state.createUpdateActionState.actionData.data;
}

const getFetchActionData = (state) => {

    if (!(state && state.createUpdateActionState && state.createUpdateActionState.actionDetails && state.createUpdateActionState.actionDetails.data)) {
        return "";
    }
    return state.createUpdateActionState.actionDetails.data;
}


const createActionSelector = (action) => {
    const {
        formData,
        actionId
    } = action

    let actionData = {}
    const docsArray = [];
    // var loFormData = new FormData();
    if (formData != null && formData !== undefined) {
        /** Construct the array of the documents */
        if (formData.selectedFileForUpload) {

            for (const [fileName, [simpleBase64, fileData]] of formData.selectedFileForUpload) {

                docsArray.push({
                    Doc_Name: fileName,
                    Action_Doc_ID: fileData.Action_Doc_ID || "0", // ??
                    Expiry_Date: fileData.Expiry_Date || new Date(), // ??
                    Doc_Type: "document",
                    data: simpleBase64
                })
            }
        }

        actionData = {
            Method_Text: "",
            Description: formData.description,
            ActionPlan: formData.ActionPlan,
            Comments: "Comments for Action 1", // ?
            Notes: formData.notes_area,
            Question_ID: formData.question && formData.question.Question_Id ? formData.question.Question_Id : formData.Question_ID,
            Assessment_Response_ID: formData.question && formData.question.Assessment_Response_ID,
            Program_ID: formData.review && formData.review.Program_ID ? formData.review.Program_ID : formData.Program_ID,
            Assessment_Template_ID: formData.review && formData.review.Assessment_Template_ID ? formData.review.Assessment_Template_ID : formData.Assessment_Template_ID,
            Assessment_ID: formData.review && formData.review.Assessment_Id ? formData.review.Assessment_Id : formData.Assessment_ID,
            Question_Template_ID: formData.question && formData.question.Question_Template_ID ? formData.question.Question_Template_ID : formData.Question_Template_ID,
            Action_ID: formData.action && formData.action.Action_ID ? formData.action.Action_ID : formData.Action_ID, // Can be sent Any, not required
            Question_Action_ID: formData.action && formData.action.Question_Action_ID ? formData.action.Question_Action_ID : formData.Question_Action_ID, // ?
            Dealer_ID: formData.review && formData.review.Dealer_ID ? formData.review.Dealer_ID : formData.Dealer_ID,
            Dealer_Name: "Adelaide Hills Toyota", // ???
            Dealer_Code: formData.review && formData.review.Dealer_Code ? formData.review.Dealer_Code : formData.Dealer_Code,
            LastModifiedOn: formData.creation_date,
            CreatedBy: formData.createdBy,
            CreatedOn: formData.creation_date,
            LastModifiedBy: formData.lastModifiedBy, // in case of edit this will pass the last modified by
            Title: formData.title,
            Program: formData.review && formData.review.Program,
            Review: formData.review && formData.review.Assessment_Name,
            Due_Date: createDateAsUTC(formData.due_date),
            Status: statusMap.get(parseInt(formData.status, 10)),
            Status_ID: parseInt(formData.status, 10),
            Priority: formData.priority,
            Assigned_To: formData.assign_to,
            UserType: formData.userType,
            Documents: docsArray,
            DateClosed:formData.DateClosed
        };
    }
    
    return actionData;
}


export {
    getFetchActionData,
    getAssignToList,
    getActionsList,
    getActionIdQuery,
    createActionSelector,
    getActionsSubmitStatus,
    getUpdateActionIdQuery
};
import axios from 'axios';

let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}
export const fetchInvestigationRecordsList = (query, payload) => {
    let modified_by = (payload !== undefined && payload.Modified_By !== undefined && payload.Modified_By !== null) ?
        payload.Modified_By : "";
    let params = query !== undefined && query !== null ? query.postParams : "";
    return axios.post(`${BASE_URL}/InvestigationList?${query.query}`, {
    "FilterBy": params
});
}

// export const fetchInvestigationCreateData = () => {
//     return axios.get(`${BASE_URL}/CreateInvestigation`);
// }

export const postInvestigationCreateData = (payload) => {
    return axios.post(`${BASE_URL}/CreateInvestigation`, payload);
}

export const fetchLookUpStatusData = () => {
    return axios.get(`${BASE_URL}/LookupStatus?type=investigation`);
}

export const fetchLookUpStageData = () => {
    return axios.get(`${BASE_URL}/LookupStage`);
}

export const deleteInvestigationFile = (query) => {
    let InvestigationDocumentsId = query !== undefined && query !== null ? query.InvestigationDocumentsId : "";
    let InvestigationId = query !== undefined && query !== null ? query.InvestigationId : "";
    return axios.delete(`${BASE_URL}/DeleteInvestigationDoc?Investigation_Doc_Id=${InvestigationDocumentsId}&Investigation_Id=${InvestigationId}`);
}

export const postUpdateInvestigation= (payload) => {
    let InvestigationId = payload !== undefined && payload !== null ? payload.Investigation_Id : "";
    return axios.post(`${BASE_URL}/UpdateInvestigation/${InvestigationId}`, payload);
}
import React from 'react';
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import XLSX from 'xlsx';
class ScorePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {

        return (

            <div className="delete-popup small-popup">
                <div className="popup__wrapper">
                    <div className="popup__body">
                        <div className="form-row">
                            {((this.props.scoreInfoerror) &&
                                <div className="col-12">
                                    <h6 className="text-center text-bold m-t-20">Invalid Request or Network Error </h6> </div>)}
                        </div>

                        <div>
                            {(((this.props.tflscore || this.props.tflscore === 0) && !this.props.scoreInfoerror) &&
                                <div>
                                    <div className="form-row">

                                        <div className="col-12">
                                            <h6 className="text-center text-bold m-t-20">{this.props.DealerNamesuburb} </h6> </div>
                                    </div>
                                    <div>

                                        <table style={{ "width": "100%", "border": "1px solid black", "border-collapse": "collapse" }}>
                                            <tr>
                                                <th style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>TFL Score</th>
                                                <td colSpan="2" style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>{this.props.tflscore}</td>
                                            </tr>
                                            <tr>
                                                <th colSpan="3" style={{ "textAlign": "center", "border": "1px solid black", "border-collapse": "collapse", "padding": "5px" }}>Section Score</th>
                                            </tr>
                                            <tr>
                                                <th style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>Section</th>
                                                <th style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>Section Id</th>
                                                <th style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>Score</th>
                                            </tr> {this.props.scoreDetailData.map((SectionScore, index) => (
                                                <tr>
                                                    <td style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>{SectionScore.SectionName}</td>
                                                    <td style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>{SectionScore.SectionName2}</td>
                                                    <td style={{ "border": "1px solid black", "border-collapse": "collapse", "padding": "5px", "text-align": "left" }}>{SectionScore.SectionScore}</td>
                                                </tr>))} </table>
                                    </div>

                                </div>)}
                            <div className="form-row">
                                <div className="col-12">
                                    <div className="buttons-wrapper text-center m-t-25">
                                        <button value="0" type="button" class="btn btn-gray" onClick={(e) => { setTimeout(() => { this.props.onClose() }, 100) }}>Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}
export default connect()(ScorePopup);

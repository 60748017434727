import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
    getReadableStories,
    getFetchError, getPaginationDetails,
} from '../../selectors/story';

import Story from './Story';
import StoriesHeader from './StoriesHeader';
import SchedulePagination from "./SchedulePagination";
import {changeStoriesPage} from "../../actions/story";
import {getLoaderStatus} from "../../selectors/loader";
import {PopUpCreateActionTrigger } from "./PopUpTrigger";

const COLUMNS = {
  title: {
    label: 'Title',
    width: '40%',
  },
  author: {
    label: 'Author',
    width: '30%',
  },
  comments: {
    label: 'Comments',
    width: '10%',
  },
  points: {
    label: 'Points',
    width: '10%',
  },
  archive: {
    width: '10%',
  },
};

export class Stories extends Component {
    /* componentDidUpdate() {
         this.focusDiv();
     } */
    constructor(props) {
        super(props);
        this.headerRef = React.createRef();
        this.focusDiv = this.focusDiv.bind(this);
    }

    focusDiv() {
        window.scrollTo(0, 0);
    }

   
    render() {

        return (
          <div className="main-content">
            <StoriesHeader ref={this.headerRef} />
            <div className="grid">
                    
                    
              {(this.props.stories || []).map((story,index) => (
                    <Story
                      key={index}
                      story={story}
                      columns={COLUMNS}
                      addActionDetails={this.props.addActionDetails}
                      activePage = {this.props.activePage} rowId={index}
                    />
))}
            </div>
            {this.props.stories.length === 0 && this.props.loader !=='show' && (
              <div className="grid">
                <div className="grid__row">
                  <div className="grid__columns-wrapper">
                            No Records Found.
                  </div>
                </div>
              </div>
)}

            {this.props.paginationData.totalCount > 20 && (
              <div className="legend-wrapper d-none d-sm-none d-md-block">
                <div className="row grid__columns-wrapper">
                  <div className="col-sm-4">
                    <p className="records-count">
                      {(this.props.paginationData.activePage * 20 + 1) - 20}
-
                      {(this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) >= 20 ? this.props.paginationData.activePage * 20 : this.props.paginationData.totalCount}
                      {' '}
of 
                      {' '}
                      {this.props.paginationData.totalCount}
                      {' '}
records
                    </p>
                  </div>
                  {/* <div className="col-sm-8">
                            <ul className="legend list-unstyled">
                                <li className="legend__dot legend__red">Not Started</li>
                                <li className="legend__dot legend__yellow">In-Progress</li>
                                <li className="legend__dot legend__green">Completed</li>
                            </ul>
                        </div> */}
                </div>
                <SchedulePagination onSelect={() => this.focusDiv()} />
              </div>
)}
            {/* <div className="button-wrapper-mobile d-block d-sm-block d-md-none">
                    <button className="btn btn-primary">Start TFL 1-8 Review</button>
                </div> */}
            {this.props.paginationData.totalCount > 20 && ((this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) > 20) && (
              <div className="text-center d-block d-sm-block d-md-none">
                <button onClick={() => this.props.onPageChange((this.props.paginationData.activePage+1))} className="btn btn-gray">
View
                        more
                </button>
              </div>
)}

          </div>

        );
    }
}



const mapStateToProps = state => ({
  stories: getReadableStories(state),
    paginationData: getPaginationDetails(state),
    loader: getLoaderStatus(state),
  error: getFetchError(state),
});

const mapDispatchToProps = dispatch => ({
    onPageChange : id => dispatch(changeStoriesPage(id,'action','mobile')),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Stories);

import React from "react";
import { connect } from 'react-redux';
import { getFilterLabel } from "../../selectors/story";
import { doSortStories } from "../../actions/story";
import { toggleSidebar } from "../../actions/header";

export const StoriesHeader = (props, { onSort, toggleFilter, filterLabel, myForwardedRef }) => {
  return (
    <div ref={myForwardedRef} tabIndex={1} >
      <div className="show-filters">
        <button id="reset" disabled={props.isApproveBtnDisabled} className="btn btn-blank m-r-10"
          onClick={() => props.handleHeaderApproveBtnClick("reset")} >
          {
            props.showApproveSelected ?
              "Reset Selected" : " Reset All"
          }
        </button>
        <span className="start-tfl-btn m-r-10">
          <span>
            <button id="start-review__button" disabled={props.isApproveBtnDisabled} className="btn btn-primary"
              onClick={() => props.handleHeaderApproveBtnClick("approve")}
            >
              {props.showApproveSelected ?
                "Approve Selected" : " Approve All"
              }
            </button>
          </span>
        </span>
        {/* <button className="btn btn-primary start-tfl-btn m-r-20">Start TFL 1-8 Review</button> */}
        <button id="Show_Filters"
          onClick={() => props.toggleFilter()}
          type="button"
          className="btn btn-white"
          data-toggle="button"
          aria-pressed="false"
          autoComplete="off"
        >
          <img src="images/filter-icon.svg" alt="Filters" className="filter-g m-r-10" />
          <img src="images/filter-w.svg" alt="Filters" className="filter-hover m-r-10" />
          <span className="show-filter-text">Filter</span>
        </button>
      </div>
    </div>
  )
}
const mapDispatchToProps = dispatch => ({
  //onSort: sortBy => dispatch(doSortStories(sortBy, 'action')),
  toggleFilter: () => dispatch(toggleSidebar()),
});

const mapStateToProps = state => ({
  filterLabel: getFilterLabel(state),
});

const ConnectedMyComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(StoriesHeader);

export default React.forwardRef((props, ref) =>
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
);
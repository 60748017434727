export const ASSESSMENT_TEMPLATE_DROPDOWN ={
    label: "Review Name",
    options: [
        {
            "Assessment_Template_ID": 3,
            "Assessment_Name": "DPSM - After Sales - Period 1 - Staff\r\n",
            "Assessment_Type": "Full",
            "Program_ID": 7,
            "Program_Name": "TFL 9"
        },
        {
            "Assessment_Template_ID": 5,
            "Assessment_Name": "DPSM - After Sales - Period 2 - Parts\r\n",
            "Assessment_Type": "Full",
            "Program_ID": 7,
            "Program_Name": "TFL 9"
        },
        {
            "Assessment_Template_ID": 4,
            "Assessment_Name": "DSM - Sales - Period 1 - Facility",
            "Assessment_Type": "Full",
            "Program_ID": 7,
            "Program_Name": "TFL 9"
        },
        {
            "Assessment_Template_ID": 2,
            "Assessment_Name": "DSM - Sales - Period 1 - Staff",
            "Assessment_Type": "Full",
            "Program_ID": 7,
            "Program_Name": "TFL 9"
        },
        {
            "Assessment_Template_ID": 6,
            "Assessment_Name": "DSM - Sales - Period 2 - Used\r\n",
            "Assessment_Type": "Full",
            "Program_ID": 7,
            "Program_Name": "TFL 9"
        }
    ],
    default_option: {
        "Assessment_Template_ID": "",
        "Assessment_Name": "Select Review Name\r\n"
    },
};

export const STATUS_DROPDOWN = {
    label: "Status",
    options: [
        {
            "Id": 0,
            "Name": "In Progress",
            "Description": "In Progress"
        },
        {
            "Id": 2,
            "Name": "Not Started",
            "Description": "Not Started"
        },
        {
            "Id": 1,
            "Name": "Complete",
            "Description": "Complete"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "All"
    },
}


export const LASTUPDATEDBY_DROPDOWN = {
    label: "Updated By",
    options: [
        {
            "Id": 1,
            "Name": "t21838",
            "Description": "t21838"
        },
        {
            "Id": 0,
            "Name": "t27072",
            "Description": "t27072"
        },
        {
            "Id": 2,
            "Name": "t27252",
            "Description": "t27252"
        },
        {
            "Id": 3,
            "Name": "t27271",
            "Description": "t27271"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "Select Updated By"
    },
}

export const LASTCONDUCTEDBY_DROPDOWN = {
    label: "Conducted By",
    options: [
        {
            "Id": 1,
            "Name": "t21838",
            "Description": "t21838"
        },
        {
            "Id": 0,
            "Name": "t27072",
            "Description": "t27072"
        },
        {
            "Id": 2,
            "Name": "t27252",
            "Description": "t27252"
        },
        {
            "Id": 3,
            "Name": "t27271",
            "Description": "t27271"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "Select Conducted By"
    },
}
export const INIT_LOCALES_LIST = [
    {
        dealer_id: "", // 1038,
        isPublished: "true",
        dealerName: "Show All"
    },
    {
        dealer_id: 1, // 1038,
        isPublished: "true",
        dealerName: "Adelaide Hills Toyota"
    },
    {
        dealer_id: 2,  // 1071,
        isPublished: "true",
        dealerName: "Airport Toyota"
    },
    {
        dealer_id: 9,   // 2319,
        isPublished: "true",
        dealerName: "Ballina Toyota"
    }
];

export const TITLE = {
    label: "Title",
    options: [
        {
            "Id": 0,
            "Name": "abc",
            "Description": "abc"
        },
        {
            "Id": 1,
            "Name": "abcdf",
            "Description": "abcdf"
        },
        {
            "Id": 2,
            "Name": "abcdfg",
            "Description": "abcdfg"
        },
        {
            "Id": 3,
            "Name": "abcdfghi",
            "Description": "abcdfghi"
        },
        {
            "Id": 4,
            "Name": "abcdfghijk",
            "Description": "abcdfghijk"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "Select Title"
    },
};

export const ASSIGNEE = {
    label: "Assignee",
    options:[
        {
            "Id": 0,
            "Name": "K10942",
            "Description": "K10942"
        },
        {
            "Id": 1,
            "Name": "K10943",
            "Description": "K10943"
        },
        {
            "Id": 2,
            "Name": "K10944",
            "Description": "K10944"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "Select Assignee"
    },
};

export const CREATOR = {
    label: "Creator",
    options:[
        {
            "Id": 0,
            "Name": "K10942",
            "Description": "K10942"
        },
        {
            "Id": 1,
            "Name": "K10943",
            "Description": "K10943"
        },
        {
            "Id": 2,
            "Name": "K10944",
            "Description": "K10944"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "Select Creator"
    },
};

export const PRIORITY = {
    label: "Priority",
    options:[
        {
            "Id": 0,
            "Name": "Low",
            "Description": "Low"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "Select Priority"
    },
};

export const ACTION_STATUS = {
    label: "Status",
    options:[
        {
            "Id": 0,
            "Name": "In-progress",
            "Description": "In Progress"
        },
        {
            "Id": 2,
            "Name": "Not Started",
            "Description": "Not Started"
        },
        {
            "Id": 1,
            "Name": "Complete",
            "Description": "Complete"
        }
    ],
    default_option: {
        "Name": "",
        "Description": "All"
    },
};
export const SAP_DEALER_CODE = {
    label: "Select SAP Code",
    options: [
        {
            "SAP_Dealer_Code: ": "36635"
        },
        {
            "SAP_Dealer_Code: ": "3931"
        },
    ],
    default_option: {
        "SAP_Dealer_Code": "",
    },
};



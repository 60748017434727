import React from 'react';
import axios from 'axios';

axios.defaults.headers.common = {
    "Ocp-Apim-Subscription-Key": `${process.env[`REACT_APP_APIM_KEY_${window.location.hostname}`]}`
};
let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}

export const postQuestionDocument = payload =>
    axios.post(`${BASE_URL}/AddQuestionDocument`, payload);

export const deleteQuestionsDocument = payload =>
    axios.delete(`${BASE_URL}/DeleteQuestionDocument?question_template_id=${payload.Question_Template_ID}&assessment_id=${payload.Assessment_ID}`, {
        data: {
            DeleteBy: payload.DeleteBy
        }});

export const postQuestionSopDocument = (payload, params) =>
    axios.post(`${BASE_URL}/DealerSopsUpload?Dealer_ID=${ 
        params.Dealer_ID  }&Sop_Name=${  params.Sop_Name  }&Document_Name=${  params.Document_Name  }&Expiry_Date=${  params.Expiry_Date}&Program_Id=${params.Program_Id}`, payload);

export const postQuestionRecommendation = payload =>
    axios.post(`${BASE_URL}/AddQuestionRecommendation`,payload);

export const updateQuestionRecommendation = payload =>
    axios.post(`${BASE_URL}/UpdateQuestionRecommendation`,payload);

export const getDealerInfoById = payload =>
    axios.get(`${BASE_URL}/DealerDetailsBySapId?Sap_Id=${payload}`);

export const fetchStartReview = (payload) =>
    axios.get(`${BASE_URL}/StartReview?Dealer_id=${payload.dealerID}&ASSESSMENT_ID=${payload.assessmentID}&user_id=${payload.userID}`);
export const DealerSectionScore = payload =>
    axios.get(`${BASE_URL}/DealerSectionScore?Program_Id=${payload.Program_Id}&Dealer_Id=${payload.Dealer_Id}&Review_Id=${payload.Review_Id}&Review_Type=${payload.Review_Type}&IS_REPORT=${payload.IS_REPORT}`);
    export   const Score = payload =>{
        return axios.get(`${BASE_URL}/Score?dealer_id=`+payload.Dealer_ID+`&program_id=`+payload.Program_ID+`&review_id=`+payload.Review_ID+`&review_type=`+payload.Review_Type);
    }
    export const TFLScoreListing = payload =>
    axios.post(`${BASE_URL}/TFLScoreListing`,payload);

export const fetchBrandName = () =>
    axios.get(`${BASE_URL}/Brand`);




import XLSX from "tempa-xlsx";
// copied from https://github.com/securedeveloper/react-data-export/blob/master/src/ExcelPlugin/utils/DataUtil.js
const strToArrBuffer = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);

    for (var i = 0; i != s.length; ++i) {
        view[i] = s.charCodeAt(i) & 0xFF;
    }

    return buf;
};

const dateToNumber = (v, date1904) => {
    if (date1904) {
        v += 1462;
    }

    var epoch = Date.parse(v);

    return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
};

const excelSheetFromDataSet = (dataSet) => {
    /*
    Assuming the structure of dataset
    {
        xSteps?: number; //How many cells to skips from left
        ySteps?: number; //How many rows to skips from last data
        columns: [array | string]
        data: [array_of_array | string|boolean|number | CellObject]
        fill, font, numFmt, alignment, and border
    }
     */
    if (dataSet === undefined || dataSet.length === 0) {
        return {};
    }

    var ws = {};
    var range = { s: { c: 10000000, r: 10000000 }, e: { c: 0, r: 0 } };
    var rowCount = 0;

    dataSet.forEach(dataSetItem => {
        var columns = dataSetItem.columns;
        console.log("TCL: excelSheetFromDataSet -> columns", columns)
        
        var xSteps = typeof (dataSetItem.xSteps) === 'number' ? dataSetItem.xSteps : 0;
        var ySteps = typeof (dataSetItem.ySteps) === 'number' ? dataSetItem.ySteps : 0;
        var data = dataSetItem.data;
        if (dataSet === undefined || dataSet.length === 0) {
            return;
        }

        rowCount += ySteps;

        var columnsWidth = []
        if (columns.length >= 0) {
            columns.forEach((col, index) => {
                var cellRef = XLSX.utils.encode_cell({ c: xSteps + index, r: rowCount });
                fixRange(range, 0, 0, rowCount, xSteps, ySteps);
                var colTitle = col;
                if (typeof col === 'object') {
                    colTitle = col.title;
                    columnsWidth.push(col.width || { wpx: 80 }); /* wch (chars), wpx (pixels) - e.g. [{wch:6},{wpx:50}] */
                }
                getHeaderCell(colTitle, cellRef, ws);
            });

            rowCount += 1;
        }

        if (columnsWidth.length > 0) {
            ws['!cols'] = columnsWidth;
        }

        for (var R = 0; R != data.length; ++R, rowCount++) {
            for (var C = 0; C != data[R].length; ++C) {
                var cellRef = XLSX.utils.encode_cell({ c: C + xSteps, r: rowCount });
                fixRange(range, R, C, rowCount, xSteps, ySteps);
                getCell(data[R][C], cellRef, ws);
            }
        }
    });
    if (range.s.c < 10000000) {
        ws['!ref'] = XLSX.utils.encode_range(range);
    }

    return ws;
};

function getHeaderCell(v, cellRef, ws) {
    var cell = {};
    var headerCellStyle = { font: { bold: true } };
    cell.v = v;
    cell.t = 's';
    cell.s = headerCellStyle;
    ws[cellRef] = cell;
}

function getCell(v, cellRef, ws) {
    //assume v is indeed the value. for other cases (object, date...) it will be overriden.
    var cell = { v };
    if (v === null) {
        return;
    }


    var isDate = (v instanceof Date);
    if (!isDate && (typeof v === 'object')) {
        cell.s = v.style;
        cell.v = v.value;
        v = v.value;
    }

    if (typeof v === 'number') {
        cell.t = 'n';
    } else if (typeof v === 'boolean') {
        cell.t = 'b';
    } else if (isDate) {
        cell.t = 'n';
        cell.z = XLSX.SSF._table[14];
        cell.v = dateToNumber(cell.v);
    } else {
        cell.t = 's';
    }
    ws[cellRef] = cell;
}

function fixRange(range, R, C, rowCount, xSteps, ySteps) {
    if (range.s.r > ySteps + rowCount) {
        range.s.r = ySteps + rowCount;
    }

    if (range.s.c > C + xSteps) {
        range.s.c = C + xSteps;
    }

    if (range.e.r < ySteps + rowCount) {
        range.e.r = ySteps + rowCount;
    }

    if (range.e.c < C + xSteps) {
        range.e.c = C + xSteps;
    }
}
// copied functions end

const formatWorksheetBeforeDownload = (ws) => {
    var range = XLSX.utils.decode_range(ws['!ref']);
    var noRows = range.e.r; // No.of rows
    var noCols = range.e.c; // No. of cols
    for (let j = 1; j <= noRows + 1; j++) {
        let isManual = false;
        for (let i = 65; i <= (noCols + 65); i++) {
            let currCell = ws[String.fromCharCode(i) + j];
            let columnName = ws[`${String.fromCharCode(i)}1`];
            let style = {
                fill: { patternType: "solid", fgColor: { rgb: "D0CECE" } },
                font: { name: 'Arial', sz: '10' }, color: { rgb: "000000" },
                alignment: { wrapText: true, vertical: 'center' },
                border: { bottom: { style: 'thin', color: { rgb: "000000" } }, right: { style: 'thin', color: { rgb: "00000000" } } }
            };

            if ((i >= 65 && i <= 68) || j === 1) {
                style.fill.fgColor = { rgb: "000000" };
                style.font.color = { rgb: "FFFFFF" };
                style.border.bottom.color = { rgb: "FFFFFF" };
                style.border.right.color = { rgb: "FFFFFF" };
                console.log(currCell.v + ' 167');
                if (j === 1) {
                    style.font.bold = true;
                }
                if (i > 66 && j !== 1) {
                    console.log(currCell.v + ' 172 ');
                    style.fill.fgColor = { rgb: "FF0000" };
                }
            } else if (columnName && columnName.v && columnName.v.indexOf('_ID') > -1) {
                console.log(currCell.v + ' 176');
                style.fill.fgColor = { rgb: 'A6A6A6' };
            }
            if (i > 65 && j !== 1 && ws[`${String.fromCharCode(i - 1)}1`].v.indexOf('Response_') !== -1 &&
                ws[`${String.fromCharCode(i)}1`].v.indexOf('_Value') !== -1) {
                style.fill.fgColor = { rgb: "FFFFFF" };;
                console.log(currCell.v + ' 182');
            }
            if (j >= 2) {
                style.fill.fgColor = { rgb: 'D0CECE' };
                style.font.color = { rgb: "000000" };
                style.border.bottom.color = { rgb: "000000" };
                style.border.right.color = { rgb: "000000" };
                style.font.italic = false;
                console.log(currCell.v + ' 190');
                if (currCell.v === "At Standard")
                    style.fill.fgColor = { rgb: '21c500' };
                if (currCell.v === "Not At Standard")
                    style.fill.fgColor = { rgb: 'eb0a1e' };
            }
           

            console.log(currCell.v + ' ' + JSON.stringify(style));
            currCell.s = style;
        }
    }
    return ws;
}

//format worksheet as required.
const formatWorksheet = (ws) => {
    var range = XLSX.utils.decode_range(ws['!ref']);
    var noRows = range.e.r; // No.of rows
    var noCols = range.e.c; // No. of cols
    for (let j = 1; j <= noRows + 1; j++) {
        let isManual = false;
        for (let i = 65; i <= (noCols + 65); i++) {
            let currCell = ws[String.fromCharCode(i) + j];
            let columnName = ws[`${String.fromCharCode(i)}1`];
            let style = {
                fill: { patternType: "solid", fgColor: { rgb: "D0CECE" } },
                font: { name: 'Arial', sz: '10' }, color: { rgb: "000000" },
                alignment: { wrapText: true, vertical: 'center' },
                border: { bottom: { style: 'thin', color: { rgb: "000000" } }, right: { style: 'thin', color: { rgb: "00000000" } } }
            };
            if ((i >= 65 && i <= 68) || j === 1) {
                style.fill.fgColor = { rgb: "000000" };
                style.font.color = { rgb: "FFFFFF" };
                style.border.bottom.color = { rgb: "FFFFFF" };
                style.border.right.color = { rgb: "FFFFFF" };
                if (j === 1) {
                    style.font.bold = true;
                }
                if (i > 66 && j !== 1) {
                    style.fill.fgColor = { rgb: "FF0000" };
                }
            } else if (columnName && columnName.v && columnName.v.indexOf('_ID') > -1) {
                style.fill.fgColor = { rgb: 'A6A6A6' };
            } else if (columnName && columnName.v === 'KPI_Type' && currCell.v === 'Manual') {
                isManual = true;
            }
            if (isManual && columnName && columnName.v === 'KPI_Value_1') {
                style.fill.fgColor = { rgb: "FFFFFF" };
            }
            if (i > 65 && j !== 1 && ws[`${String.fromCharCode(i - 1)}1`].v.indexOf('Response_') !== -1 &&
                ws[`${String.fromCharCode(i)}1`].v.indexOf('_Value') !== -1) {
                style.fill.fgColor = { rgb: "FFFFFF" };;
            }
            if (j === 2) {
                style.fill.fgColor = { rgb: 'D0CECE' };
                style.font.color = { rgb: "000000" };
                style.border.bottom.color = { rgb: "000000" };
                style.border.right.color = { rgb: "000000" };
                style.font.italic = true;
            }
            currCell.s = style;
        }
    }
    return ws;
}

export {
    strToArrBuffer,
    excelSheetFromDataSet,
    formatWorksheet,
    formatWorksheetBeforeDownload
};
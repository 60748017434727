import {
    SCHEDULE_ADD_DROPDOWN
} from '../constants/actionTypes';
import _ from 'lodash';
import {
    getTitleOptions,
    getAssessmentDropdownOptions,
    getStatusDropdownOptions,
    getLastUpdateByOptions,
    getLastConductedByOptions,
    getAssigneeOptions, getPriorityOptions, getCreatorOptions
} from '../selectors/dropdown'
import {
    ASSESSMENT_TEMPLATE_DROPDOWN,
    STATUS_DROPDOWN,
    LASTUPDATEDBY_DROPDOWN,
    LASTCONDUCTEDBY_DROPDOWN,
    TITLE, ASSIGNEE, PRIORITY,CREATOR
} from "../constants/dropdowns";

const INITIAL_STATE ={
    'AssessmentStatusLookup':[],
    'StatusLookup':[],
    'LastUpdateByLookup':[],
    'LastConductedByLookup':[],
    'TitleLookup':[],
    'AssigneeLookup':[],
    'PriorityLookup':[],
    'CreatorLookup':[],
    'oktaUsers':[],
    'InvestigationLookup':[],
    'ScoringLookup':[]
};


/* const applyResetSortAndFilters = (state, action) =>
    INITIAL_STATE;

const applyFilterStories = (state, action) => {
    const tmpFilterState = state.filters.filter(obj =>{
        return obj.Field !== action.obj.Field
    });
    return ({
        ...state, filters: [...tmpFilterState,action.obj], activePage: 1,sortBy: {
            sortedField: null,
            sortOrder: "desc"
        },
    })
}; */

const applyAddDropdown = (state, action) => {
    const newState = {...state};
    switch(action.assessmentType) {
        case 'StatusLookup': {
            const data = action.optionsData.map((option) =>{
                if(option.Description === 'Completed'){
                    option.Description = 'Complete';
                }
                option.Description = option.Description.replace("-"," ").toUpperCase();
                return option;
            });
            newState.StatusLookup = data;
            return newState;
        }
        
        case 'AssessmentStatusLookup': {
            newState.AssessmentStatusLookup = action.optionsData;
            return newState;
        }
        
        case 'LastUpdateByStatusLookup': {
           
            newState.LastUpdateByLookup =getLastUpdateByOptions(action.optionsData,LASTUPDATEDBY_DROPDOWN.default_option);
            return newState;
        }
        case 'LastConductedByStatusLookup': {
            newState.LastConductedByLookup =getLastConductedByOptions(action.optionsData,LASTCONDUCTEDBY_DROPDOWN.default_option);
            return newState;
        }
        case 'TitleLookup': {
            newState.TitleLookup =getTitleOptions(action.optionsData,TITLE.default_option);
            return newState;
        }
        case 'AssigneeLookup': {
            newState.AssigneeLookup =getAssigneeOptions(action.optionsData,ASSIGNEE.default_option);
            return newState;
        }
        case 'CreatorLookup': {
            newState.CreatorLookup =getCreatorOptions(action.optionsData,CREATOR.default_option);
            return newState;
        }
        case 'PriorityLookup': {
            newState.PriorityLookup = action.optionsData;
            return newState;
        }
        case 'OktaUsers': {
            let newarr =[];
            for(let j=0;j< action.optionsData.length;j++) {
                newarr.push(action.optionsData[j]);
            }
            newState.oktaUsers = newarr;
            return newState;
        }
        case 'InvestigationLookup': {
            newState.InvestigationLookup = action.optionsData.data;
            return newState;
        }
        case 'ScoringLookup': { 
            newState.ScoringLookup = action.optionsData.data;
            return newState;
        }
        default:
            return newState;
    }
};

export function dropdownsReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case SCHEDULE_ADD_DROPDOWN : {
            return applyAddDropdown(state, action);
        }
        default : return state;
    }
}

export default dropdownsReducer;
import React, { Component } from "react";
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Moment from "moment";

export default class CustomDateTimePicker extends Component {

  componentDidMount () {
    
    var datePickerbutton = document.getElementsByClassName('rw-btn-select');
    
    if (datePickerbutton.length) {
      var $this = this;
        datePickerbutton[0].onclick = () => {
            $this.changeTitles();
            setTimeout(() => {
                document.getElementsByClassName('rw-calendar-btn-right')[0].onclick = $this.changeTitles;
                document.getElementsByClassName('rw-calendar-btn-left')[0].onclick = $this.changeTitles;
            }, 0);
        }
    }
  }

  changeTitles = () => {
    setTimeout(()=> {
        var primaryBtn = document.querySelectorAll('#createdOn-investigation .rw-calendar-footer .rw-btn-primary');
        primaryBtn[0].innerText = Moment(primaryBtn[0].innerText).format('DD MMMM, YYYY');
        var dateCells = document.querySelectorAll('#createdOn-investigation .rw-cell');
        for (var i=0; i< dateCells.length; i++ ) {
            let currCellDate = new Date(dateCells[i].getAttribute('title'));
            dateCells[i].setAttribute('title', Moment(currCellDate).format('DD MMMM, YYYY'));
            console.log(dateCells[i].getAttribute('title')); 
        }
    }, 0);
  };

  render() {
    return (
      <DateTimePicker
        {...this.props}
        />
    )
  }

}


import React from "react";
import { connect } from 'react-redux';
import {getFilterLabel, getPaginationDetails} from "../../selectors/story";
import {doSortStories} from "../../actions/story";
import {toggleSidebar} from "../../actions/header";
import StartReviewModal from './StartReviewModal'

export const StoriesHeader = ({onSort, onToggleFilter, filterLabel,paginationData,myForwardedRef}) => (
  <div>
    <div ref={myForwardedRef} tabIndex={1} className="page-header">
      <div className="row grid__columns-wrapper">
        <div className="col-3">
          <h3 id="reviews__heading">Reviews</h3>
        </div>
        <div className="col-9">
          <div className="show-filters">
          <span className="start-tfl-btn"><StartReviewModal initialModalState={false} /></span>
            <button
              id="Show_Filters"
              onClick={() => onToggleFilter()}
              type="button"
              className="btn btn-white"
              data-toggle="button"
              aria-pressed="false"
              autoComplete="off"
            >
              <img src="images/filter-icon.svg" alt="Filters" className="filter-g m-r-10" />
              <img src="images/filter-w.svg" alt="Filters" className="filter-hover m-r-10" />
              <span className="show-filter-text">Filter</span>
              {/* <span className="show-filter-text">{filterLabel}</span> */}
              {/* <span className="hide-filter-text">Hide Filters</span> */}
            </button>
          </div>
        </div>
      </div> 
    </div>

    <div className="legend-wrapper d-none d-sm-none d-md-block">
      <div className="row grid__columns-wrapper">
        <div className="col-sm-12">
          <p className="records-count">
            {(paginationData.activePage*20+1)-20}
-
            {(paginationData.totalCount - ((paginationData.activePage*20+1)-20)) >= 20 ? paginationData.activePage*20:paginationData.totalCount}
            {' '}
of 
            {' '}
            {paginationData.totalCount}
            {' '}
records
          </p>
        </div>
        {/* <div className="col-sm-8">
          <ul className="legend list-unstyled">
            <li className="legend__dot legend__red">Not Started</li>
            <li className="legend__dot legend__yellow">In-Progress</li>
            <li className="legend__dot legend__green">Completed</li>
          </ul>
        </div> */}
      </div>
    </div>

    <div className="grid">
      <div className="grid__labels d-none d-md-none d-lg-block">
        <div className="row grid__columns-wrapper">
          <div onClick={() => onSort('Dealer_Name')} id="location__sort" className="col-md-3 grid__label-sort">
                        Location
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
         
          <div onClick={() => onSort('Assessment_Template_Name')} id="review__sort" className="col-md-2 grid__label-sort">
                        Review
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div onClick={() => onSort('Program')} id="program__sort" className="col-md-1 col-4 grid__label-sort">
                  Program
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
            </div>
            
            <div onClick={() => onSort('Last_Modified_By')} id="update-by__sort" className="col-md-2 col-8 grid__label-sort">
                        Updated By
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
          <div onClick={() => onSort('StartDate')} id="start-date__sort" className="col-md-2 col-4 grid__label-sort">
                        Start Date
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div>
        
          {/* <div onClick={() => onSort('EndDate')} className="col-md-2 col-5 grid__label-sort">
                        End Date
            <span className="grid__sort-icons">
              <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
              <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
            </span>
          </div> */}
          <div onClick={() => onSort('Status')} id="status__sort" className="col-md-2 col-3 grid__label-sort">
                Status
          <span className="grid__sort-icons">
            <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
          <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
          </span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
const mapDispatchToProps = dispatch => ({
    onSort: sortBy => dispatch(doSortStories(sortBy,'schedule')),
    onToggleFilter: () => dispatch(toggleSidebar()),
});

const mapStateToProps = state => ({
    filterLabel: getFilterLabel(state),
    paginationData: getPaginationDetails(state),
});

const ConnectedMyComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(StoriesHeader);

export default React.forwardRef((props, ref) =>
  <ConnectedMyComponent {...props} myForwardedRef={ref} />
);
import React, { Component }  from 'react';
import { UncontrolledCollapse } from 'reactstrap';

class Faqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapse: false };
  }

  componentDidMount() {
      document.title = "Help";

      document.body.classList.remove('nav-open');
      document.body.classList.remove('filter-open');
  }

  redirectToUserGuid = (e) => {
    localStorage.removeItem('isUserFirstVisit')
      this.props.history.push({
              pathname: `/`,
              state: { isComingFromFaq:true }

          }
      );

    e.preventDefault();
  }

  render() {
    return (
      <div className="main-content">

        <div className="faq-banner">
          <img className="m-b-20" src="../images/Faq-banner-icon.svg" alt="FAQs" />
          <h3 className="desktop-only">Help</h3>
          <h3 className="small-devices-only">FAQs</h3>
        </div>
        <div className="faq-content">
          <div className="text-right">
            <button className="btn btn-primary guided-learning-btn" onClick={(e)=>this.redirectToUserGuid(e)}>Guided Learning</button>
          </div>
          <div className="faq">
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">1</div>
                <div className="faq__question">
                I need more help with the application. Where can I go for help?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-1" className="faq__collapse">
                <div className="faq__detail">
                  <p>If you need help conducting a review, please contact your Program Manager.</p>
                  <p>
                  If you're having a technical difficulty, please call the ISD Service Desk on <a href="tel:1800 505 350" > 1800 505 350 </a> or email <a href="mailto:toyota.servicedesk@toyota.com.au">toyota.servicedesk@toyota.com.au</a></p>
                    
                 
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-1" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">2</div>
                <div className="faq__question">
                Can I assign actions to Dealers?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-2" className="faq__collapse">
                <div className="faq__detail">
                  <p>Yes, you can! You can assign Actions to anyone who is authorised to see that Review − a TMCA employee or a Dealer. </p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-2" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">3</div>
                <div className="faq__question">
                Who gets notified when a review is complete?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-3" className="faq__collapse">
                <div className="faq__detail">
                  <p>
                  No one is automatically notified when a Review is complete. You can, however, share a Review with anyone who is authorised to see that Review. In the Reviews screen, look for the three vertical dots on the far right of each Review. </p>
                  
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-3" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">4</div>
                <div className="faq__question">
                Can Dealers use this application?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-4" className="faq__collapse">
                <div className="faq__detail">
                  <p>Dealers have been technically enabled to use PREP. Please speak with your Program Manager about when they expect Dealers to start using it.</p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-4" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">5</div>
                <div className="faq__question">
                  I submitted a review but realised there is a mistake. What should I do?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-5" className="faq__collapse">
                <div className="faq__detail">
                  <p>If the Review is Pending 1st, you can change the Review and re-submit it.</p>
                  <p>If it's Pending 2nd, your Regional Manager or Program Manager can send it back to you to correct.</p>
                  <p>If the Review is Complete (all approvals received), please let your Program Manager know.</p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-5" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">6</div>
                <div className="faq__question">
                I can't access the app. Who do I call?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-6" className="faq__collapse">
                <div className="faq__detail">
                  <p>Call the ISD Service Desk on <a href="tel:1800 505 350" > 1800 505 350 </a>.</p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-6" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">7</div>
                <div className="faq__question">
                I have a great idea to improve this application. Who can I suggest it to?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-7" className="faq__collapse">
                <div className="faq__detail">
                  <p>That's great! Please send an email to   <a href="mailto:toyota.servicedesk@toyota.com.au">toyota.servicedesk@toyota.com.au</a></p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-7" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">8</div>
                <div className="faq__question">
                What's the difference between an Action and an Observation?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-8" className="faq__collapse">
                <div className="faq__detail">
                  <p>An Action is a compulsory measure the Dealer must complete. An Observation is an optional activity − a suggested way to improve. </p>
                  <p>You can create an Action or an Observation for any question.</p>
                  
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-8" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">9</div>
                <div className="faq__question">
                Can I make an Observation or create an Action outside of a review?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-9" className="faq__collapse">
                <div className="faq__detail">
                  <p>No. All Actions and Observations must be linked to specific questions within a Review.
                  
                  </p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-9" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">10</div>
                <div className="faq__question">
                What's the difference between an SOP and Photos/Documents?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-10" className="faq__collapse">
                <div className="faq__detail">
                  <p>A Standard Operating Procedure (SOP) is maintained by the Dealer. You can upload one SOP to multiple reviews and questions. This is easy to do from the Upload SOP screen.    </p>
                  <p>All SOPs have an expiration date, which defaults to one year, but you can change this. Use the <b>Add SOP</b> button to add an SOP.
</p>
                  <p>Other documentation or evidence, such as photos or marketing material, can be uploaded against individual questions. Use the <b>Add Photos/Documents</b> button to add evidence.
</p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-10" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">11</div>
                <div className="faq__question">
                Do I have to complete the entire TFL 1-8 Review?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-11" className="faq__collapse">
                <div className="faq__detail">
                  <p>You can select which criterion to complete, e.g. TFL 1. Once you start a criterion, you must answer all questions in that criterion.
                  </p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-11" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">12</div>
                <div className="faq__question">
                I have a question about some review criteria. Who can I speak to?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-12" className="faq__collapse">
                <div className="faq__detail">
                  <p>If you have any questions about criteria or reviews, contact your Program Manager.</p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-12" href="#" />
            </div>
            <div className="faq__row">
              <div className="faq__header clearfix">
                <div className="faq__count">13</div>
                <div className="faq__question">
                Which programs can I use PREP for?
                </div>
              </div>
              <UncontrolledCollapse toggler="#tfl-faq-13" className="faq__collapse">
                <div className="faq__detail">
                  <p>Technically you can use PREP for any Dealer review program. As of Sep-19, regional field staff are using PREP for TFL, TSM, START and DPOK.</p>
                </div>
              </UncontrolledCollapse>
              <a className="faq__toggle-button collapsed" id="tfl-faq-13" href="#" />
            </div>
 
          </div>
        </div>
      </div>
   
    )
  }
}

export default Faqs;
import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from 'react-router-dom';
import { PopUpCreateActionTrigger } from "./PopUpTrigger";
import TruncateMarkup from 'react-truncate-markup';

const Story = ({ story, columns, onArchive, addActionDetails,activePage, rowId }) => {

    const {
        Action_ID,
        Dealer_Name,
        Title,
        Program,
        Assessment_Template_Name,
        Due_Date,
        Status,
        Priority,
        Assigned_To,
        AssessmentSubmitDate,
        Rating,
        DateClosed,
        Creator,
        createdon,
        lastmodifiedmy,
        lastmodifiedon
    } = story;

    const statusClass = classNames({
        'action__status': true,
        // 'action__status--gray': (Status === 'Not Started'),
        'action__status--blue': (Status === 'In-progress' || Status === 'InProgress' || Status === 'In Progress'|| Status === 'Not Started'),
        'action__status--green': (Status === 'Completed' || Status === 'Complete'),
        'action__status--red': (Status === 'Overdue')
    });
    const priorityClass = classNames({
        'action__status': true,
        'action__priority--high': (Priority === 'High'),
        'action__priority--medium': (Priority === 'Medium'),
        'action__priority--low': (Priority === 'Low')
    });


    const triggerEvent = (title) => {
        return (
          <div className="col-md-2 col-12 grid__column grid__template-name">
            <span className="grid__labels-mobile">Title</span>
            {title}
          </div>
)

    }


    return (
        PopUpCreateActionTrigger(

          <a className="grid__row-link" href="#">
            <div className="grid__row">
              <div className="row grid__columns-wrapper">
                <div className="col-lg-2 col-md-6 col-12 grid__order-md-3 grid__order-sm-1 grid__column grid__dealer-name  mar-md-b-10" id={`dealer-name__row${rowId}`}>
                  <span className="grid__labels-mobile">Location</span>
                  {Dealer_Name}
                </div>
                <div className="col-lg-2 col-md-6 col-12 grid__order-md-1 grid__order-sm-2 grid__column grid__template-name  mar-md-b-10" id={`title__row${rowId}`}>
                      <span className="grid__labels-mobile">Title</span>
                    <div className="word-break"> {Title}</div>
                  </div>
                <div className="col-lg-2 col-md-6 col-12 grid__order-md-5 grid__order-sm-3 grid__column program  mar-md-b-10" id={`review__row${rowId}`}>
                  <span className="grid__labels-mobile">Review Name</span>
                  <TruncateMarkup lines={2} >
                    <div className="word-break" title={Assessment_Template_Name}>
                      {Assessment_Template_Name}
                    </div>
                  </TruncateMarkup>
                          
                </div>
                <div className="col-lg-1 col-md-6 col-4 grid__order-md-4 grid__order-sm-4 grid__column start-date  mar-md-b-10" id={`due-date__row${rowId}`}>
                  <span className="grid__labels-mobile">created Date</span>
                  <span className="actions-due-date">
                    {AssessmentSubmitDate && (
                    <Moment format="DD-MM-YYYY">
                      {AssessmentSubmitDate}
                    </Moment>
)}
                  </span>
                </div>
                <div className="col-lg-1 col-md-6 col-4 grid__order-md-4 grid__order-sm-4 grid__column start-date  mar-md-b-10" id={`due-date__row${rowId}`}>
                  <span className="grid__labels-mobile">Due Date</span>
                  <span className="actions-due-date">
                    {Due_Date && (
                    <Moment format="DD-MM-YYYY">
                      {Due_Date}
                    </Moment>
)}
                  </span>
                </div>
                <div className="col-lg-1 col-md-6 col-4 grid__order-md-2 grid__order-sm-5 grid__column last-updated  mar-md-b-10" id={`DateClosed__row${rowId}`}>
                  <span className="grid__labels-mobile">Date Closed</span>
                  <span className="actions-due-date">
                    {DateClosed && (
                    <Moment format="DD-MM-YYYY">
                      {DateClosed}
                    </Moment>
)}
                  </span>
                </div>
                <div className="col-lg-1 col-md-3 col-4 grid__order-md-6 grid__order-sm-6 grid__column grid__template-name  mar-md-b-10" id={`assignee__row${rowId}`}>
                  <span className="grid__labels-mobile">Assignee</span>
                  {Assigned_To}
                </div>
                
                <div className="col-lg-1 col-md-3 col-12 grid__order-md-7 grid__order-sm-7 grid__column last-updated  mar-md-b-10" id={`status__row${rowId}`}>
                  <span className="grid__labels-mobile">Status</span>
                  <span className={statusClass}>{Status==="Not Started"?"IN PROGRESS": Status.toUpperCase()}</span>
                </div>
                <div className="col-lg-1 col-md-3 col-4 grid__order-md-6 grid__order-sm-6 grid__column grid__template-name  mar-md-b-10" id={`assignee__row${rowId}`}>
                  <span className="grid__labels-mobile">Rating</span>
                  <span className={`rating-${Rating==="Not At Standard"?"ns":Rating==="At Standard"?"as":""}`}>{Rating}</span>
                  
                </div>
                {/* <div className="col-md-1 col-4 grid__column status">
                  <span className="grid__labels-mobile">Creator</span>
                  {Creator}
              </div> */}
                {/* <div className="start-assessment d-none d-sm-none d-md-block">
                  <button className="btn btn-primary">Start Assessment</button>
              </div> */}
              </div>
            </div>
          </a>,
            "UpdateAction",
            null, null, story.Dealer_Name, story.CreatedBy,
            {},
            story.Question_Action_ID,
            addActionDetails)
    );
}

export default connect(
    null,
    null
)(Story);
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
    doAddTFLScoringList, doFetchTFLScoringErrors,
  doAddScoreStories, doFetchErrorScoreStories, doAddScoringList, doFetchScoringErrors,
  doAddTflBreakdownScoreList, doFetchBreakdownScoreErrors, doApproveStatus, doResetStatus, doFetchScoreList, doAddScoreOverriedData, saveScoreOverrideResp, doFetchScoreOverrideData, deleteRespScoreOverride
} from '../actions/TflScores';
import {
  fetchScoreStories,
    fetchScoringList,
    fetchTFLScoringList,
  fetchPMAScoreBreakdown,
  approveScore,
  resetScore,
  fetchScoreOverriedData,
  deleteSelectedOverrideFiles,
  savePMAOverRideScore
} from '../api/TflScores';
import { getScheduleListParams, getChangeScoreStatusParams } from '../selectors/story';
import { doHideLoader, doShowLoader } from "../actions/loader";
import { SCORE_OVERRIDE_DATA } from '../components/TflScore/models/ScoreOverride';

export function* handlefetchScoringList(action) {
  yield put(doShowLoader());
  const { reviewType, deviceType, payload } = action;
  const filterState = yield select((state) => state.filterState);
  filterState.reviewType = action.reviewType;
  const query = getScheduleListParams(filterState);
  
  const index  = query.postParams.findIndex(val=> val.Field === 'Program');
  query.postParams.splice(index, 1);  
  try {
    let result;
    result = yield call(fetchScoringList, query, payload); // uncomment once APIs are Live
    for (let i = 0; i < result.data.data.length; i++) {
      result.data.data[i].isChecked = false;
    }
    
    // result = FETCHSCORINGLIST;
    yield put(doAddScoringList(result.data.data, result.Count, deviceType));
    yield put(doHideLoader());
  } catch (error) {
    yield put(doFetchScoringErrors(error));
    yield put(doHideLoader());
  }
}


export function* handlefetchTFLScoringList(action) {
    yield put(doShowLoader());
    const { reviewType, deviceType, payload } = action;
    const filterState = yield select((state) => state.filterState);
    filterState.reviewType = action.reviewType;
    const query = getScheduleListParams(filterState);

    console.log('Inside Handle TFL Score List method')
    const index = query.postParams.findIndex(val => val.Field === 'Program');
    query.postParams.splice(index, 1);
    try {
        let result;
        result = yield call(fetchTFLScoringList, query, payload); // uncomment once APIs are Live
        for (let i = 0; i < result.data.data.length; i++) {
            result.data.data[i].isChecked = false;
        }

        // result = FETCHSCORINGLIST;
        yield put(doAddTFLScoringList(result.data.data, result.Count, deviceType));
        yield put(doHideLoader());
    } catch (error) {
        yield put(doFetchTFLScoringErrors(error));
        yield put(doHideLoader());
    }
}



export function* hanldefetchPMAScoreBreakdown(action) {
  yield put(doShowLoader());
  const { reviewType, deviceType, paramObj } = action;
  const filterState = yield select((state) => state.filterState);
  const query = getScheduleListParams(filterState);
  try {
    let result;
    result = yield call(fetchPMAScoreBreakdown, paramObj); // uncomment once APIs are Live
    // result = FETCH_PMA_SCORE_BK_LIST;
    yield put(doAddTflBreakdownScoreList(result.data.data, result.Count, deviceType));
    yield put(doHideLoader());
  } catch (error) {
    yield put(doFetchBreakdownScoreErrors(error));
    yield put(doHideLoader());
  }
}

export function* handleResetStatus(requestPayload) {
  yield put(doShowLoader());
  try {
    let result;
    result = yield call(resetScore, requestPayload.payload);
    if (result.status === 200) {
      yield put(doFetchScoreList('score', requestPayload.payload));
    } else {
      yield put(doHideLoader(null, 'resetStatus'));
    }
  } catch (error) {
    yield put(doHideLoader());
  }

}

export function* handleApproveStatus(requestPayload) {
  yield put(doShowLoader());
  try {
    let result;
    result = yield call(approveScore, requestPayload.payload);
    if (result.status === 200) {
      yield put(doFetchScoreList('score', requestPayload.payload));
    } else {
      yield put(doHideLoader(null, 'approveStatus'));
    }
  } catch (error) {
    yield put(doHideLoader());
  }

}

export function* handleFetchScoreOverrideData(action) {
  const { query } = action;
  try {
    let result;
    result = yield call(fetchScoreOverriedData, query);
    yield put(doAddScoreOverriedData(result.data.data));
    // result = SCORE_OVERRIDE_DATA;
    // yield put(doAddScoreOverriedData(result.data));
    yield put(doHideLoader());
  } catch (error) {
    console.log(error);
  }
}

export function* handleDeleteScoreOverrideFiles(requestPayload) {
  try {
    let result;
    result = yield call(deleteSelectedOverrideFiles, requestPayload.payload);
    yield put(deleteRespScoreOverride(result.data));
    if (result.status === 200) {
      yield put(doFetchScoreOverrideData(requestPayload.payload.History_Id));
    }
  } catch (error) {
    console.log(error);;
  }
}

export function* handleSaveScoreOverrideData(payload) {
  try {
    let result;
    result = yield call(savePMAOverRideScore, payload);
    yield put(saveScoreOverrideResp(result.data));
    if (result.status === 200) {
      yield put(doFetchScoreOverrideData(payload.payload.History_Id));
    }
  } catch (error) {
    console.log(error);
  }
}
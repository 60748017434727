import {
  STORIES_ADD,
  STORIES_FETCH,
  STORIES_FETCH_ERROR,
    STORIES_SORT,
    STORIES_FILTER,
    STORIES_PAGE_CHANGE,
    RESET_SORT_FILTER,
    SCHEDULE_DROPDOWNS_FETCH,
    SCHEDULE_ADD_DROPDOWN,
    DO_SHARE_REVIEW_STATUS,
    REMOVE_FILTER,
    ADD_FILTER,
    UN_AUTHORIZED_USER,
    FETCH_OKTA_USERS
} from '../constants/actionTypes';

const doAddStories = (stories, count, deviceType) => ({
  type: STORIES_ADD,
  stories,
    count,
    deviceType
});

const doShareReviewStatus = (status) => ({
    type: DO_SHARE_REVIEW_STATUS,
    status
});
const doFetchScheduleDropDowns = (statusType) => ({
    type: SCHEDULE_DROPDOWNS_FETCH,
    statusType

});
const doFilterStories = (reviewType) => ({
    type: STORIES_FILTER,
    reviewType
});

const doRemoveFilter = (obj) => ({
  type: REMOVE_FILTER,
  obj
})

const doSortStories = (sortBy,reviewType) => ({
    type: STORIES_SORT,
    sortBy,
    reviewType,
});

const doResetSortAndFilter = (reviewType) => ({
    type: RESET_SORT_FILTER,
    reviewType
});

const changeStoriesPage = (pageNo,reviewType,deviceType) => ({
    type: STORIES_PAGE_CHANGE,
    pageNo,
    reviewType,
    deviceType
});

const doFetchStories = reviewType => ({
  type: STORIES_FETCH,
  reviewType,
});

const doFetchErrorStories = error => ({
  type: STORIES_FETCH_ERROR,
  error,
});

const doAddToDropdowns = (optionsData, assessmentType) => ({
    type: SCHEDULE_ADD_DROPDOWN,
    optionsData,
    assessmentType
});

const doAddFilter = (obj, reviewType) => ({
    type: ADD_FILTER,
    obj,
    reviewType
});

const unAuthorisedUser = (payload) => ({
    type: UN_AUTHORIZED_USER,
    payload
})
const fetchOktaUsers = () => ({
  type: FETCH_OKTA_USERS
})

export {
  doAddStories,
  doFetchStories,
  doSortStories,
  doFilterStories,
  doFetchErrorStories,
  changeStoriesPage,
  doResetSortAndFilter,
  doFetchScheduleDropDowns,
  doAddToDropdowns,
  doShareReviewStatus,
  doRemoveFilter,
  doAddFilter,
  unAuthorisedUser,
  fetchOktaUsers
};
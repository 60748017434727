import {
    FETCH_USER_ACCESS_ROLES,
    FETCH_USER_ACCESS_ROLES_SUCCESS,
    FETCH_USER_ACCESS_ROLES_ERROR,
    SET_SECURED
} from '../constants/actionTypes';

const INITIAL_STATE = {
    userAccessRoles:'',
    userAccessRolesError:'',
    isFetching:false,
    forSecured:false
}

function userAccessRolesReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case FETCH_USER_ACCESS_ROLES : {
            return {...state,isFetching:true}
        }
        case FETCH_USER_ACCESS_ROLES_SUCCESS : {
            return {...state,userAccessRoles:action.payload,isFetching:false}
        }

        case SET_SECURED: {
            return {...state, forSecured:true}
        }

        case FETCH_USER_ACCESS_ROLES_ERROR : {
            return {...state,userAccessRolesError:action.error,isFetching:false}
        }

        default : return state;
    }
}

export default userAccessRolesReducer;
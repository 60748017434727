import React, { Component } from 'react';
import {connect} from "react-redux";
import {doFetchStories, doResetSortAndFilter,changeStoriesPage} from "../../actions/story";
import Stories from "./Stories";
import Filter from "../Common/Filter";
import { getPaginationDetails, getUnAuthResponse } from '../../selectors/story';
import UnauthorizePage from "../Common/UnauthorizePage"

import {doFetchProgramsList} from "../../actions/programsList";

export class Schedule extends Component {

    componentDidMount() {
        document.title = "Actions";
        // Actions being fetched from Filters, so commenting below lines
        //this.props.onResetSortAndFilters('action');
        //this.props.onFetchStories('action');
        this.props.onFetchProgramsList();
        document.body.classList.remove('nav-open');
        document.body.classList.remove('filter-open');
    }

    addActionDetails = (actionDetails, qId, activePage) => {
        // this.props.addActionDetails(actionDetails, qId);
       // this.props.history.push(`/actions`);
     //  this.props.onResetSortAndFilters('action');
     //  this.props.onFetchStories('action');
      this.props.onPageChange((this.props.paginationData && this.props.paginationData.activePage) || 0);
     }

    render() {
        return (
            <div>
            {this.props.unauthUser && this.props.unauthUser.StatusCode===401 ? (
                <UnauthorizePage msg={this.props.unauthUser.Message} />
            ) : (
             <div>   
            <Stories addActionDetails={this.addActionDetails} activePage = {this.props.paginationData.activePage}/>
            <Filter
              reviewType="action"
              filtersToDisplay={{
                        dealer_list: true,
                        assessment_template_lookup: true,
                        title: true,
                        status_lookup: true,
                        assignee: true,
                        priority: true,
                        due_date: true,
                        creator: true,
                        program_list: true,
                        sap_dealer_code: false,
                        year: false,
                        investigation_title: false,
                        stage: false,
                        closed_on_date: false,
                        actionProgramyear: true

                    }}
            />
           </div>
            )}
          </div>

        );
    }
}


const mapStateToProps = (state,ownProps) => ({
  paginationData: getPaginationDetails(state),
  unauthUser:getUnAuthResponse(state)
});

const mapDispatchToProps = (dispatch) => ({
    onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onFetchStories: reviewType => dispatch(doFetchStories(reviewType)),
    onPageChange: (id) => dispatch(changeStoriesPage(id,'action')),
    onFetchProgramsList: query => dispatch(doFetchProgramsList(query)),
});

export const Action = connect(
    mapStateToProps,
    mapDispatchToProps
)(Schedule);

import React from "react";
import Select from "react-select";

import  { multiselectOptions} from "../../constants/uploadSOPConstants";

import MultiSelectDropdown from '../Common/MultiSelectDropdown';

const LocationList = (props) => {
    let selectObj={};
    // if (props.reviewType === 'score') {
    //     selectObj = {
    //         optionId: "Dealer_ID",
    //         optionValue: "DealerNamesuburb"
    //     }
    // }
    // else {
        selectObj = {
            optionId: "Dealer_ID",
            optionValue: "Location_Name"
        }
    //}
    return (
        <div className={`form-group custom-multiselect ${props.selectedOption && props.selectedOption.length > 0 ? '' : 'not-selected'}`}>
            <label htmlFor="location" id="location__label">
                Location (All Tabs) 
            </label>
            
            <MultiSelectDropdown
                forEle="location"
                handleChange={props.onSelectDealer}
                dropDownList={props.locationsDropDownList}
                value={props.selectedOption} selectObj={selectObj}
                reference={props.reference}
            /> 
        </div>


    )
}

export default LocationList;





import React, { Component } from 'react';

const DisplaySOPListHeader = ({onSort, toggleFilter, filterLabel,paginationData,myForwardedRef}) => (
  <div className="grid__labels d-none d-md-none d-lg-block">
    <div className="row grid__columns-wrapper">
      <div className="col-md-2 grid__label-sort" id="program__sort" onClick="">
            Program
        <span className="grid__sort-icons">
          <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
          <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
          {/* remove "grid__sort-down-arrow--selected" class to show light gray icons */}
        </span>
      </div>
      <div className="col-md-2 grid__label-sort" id="sop-name__sort" onClick="">
            SOP Name
        <span className="grid__sort-icons">
          <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
          <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
          {/* remove "grid__sort-down-arrow--selected" class to show light gray icons */}
        </span>
      </div>
      <div className="col-md-2" id="doc-name__sort">Document Name</div>
      <div className="col-md-2" id="doc-ver__sort">Document Version</div>
      <div className="col-md-2" id="upload-date__sort">Uploaded Date</div>
      <div className="col-md-2" id="expiry-date__sort">
            Expiry Date
        <span className="grid__sort-icons">
          <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
          <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
          {/* remove "grid__sort-down-arrow--selected" class to show light gray icons */}
        </span>
      </div>
    </div>
  </div>
)

export default DisplaySOPListHeader;

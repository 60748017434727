import {call, put, select} from 'redux-saga/effects';
import {fetchUserAccessRoles} from '../api/userRoles';
import {fetchUserAccessRolesSuccess, fetchUserAccessRolesError} from "../actions/userAccessRoles";
import {doHideLoader, doShowLoader} from "../actions/loader";


export function* handleFetchUserAccessRoles() {
    try {
        const result = yield call(fetchUserAccessRoles);

        yield put(fetchUserAccessRolesSuccess(result));

    } catch (error) {
        yield put(fetchUserAccessRolesError(error));
    }
}
import { call, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
doPostAuditLogData,
doFetchAuditLogSuccess,
doFetchAuditLogError
} from '../actions/auditLog';
import {
   postAuditLog
} from '../api/audit_log';

export function* handleAuditLogData(requestPayload) {
    try {
        let result;
        result = yield call(postAuditLog, requestPayload.payload);
         yield put(doFetchAuditLogSuccess(result));
        } catch (error) {
        yield put(doFetchAuditLogError(error));
    }
}
import React, { Component }  from 'react';

class UnauthorizePage extends React.Component {
  constructor(props) {
    super(props);
  }
  
  componentDidMount() {
    document.title = "Unauthorized resource";
  }
  
  
  
  render() {
    const defaultMsg = "You are not authorized to access the application.";
    return (
      <div className="container-fluid logout-screen h-100">
        <div className="row grid__columns-wrapper h-100">
            <div className="col-12 text-center align-self-center">
                <div className="login__box position-relative">
                    <div className="login-content ">
                        <p className="text-f-16">{this.props.msg || defaultMsg}</p>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default UnauthorizePage;
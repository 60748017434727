export const STORY_ARCHIVE = 'STORY_ARCHIVE';
export const STORIES_FETCH = 'STORIES_FETCH';
export const STORIES_FETCH_ERROR = 'STORIES_FETCH_ERROR';
export const STORIES_ADD = 'STORIES_ADD';
export const STORIES_SORT = 'STORIES_SORT';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const STORIES_FILTER = 'STORIES_FILTER';
export const RESET_SORT_FILTER = 'RESET_SORT_FILTER';
export const STORIES_PAGE_CHANGE = 'STORIES_PAGE_CHANGE';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';
export const SHOW_LOADER_MODAL = 'SHOW_LOADER_MODAL';
export const HIDE_LOADER_MODAL = 'HIDE_LOADER_MODAL';
export const SCHEDULE_DROPDOWNS_FETCH = 'SCHEDULE_DROPDOWNS_FETCH';
export const SCHEDULE_ADD_DROPDOWN = 'SCHEDULE_ADD_DROPDOWN';
export const CLEAR_REVIEW_DATA = 'CLEAR_REVIEW_DATA';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const ADD_FILTER = 'ADD_FILTER';

// For Upload SOP Docs module - START
export const FETCH_LOCATIONS_LIST = "FETCH_LOCATIONS_LIST";
export const FETCH_LOCATIONS_LIST_SUCCESS = "FETCH_LOCATIONS_LIST_SUCCESS";
export const FETCH_LOCATIONS_LIST_ERROR = "FETCH_LOCATIONS_LIST_ERROR";
export const FILTER_ACTIVE_LOCATION = "FILTER_ACTIVE_LOCATION";


export const ADD_PROGRAMS_LIST = "ADD_PROGRAMS_LIST";
export const FETCH_PROGRAMS_LIST = "FETCH_PROGRAMS_LIST";
export const FETCH_PROGRAMS_LIST_ERROR = "FETCH_PROGRAMS_LIST_ERROR";

export const ADD_SOPNAMES_LIST = "ADD_SOPNAMES_LIST";
export const FETCH_SOPNAMES_LIST = "FETCH_SOPNAMES_LIST";
export const FETCH_SOPNAMES_LIST_ERROR = "FETCH_SOPNAMES_LIST_ERROR";

export const FETCH_DEALERSOP_DOCS_LIST = "FETCH_DEALERSOP_DOCS_LIST";
export const ADD_SOPDOCS = "ADD_SOPDOCS";
export const ADD_SOPDOCS_FORMFIELDS = "ADD_SOPDOCS_FORMFIELDS";
export const SORT_SOPDOCS = "SORT_SOPDOCS";
export const FETCH_ERROR_SOPDOCS = "FETCH_ERROR_SOPDOCS";

export const UPLOAD_SOP_DOCUMENT = "UPLOAD_SOP_DOCUMENT";
export const UPLOAD_SOP_DOCUMENT_ERRORS = "UPLOAD_SOP_DOCUMENT_ERRORS";
// For Upload SOP Docs module - END

// For Create/Update Actions - START
export const ADD_ASSIGNTO_LIST = "ADD_ASSIGNTO_LIST";
export const FETCH_ASSIGNTO_LIST = "FETCH_ASSIGNTO_LIST";
export const FETCH_ASSIGNTO_LIST_ERROR = "FETCH_ASSIGNTO_LIST_ERROR";

export const CREATE_ACTION = "CREATE_ACTION";
export const CREATE_ACTION_DATA = "CREATE_ACTION_DATA";
export const UPDATE_ACTION = "UPDATE_ACTION";



export const ACTION_ERRORS = "ACTION_ERRORS";
export const FETCH_ACTIONDATA = "FETCH_ACTIONDATA";
export const FETCH_ERROR_ACTIONDATA = "FETCH_ERROR_ACTIONDATA";
// For Create/Update Actions - END
export const FETCH_SINGLE_REVIEW = "FETCH_SINGLE_REVIEW";
export const REVIEW_FETCH_ERROR = "REVIEW_FETCH_ERROR";
export const ADD_SINGLE_REVIEW = "ADD_SINGLE_REVIEW";
export const ADD_GROUP_ID = "ADD_GROUP_ID";
export const SAVE_SINGLE_REVIEW = "SAVE_SINGLE_REVIEW";
export const UPDATE_SINGLE_REVIEW = "UPDATE_SINGLE_REVIEW";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const VALIDATE_SINGLE_REVIEW = "VALIDATE_SINGLE_REVIEW";
export const ADD_ACTION_DETAILS = "ADD_ACTION_DETAILS";
export const Do_COMPLETE_REVIEW = "Do_COMPLETE_REVIEW";
export const FETCH_ACTIONDATA_SUC = "FETCH_ACTIONDATA_SUC";
export const CLEAR_DATA = "CLEAR_DATA";
export const ADD_QUESTION_SOP = 'ADD_QUESTION_SOP';
export const ADD_QUESTION_DOCUMENT = "ADD_QUESTION_DOCUMENT";
export const DO_SUCCESS = "DO_SUCCESS";
export const DELETE_DOCUMENT = "DELETE_DOCUMENT";
export const ADD_QUESTION_SOP_DOCUMENT = 'ADD_QUESTION_SOP_DOCUMENT';
export const DO_SHARE_REVIEW = "DO_SHARE_REVIEW";
export const DO_SHARE_REVIEW_STATUS = "DO_SHARE_REVIEW_STATUS";
export const ADD_RECOMMENDATION = 'ADD_RECOMMENDATION';
export const UPDATE_RECOMMENDATION = 'UPDATE_RECOMMENDATION';
export const FETCH_TFL18_ASSESSMENT = 'FETCH_TFL18_ASSESSMENT';
export const FETCH_TFL18_ASSESSMENT_DATA = 'FETCH_TFL18_ASSESSMENT_DATA';
export const FETCH_TFL18_ASSESSMENT_ERROR = 'FETCH_TFL18_ASSESSMENT_ERROR';
export const SCORE_DATA = 'SCORE_DATA';
export const SCORE_DATA_SUCCESS = 'SCORE_DATA_SUCCESS';
export const SCORE_DATA_ERROR = 'SCORE_DATA_ERROR';
export const UPDATESCORE_DATA = 'UPDATESCORE_DATA';
export const UPDATESCORE_DATA_SUCCESS = 'UPDATESCORE_DATA_SUCCESS';
export const UPDATESCORE_DATA_ERROR = 'UPDATESCORE_DATA_ERROR';
export const TFLSCORELISTING_DATA = 'TFLSCORELISTING_DATA';
export const TFLSCORELISTING_SUCCESS = 'TFLSCORELISTING_SUCCESS';
export const TFLSCORELISTING_ERROR = 'TFLSCORELISTING_ERROR';
/** ***DEALER INFO**** */
export const FETCH_DEALER_INFO_BY_ID = 'FETCH_DEALER_INFO_BY_ID';
export const FETCH_DEALER_INFO_BY_ID_SUCCESS = 'FETCH_DEALER_INFO_BY_ID_SUCCESS';
export const FETCH_DEALER_INFO_BY_ID_ERROR = 'FETCH_DEALER_INFO_BY_ID_ERROR';

/** ***UNAUTHORIZED USER**** */
export const UN_AUTHORIZED_USER = 'UN_AUTHORIZED_USER ';
export const UN_AUTHORIZED_UPLOAD_SOP = 'UN_AUTHORIZED_UPLOAD_SOP ';
export const UN_AUTHORIZED_REVIEW = 'UN_AUTHORIZED_REVIEW ';

export const FETCH_OKTA_USERS = 'FETCH_OKTA_USERS';

export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const CLEAR_DOC_DATA = 'CLEAR_DOC_DATA';
export const POPULATE_KPI_DATA = 'POPULATE_KPI_DATA';


/*******--Start review--********/
export const FETCH_START_REVIEW = 'FETCH_START_REVIEW';
export const FETCH_START_REVIEW_SUCCESS = 'FETCH_START_REVIEW_SUCCESS';
export const FETCH_START_REVIEW_ERROR = 'FETCH_START_REVIEW_ERROR';

/*******--Start review--********/
export const FETCH_BRAND_NAME = 'FETCH_BRAND_NAME';
export const FETCH_BRAND_NAME_SUCCESS = 'FETCH_BRAND_NAME_SUCCESS';
export const FETCH_BRAND_NAME_ERROR = 'FETCH_BRAND_NAME_ERROR';


/***********TFL Score view  */

export const TFL_SCORE_STORIES_ADD = "TFL_SCORE_STORIES_ADD";
export const TFL_SCORE_STORIES_FETCH = "TFL_SCORE_STORIES_FETCH";
export const TFL_SCORE_STORIES_FETCH_ERROR = "TFL_SCORE_STORIES_FETCH_ERROR";
export const FETCH_TFL_SCORE_LIST = "FETCH_TFL_SCORE_LIST";
export const TFL_SCORE_LIST_ERRORS = "TFL_SCORE_LIST_ERRORS";
export const ADD_TFL_SCORE_LIST = "ADD_TFL_SCORE_LIST";
/********* TFL Scores ****** */

export const SCORE_STORIES_ADD = "SCORE_STORIES_ADD";
export const SCORE_STORIES_FETCH = "SCORE_STORIES_FETCH";
export const SCORE_STORIES_FETCH_ERROR = "SCORE_STORIES_FETCH_ERROR";

export const ADD_SCORE_LIST = "ADD_SCORE_LIST";
export const FETCH_SCORE_LIST = "FETCH_SCORE_LIST";
export const SCORE_LIST_ERRORS = "SCORE_LIST_ERRORS";

export const ADD_BREAKDOWN_SCORE_LIST = "ADD_BREAKDOWN_SCORE_LIST";
export const FETCH_BREAKDOWN_SCORE_LIST = "FETCH_BREAKDOWN_SCORE_LIST";
export const BREAKDOWN_SCORE_LIST_ERRORS = "BREAKDOWN_SCORE_LIST_ERRORS";
export const APPROVE_STATUS = "APPROVE_STATUS";
export const RESET_STATUS = "RESET_STATUS";
export const FETCH_SCOREOVERRIDE_DATA = "FETCH_SCOREOVERRIDE_DATA";
export const ADD_SCOREOVERRIDE_DATA = "ADD_SCOREOVERRIDE_DATA";
export const DELETE_OVERRIDE_FILES = "DELETE_OVERRIDE_FILES";
export const SAVE_OVERRIDE_DATA = "SAVE_OVERRIDE_DATA";
export const SAVE_SCOREOVERRIDE_RESPONSE = "SAVE_SCOREOVERRIDE_RESPONSE";
export const DELETE_SCOREOVERRIDE_RESPONSE = "DELETE_SCOREOVERRIDE_RESPONSE";


/************** FETCH_USER_ACCESS_ROLES ***********/

export const FETCH_USER_ACCESS_ROLES = 'FETCH_USER_ACCESS_ROLES';
export const FETCH_USER_ACCESS_ROLES_SUCCESS = 'FETCH_USER_ACCESS_ROLES_SUCCESS';
export const SET_SECURED = 'SET_SECURED';
export const FETCH_USER_ACCESS_ROLES_ERROR = 'FETCH_USER_ACCESS_ROLES_ERROR';

/**********************************  INVESTIGATION ACTIONS  ******************************************** */

export const FETCH_INVESTIGATION_RECORDS = "FETCH_INVESTIGATION_RECORDS";
export const POPULATE_INVESTIGATION_LIST = "POPULATE_INVESTIGATION_LIST";
export const INVESTIGATION_LIST_ERRORS = "INVESTIGATION_LIST_ERRORS";
export const FETCH_INVESTIGATION_CREATE_DATA = "FETCH_INVESTIGATION_CREATE_DATA";
export const POPULATE_INVESTIGATION_CREATE_DATA = "POPULATE_INVESTIGATION_CREATE_DATA";
export const INVESTIGATION_CREATE_DATA_ERRORS = "INVESTIGATION_CREATE_DATA_ERRORS";
export const POST_NEW_INVESTIGATION_DATA = "POST_NEW_INVESTIGATION_DATA";
export const SAVE_NEW_INVESTIGATION_RESPONSE = "SAVE_NEW_INVESTIGATION_RESPONSE";
export const FETCH_LOOKUP_STAGE_DATA = "FETCH_LOOKUP_STAGE_DATA";
export const FETCH_LOOKUP_STATUS_DATA = "FETCH_LOOKUP_STAGE_DATA";
export const LOOKUP_STATUS_ERRORS = "LOOKUP_STATUS_ERRORS";
export const LOOKUP_STAGE_ERRORS = "LOOKUP_STAGE_ERRORS";
export const POPULATE_LOOKUP_STATUS_DATA = "POPULATE_LOOKUP_STATUS_DATA";
export const POPULATE_LOOKUP_STAGE_DATA = "POPULATE_LOOKUP_STAGE_DATA";
export const DELETE_INVESTIGATION_FILES = "DELETE_INVESTIGATION_FILES";
export const DELETE_INVESTIGATION_RESPONSE = "DELETE_INVESTIGATION_RESPONSE";
export const POST_UPDATE_INVESTIGATION_DATA = "POST_UPDATE_INVESTIGATION_DATA";
export const SAVE_UPDATE_INVESTIGATION_RESPONSE = "SAVE_UPDATE_INVESTIGATION_RESPONSE";

export const SET_USER_TYPE = "SET_USER_TYPE";
/*************************************   AUDIT LOG ACTIONS **********************************************  */
export const POST_AUDIT_LOG_DATA = "POST_AUDIT_LOG_DATA";
export const FETCH_AUDIT_LOG_ERROR = "FETCH_AUDIT_LOG_ERROR";
export const FETCH_AUDIT_LOG_SUCCESS= "FETCH_AUDIT_LOG_SUCCESS";


/***** */
export const UPDATE_CSV_REVIEW = 'UPDATE_CSV_REVIEW';

export const DO_CLEAR_CSV_ERR = 'DO_CLEAR_CSV_ERR';

const getLocationsList = ({locationListState}) => {
    return locationListState.locationsList;
}


const getProgramsList = ({programsListState}) => {
    return programsListState.programsList;
}

const getSOPNamesList = ({sopNamesListState}) => {
    return sopNamesListState.sopNamesList;
}

const getDealerLocationId = ({uploadSOPState}) => {
    const uploadFormFields = uploadSOPState.uploadFormFields;
    if (uploadFormFields != null) {
        return uploadFormFields.selectedLocation;
    }
}

const getDealerSOPListForDisplay = ({uploadSOPState}) => {
    return uploadSOPState.dealerSopDocsList;
}

const getUnAuthSOP = ({ uploadSOPState }) =>{
    return uploadSOPState.sopResponseStatus ? uploadSOPState.sopResponseStatus : ''; 
}


const getDealerSOPListQuery = (uploadSOPState, action) => {

    const selectedProgram = action.sopdropselect !== null && action.sopdropselect !== undefined ? action.sopdropselect.id : "";
    const selectedLocation = action.selectedLocation !== null && action.selectedLocation !== undefined ? action.selectedLocation.id : "";
    const selectedStatus = action.selectedStatus!== null && action.selectedStatus !== undefined ? action.selectedStatus: "";
    const sortOrder = "desc";
    const sortField = "Sop_Name";

    let query = `is_active=${selectedStatus}&Program_Id=${selectedProgram}&dealer_ID=${selectedLocation}`;
    query += `&orderby=${sortOrder}&field=${sortField}`;
    return ({
        query,
    })
}

const getUploadSOPDocQuery = (baseUrl, selectedLocation, sopdropselect, programselect, selectedFileForUpload, expiry_date) => {


    let sopNames = "";
    const sopdropdownNames = sopdropselect.map(sopDoc => {
        if (sopNames !== undefined && sopNames.length > 0) {
            sopNames = sopNames.concat(",");
        }

        sopNames = sopNames.concat(sopDoc.value);
        return sopNames;
    });

    let url = baseUrl;
    let programselectId = programselect !== null && programselect !== undefined ? programselect.id : null;
    url = url.concat("?Dealer_ID=");
    url = url.concat(selectedLocation);
    url = url.concat("&Sop_Name=");
    url = url.concat(sopNames);
    // url = url.concat(sopdropselect.value);
    url = url.concat("&Document_Name=");
    url = url.concat(selectedFileForUpload.name);
    url = url.concat("&Expiry_Date=");
    url = url.concat(expiry_date);
    url = url.concat("&Program_Id=");
    url = url.concat(programselectId);
    return url;
}

const getFormDataForUploadSOP = selectedFileForUpload => {
    // FileUpload functionality
    const formdata = new FormData();
    formdata.append(
        "file",
        selectedFileForUpload,
        selectedFileForUpload.name
    );
    return formdata;
}

export {
    getDealerSOPListQuery, getLocationsList, getProgramsList,
    getDealerLocationId, getSOPNamesList, getDealerSOPListForDisplay,
    getUploadSOPDocQuery, getFormDataForUploadSOP, getUnAuthSOP
};

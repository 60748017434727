
import React from "react";
import { Skeleton } from '@mui/material';
import TruncateMarkup from 'react-truncate-markup';
import Skeleton_ReviewQuestion from "./Skeleton_ReviewQuestion"
function ReturnSkeleton(program) {
  return (
    <Skeleton_ReviewQuestion programName={program} />
  )
}

export default function SkeletonInProgress(props) {
  return (

    <div className="main-content-skeleton main-content-conduct-assessment">
      <div className="small-popup-wrap">
      </div>
      <div className={`page-header page-header-conduct-assessment`}>
        <div className="row">
          <div className={`col-md-5 col-lg-6 col-12`}>
            {
              <div className="h2" >
                <Skeleton variant="text" className="col-md-6 col-lg-3 grid__column grid__dealer-name grid__order-md-1 mar-md-b-10" />
              </div>
            }

            {<div >


              <div className="row m-t-10">
                <div className="col-6">
                  <Skeleton variant="rectangular" className='location_skeleton' />
                </div>

                <div className="col-6">
                </div>

                <div className="col-12 col-md-6">
                  <div className="dealership-location">
                    <Skeleton variant="text" className='location_details_skeleton' />
                  </div>
                </div>
                <div className="col-12 col-md-6 text-right text-m-left mar-tn-10">
                </div>
              </div>
            </div>}
          </div>
          <div className="col-md-7 col-lg-6 d-none d-sm-none d-md-block">
            <div id='skeleton-button-container' className="show-filters">
              {props.programName === "TFL"  && (
                <>
                  <Skeleton variant="rectangular" id='skeleton_export' className='btn-skeleton' />
                  <Skeleton variant="rectangular" id='skeleton_score' className='btn-skeleton' />
                  <Skeleton variant="rectangular" id='skeleton_share' className='btn-skeleton' />
                </>)
              }
              {props.programName === "Lexus" && 
                  <Skeleton variant="rectangular" id='skeleton_share' className='btn-skeleton' />
              }
                {props.programName === "DPOK"  && (
                <>
                  <Skeleton variant="rectangular" id='skeleton_export' className='btn-skeleton' />
                  <Skeleton variant="rectangular" id='skeleton_score' className='btn-skeleton' />
                  <Skeleton variant="rectangular" id='skeleton_share' className='btn-skeleton' />
                </>)
              }
            </div>
          </div>
        </div>


      </div>
      <div className="legend-wrapper d-none d-sm-none d-md-block">
        <div className="row grid__columns-wrapper">
          <div className="col-sm-12">

          </div>
        </div>
      </div>
      <div className="SkeletonSectionCointainer">
        <div class="sectionName">
        {props.programName==="TFL"&& <Skeleton variant="text" height={67} id="SkeletonSectionNameTFL" className="SectionHeading" />}
          {props.programName!=="TFL"&& (
            <>
            <div className="sectionNameContainer">
            <Skeleton variant="text" height={67} id="SkeletonSectionName" className="SectionHeading" />
            <Skeleton variant="text" height={67} id="SkeletonSectionName" className="SectionHeading" />
            <Skeleton variant="text" height={67} id="SkeletonSectionName" className="SectionHeading" />
            <Skeleton variant="text" height={67} id="SkeletonSectionName" className="SectionHeading" />
            <Skeleton variant="text" height={67} id="SkeletonSectionName" className="SectionHeading" />
            <Skeleton variant="text" height={67} id="SkeletonSectionName" className="SectionHeading" />

            </div>
            </>
          )
          
          }
        </div>
      </div>
      <div className="tab-pane fade show active" role="tabpanel" aria-labelledby="tab04">

      </div>
      {[...Array(2)].map((n) => ReturnSkeleton(props.programName))}
      <div className={`buttons-wrapper text-center d-none d-sm-none m-t-30 d-md-block`}>
        <div id='skeleton-button-container_bottom' className="show-filters">
          <Skeleton variant="rectangular" id='skeleton_share' className='btn-skeleton' />
        </div>
      </div>
    </div>


  )
}
import React, {Component} from 'react';
import Pagination from "react-js-pagination";
import {connect} from "react-redux";
import {doSortStories, changeStoriesPage} from "../../actions/story";
import {getFetchError, getReadableStories} from "../../selectors/story";
import StoriesHeader from "./StoriesHeader";

export class InvestigationPagination extends Component {
    onPageChange(id) {
        this.props.onSelect();
        this.props.onPageChange(id);
    }

    render() {
        return (
          <div id="Investigation_Pagination" className="grid-pagination d-none d-sm-none d-md-block">
            <Pagination
              itemClass="grid-pagination__list-item list-inline-item"
              linkClass="grid-pagination__link"
              innerClass="grid-pagination__list list-unstyled"
              activeClass="active"
              activePage={this.props.paginationData.activePage}
              itemsCountPerPage={20}
              totalItemsCount={this.props.paginationData.totalCount}
              pageRangeDisplayed={10}
              onChange={(id) => this.onPageChange(id)}
              firstPageText="⟨⟨"
              lastPageText="⟩⟩"
            />
          </div>

        );
    }
}
const mapDispatchToProps = dispatch => ({
    onPageChange: (id) => {
        dispatch(changeStoriesPage(id,'investigation'));
    }
});

const mapStateToProps = (state,ownProps) => ({
   // paginationData: getPaginationDetails(state),
    onSelect: ownProps.onSelect
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvestigationPagination);
import React from 'react';
import { connect } from "react-redux";
import { doFetchScoreOverrideData, doDeleteOverrideFiles, doSaveOverrideData, doFetchScoreList } from "../../actions/TflScores";
import { getScoreOverrideResponse, fetchSavedScoreOverrideResp, fetchDeleteScoreOverrideResp } from '../../selectors/TflScores';
import Moment from 'react-moment';
import LoaderModal from "../Common/LoaderModal";
import {
    doShowModalLoader,
    doHideModalLoader
} from "../../actions/loader";

import is from 'is_js';
import { getBase64 } from "../../utils/utils";
import { validateUploadedFile } from "../../FormValidators/CreateActionValidator";
import { fieldErrorDiv } from "../../constants/uploadSOPConstants";
import { getCurrentUser } from "../../selectors/dropdown";
import moment from 'moment';
import {doPostAuditLogData} from "../../actions/auditLog";


class ScoreOverride extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileArray: [],
            hideTimer: true,
            uploadedFiles: [],
            filesTobeUploaded: [],
            selectedFileForUpload: new Map(),
            newScore: 0,
            userComments: "",
            showDownloadDelete: false,
            showMsg: false,
            saveBtnClicked: false,
            addFileMessage: '',
            deleteFileMessage: ''
        };
    }

    componentDidMount() {
        let history_Id = (this.props.scoreObj !== undefined && this.props.scoreObj.History_Id !== null) ? this.props.scoreObj.History_Id : null;
        this.props.onFetchScoreOverrideData(history_Id);
        this.hideModal();
    }

    componentDidUpdate(newProps) {
        if (newProps.saveResponse !== undefined && newProps.saveResponse !== null && newProps.saveResponse.hasOwnProperty("Success") && this.state.saveBtnClicked) {
            this.setState({
                showMsg: true,
                successful: newProps.saveResponse.Success,
                saveBtnClicked: false
            }, function () {
                setTimeout(() => {
                    this.setState({
                        showMsg: false
                    }, function () {
                        this.props.onClose();
                        this.props.history.push(`/TflScore`);
                        const payload = {
                            "Modified_By": this.props.currentUser ?
                                this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                                + ' (' + this.props.currentUser.employeeNumber + ')' : 'default'
                        }
                        this.props.onfetchScoringList('score', payload);
                    });
                }, 5000);
            });
        }
    }

    hideAlert() {
        this.setState({
            showMsg: false,
        });
    }


    manageForIOSDevice = (fileName, fileData, counter) => {
        let elem = '';
        const isIOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
        if (isIOS) {
            if (this.props.mode === 'CreateAction') {
                elem = '';
            } else {
                elem = <a href="#" className="m-r-20" id={`download-file-${counter}__action-link"`} download={fileName} onClick={(event) => { this.handleFileDownload(event, fileName, fileData) }}><img src="./../images/download.svg" alt="Download" /></a>
            }
        } else {
            elem = <a href="#" className="m-r-20" id={`download-file-${counter}__action-link"`} download={fileName} onClick={(event) => { this.handleFileDownload(event, fileName, fileData) }}><img src="./../images/download.svg" alt="Download" /></a>

        }
        return elem
    }

    // displayDocumentList = (selectedFileForUpload) => {
    //     const finalList = [];
    //     if (selectedFileForUpload) {
    //         let counter = 0;
    //         for (const obj of selectedFileForUpload) {
    //             const [fileName, [simpleBase64, fileData]] = obj;
    //             finalList.push(<li className={fileData.type.includes("image") ? "image-icon" : "doc-icon"} key={fileName.toString()}>
    //                 <span id="newlyAddedDoc" className="file-name">{fileName.toString()}</span>
    //                 <label className="custom-checkbox float-right">
    //                     <input type="checkbox" name="addNewFiles" id="addNewFiles"
    //                         onChange={(e) => this.uploadSelectedFiles(e, obj, fileName)} />
    //                     <span className="custom-checkmark"></span>
    //                 </label>
    //             </li>)
    //             counter++;
    //         }
    //     }
    //     return finalList;

    // }

    displayDocumentList = (selectedFileForUpload) => {
        const finalList = [];
        if (selectedFileForUpload) {
            let counter = 0;
            for (const obj of selectedFileForUpload) {
                const [fileName, [simpleBase64, fileData]] = obj;
                finalList.push(<li className={fileData.type.includes("image") ? "image-icon" : "doc-icon"} key={fileName.toString()} >
                    <span id="newlyAddedDoc" className="file-name" title={fileName.toString()}>{fileName.toString()}</span>
                    <div className="action-btns">
                        <div className="download-icon" onClick={(e) => this.downloadNewFiles(e, obj, fileName)}>
                            <a href="#">
                                <img src="../images/download.svg" width="16px">
                                </img>
                            </a>
                        </div>
                        <div className="delete-icon">
                            <a href="#">
                                <img src="../images/delete.svg" width="16px" onClick={(e) => this.deleteNewFiles(e, obj, fileName)} ></img>
                            </a>
                        </div>
                    </div>
                </li>)
                counter++;
            }
        }
        return finalList;

    }



    handleAddDocBtn(e) {
        let tempUploadArr = [...this.state.uploadedFiles];
        e.preventDefault();
        let msg = '';
        if(this.props.CessationDate === '') {
          msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' added  ' 
        + e.target.files[0].name + ' to ' + this.props.scoreOverrideObj.Location + ' on ' 
        + moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        else {
        msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' added  ' 
        + e.target.files[0].name + ' to ' + this.props.scoreOverrideObj.Location +  'with cessation date ' +
        this.props.CessationDate +' on ' 
        + moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
       
        
        this.setState({addFileMessage: msg });
        const fileObjectUpload = validateUploadedFile(e);
        const existingMap = this.state.selectedFileForUpload || new Map();
        if (fileObjectUpload !== undefined) {
            const fileBasedata = "";
            getBase64(fileObjectUpload).then((el) => {
                const arr = el.split("base64,")
                const simpleBase64 = arr[1];
                existingMap.set(new String(fileObjectUpload.name), [simpleBase64, fileObjectUpload]);
                this.setState({
                    selectedFileForUpload: existingMap,
                    fileUploadValid: true
                });
            });

        }
    }

    handleSaveBtnClick() {
        let payload = {
            "New_Score": this.state.newScore,
            "Comments": this.state.userComments,
            "History_Id": this.props.scoreObj.History_Id,
            "CreatedBy":  this.props.currentUser ?
                                this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                                + '(' + this.props.currentUser.employeeNumber + ')' : 'default',
            "Status_ID": 7,
            "Status": "Not Approved",
        }

        payload.Documents = [];
        for (const obj of this.state.selectedFileForUpload) {
            let tempArr = {};
            const [fileName, [simpleBase64, fileData]] = obj;
            tempArr.Doc_Name = fileName.toString();
            tempArr.data = simpleBase64;
            payload.Documents.push(tempArr);
        }
         
        let msg = '';
        if (this.props.CessationDate === '') {
            msg = this.state.addFileMessage + this.state.deleteFileMessage + this.props.currentUser.firstName + ' '
             + this.props.currentUser.lastName + ' override score for  ' + this.props.scoreOverrideObj.Location + 
             ' with original score ' + this.props.scoreObj.Score + ' to new score ' + this.state.newScore + 
             ' with comments ' + this.state.userComments + ' on ' + moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        else {
            msg =this.state.addFileMessage + this.state.deleteFileMessage + this.props.currentUser.firstName + ' ' 
            + this.props.currentUser.lastName + ' override score for  ' + this.props.scoreOverrideObj.Location + 
            ' with original score ' + this.props.scoreObj.Score + ' to new score ' +
            this.state.newScore + ' with comments ' + this.state.userComments  +  'with cessation date ' +
            this.props.CessationDate + ' on ' +  moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        const reqPayload = {
            LogMessage: msg,
            AuditLogModule: "TFL SAP Score",
            AcionAPISource: "PMASCoreOVerride",
            CreatedBy: this.props.currentUser ?
                this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                + '(' + this.props.currentUser.employeeNumber + ')' : 'default',
            CreatedOn: moment(new Date()).format('HH:MM DD MMM YYYY')
        }
        this.props.doPostAuditLogData(reqPayload);
        this.props.onSaveOverrideData(payload);
        this.clearSelectedCheckboxes("addNewFiles");
        this.setState({
            saveBtnClicked: true
        });
    }

    hideModal() {
        setTimeout(() => {
            this.setState({
                hideTimer: false
            });
        }, 5000);
    }


    handleFileDownload = (fileObj) => {
        window.URL = window.URL || window.webkitURL;
        const url = fileObj.BlobURI || window.URL.createObjectURL(fileObj);
        const a = document.createElement('a');
        a.href = url;
        a.download = fileObj.Doc_Name;
        if (is.safari()) {
            a.target = '_blank';
        }
        a.click();

    }

    handleNewScore(event) {
        try {
            let num = parseInt(event.target.value);
            if (isNaN(num)) {
                throw({convertTo: ''});
            } else if (num > 100) {
                throw({convertTo: 100});
            } else if (num < 0) {
                throw({convertTo: 0});
            }
            event.target.value = num;
        } catch (exc) {
            event.target.value = exc.convertTo;
            event.preventDefault();
        }
        this.setState({
            newScore: event.target.value
        });
    }

    deleteSelectedFiles(fileObj, historyObj) {
        let payload = {};
        payload.filesToBeDeleted = fileObj.Doc_Id;
        payload.PMAHistory_Id = historyObj.PMA_History_Id;
        payload.History_Id = this.props.scoreObj.History_Id;
        this.props.onDeleteOverrideFiles(payload);
         let msg = '';
        if(this.props.CessationDate === '') {
          msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' deleted  ' 
            + fileObj.Doc_Name.toString() + ' to ' + this.props.scoreOverrideObj.Location + ' on ' +
            moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        else {
        msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' deleted  ' 
            + fileObj.Doc_Name.toString() + ' to ' + this.props.scoreOverrideObj.Location + 'with cessation date ' +
            this.props.CessationDate +' on ' + moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        this.setState({deleteFileMessage:  msg });
    }

    // deleteNewFiles = () => {
    //     const existingMap = this.state.selectedFileForUpload || new Map();
    //     for (const obj of this.state.selectedFileForUpload) {
    //         const [fileName, [simpleBase64, fileData]] = obj;
    //         const selectedFile = this.state.filesTobeUploaded.filter(item => item.fileName === fileName);
    //         if (selectedFile && selectedFile.length > 0 && fileName === selectedFile[0].fileName) {
    //             existingMap.delete(fileName)
    //             this.setState({
    //                 selectedFileForUpload: existingMap,
    //                 fileUploadValid: true
    //             });
    //         }
    //     }
    //     this.clearSelectedCheckboxes("addNewFiles");
    // }

    deleteNewFiles = (e, obj, fileNameSel) => {
         let msg = '';
        if(this.props.CessationDate === '') {
          msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' deleted  ' 
            + fileNameSel.toString() + ' to ' + this.props.scoreOverrideObj.Location + ' on ' +
            moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        else {
        msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' deleted  ' 
            + fileNameSel.toString() + ' to ' + this.props.scoreOverrideObj.Location + 'with cessation date ' +
            this.props.CessationDate +' on ' + moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        this.setState({deleteFileMessage:  msg });
        
        const existingMap = this.state.selectedFileForUpload || new Map();
        for (const obj of this.state.selectedFileForUpload) {
            const [fileName, [simpleBase64, fileData]] = obj;
            if (fileName.toString() === fileNameSel.toString()) {
                existingMap.delete(fileName)
                this.setState({
                    selectedFileForUpload: existingMap,
                    fileUploadValid: true
                });
            }
        }
        this.clearSelectedCheckboxes("addNewFiles");

    }

    clearSelectedCheckboxes(param) {
        let elem = param !== undefined ? param : "";
        let checkboxes = document.getElementsByName(elem);
        for (let i in checkboxes) {
            if (checkboxes[i].checked) {
                checkboxes[i].checked = false;
            }
        }
    }

    uploadSelectedFiles(event, fileObj, fileName) {
        let tempArr = [...this.state.filesTobeUploaded];
        if (event.target.checked) {
            tempArr.push({ event: event, fileData: fileObj, fileName: fileName });
        } else {
            for (let i = tempArr.length; i--;) {
                if (tempArr[i].fileData.Document_Name === fileObj.Document_Name) {
                    tempArr.splice(i, 1);
                }
            }
        }
        this.setState({
            filesTobeUploaded: tempArr,
        });
    }

    // downloadNewFiles() {

    //     for (const obj of this.state.selectedFileForUpload) {
    //         const [fileName, [simpleBase64, fileData]] = obj;
    //         const selectedFile = this.state.filesTobeUploaded.filter(item => item.fileName === fileName);
    //         if (selectedFile && selectedFile.length > 0 && fileName === selectedFile[0].fileName) {
    //             if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
    //                 window.navigator.msSaveOrOpenBlob(fileData, fileName.toString());
    //             } else {
    //                 window.URL = window.URL || window.webkitURL;
    //                 const url = fileData.BlobURI || window.URL.createObjectURL(fileData);
    //                 const a = document.createElement('a');
    //                 a.href = url;
    //                 a.download = fileName;
    //                 if (is.safari()) {
    //                     a.target = '_blank';
    //                 }
    //                 a.click();
    //             }
    //         }
    //     }
    //     this.clearSelectedCheckboxes("addNewFiles");

    // }

    downloadNewFiles(e, obj, fileNameSel) {
        for (const obj of this.state.selectedFileForUpload) {
            const [fileName, [simpleBase64, fileData]] = obj;
            if (fileName.toString() === fileNameSel.toString()) {
                if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
                    window.navigator.msSaveOrOpenBlob(fileData, fileName.toString());
                } else {
                    window.URL = window.URL || window.webkitURL;
                    const url = fileData.BlobURI || window.URL.createObjectURL(fileData);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = fileName;
                    if (is.safari()) {
                        a.target = '_blank';
                    }
                    a.click();
                }
            }
        }
        this.clearSelectedCheckboxes("addNewFiles");
    }


    handleCommentValue(e) {
        let comments = e.target.value;
        this.setState({
            userComments: comments
        });
    }

    render() {
        let score_override_obj = this.props.scoreOverrideObj;
        let isReadOnlyMode = (this.props.scoreObj.Status === "Not Approved" && this.props.scoreObj.is_Authorized) ? false : true;
        let showAddBtnCls = isReadOnlyMode ? "add-more hide" : "add-more";
        let alertClass = this.state.successful ? "alert alert-center alert-success alert-dismissible" : "alert alert-danger alert-dismissible";
        let message = this.state.successful ? "Score override successfully." : "Score override failed.";
        return (
            <div className="tflscore-popup" id="closePopUp">
                <div className="main-wrapper popup">
                    <div className="main-content row">
                        <div className="popup__wrapper align-self-center">
                            <div className="popup__header" id="headerDiv">
                                <h5>Score Override</h5>
                            </div>
                            {<LoaderModal mLoad={this.state.hideTimer} />}
                            {this.state.showMsg ?
                                <div className={alertClass} role="alert">
                                    <button type="button" id="messageButton" className="close" data-dismiss="alert" aria-label="Close" onClick={() => this.hideAlert()}><span aria-hidden="true">&times;</span></button>
                                    {' '}
                                    {message}
                                    {' '}
                                </div>
                                : null
                            }
                            <div className="popup__body">
                                <div className="form-row">
                                    <div className="col-7">
                                        <div className="tflscore-popup__left-col">
                                            <div className="form-row">
                                                <div className="col-8">
                                                    <div className="form-group">
                                                        <label className="popup__labels">Location</label>
                                                        <p className="popup__data">{score_override_obj.Location}</p>
                                                    </div>

                                                </div>
                                                <div className="col-4">
                                                    <div className="form-group">
                                                        <label className="popup__labels">Current Score</label>
                                                        <p className="popup__data">{score_override_obj && score_override_obj.History && score_override_obj.History.length > 0 ? score_override_obj.History[0].Newscore : score_override_obj && score_override_obj.OriginalScore}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="popup__score-history-wrap">
                                                <div className="form-group m-b-0">
                                                    <label className="popup__labels">Score History</label>
                                                </div>
                                                {(score_override_obj.History || []).map((historyObj, index) => (
                                                    <div className="form-row">
                                                        <div className="col-12">

                                                            <div className="form-group">
                                                                <p className="popup__date">
                                                                    <Moment format="YYYY-MM-DD">
                                                                        {historyObj.ScoreHistoryDate}
                                                                    </Moment>
                                                                </p>
                                                            </div>
                                                            <div className="popup__history-data">

                                                                <div className="form-row">
                                                                    <div className="col-3">
                                                                        <div className="form-group">
                                                                            <label className="popup__labels">Original Score</label>
                                                                            <p className="popup__data">{historyObj.OriginalScore}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-group">
                                                                            <label className="popup__labels">New Score</label>
                                                                            <p className="popup__data">{historyObj.Newscore}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <div className="form-group">
                                                                            <label className="popup__labels">Updated by</label>
                                                                            <p className="popup__data">{historyObj.UpdatdedBy}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="col-12">
                                                                        <div className="form-group">
                                                                            <label className="popup__labels">Comment</label>
                                                                            <p className="popup__data">{historyObj.Comment}</p>
                                                                        </div>
                                                                        <div className="document-list m-t-0">
                                                                            <ul>
                                                                                {(historyObj.Document || []).map((docObj, index) => {
                                                                                    if (docObj.Doc_Id !== null && docObj.Doc_Id !== undefined) {
                                                                                        return <li className="image-icon" key={index} >
                                                                                            <span className="file-name" title={docObj.Doc_Name}>{docObj.Doc_Name}</span>
                                                                                            <div className="action-btns">
                                                                                                <div className="download-icon" onClick={() => this.handleFileDownload(docObj)}>
                                                                                                    <a href="#">
                                                                                                        <img src="../images/download.svg" width="16px">
                                                                                                        </img>
                                                                                                    </a>
                                                                                                </div>
                                                                                                {!isReadOnlyMode ?
                                                                                                    <div className="delete-icon">
                                                                                                        <a href="#">
                                                                                                            <img src="../images/delete.svg" width="16px" onClick={() => this.deleteSelectedFiles(docObj, historyObj)} ></img>
                                                                                                        </a>
                                                                                                    </div>
                                                                                                    : null
                                                                                                }
                                                                                            </div>


                                                                                        </li>
                                                                                    } else {
                                                                                        return null;
                                                                                    }
                                                                                })}
                                                                            </ul>

                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                ))}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <div className="tflscore-popup__right-col">
                                            <div className="form-group">
                                                <label for="score" id="score">New Score<span className="required"> *</span></label>

                                                <input id="score" tabIndex={1} className="form-control" type="number" min={0} max={100} name="score" placeholder="Select Score" disabled={isReadOnlyMode}
                                                    onKeyUp={(e) => this.handleNewScore(e)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label for="score" id="score">Comment<span className="required"> *</span></label>
                                                <textarea rows={4} className="form-control" disabled={isReadOnlyMode}
                                                    onChange={(e) => this.handleCommentValue(e)} />
                                            </div>

                                            <div className="photos-document">
                                                <div className="photo-icon">
                                                    <img src="images/camera.svg" width="26px"></img>
                                                    <span>Photos & Documents</span>
                                                </div>
                                                <div className="add-more">
                                                    <label for="add-more" className={showAddBtnCls} >+ Add </label>
                                                    <input id="add-more" type="file" name="myFile" className="hide"
                                                        onChange={(e) => this.handleAddDocBtn(e)} onClick={(e) => {
                                                            e.target.value = null
                                                        }}
                                                    ></input>
                                                </div>


                                            </div>
                                            <div className="document-list">
                                                {/* {this.state.selectedFileForUpload.size > 0 ?
                                                    <div className="action-btns fixed-btns">
                                                        <div className="download-icon"><a href="#"><img width="16px" src="../images/download.svg"
                                                            onClick={() => this.downloadNewFiles()}></img></a></div>
                                                        <div className="delete-icon"><a href="#"><img width="16px" src="../images/delete.svg"
                                                            onClick={() => this.deleteNewFiles()}
                                                        ></img></a></div>
                                                    </div>
                                                    : null
                                                } */}
                                                <div id="uploadfileerror" style={fieldErrorDiv} />
                                                <ul>
                                                    {this.displayDocumentList(this.state.selectedFileForUpload)}
                                                </ul>

                                            </div>
                                            <div className="save-btn text-center m-t-30">
                                                <button className="btn btn-primary" id="score-override-saveBtn" onClick={() => this.handleSaveBtnClick()} disabled={(this.state.newScore !== "" && this.state.userComments !== "") ? false : true}>Save</button>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    scoreOverrideObj: getScoreOverrideResponse(state),
    saveResponse: fetchSavedScoreOverrideResp(state),
    deleteResponse: fetchDeleteScoreOverrideResp(state),
    currentUser: getCurrentUser(state.dropdownState),
});

const mapDispatchToProps = (dispatch) => ({
    onFetchScoreOverrideData: (query) => dispatch(doFetchScoreOverrideData(query)),
    showModalLoader: () => dispatch(doShowModalLoader()),
    hideModalLoader: () => dispatch(doHideModalLoader()),
    onDeleteOverrideFiles: (payload) => dispatch(doDeleteOverrideFiles(payload)),
    onSaveOverrideData: (payload) => dispatch(doSaveOverrideData(payload)),
    onfetchScoringList: (reviewType, payload) => dispatch(doFetchScoreList(reviewType, payload)),
    doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ScoreOverride);

import React from "react";
import {formatMomentDate, wordWrap} from "../../constants/utilityfunctions";

class DisplaySOPDocument extends React.Component {

    constructor(props) {
        super(props);
        this.state = {finalArray: []};
    }

    render() {
        const {
            Sop_Name,
            Document_Name,
            Document_Version,
            UploadDate,
            ExpiryDate,
            Program,
            BlobURI,
            DownloadFileName
        } = this.props.sopDoc;
        const rowId = this.props.rowIndex


        return (
            <div className="grid__row">
                <div className="row grid__columns-wrapper">
                    <div className="col-lg-2 col-md-4 col-5 grid__column" id={`program__row${rowId}`}>
                        <span className="grid__labels-mobile">Program name</span>
                        {Program}
                    </div>
                    <div className="col-lg-2 col-md-4 col-7 grid__column" id={`sop-name__row${rowId}`}>
                        <span className="grid__labels-mobile">SOP</span>
                        {Sop_Name}
                    </div>
                    <div className="col-lg-2 col-md-4 col-12 grid__column" id={`document__row${rowId}`}>
                        <span className="grid__labels-mobile">Document name</span>
                        <a href={BlobURI} id={`document-link__row${rowId}`} download={DownloadFileName} target='_blank'>
                            {/* <Ellipsis textToEllipsis={Document_Name} /> */}
                            {wordWrap(Document_Name, 13)}
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-5 grid__column grid__order-md-5 grid__order-sm-4 " id={`upload-date__row${rowId}`}>
                        <span className="grid__labels-mobile">Uploaded date</span>
                        {Document_Version}
                    </div>
                    <div className="col-lg-2 col-md-4 col-3 grid__column grid__order-md-4 grid__order-sm-5 " id={`doc-ver__row${rowId}`}>
                        <span className="grid__labels-mobile">Document Version</span>
                        {formatMomentDate(UploadDate)}
                    </div>
                    <div className="col-lg-2 col-md-4 col-4 grid__column grid__order-md-6 grid__order-sm-6 " id={`expiry-date__row${rowId}`}>
                        <span className="grid__labels-mobile">Expiry Date</span>
                        {formatMomentDate(ExpiryDate)}
                    </div>
                </div>
            </div>
        );
    }
}

export default DisplaySOPDocument;

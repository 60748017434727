import React from 'react';
import Popup from "reactjs-popup";
import ShareReview from './ShareReview';

const contentStyle = {
};

const preventDefault = (e) => {
  e.preventDefault();
  e.stopPropagation();
}

const PopUpShareReviewTrigger = (triggerAction, story, isOpen, callback, forItemMenu) => (

  <Popup
    trigger={triggerAction}
    modal
    contentStyle={contentStyle}
    closeOnDocumentClick
    closeOnEscape
    lockScroll
    open={isOpen}
    onClose={callback}
  >
    {close => (
      <div onClick={(e) =>preventDefault(e)}>
        <div className="close__wrapper">
        <div className="popup__close">
          <label onClick={close}><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
        </div>
        </div>
        
         
        <ShareReview
          reviewType={story.Assessment_Template_Name}
          reviewId={story.Assessment_ID}
          assessorName={story.Last_Modified_By}
          dealerLocation={story.Dealer_Name}
          dealer_ID={story.Dealer_ID}
          dealerEmail={story.Email}
          assessmentId={story.Assessment_ID}
          mine={true}
          handleClose={close}
          defaultMsg={story.defaultMsg}
          forItemMenu={forItemMenu}
        />
      </div>
    )}

  </Popup>
);

export {PopUpShareReviewTrigger};

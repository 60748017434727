import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { createGenerateClassName } from '@material-ui/core/styles';
import {doResetSortAndFilter, doSortStories} from "../../actions/story";
import {fetchTflBreakdownScoreList} from "../../actions/TflScores";
import {getTflBreakDownScoreList} from '../../selectors/TflScores';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: true,
    productionPrefix: '',
});

class TflScorebreakdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            locationName: this.props.location.state.Dealer_Name,
        };
        this.renderTabVal = 0;
        this.headerRef = React.createRef();
        this.successRef = React.createRef();
    }
    
    componentWillUnmount() {
    }
    componentDidMount() {
        let paramObj={};
        if(this.props && this.props.location !== undefined && this.props.location !== null){
            paramObj.dealer_id = this.props.location.state !== undefined ? this.props.location.state.dealer_Id: null;
            paramObj.history_id = this.props.location.state !== undefined ? this.props.location.state.history_Id: null ;
        }
        this.props.onfetchScoreBreakdownList('breakdownScore',paramObj);
        this.props.onResetSortAndFilters('score');
        document.title = "TFL SAP Scores Breakdown";
        if (this.props.getFilterStatus === 'Hide Filters') {
            this.props.onToggleFilter();
        }
        document.body.classList.remove('nav-open');
        document.body.classList.remove('filter-open');

        window.scrollTo(0, 0);
    }

    handleBackBtnClick(){
        this.props.history.push('/TflScore');
    }

    render() {
        return (
            <div>
            <div className="main-content error-screen text-center">
            <h2 className="m-t-50 m-b-30">Desktop only screen</h2>
            <a href="/home" className="btn btn-primary">Back to Home</a>
        </div>
            <div className="main-content tfl-score">
                <div>
                    <div className="page-header">
                        <div className="row">
                            <div className="col-12">
                                <h3>TFL SAP Scores Breakdown</h3>
                            </div>
                            <div className="col-6">
                                <h6>{this.state.locationName}</h6>
                            </div>
                            <div className="col-6 text-right">
                                <div className="total-score">Total:<b>
                                { (this.props.breakDownScoreListData !== undefined && this.props.breakDownScoreListData !== null  && this.props.breakDownScoreListData.length > 0) ?
                                    this.props.breakDownScoreListData[0].TFL_Score
                                    : 0
                                }</b></div>
                            </div>
                        </div>
                    </div>
                   
                    <div className="grid m-t-30">
                        <div className="grid__labels d-none d-md-none d-lg-block">
                            <div className="row grid__columns-wrapper">
                                <div id="location__sort" className="col-lg-4 col-md-4 grid__label-sort">

                                    Location
                                    <span className="grid__sort-icons">
                                        <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                        <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                    </span>
                                </div>
                                <div className="col-md-1">
                                    Level
                                 
                                </div>
                                <div className="col-md-1">
                                    TFL Score
                                 
                                </div>
                                <div className="col-md-1">
                                    Stage 1
                                 
                                </div>
                                <div className="col-md-1">
                                Stage 2
                                 
                                </div>
                                <div className="col-md-1">
                                Stage 3
                                 
                                </div>
                                <div className="col-md-1">
                                Stage 4

                                </div>
                                {/* <div className="col-md-1">
                                Score

                                </div> */}
                        
                              
                                <div id="cessation__sort" className="col-md-2 grid__label-sort">
                                    Cessation Date
                                    <span className="grid__sort-icons">
                                        <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                        <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                {this.props.breakDownScoreListData && this.props.breakDownScoreListData.length === 0 && this.props.loader !== 'show' && (
                    <div className="grid">
                        <div className="grid__row">
                            <div className="grid__columns-wrapper">
                                No Records Found.
                        </div>
                        </div>
                    </div>
                )}
                <div className="grid">
                {(this.props.breakDownScoreListData || []).map((brkScoreObj, index) => (
                    <a href="#" className="grid__row-link" key={index}>
                        <div className="grid__row">
                            <div className="row grid__columns-wrapper">
                                <div className="col-md-6 col-lg-4 grid__column grid__dealer-name mar-md-b-10">
                                    <span className="grid__labels-mobile">Location</span>
                                    {brkScoreObj.Location}
                                </div>

                                <div className="col-md-6 col-lg-1 grid__column grid__sapcode mar-md-b-10">
                                    <span className="grid__labels-mobile">Level</span>
                                    {brkScoreObj.Level}
                                </div>
                                <div className="col-md-6 col-lg-1 col-4 grid__column program mar-md-b-10">
                                    <span className="grid__labels-mobile">TFL</span>
                                    {brkScoreObj.TFL_Score}
                                </div>
                                <div className="col-md-6 col-lg-1 col-8 grid__column last-updated  mar-md-b-10">
                                    <span className="grid__labels-mobile">Breach1</span>
                                    {brkScoreObj.Stage1}
                                </div>
                                <div className="col-md-6 col-lg-1 col-4 grid__column ">
                                    <span className="grid__labels-mobile">Breach2</span>
                                    {brkScoreObj.Stage2}
                                </div>

                                <div className="col-md-6 col-lg-1 col-4 grid__column ">
                                    <span className="grid__labels-mobile">Breach3</span>
                                    {brkScoreObj.Stage3}
                                </div>

                                <div className="col-md-6 col-lg-1 col-4 grid__column ">
                                    <span className="grid__labels-mobile">Breach4</span>
                                    {brkScoreObj.Stage4}
                                </div>

                              {/*}  <div className="col-md-6 col-lg-1 col-4 grid__column ">
                                    <span className="grid__labels-mobile">Score</span>
                                    {brkScoreObj.Score}
                                </div> */}
                                <div className="col-md-6 col-lg-2 col-8 grid__column status  text-md-r">
                                    <span className="grid__labels-mobile">Cessation</span>
                                    {brkScoreObj.Cessation}
                                </div>

                            </div>
                        </div>
                    </a>
                     ))}
                </div>
                <div className="legend-wrapper">
                    <div className="row grid__columns-wrapper">
                        <div className="col-sm-12">
                            <div className="buttons-wrapper text-center m-t-15">
                                <button type="button" class="btn btn-gray " id="back-button"
                                onClick = {()=>this.handleBackBtnClick()}
                                >
                                Back
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>




        );
    }
}

const mapStateToProps = (state) => ({
    breakDownScoreListData  : getTflBreakDownScoreList(state),
});
const mapDispatchToProps = (dispatch) => ({
    onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onfetchScoreBreakdownList: (reviewType, paramObj) => dispatch(fetchTflBreakdownScoreList(reviewType, paramObj)),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TflScorebreakdown);
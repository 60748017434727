
function validateCreateActionForm(programName) {
  let { assigneeValid, titleValid, descValid, notesValid, priorityValid, statusValid,ActionPlan } = false;
  assigneeValid = isAssignToValid();
  titleValid = isTitleValid();
 descValid = isDescriptionValid();
 // notesValid = isNotesValid();
  // priorityValid = isPriorityValid();
  statusValid = isStatusValid();
  descValid = isDescriptionValid();
  return assigneeValid && titleValid    && statusValid;
}

function validateUpdateActionForm() {
  let { assigneeValid } = false;
  assigneeValid = isAssignToValid();
  return assigneeValid;
}

function isAssignToValid() {
  const assignToValue = document.getElementById("assign_to").value;
  const assignToValid = validateField("assign_to", assignToValue);
  return assignToValid;
}

function isTitleValid() {
  return validateField("title", document.getElementById("title") !== null ? document.getElementById("title").value : "");
}

function isDescriptionValid() {
  return validateField("description", document.getElementById("description") !== null ? document.getElementById("description").value : "");
} 
/*
function isNotesValid() {
  return validateField("notes", document.getElementById("notes_area") !== null ? document.getElementById("notes_area").value : "");
} */
function isActionPlannValid() {
  return validateField("ActionPlan", document.getElementById("ActionPlan") !== null ? document.getElementById("ActionPlan").value : "");
} 
function isPriorityValid() {
  const pariorityValue = [document.getElementById("priority1").checked, document.getElementById("priority2").checked, document.getElementById("priority3").checked];
  return pariorityValue.indexOf(true) != -1;
}

function isStatusValid() {
  const statusValue = [document.getElementById("status1").checked, document.getElementById("status2").checked, document.getElementById("status3").checked];
  return statusValue.indexOf(true) != -1;
}
function validateField(fieldName, value) {
  switch (fieldName) {

    case 'location_input':
      return value.length > 0;
    case 'title':
      return value.length > 0 && value.length <= 100;
    case 'description':
      return value.length >= 0 && value.length < 1000;
    case 'ActionPlan':
      return value.length >= 0 && value.length < 1000;  
    case 'assign_to':
      return value.length > 0;
    case 'notes':
      return value.length > 0 && value.length < 500;
    default:
      break;
  }

}

function validateUploadedFile ( event ) {
  const validationFieldID = event.target;
  if(validationFieldID.type === "file" && validationFieldID.files[0] != null) {

      let fileName = validationFieldID.files[0].name;
      fileName = fileName.slice (0,20);
      const fileSize = validationFieldID.files[0].size;
      const fileType = validationFieldID.files[0].type;

     // var infoArea = document.getElementById( 'UploadFileName' );
     // infoArea.textContent = "  " + fileName;
      if (fileSize < 10485760) {
            // this.setState({selectedFileForUpload: event.target.files[0]});

            var errArea = document.getElementById( 'uploadfileerror' );
            errArea.textContent = "";
            return event.target.files[0];
      } 
        var errArea = document.getElementById( 'uploadfileerror' );
        errArea.textContent = "** file size must not exceed 10 MB";
        return undefined;
      
    }
}

export { validateCreateActionForm, validateUpdateActionForm, isAssignToValid, isTitleValid,   isPriorityValid, isStatusValid, validateUploadedFile ,isDescriptionValid};

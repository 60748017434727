import { TOGGLE_SIDEBAR ,FETCH_BRAND_NAME,FETCH_BRAND_NAME_SUCCESS,FETCH_BRAND_NAME_ERROR} from '../constants/actionTypes';

export const toggleSidebar = id => ({
    type: TOGGLE_SIDEBAR,
    id,
});

export const fetchBrandName = () =>({
    type: FETCH_BRAND_NAME

})
export const fetchBrandNameSuccess = (payload) =>({
    type: FETCH_BRAND_NAME_SUCCESS,
    payload

})
export const fetchBrandNameError = (error) =>({
    type: FETCH_BRAND_NAME_ERROR,
    error

})



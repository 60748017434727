import React, {Component} from 'react';
import { connect } from 'react-redux';
import { toggleSidebar ,fetchBrandName} from '../../actions/header';
import { getToken, getTokenInfo } from '../../utils/utils';
import { fetchOktaUsers } from '../../actions/story';
import { fetchUserAccessRoles } from '../../actions/userAccessRoles';
import { getCurrentUser } from '../../selectors/dropdown';



const applyQueryState = isNavOpen => () => ({
    isNavOpen
});

export class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isNavOpen: false,
            isLogout:false
        };
        this.onToggleSidebar = this.onToggleSidebar.bind(this);
    }

    onToggleSidebar() {
        const { isNavOpen } = this.state;
        if (isNavOpen) {
            document.body.classList.remove('nav-open');
        } else {
            document.body.classList.add('nav-open');
        }
        document.body.classList.remove('filter-open');

        this.setState(applyQueryState(!isNavOpen));
    }

    onChange(event) {
        const { value } = event.target;
        this.setState(applyQueryState(value));
    }

    toggleLogout =(e)=>{
        this.setState((prevState) => {
            return {isLogout: !prevState.isLogout };
        })
        e.preventDefault()
    }

    logout=(e)=>{
        const data = localStorage.getItem('isUserFirstVisit');
        this.props.auth.logout('/logout');
        this.props.history.push('/logout')
        localStorage.clear();
        localStorage.setItem('isUserFirstVisit',data)
        e.preventDefault()

    }
    componentDidMount () {
     
      const token = getToken();
      
      if (token && this.props.brands.brandNames.length === 0) {
        this.props.fetchBrandName();
      }
      if (token && (!this.props.oktaUsers || this.props.oktaUsers.length === 0)) {
          this.props.fetchOktaUsers();
      }
      if (token && !this.props.userRoles && !this.props.isFetching) {
          this.props.fetchUserAccessRoles();
      }
    }

    componentDidUpdate (prevProps) {
      if (prevProps.currentUser.name !== this.props.currentUser.name) {
        if (this.props.brands.brandNames.length === 0) {
          this.props.fetchBrandName();
        }
        if (!this.props.oktaUsers || this.props.oktaUsers.length === 0) {
          this.props.fetchOktaUsers();
        }
        if (!this.props.userRoles && !this.props.isFetching) {
            this.props.fetchUserAccessRoles();
        }
      }
    }

    render() {
        const brandLogo = this.props.brands.brandNames.length === 1 &&
            this.props.brands.brandNames.indexOf('LEXUS') !== -1 ? "../images/lexus.png" : "../images/logo.png";
        return (
          <div className="header">
            <div onClick={(e) => this.onToggleSidebar(e)} className="header__hamburger">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="15"
                viewBox="0 0 24 15"
                className="undefined"
              >
                <title className="undefined">Menu</title>
                <g id="Page-1" className="header__hamburger-icon1">
                  <g id="Logo" className="header__hamburger-icon2">
                    <g id="Group-2" className="undefined">
                      <path
                        d="M1 0L22.5 0C23.1 0 23.5 0.4 23.5 1L23.5 1.4C23.5 1.9 23.1 2.4 22.5 2.4L1 2.4C0.4 2.4 0 1.9 0 1.4L0 1C0 0.4 0.4 0 1 0ZM1 6.3L22.5 6.3C23.1 6.3 23.5 6.8 23.5 7.3L23.5 7.7C23.5 8.2 23.1 8.7 22.5 8.7L1 8.7C0.4 8.7 0 8.2 0 7.7L0 7.3C0 6.8 0.4 6.3 1 6.3ZM1 12.6L22.5 12.6C23.1 12.6 23.5 13.1 23.5 13.6L23.5 14C23.5 14.6 23.1 15 22.5 15L1 15C0.4 15 0 14.6 0 14L0 13.6C0 13.1 0.4 12.6 1 12.6Z"
                        id="menu"
                        className="undefined"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <img
              className="header__logo-image"
              src={brandLogo}
              alt="Toyota"
            />
             <div className="header__user-login">
              <span className="user-name">{this.props.currentUser ? this.props.currentUser.name : ''}</span>
              <a href="#" className={`header__user-image ${this.state.isLogout ? `open`:null}`} onClick={this.toggleLogout}>
                <img className="d-only"   width="14px" src="../images/arrow-solid-down.svg"/>
                <img className="m-only"   width="24px" src="../images/icons-profile.svg"/>
              </a>
              <div className="logout-menu"><a  href="#" title="Logout" onClick={this.logout}> Logout </a></div>
            </div>
            <div className="header__faq-icon">
            <a href="/faqs"><img className="header__help-image" src="../images/help.svg" alt="Help"
            /></a>
            </div>
           
          </div>
        );
    }
}
const mapStateToProps = state => ({
    brands:state.headerState.brands,
    reviewDropDownList:state.dropdownState.AssessmentStatusLookup,
    oktaUsers: state.dropdownState.oktaUsers,
    userRoles:state.userAccessRolesState.userAccessRoles.data,
    isFetching:state.userAccessRolesState.isFetching,
    currentUser: getTokenInfo()
});
const mapDispatchToProps = dispatch => ({
    fetchBrandName: ()=>dispatch(fetchBrandName()),
    fetchOktaUsers: () =>dispatch(fetchOktaUsers()),
    fetchUserAccessRoles: ()=>dispatch(fetchUserAccessRoles())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);
import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { createGenerateClassName } from '@material-ui/core/styles';
import TFLScoreStoriesHeader from './TFLScoreStoriesHeader';
import Filter from "../Common/Filter";
import { doResetSortAndFilter, doSortStories } from "../../actions/story";
import ScoreOverride from "./ScoreOverride";
import { doFetchScoreList, doApproveStatus, doResetStatus, doFetchScoreTFLList } from "../../actions/TflScores";
import { getTflScoreList, getTflSapScoreList } from '../../selectors/TflScores';
import { getCurrentUser } from "../../selectors/dropdown";
import TruncateMarkup from 'react-truncate-markup';
import { doFetchProgramsList } from "../../actions/programsList";
import Popup from "reactjs-popup";
import {doPostAuditLogData} from "../../actions/auditLog";
import Moment from 'moment';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
};
TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};
const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});
const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: true,
    productionPrefix: '',
});
class TflScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeId: null,
            areAllLocationsSelected: false,
            counterState: 0,
            isApproveBtnDisabled: true,
            showApproveSelected: false,
            countApprovedStatus: 0,
            approvedstateCounter: 0,
            errorMsg: "",
            updatedScoringData: [],
            showOverlay: false,
            makeAPIcall: false,
        };
        this.renderTabVal = 0;
        this.headerRef = React.createRef();
        this.successRef = React.createRef();
        this.handleHeaderApproveBtnClick = this.handleHeaderApproveBtnClick.bind(this);
    }

    handleClickShowAlert(type) {
        this.setState({
            showingAlert: true,
            saveType: type
        });
    }

    hideAlert() {
        this.setState({
            showingAlert: false
        });
    }

    componentWillUnmount() {
    }
    componentDidMount() {
        this.props.onResetSortAndFilters('score');
        this.props.onFetchProgramsList();
        document.title = "TFL Scores";
        if (this.props.getFilterStatus === 'Hide Filters') {
            this.props.onToggleFilter();
        }
        document.body.classList.remove('nav-open');
        document.body.classList.remove('filter-open');

        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        //being called in Filter.js
        /* if (this.props.currentUser !== undefined && !this.state.makeAPIcall) {
            const payload = {
                "Modified_By": this.props.currentUser ?
                    this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                    + ' (' + this.props.currentUser.employeeNumber + ')' : 'default'
            }
            this.props.onfetchScoringList('score', payload);
            this.setState({
                makeAPIcall: true
            });
        } */
    }

    toggleClass(index, e) {
        this.setState({ activeId: index });
        e.currentTarget.classList.contains('open') ?
            e.currentTarget.classList.remove('open') :
            e.currentTarget.classList.add('open')
    }

    approveStatus(e, scoreObj, type = 'approveStatus') {
        let updatedStatus = '';
        let msg = '';
        if (scoreObj.Status.toLowerCase() === 'Not Approved'.toLowerCase()) {
            updatedStatus = 'Stage 1';

        }
        else if (scoreObj.Status.toLowerCase() === 'Stage 1'.toLowerCase()) {
            updatedStatus = 'Stage 2';
        }
        else if (scoreObj.Status.toLowerCase() === 'Stage 2'.toLowerCase()) {
            updatedStatus = 'Approved';
        }
        if (scoreObj.is_Authorized) {
            if (scoreObj.CessationDate === '' || scoreObj.CessationDate === null) {
                msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' approved PMA location ' +
                    scoreObj.Dealer_Name + ' with score ' + scoreObj.Score + ' with previous status ' + scoreObj.Status
                    + ' status updated to ' + updatedStatus + ' for year ' + scoreObj.Year + ' on ' +
                    Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
            }
            else {
                msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' approved PMA location ' +
                    scoreObj.Dealer_Name + ' with score ' + scoreObj.Score + ' with previous status ' + scoreObj.Status
                    + ' status updated to ' + updatedStatus + ' for year ' + scoreObj.Year + ' with cessation date ' +
                    scoreObj.CessationDate +' on ' +
                    Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
            }
            this.saveAuditLogData(msg);
        }

        const payload =
        {
            "History_Id": scoreObj.History_Id,
            "Modified_By": this.props.currentUser ?
                this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName +
                ' (' + this.props.currentUser.employeeNumber + ')' : 'default'
        }
        this.props.onApprove(payload);
        let selectedCheckbox = document.getElementById('checkbox' + this.state.activeId);
        if (selectedCheckbox) {
            selectedCheckbox.checked = false;
        }
        let line_item = document.getElementById('line-item' + this.state.activeId);
        if (line_item) {
            line_item.classList.remove('open');
        }
        this.handleClickShowAlert(type);
    }

    resetStatus(e, scoreObj, type = 'resetStatus') {
        const payload =
            {
                "History_Id": scoreObj.History_Id,
                "Modified_By": this.props.currentUser ?
                    this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                    + ' (' + this.props.currentUser.employeeNumber + ')' : 'default'
            }

        this.props.onReset(payload);
        let selectedCheckbox = document.getElementById('checkbox' + this.state.activeId);
        if (selectedCheckbox) {
            selectedCheckbox.checked = false;
        }
        let line_item = document.getElementById('line-item' + this.state.activeId);
        if (line_item) {
            line_item.classList.remove('open');
        }
        this.handleClickShowAlert(type);
        let msg = '';
        if (scoreObj.CessationDate === '' || scoreObj.CessationDate === null) {
            msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' reset status for ' +
                scoreObj.Dealer_Name + ' with status ' + scoreObj.Status + ' to status Not Approved '
                + ' with original score ' + scoreObj.Score + ' on ' +
                Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        else {
            msg = this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' reset status for ' +
                scoreObj.Dealer_Name + ' with status ' + scoreObj.Status + ' to status Not Approved '
                + ' with original score ' + scoreObj.Score + ' with cessation date ' +
                scoreObj.CessationDate + ' on ' +
                Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        }
        this.saveAuditLogData(msg);

    }

    selectAllLocations(event) {
        const areAllLocationsSelected = event.target.checked;
        let updatedScoringDataArr = [...this.props.scoreListData];
        let checkboxes = document.getElementsByName("LocationCheckbox");
        let tempCountApprovedStatus = 0;
        for (let i = 0; i < this.props.scoreListData.length; i++) {
            if (this.props.scoreListData[i].Status === "Approved") {
                tempCountApprovedStatus++;
            }
        }
        if (areAllLocationsSelected) {
            for (let i in checkboxes) {
                if (checkboxes[i].checked === false) {
                    checkboxes[i].checked = true;
                }
            }
            this.setState({
                counterState: this.props.scoreListData.length,
                showApproveSelected: false,
                approvedstateCounter: tempCountApprovedStatus
            });

            for (let k = 0; k < updatedScoringDataArr.length; k++) {
                updatedScoringDataArr[k].isChecked = true;
            }

        } else {
            for (let j in checkboxes) {
                if (checkboxes[j].checked === true) {
                    checkboxes[j].checked = false;
                }
            }
            this.setState({
                counterState: 0,
                approvedstateCounter: 0
            });

            for (let k = 0; k < updatedScoringDataArr.length; k++) {
                updatedScoringDataArr[k].isChecked = false;
            }
        }


        this.setState({
            areAllLocationsSelected: areAllLocationsSelected,
            isApproveBtnDisabled: !areAllLocationsSelected,
            countApprovedStatus: tempCountApprovedStatus,
            updatedScoringData: updatedScoringDataArr
        });

    }

    getValueofIndividualCheckBox() {
        let checkboxes = document.getElementsByName("LocationCheckbox");
        let temp = [];
        for (let idx in checkboxes) {
            if (checkboxes[idx].checked === true) {
                temp.push(checkboxes[idx].checked);
            }
        }
        if (checkboxes.length === temp.length) {
            this.setState({
                areAllLocationsSelected: true
            });
        }
        else {
            this.setState({
                areAllLocationsSelected: false
            });
        }
        return temp;
    }
    isLocationSelected(e, index, scoreObj) {
        let counter = this.state.counterState;
        let approveCounter = this.state.approvedstateCounter;
        let updatedScoringDataArr = [...this.props.scoreListData];
        let temp = this.getValueofIndividualCheckBox();
        if (temp.length > 0) {
            this.setState({ isApproveBtnDisabled: false });
        } else {
            this.setState({ isApproveBtnDisabled: true });

        }
        this.setState({
            showApproveSelected: true
        });

        if (e.target.checked) {
            counter++;
            if (scoreObj.Status === "Approved") {
                approveCounter++;
            }

            for (let k = 0; k < updatedScoringDataArr.length; k++) {
                if (scoreObj.History_Id === updatedScoringDataArr[k].History_Id) {
                    updatedScoringDataArr[k].isChecked = true;
                }
            }
            this.setState({
                counterState: counter,
                approvedstateCounter: approveCounter,
                updatedScoringData: updatedScoringDataArr
            },
                function () {

                    if (this.state.counterState === this.props.scoreListData.length) {
                        this.setState({
                            areAllLocationsSelected: true,
                            showApproveSelected: false,
                        });
                    }
                });
        } else {
            counter--;
            if (scoreObj.Status === "Approved") {
                approveCounter--;
            }
            for (let k = 0; k < updatedScoringDataArr.length; k++) {
                if (scoreObj.History_Id === updatedScoringDataArr[k].History_Id) {
                    updatedScoringDataArr[k].isChecked = false;
                }
            }
            this.setState({
                counterState: counter,
                approvedstateCounter: approveCounter,
                updatedScoringData: updatedScoringDataArr
            },
                function () {
                    if (this.state.counterState !== this.props.scoreListData.length) {
                        this.setState({
                            areAllLocationsSelected: false,

                        });
                    }
                });
        }

    }

    handleHeaderApproveBtnClick(param) {
        if (param === "approve" || param === "reset") {
            this.postDataForScoreStatus(param);
        }
        this.getValueofIndividualCheckBox();
    }

    handleApproveBtnClick(param) {
        this.postDataForScoreStatus(param);
    }

    postDataForScoreStatus(param) {
        let tempHistoriId = [];
        let message = '';        
        let counter = 0;
        let scorinngArr = this.state.updatedScoringData;
        for (let k = 0; k < scorinngArr.length; k++) {
            let updatedStatus = '';           
            if (scorinngArr[k].isChecked) {
                console.log('Scoring array', scorinngArr[k]);
                if (scorinngArr[k].Status.toLowerCase() === 'Not Approved'.toLowerCase()) {
                    updatedStatus = 'Stage 1';
                }
                else if (scorinngArr[k].Status.toLowerCase() === 'Stage 1'.toLowerCase()) {
                    updatedStatus = 'Stage 2';
                }
                else if (scorinngArr[k].Status.toLowerCase() === 'Stage 2'.toLowerCase()) {
                    updatedStatus = 'Approved';
                }

                if (param === "approve") {
                    if (scorinngArr[k].is_Authorized) {
                        if (scorinngArr[k].CessationDate === '' || scorinngArr[k].CessationDate === null) {
                            message = message + this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' approved PMA location ' +
                                scorinngArr[k].Dealer_Name + ' with score ' + scorinngArr[k].Score + ' with previous status ' + scorinngArr[k].Status
                                + ' status updated to ' + updatedStatus + ' for year ' + scorinngArr[k].Year + ' on ' +
                                Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
                        }
                        else {
                            message = message + this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' approved PMA location ' +
                                scorinngArr[k].Dealer_Name + ' with score ' + scorinngArr[k].Score + ' with previous status ' + scorinngArr[k].Status
                                + ' status updated to ' + updatedStatus + ' for year ' + scorinngArr[k].Year + ' with cessation date ' +
                                scorinngArr[k].CessationDate + ' on ' +
                                Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
                        }
                    }
                }
                else {
                    if (scorinngArr[k].CessationDate === '' || scorinngArr[k].CessationDate === null) {
                        message = message + this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' reset status for ' +
                            scorinngArr[k].Dealer_Name + ' with status ' + scorinngArr[k].Status + ' to status Not Approved '
                            + ' with original score ' + scorinngArr[k].Score + ' on ' +
                            Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
                    }
                    else {
                        message = message + this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName + ' reset status for ' +
                            scorinngArr[k].Dealer_Name + ' with status ' + scorinngArr[k].Status + ' to status Not Approved '
                            + ' with original score ' + scorinngArr[k].Score + ' with cessation date ' +
                            scorinngArr[k].CessationDate + ' on ' +
                            Moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
                    }
                }
                
                tempHistoriId.push(scorinngArr[k].History_Id);
            }
            if ((scorinngArr[k].Status === "Approved" || !scorinngArr[k].is_Authorized) && scorinngArr[k].isChecked) {
                counter++;
            }
        }
        this.saveAuditLogData(message);
        let selectedHistory_Ids = tempHistoriId.join(", ");
        const payload =
        {
            "History_Id": selectedHistory_Ids,
            "Modified_By": this.props.currentUser ?
                this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName +
                ' (' + this.props.currentUser.employeeNumber + ')' : 'default'
        }
        
        if (param === "approve") {
            this.props.onApprove(payload);
        } else {
            this.props.onReset(payload);
        }

        let checkboxes = document.getElementsByName("LocationCheckbox");
        for (let idx in checkboxes) {
            if (checkboxes[idx].checked === true) {
                checkboxes[idx].checked = false;
            }
        }
        this.setState({
            areAllLocationsSelected: false,
            isApproveBtnDisabled: true,
            counterState: 0
        });

        //let msg = "";
        // if (counter > 0) {
        let msg = "";
        if (param === "approve") {
            if(counter === 1){
                msg = "No action taken on " + counter  + " record.";
            }
            else if(counter > 1){
            msg = "No action taken on " + counter  + " records.";
            }
           // msg = "No action taken on " + counter + " out of " + tempHistoriId.length + " selected as records are already in approved status";
          //  msg = (tempHistoriId.length - counter) + " PMA records approved to next stage"
        }
        this.setState({
            errorMsg: msg
        });
        // }

        setTimeout(() => {
            this.setState({
                errorMsg: ""
            });
        }, 5000);
    }

    hideAlert() {
        this.setState({
            errorMsg: "",
        });
    }

    saveAuditLogData(msg) {
            const reqPayload = {
                LogMessage: msg,
                AuditLogModule: "TFL Score",
                AcionAPISource: "ChangeScoreStatus",
                CreatedBy: this.props.currentUser ?
                    this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                    + '(' + this.props.currentUser.employeeNumber + ')' : 'default',
                CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
            }
            this.props.doPostAuditLogData(reqPayload);
    }

    handleLocationClick(index, scoreParams) {
        this.props.history.push({
            pathname: '/sectionscoreview-breakdown',
            state: {
                "Program_Id": scoreParams.Program_Id,
                "Dealer_Id": scoreParams.Dealer_ID,
                "Review_Id": scoreParams.Review_Id,
                "Review_Type": scoreParams.Review_Type,
                "IS_REPORT": false,
                "DealerNamesuburb": scoreParams.Dealer_Name,
                "totalscore": scoreParams.Score
            }
        }
        )
    }

    applySorting(query) {

        let scorinngArr = [...this.state.updatedScoringData];
        for (let k = 0; k < scorinngArr.length; k++) {
            if (scorinngArr[k].isChecked) {
                scorinngArr[k].isChecked = false;
            }
        }
        this.setState({
            updatedScoringData: scorinngArr,
            counterState: 0,
            areAllLocationsSelected: false,
            isApproveBtnDisabled: true,
        });
        let checkboxes = document.getElementsByName("LocationCheckbox");
        for (let idx in checkboxes) {
            if (checkboxes[idx].checked === true) {
                checkboxes[idx].checked = false;
            }
        }

        this.props.onSort(query);


    }


    render() {

        const contentStyle = {
            maxWidth: "930px",
            width: "90%",
            background: "#000"
        };

        return (
            <div>
                <div className="main-content error-screen text-center">
                    <h2 className="m-t-50 m-b-30">Desktop only screen</h2>
                    <a href="/home" className="btn btn-primary">Back to Home</a>
                </div>
                <div className="main-content tfl-score">
                    <div>
                        <div className="page-header">
                            <div className="row">
                                <div className="col-4">
                                    <h3>TFL Scores</h3>
                                </div>
                                <div className="col-8">
                                    <TFLScoreStoriesHeader ref={this.headerRef}
                                        isApproveBtnDisabled={this.state.isApproveBtnDisabled}
                                        showApproveSelected={this.state.showApproveSelected}
                                        handleHeaderApproveBtnClick={(e) => this.handleHeaderApproveBtnClick(e)}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        <div className="legend-wrapper d-none d-sm-none d-md-block">
                            <div className="row grid__columns-wrapper">
                                <div className="col-sm-12">
                                    <p className="records-count">
                                        {
                                            (this.state.errorMsg !== "") ?
                                                <div id="successMessage" className="alert alert-success alert-dismissible alert-center " role="alert">
                                                    <button
                                                        type="button"
                                                        className="close"
                                                        data-dismiss="alert"
                                                        aria-label="Close"
                                                        onClick={() => this.hideAlert() }
                                                        >
                                                        <span aria-hidden="true">&times; </span>
                                                    </button>
                                                    {this.state.errorMsg}

                                                </div>
                                                :
                                                null
                                        }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="grid__labels d-none d-md-none d-lg-block">
                                <div className="row grid__columns-wrapper">
                                    <div id="location__sort" className="col-lg-4 col-md-4 grid__label-sort">
                                        <span> Location </span>

                                    </div>
                                    <div id="sap__sort" className="col-md-3 grid__label-sort">
                                        SAP Dealer Code
                                      
                                    </div>
                                    <div id="year__sort" className="col-md-3 grid__label-sort">
                                        Year

                                    </div>
                                    <div id="year__sort" className="col-md-2 grid__label-sort">
                                        TflScore

                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    {this.props.scoreListData && this.props.scoreListData.length === 0 && this.props.loader !== 'show' && (
                        <div className="grid">
                            <div className="grid__row">
                                <div className="grid__columns-wrapper">
                                    No Records Found.
                        </div>
                            </div>
                        </div>
                    )}
                    <div className="grid">
                        {(this.props.scoreListData || []).map((scoreObj, index) => (
                            <a className="grid__row-link" >
                                <div className="grid__row">
                                    <div className="row grid__columns-wrapper">

                                        <div className="col-md-4 col-lg-4 grid__column grid__sapcode mar-md-b-10" onClick={() => this.handleLocationClick(index, scoreObj)}>
                                            <span className="grid__labels-mobile">Location</span>
                                            {scoreObj.Dealer_Name}
                                        </div>

                                        <div className="col-md-3 col-lg-3 grid__column grid__sapcode mar-md-b-10" onClick={() => this.handleLocationClick(index, scoreObj)}>
                                            <span className="grid__labels-mobile">SAP code</span>
                                            {scoreObj.SAP_Dealer_Code}
                                        </div>


                                        <div onClick={() => this.handleLocationClick(index, scoreObj)} className="col-md-3 col-lg-3 grid__column last-updated  mar-md-b-10">
                                            <span className="grid__labels-mobile">Year</span>
                                            {scoreObj.Year}
                                        </div>
                                        <div onClick={() => this.handleLocationClick(index, scoreObj)} className="col-md-3 col-lg-2  grid__column last-updated  mar-md-b-10">
                                            <span className="grid__labels-mobile">TFLScore</span>
                                            {scoreObj.Score}
                                        </div>

                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                    


                </div>
                <Filter
                    reviewType="TFLscore"
                    filtersToDisplay={{
                        dealer_list: true,
                        assessment_template_lookup: false,
                        status_lookup: false,
                        last_modified_by: false,
                        start_date: false,
                        end_date: false,
                        program_list: false,
                        sap_dealer_code: true,
                        year: false,
                        TFLScoreyear: true,
                        investigation_title: false,
                        stage: false,
                        closed_on_date: false,
                        region: true
                    }}
                    regionList={[
                        {
                           "region": "Central",
                           "regionlabel": "Central"
                        },
                        {
                           "region": "Eastern",
                           "regionlabel": "Eastern"
                        },
                        {
                           "region": "Northern",
                           "regionlabel": "Northern"
                        },
                        {
                           "region": "Southern",
                           "regionlabel": "Southern"
                        },
                        {
                            "region": "Western",
                            "regionlabel": "Western"
                         }
                     ]}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    currentUser: getCurrentUser(state.dropdownState),
    scoreListData: getTflScoreList(state),
    // scoreListData: getTflSapScoreList(state),
    
});
const mapDispatchToProps = (dispatch) => ({
    onfetchScoringList: (reviewType, payload) => dispatch(doFetchScoreTFLList(reviewType, payload)),
    onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onFetchProgramsList: query => dispatch(doFetchProgramsList(query)),
    onSort: sortBy => dispatch(doSortStories(sortBy, 'TFLscore')),
    onApprove: (payload) => dispatch(doApproveStatus(payload)),
    onReset: (payload) => dispatch(doResetStatus(payload)),
    doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TflScore);

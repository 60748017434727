import { Skeleton } from '@mui/material';
import * as React from 'react';
export default function Skeletons(props) {
    return (
        <div className="grid__row">
            <div className="row grid__columns-wrapper">

                <div className="col-md-6 col-lg-3 grid__column grid__dealer-name grid__order-md-1 mar-md-b-10">
                    <Skeleton variant="text" />
                </div>
                <div className="col-md-6 col-lg-2 grid__column grid__template-name grid__order-md-3 grid__order-sm-2 mar-md-b-10">
                    <Skeleton variant="text" />
                </div>
                <div className="col-md-6 col-lg-1 col-4 grid__column program grid__order-md-2 grid__order-sm-3 mar-md-b-10" >
                    <Skeleton variant="text" />
                </div>
                <div className="col-md-6 col-lg-2 col-8 grid__column last-updated grid__order-md-5 grid__order-sm-4 mar-md-b-10" >
                    <Skeleton variant="text" />
                </div>
                <div className="col-md-6 col-lg-2 col-4 grid__column start-date grid__order-md-4" >
                    <Skeleton variant="text" />
                </div>
                <div className="col-md-6 col-lg-2 col-4 grid__column start-date grid__order-md-4" >
                    <Skeleton variant="text" />
                </div>

            </div>
        </div>
    );
}


import React from "react";
// TODO:: Move this loop to a constant

export default function selectedLocation (locationsDropDownList, userselection) {
  if (locationsDropDownList != null) {
    for (let i = 0; i < locationsDropDownList.length; i++) {
      const item = locationsDropDownList[i];
      if (item.dealerName === userselection) {
        // TODO: Call the Container components HERE
        return item.dealer_id;
      }
    }
  }
}

function multiselectOptions (firstOption, dropDownList, optionid, optionvalue, selectedValue) {


    const multipleSelectOptions = dropDownList.map((dataOption, index) => {

    const id = (optionid !== null) ? dataOption [optionid] :dataOption .Id;
    const displayValue = dataOption [optionvalue];

    return (
        {
          id,
          label: displayValue,
          value: displayValue
        } );
    });
    // multipleSelectOptions.concat(firstOption);
    return multipleSelectOptions;
}

function dropdownOptions (dropDownList, optionid, optionvalue, selectedValue ) {
    // alert ("into dropdownOptions::", optionid+optionvalue);
    // alert ("into dropDownList::", dropDownList.length);
    const options = dropDownList.map((dataOption, index) => {
// let sopSelect = (sopSelected === dataOption.sopName? "selected":"");

        const id = (optionid !== null) ? dataOption [optionid] :dataOption [id];
        const displayValue = dataOption [optionvalue];

        // let selected={selectedValue === dataOption.value? "selected":"false"

        return (
          <option
            key={id}
            id={id}
            value={displayValue}
          >
            {displayValue}
          </option>
        );
    });
    return options;
}


function dropdownOptionsAction (dropDownList, optionid, optionvalue, selectedValue ) {

  if(!dropDownList || dropDownList.size <=0) return;
  const options = dropDownList.map((dataOption, index) => {
  
  const id = dataOption.Id;
  const displayValue = dataOption.Name;
  
    return (
      <option
        key={id}
        id={`${optionvalue}-${id}`}
        value={displayValue}
      >
        {displayValue}
      </option>
      );
    });
    return options;
  }
  
  function dropdownOptionsShare (dropDownList, optionid, optionvalue, selectedValue ) {

    if(!dropDownList || dropDownList.size <=0) return;
    const options = dropDownList.map((dataOption, index) => {
    
    const id = dataOption.Email;
    const displayValue = dataOption.Email;
    
      return (
        <option
          key={id}
          id={`${optionvalue}-${id}`}
          value={displayValue}
        >
          {displayValue}
        </option>
        );
      });
      return options;
    }

const fieldErrorDiv = { color: 'red'};

export {dropdownOptions, selectedLocation, fieldErrorDiv, multiselectOptions, dropdownOptionsAction, dropdownOptionsShare };

import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers';
import rootSaga from '../sagas';
import NetworkService from './NetworkService';

const logger = createLogger();
const saga = createSagaMiddleware();
let middlewares = [saga];
/* Redux-logger to be used only for the developement mode*/
if(process.env.NODE_ENV=== 'development')
{
  middlewares = [...middlewares,logger]
}
export const store = createStore(
  rootReducer,
  undefined,
  applyMiddleware(...middlewares)
);
NetworkService.setupInterceptors(store);


saga.run(rootSaga);

export default store;
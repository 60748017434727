import React from "react";
import { connect } from "react-redux";
import 'react-widgets/dist/css/react-widgets.css';
import '../App.css';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Select from "react-select";
import MultiSelectDropdown from '../Common/MultiSelectDropdown';
import { getLocationsList, getProgramsList, getSOPNamesList } from '../../selectors/uploadSOP';
import {
    calculateOneYrFromNov,
    formatMomentDate,
} from "../../constants/utilityfunctions";
import { doFetchSOPDocsList, doUploadSOPDocumentError } from "../../actions/actionsUploadSOP";
import {
    uploadSOPDocAction
} from "../../eventhandlers/eventsUploadSOP";
import { multiselectOptions } from "../../constants/uploadSOPConstants";
import {
    validateUploadedFile,
    validateUploadSOPForm,
    isLocationValid,
    isSopNameValid
} from "../../FormValidators/UploadSOPDocsValidator";
import "react-picky/dist/picky.css";
import { doShowLoader } from "../../actions/loader";
import { doFetchSOPNamesList } from "../../actions/sopNameList";
import { fetchLocationList } from "../../actions/locationsList";
import {doPostAuditLogData} from "../../actions/auditLog";
import Moment from 'moment';
import { getCurrentUser } from "../../selectors/dropdown";

export class UploadSOPForm extends React.Component {
    constructor(props) {
        super(props);

        // For Datalist
        this.state = { selectedLocation: "", sopdocsList: [], sopSelectedOptions: null, locationSelectedOptions: null, programSelectedOptions: null, expiry_date: "", programselect: "", selectedFileForUpload: "", formValid: "", sopNameValid: "", fileUploadValid: "", programValid: "", Status: "1", isActive: false, isReadOnlyMode: false, isReviewDisabled: true, isLocationDisabled: true };
        // Form data
        // this.state = { expiry_date: "", programselect: "", selectedFileForUpload: "" };
        // // For Form Validation
        // this.state = { formValid: "", sopNameValid: "", fileUploadValid: "", programValid: "", Status: "1" };
        // this.state = { isActive: false, isReadOnlyMode: false }   // For Success/Failure Message Notifications

        this.sopNameRef = React.createRef();
        this.programRef = React.createRef();
        this.fileUploadRef = React.createRef();
        this.expiryDateRef = React.createRef();
        this.addSOPButtonRef = React.createRef();
        this.isFirstTime = false;
        this.handleLocation = this.handleLocation.bind(this);
    }

    componentWillMount() {
        const expiry_date = calculateOneYrFromNov();
        this.setState({ expiry_date });
    }

    componentDidMount() {
        // const paylaod = {
        //     is_active: 1,
        //     program_id: ''
        // }
        // this.props.onFetchLocationList(paylaod);
        document.body.classList.remove('filter-open');
        document.body.classList.add('upload-sop-w');
    }
    
    hideAlert() {
        this.setState({
            isActive: false,
        });
    }

    displayDealerList = (status, location, program) => {
        this.props.callForDealerSOPList(status, location, program);
    }


    handleSopNameDropdown = (sopSelectedOptions) => {
        this.setState({ sopSelectedOptions });
        if (isSopNameValid(sopSelectedOptions)) {
            this.setState({ sopNameValid: true, formValid: false });
        } else {
            this.setState({ sopNameValid: false, formValid: false });
        }
    };

    handleLocation = (locationSelectedOptions) => {
        this.setState({ locationSelectedOptions, isReviewDisabled: false });
        this.sopNameRef.current.state.value = '';
        this.setState({ sopSelectedOptions: [] });
        const errLocArea = document.getElementById('locationerror');
        if (errLocArea) {
            if (isLocationValid(locationSelectedOptions)) {
                errLocArea.textContent = "";
            } else {
                errLocArea.textContent = "** Invalid Location";
            }
        }

       // this.setState({ formValid: validateUploadSOPForm(locationSelectedOptions, this.state.sopSelectedOptions, this.state.programSelectedOptions) && this.state.selectedFileForUpload !== undefined });

        //S - Upload SOP/WIP
        let locationID = locationSelectedOptions !== undefined && locationSelectedOptions !== null ? locationSelectedOptions.id : null;
        let programID = this.state.programSelectedOptions !== undefined && this.state.programSelectedOptions !== null  ?this.state.programSelectedOptions.id : null
        const query = {
            location: locationID,
            program: programID
        }
        this.props.onFetchSOPNamesList(query);
        if (locationSelectedOptions && locationSelectedOptions !== null) {
            this.displayDealerList(this.state.Status, locationSelectedOptions, this.state.programSelectedOptions);
        }
        this.setState({formValid: false} );
        if(document.getElementById("browse_document")) {
        document.getElementById("browse_document").value = ""; 
        }
        if(document.getElementById("UploadFileName")) {
        document.getElementById('UploadFileName').textContent = "";
        }
        this.setState({ expiry_date: calculateOneYrFromNov(), sopNameValid: false });
        //E
    };

    handleProgramDropdown = (programSelectedOptions) => {
        this.setState({ programSelectedOptions, programValid: true, isLocationDisabled: false,  isReviewDisabled: true, locationSelectedOptions: null});
        // S - Upload SOP/WIP
        let _self = this;
        const paylaod = {
            is_active: _self.state.Status,
            program_id: programSelectedOptions.id
        }
        this.props.onFetchLocationList(paylaod);
        if(this.sopNameRef.current !== null && this.sopNameRef.current !== undefined){
        this.sopNameRef.current.state.value = '';
        }
        this.setState({ sopSelectedOptions: [], formValid: false });
        if (document.getElementById("browse_document")) {
            document.getElementById("browse_document").value = "";
        }
        if (document.getElementById("UploadFileName")) {
            document.getElementById('UploadFileName').textContent = "";
        }
        this.setState({ expiry_date: calculateOneYrFromNov(), sopNameValid: false });
        //E
      //  this.setState({ formValid: validateUploadSOPForm(this.state.locationSelectedOptions, this.state.sopSelectedOptions, programSelectedOptions) && this.state.selectedFileForUpload !== undefined });
        // const query = {
        //     location:this.state.locationSelectedOptions,
        //     program:programSelectedOptions
        // }
        // this.props.onFetchSOPNamesList(query);
        
    }

    handleFileUpload = (event) => {
        /* if (this.isFirstTime) {
            const infoArea = document.getElementById('UploadFileName');
            infoArea.textContent = "";
            this.setState({ selectedFileForUpload: null, fileuploadValid: "", formValid: false });
            this.isFirstTime = false;
            return;
        }
        this.isFirstTime = true; */
        if (event.target.value.length > 0) {
            
            const selectedFileForUpload = validateUploadedFile(event);
            const fileUpload = (selectedFileForUpload !== undefined) ?
            this.setState({ selectedFileForUpload, fileUploadValid: true }) :
            this.setState({ selectedFileForUpload: undefined });
            this.setState({ formValid: validateUploadSOPForm(this.state.locationSelectedOptions, this.state.sopSelectedOptions, this.state.programSelectedOptions) && selectedFileForUpload !== undefined });
        } 

    }

    onSelectStatus = (e, status) => {
        const payload = {
            is_active: status,
            program_id: ''
        }

        this.props.onFetchLocationList(payload);
        this.setState({ Status: status.toString() });
        //this.displayDealerList(status.toString(),this.state.locationSelectedOptions, this.state.programSelectedOptions);
        this.setState({
            selectedFileForUpload: null,
            sopNameValid: false,
            fileuploadValid: "",
            formValid: false,
            locationSelectedOptions: '',
            programSelectedOptions: null,
            sopSelectedOptions: []
        });
        this.sopNameRef.current.state.value = '';
        if(document.getElementById("browse_document")) {
        document.getElementById("browse_document").value = "";
        }
        if(document.getElementById("UploadFileName")) {
        document.getElementById('UploadFileName').textContent = "";
        }
        this.setState({ expiry_date: calculateOneYrFromNov() });
        status ? this.setState({ isReadOnlyMode: false }) : this.setState({ isReadOnlyMode: true });
    }

    onResetForm() {
        this.setState({
            selectedFileForUpload: null,
            sopNameValid: false,
            fileuploadValid: "",
            formValid: false,
            locationSelectedOptions: '',
            programSelectedOptions: null
        });
        this.setState({ sopSelectedOptions: undefined });
        this.sopNameRef.current.state.value = ''
        const infoArea = document.getElementById('UploadFileName');
        infoArea.textContent = "";
        this.isFirstTime = false;
        this.programRef.current.value = '';
        if( document.getElementById("browse_document")) {
        document.getElementById("browse_document").value = "";
        }
        this.setState({ expiry_date: calculateOneYrFromNov() });
    }

    onSubmitUploadSOP = event => {
        event.preventDefault();
        this.props.showLoader();
        const reader = new FileReader();
        reader.readAsDataURL(this.state.selectedFileForUpload);
        this.setState({ isActive: false, UploadResponse: '' })
        try {

            reader.onload = event => {

                const uploadSOPResult = uploadSOPDocAction(event, this.state.locationSelectedOptions.id, this.state.sopSelectedOptions, this.state.programSelectedOptions,
                    this.state.selectedFileForUpload, formatMomentDate(this.state.expiry_date));

                if (uploadSOPResult !== undefined) {

                    uploadSOPResult
                        .then(res => {
                            this.setState({ UploadResponse: res.statusText });
                            this.setState({ isActive: true });
                            if (res.status === 200 && res.statusText === "OK") {
                                const currentUser = this.props.currentUser ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName : '';
                                console.log('this.state.selectedFileForUpload', this.state.selectedFileForUpload);
                                const reqPayload = {
                                    LogMessage: currentUser + ' added SOP ' + this.state.selectedFileForUpload.name + ' ' + Moment(new Date()).format('HH:MM DD MMM YYYY') 
                                    + ' for ' + this.state.programSelectedOptions.label + ' for ' + this.state.locationSelectedOptions.label + ' on ' + Moment(new Date()).format('HH:MM DD MMM YYYY') + '.',
                                    AuditLogModule: "Upload SOP",
                                    AcionAPISource: "UploadSOPDOCS",
                                    CreatedBy: this.props.currentUser ?
                                        this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
                                        + '(' + this.props.currentUser.employeeNumber + ')' : 'default',
                                    CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
                                }
                                this.props.doPostAuditLogData(reqPayload);
                                const dealerSOPList = this.props.callForDealerSOPList(this.state.Status, this.state.locationSelectedOptions, this.state.programSelectedOptions);
                                this.onResetForm();
                            }
                        })
                }
            };
        } catch (error) {
            this.props.callUploadSopDocumentErrors(error);
            this.setState({ UploadResponse: error.toString() });
            this.setState({ isActive: true });

        }
    }

    render() {
        const { locationSelectedOptions, programSelectedOptions } = this.state;

        setTimeout(() => {
            const succMessage = document.getElementById('successMessage');
            if (succMessage !== null) succMessage.style.display = "none";

        }, 5000);
        const fieldErrorDiv = { color: 'red' };

        return (
            <React.Fragment>
                <div className="page-header page-header-upload-document">
                    <div className="grid__columns-wrapper">
                        <h3 id="upload-sop__heading">Upload SOP</h3>
                    </div>
                </div>

                <div className="add-documents">
                    <div className="add-documents__form">
                        <form id="AddSOPDocsForm" onSubmit={this.onSubmitUploadSOP}>
                            <div className="form-row">
                                <div className="form-group col-sm-12 text-left">
                                    <div><label htmlFor="location-status" id="location-status__id">Location Status</label></div>
                                    <label className="custom-radio">
                                        <input type="radio" id="active-radio" defaultChecked="checked" name="radio" onChange={(e) => this.onSelectStatus(e, 1)} />
                                        <span className="custom-checkmark" />
                                        Active
                     </label>
                                    <label className="custom-radio">
                                        <input type="radio" id="inactive-radio" name="radio" onChange={(e) => this.onSelectStatus(e, 0)} />
                                        <span className="custom-checkmark" />
                                        Inactive
                  </label>
                                </div>

                                <div className="form-group col-md-6 col-lg-4">
                                    <label htmlFor="program" id="program__label">
                                        Program
                                        <span className="required">*</span>
                                    </label>
                                    <Select
                                        id="program_input" classNamePrefix='program' className="dropdown-with-search"
                                        value={programSelectedOptions}
                                        ref={this.programRef}

                                        onChange={this.handleProgramDropdown}
                                        placeholder="Select Program"
                                        options={multiselectOptions("Select Program", this.props.programsDropDownList, "Id", "Name", null)}

                                        isSearchable="true"
                                    />
                                </div>
                                <div className="form-group col-md-6 col-lg-4">
                                    <label htmlFor="location" id="location__label">
                                        Location
                                        <span className="required">*</span>
                                    </label>
                                    <fieldset>

                                        <Select
                                            id="location_input" classNamePrefix='location' className="dropdown-with-search"
                                            value={locationSelectedOptions}
                                            onChange={this.handleLocation}
                                            placeholder="Search Location"
                                            options={multiselectOptions("Select Location", this.props.locationsDropDownList, "Dealer_ID", "Location_Name", null)}
                                            isSearchable="true"
                                            isDisabled={this.state.isLocationDisabled}
                                        />
                                        <div id="locationerror" style={fieldErrorDiv} />
                                    </fieldset>
                                </div>
                            </div>
                            <div className={!this.state.isReadOnlyMode ? "form-row" : "form-row disabled-review"}>
                                <div className={this.state.isReviewDisabled ? "form-group col-lg-4 col-md-6 disabled-review upload-sop" : "form-group col-lg-4 col-md-6 upload-sop"}>
                                    <label htmlFor="sop_name" id="SOP__label">
                                        SOP Name
                                        <span className="required">*</span>
                                    </label>
                                    <div id="sop_select_container" className={`custom-multiselect ${this.state.sopSelectedOptions && this.state.sopSelectedOptions.length > 0 ? '' : 'not-selected'}`}>
                                        <MultiSelectDropdown
                                            id ="multiSelectSopNameDropDown"
                                            forEle="SOP"
                                            reference={this.sopNameRef}
                                            handleChange={this.handleSopNameDropdown}
                                            dropDownList={this.props.sopNamesDropDownList}
                                            value={this.state.sopSelectedOptions}
                                            selectObj={{ optionId: "ID", optionValue: "Name" }}
                                           
                                        />
                                    </div>
                                </div>
                                <div className="form-group col-md-6 col-lg-4">
                                    <label>&nbsp;</label>
                                    <input
                                        type="file"
                                        className="form-control inputfile"
                                        id="browse_document"
                                        encType="multipart/form-data"
                                        disabled={!this.state.sopNameValid}
                                        onChange={(eve) => this.handleFileUpload(eve, this.props.locationsDropDownList)}
                                        ref={this.fileUploadRef}
                                    />
                                    <label className="disable" htmlFor="browse_document" id="UploadFileName" />
                                    <div id="uploadfileerror" style={fieldErrorDiv} />

                                </div>
                                <div className="form-group col-md-6 col-lg-3">
                                    <label htmlFor="expiry_date" id="expiry-date__label">Document Expiry Date <span className="required">*</span></label>
                                    <DateTimePicker
                                        id="expiry-date"
                                        ref={this.expiryDateRef}
                                        inputProps={{
                                            component: props => <input id="expiry-date__input" {...props} readOnly />
                                        }}
                                        onChange={expiry_date => this.setState({ expiry_date })}
                                        dropDown
                                        // min={getStartExpiryDate()}
                                        // max={getEndExpiryDate()}
                                        time={false}
                                        format="DD-MM-YYYY"
                                        disabled={this.state.isReadOnlyMode}
                                        value={new Date(this.state.expiry_date)}
                                      
                                    />

                                </div>
                            </div>
                            <div className="btn-wrapper text-center">
                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                    id="add-sop__button"
                                    ref={this.addSOPButtonRef}
                                    disabled={!this.state.formValid}
                                >
                                    Add SOP
                                </button>
                            </div>
                            <br />
                            {this.state.isActive && this.state.UploadResponse === "OK" && (
                                <div id="successMessage" className="alert alert-success alert-dismissible" role="alert">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        onClick={() => this.hideAlert()}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    Document Uploaded Successfully.

                                </div>
                            )}

                            {this.state.isActive && this.state.UploadResponse !== "OK" && (
                                <div className="alert alert-danger alert-dismissible" role="alert">
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="alert"
                                        aria-label="Close"
                                        onClick={() => this.hideAlert()}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    Oops! Upload document Failed!!!
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}
const mapStateToProps = state => ({
    locationsDropDownList: getLocationsList(state),
    programsDropDownList: getProgramsList(state),
    sopNamesDropDownList: getSOPNamesList(state),
    currentUser: getCurrentUser(state.dropdownState),
});

const mapDispatchToProps = dispatch => ({
    callForDealerSOPList: (status, location, program) => dispatch(doFetchSOPDocsList(status, location, program)),
    // callUploadSopDocument : (uploadEvent, selectedLocation, sopdropselect,programselect, selectedFileForUpload, expiry_date) => dispatch(doUploadSOPDocument(uploadEvent,selectedLocation, sopdropselect,programselect, selectedFileForUpload, expiry_date)),
    callUploadSopDocumentErrors: error => dispatch(doUploadSOPDocumentError(error)),
    showLoader: () => dispatch(doShowLoader()),
    onFetchSOPNamesList: query => dispatch(doFetchSOPNamesList(query)),
    onFetchLocationList: query => dispatch(fetchLocationList(query)),
    doPostAuditLogData: (payload) => dispatch(doPostAuditLogData(payload)),
    //test: state => dispatch(getSOPNamesList(state))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadSOPForm);
import {
  ADD_PROGRAMS_LIST,
  FETCH_PROGRAMS_LIST,
  FETCH_PROGRAMS_LIST_ERROR
} from '../constants/actionTypes';

const doAddProgramsList = programsList => ({
  type: ADD_PROGRAMS_LIST,
  programsList,
});

const doFetchProgramsList = programsList => ({
  type: FETCH_PROGRAMS_LIST,
  programsList,
});

const doFetchErrorProgramsList = error => ({
  type: FETCH_PROGRAMS_LIST_ERROR,
  error,
});




export {
  doAddProgramsList,
  doFetchProgramsList,
  doFetchErrorProgramsList
};

import {
    POST_AUDIT_LOG_DATA,
    FETCH_AUDIT_LOG_SUCCESS,
    FETCH_AUDIT_LOG_ERROR
} from '../constants/actionTypes';

const INITIAL_STATE = {
    auditLogData:'',
    auditLogError:''
}

function auditLogReducer(state = INITIAL_STATE, action) {
    switch(action.type) {
        case POST_AUDIT_LOG_DATA : {
            return {...state}
        }
        case FETCH_AUDIT_LOG_SUCCESS : {
            return {...state,auditLogData:action.payload}
        }
        case FETCH_AUDIT_LOG_ERROR : {
            return {...state,auditLogError:action.payload.error}
        }

        default : return state;
    }
}

export default auditLogReducer;
import ProgressBar from 'react-bootstrap/ProgressBar';
import React from 'react';
import { useEffect ,useState} from 'react';
const CreateQuestionObject = (props) => {
  let tempOBJ = {}
  
    props.review.map((data) => {
      (data.Criterias || []).map((criteria) => {
          {(criteria.Questions || []).map((question) => (
            
            tempOBJ = { ...tempOBJ,[question.Question_Id]:question.Status }
            
          ))}
      });
    });
   
  return tempOBJ;
};


function ResponseProgressBar(props) {
  const [ASstatus, setASstatus] = useState(0);
  const [NASstatus, setNASstatus] = useState(0);
  const [objectLenght, setobjectLenght] = useState(0);
  const [QuestionList, setQuestionList] = useState(0);

  let tempList = {}

  let ASvalue=0;
  let NASvalue=0;
  useEffect(() => {
    tempList= CreateQuestionObject(props);
    setQuestionList(tempList)
    let FindobjectLenght=Object.keys(tempList).length;
    setobjectLenght(FindobjectLenght)
    Object.values(tempList).map((value, index) => {
      if(value ==='At Standard'){
        ASvalue+=1;
      }else if(value ==='Not At Standard') {
        NASvalue+=1;
      }
     
    })
    setASstatus(ASvalue)
    setNASstatus(NASvalue)

  }, []);

  useEffect(() => {
    let tempOBJ= {...QuestionList,...props.resposeStateValue}
    let AStemp=ASvalue
    let NAStemp=NASvalue
   
      Object.values(tempOBJ).map((value) => {
        if(value ==='At Standard'){
          AStemp+=1;
        }else if(value ==='Not At Standard') {
          NAStemp+=1;
        }
       
      })
      setASstatus(AStemp)
      setNASstatus(NAStemp)
  },[props.resposeStateValue]);
  let Aspercentage=(ASstatus/objectLenght)*100
   let Nspercentage=(NASstatus/objectLenght)*100
   let notSelectedpercentage=(100-Aspercentage-Nspercentage)
   let notSelected= objectLenght-(ASstatus+NASstatus)
  return (
    <>
      <ProgressBar className='linear'>
        <ProgressBar animated variant="success" now={Aspercentage}  label={`${Math.round(Aspercentage)}% (${ASstatus} At Standard)`} key={1} />
        <ProgressBar animated variant="danger" now={Nspercentage} label={`${Math.round(Nspercentage)}% (${NASstatus} Not At Standard)`} key={2} />
        <ProgressBar  animated variant="secondary" now={notSelectedpercentage} label={`${Math.round(notSelectedpercentage)}% (${notSelected} not selected)`} key={3} />
      </ProgressBar>
    </>
  );
}

export default ResponseProgressBar;
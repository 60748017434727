import { TOGGLE_SIDEBAR ,FETCH_BRAND_NAME,FETCH_BRAND_NAME_SUCCESS,FETCH_BRAND_NAME_ERROR} from '../constants/actionTypes';

const INITIAL_STATE = {
    isNavOpen: 'Show Filters',
    brands:{
        brandNames:[],
        Count:''
    },
    error:null
};

export function headerReducer(state = INITIAL_STATE, action) {
    switch(action.type) {

        case TOGGLE_SIDEBAR : {
            document.body.classList.toggle('filter-open');
            return {
                ...state, isNavOpen: (state.isNavOpen ==='Show Filters' ?'Hide Filters':'Show Filters')
            };
        }

        case FETCH_BRAND_NAME : {
            return {...state};
        }

        case FETCH_BRAND_NAME_SUCCESS : {
            const brands = {
                brandNames:action.payload.data.data,
                Count:action.payload.data.Count
            }
            return {...state,brands};
        }

        case FETCH_BRAND_NAME_ERROR : {
            const error = action.error;
            return {...state ,error};
        }

        default : return state;
    }
}

export default headerReducer;
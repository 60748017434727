import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";
import { createGenerateClassName } from '@material-ui/core/styles';
import StoriesHeader from './StoriesHeader';
import Filter from "../Common/Filter";
import { doResetSortAndFilter, doSortStories } from "../../actions/story";
import { getCurrentUser } from "../../selectors/dropdown";
import TruncateMarkup from 'react-truncate-markup';
import { doFetchProgramsList } from "../../actions/programsList";
import { doFetchInvestigationRecords, doFetchLookUpStage } from "../../actions/Investigations";
import { getInvestigationRecordsData, getInvestigationListError, getPaginationDetails, getLookUpStageData, getInvestigationRecordsCount } from '../../selectors/Investigations';
import Popup from "reactjs-popup";
import classNames from 'classnames';
import Moment from 'react-moment';
import InvestigationPagination from "./InvestigationPagination";
import InvestigationOverlay from "./InvestigationOverlay";
import { getLocationsList } from '../../selectors/uploadSOP';
import { fetchLocationList } from "../../actions/locationsList";
import UnauthorizePage from "../Common/UnauthorizePage"

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
});

const generateClassName = createGenerateClassName({
    dangerouslyUseGlobalCSS: true,
    productionPrefix: '',
});

class Investigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isInvestigationUpdate: null,
            locationFetched: false
        };
        this.headerRef = React.createRef();
        this.successRef = React.createRef();
        this.focusDiv = this.focusDiv.bind(this);

    }
    focusDiv() {
        window.scrollTo(0, 0);
    }
    
    componentDidMount() {
        // investigations being fetched from filter, hence commenting here.
        //let payload = {};
        //this.props.onFetchInvestigationRecords('Investigation', payload);
        //this.props.onResetSortAndFilters('investigation');
        this.props.onFetchProgramsList();
        document.title = "Investigations";
        if (this.props.getFilterStatus === 'Hide Filters') {
            this.props.onToggleFilter();
        }
        this.props.onFetchLookUpStage();
        document.body.classList.remove('nav-open');
        document.body.classList.remove('filter-open');
        window.scrollTo(0, 0);
    }

    componentDidUpdate() {
        if (this.props.investigationList && this.props.investigationList.length && !this.props.locationsDropDownList && !this.state.locationFetched) {
            const location_payload = {
                is_active: '1',
                program_id: ''
            }
            this.props.onFetchLocationList(location_payload);
            this.setState({ locationFetched: true });
        }
    }


    render() {

        const contentStyle = {
            maxWidth: "930px",
            width: "90%",
            background: "#000"
        };
        return (

            !this.props.isInvestigationAccessForPowerUser && !this.props.isInvestigationAccessForRM ? (
                <UnauthorizePage msg={'You are not authorized to access the application.'} />
            ) : (
                    <div>

                        <div className="main-content error-screen text-center">
                            <h2 className="m-t-50 m-b-30">Desktop only screen</h2>
                            <a href="/home" className="btn btn-primary">Back to Home</a>
                        </div>
                        <div className="main-content investigation">
                            <div>
                                <div className="page-header">
                                    <div className="row">
                                        <div className="col-4">
                                            <h3>Investigations</h3>
                                        </div>
                                        <div className="col-8">
                                            <StoriesHeader ref={this.headerRef}
                                                investigationList={this.props.investigationList}
                                                isInvestigationUpdate={this.state.isInvestigationUpdate}
                                                isInvestigationAccessForPowerUser={this.props.isInvestigationAccessForPowerUser}
                                                isInvestigationAccessForRM={this.props.isInvestigationAccessForRM}
                                                />

                                        </div>
                                    </div>
                                </div>
                                <div className="legend-wrapper d-none d-sm-none d-md-block">
                                    <div className="row grid__columns-wrapper">
                                        <div className="col-sm-12">
                                            <p className="records-count">
                                                {(this.props.paginationData.activePage * 20 + 1) - 20}
                                                -
                                                {(this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) >= 20 ? this.props.paginationData.activePage * 20 : this.props.paginationData.totalCount}
                                                {' '}
                                                of
                                                {' '}
                                                {this.props.paginationData.totalCount}
                                                {' '}
                                                records
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="grid__labels d-none d-md-none d-lg-block">
                                        <div className="row grid__columns-wrapper">
                                            <div id="location__sort" className="col-lg-3 col-md-3 grid__label-sort" onClick={() => this.props.onSort('Dealer_Name') }>
                                                Location
                                                <span className="grid__sort-icons">
                                                    <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                                    <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                                </span>
                                            </div>
                                            <div id="title__sort" onClick="" className="col-lg-2 col-md-2" onClick={() => this.props.onSort('Title') }>
                                                Title
                                                <span className="grid__sort-icons">
                                                    <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                                    <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                                </span>
                                            </div>
                                            <div id="created_on-sort" className="col-lg-2 col-md-2" onClick={() => this.props.onSort('CreatedOn') }>
                                                Created On
                                                <span className="grid__sort-icons">
                                                    <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                                    <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                                </span>
                                            </div>
                                            <div id="closed_on_sort" onClick="" className="col-lg-2 col-md-2" onClick={() => this.props.onSort('ClosedOn') }>
                                                Closed on
                                                <span className="grid__sort-icons">
                                                    <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                                    <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                                </span>
                                            </div>
                                            <div id="stage__sort" className="col-lg-1 col-md-1" onClick={() => this.props.onSort('Stage') }>
                                                Stage
                                                <span className="grid__sort-icons">
                                                    <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                                    <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                                </span>

                                            </div>
                                            <div id="status_sort" onClick="" className="col-lg-2 col-md-2" onClick={() => this.props.onSort('Status') }>
                                                Status
                                                <span className="grid__sort-icons">
                                                    <span className="grid__sort-arrow grid__sort-up-arrow grid__sort-up-arrow--selected" />
                                                    <span className="grid__sort-arrow grid__sort-down-arrow grid__sort-down-arrow--selected" />
                                                </span>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.investigationList && this.props.investigationList.length === 0 && this.props.loader !== 'show' && (
                                <div className="grid">
                                    <div className="grid__row">
                                        <div className="grid__columns-wrapper">
                                            No Records Found.
                                        </div>
                                    </div>
                                </div>
                            ) }
                            <div className="grid">
                                {(this.props.investigationList || []).map((recordObj, index) => {

                                    let isUpdateDisabled = false;
                                    if (this.props.isInvestigationAccessForRM && !this.props.isInvestigationAccessForPowerUser && (recordObj.ClosedOn && recordObj.ClosedOn !== null && recordObj.ClosedOn !== undefined && recordObj.ClosedOn !== "")) {
                                        isUpdateDisabled = true;
                                    }
                                    //  if (recordObj.ClosedOn && recordObj.ClosedOn !== null && recordObj.ClosedOn !== undefined && recordObj.ClosedOn !== "") {
                                    //     isUpdateDisabled = true;
                                    // }

                                    return (<Popup
                                        contentStyle={contentStyle}
                                        trigger={
                                            <a className="grid__row-link" >
                                                <div className="grid__row">
                                                    <div className="row grid__columns-wrapper">
                                                        <div className="col-md-6 col-lg-3 grid__column mar-md-b-10">
                                                            <TruncateMarkup lines={2} >
                                                                <div title={recordObj.Dealer_Name}>
                                                                    {recordObj.Dealer_Name}
                                                                </div>
                                                            </TruncateMarkup>

                                                        </div>
                                                        <div className="col-md-6 col-lg-2 grid__column grid__sapcode mar-md-b-10">
                                                            <TruncateMarkup lines={2} >
                                                                <div className="word-break" title={recordObj.Title}>
                                                                    {recordObj.Title}
                                                                </div>
                                                            </TruncateMarkup>
                                                        </div>
                                                        <div className="col-md-6 col-lg-2 grid__column  mar-md-b-10">
                                                            {(recordObj.CreatedOn &&
                                                                <Moment format="DD-MM-YYYY">
                                                                    {recordObj.CreatedOn}
                                                                </Moment>
                                                            ) }
                                                        </div>
                                                        <div className="col-md-6 col-lg-2 col-4 grid__column program mar-md-b-10">
                                                            {(recordObj.ClosedOn &&
                                                                <Moment format="DD-MM-YYYY">
                                                                    {recordObj.ClosedOn}
                                                                </Moment>
                                                            ) }
                                                        </div>
                                                        <div className="col-md-6 col-lg-1 col-8 grid__column last-updated  mar-md-b-10">
                                                            {recordObj.Stage}
                                                        </div>
                                                        <div className="col-md-6 col-lg-2 col-4 grid__column ">
                                                            {(recordObj.Status.toUpperCase() === "Closed".toUpperCase()) &&
                                                                <span className="action__status action__status--gray"> {recordObj.Status}</span>
                                                            }
                                                            {(recordObj.Status.toUpperCase() === "Open".toUpperCase()) &&
                                                                <span className="action__status action__status--yellow"> {recordObj.Status}</span>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>

                                        }
                                        modal
                                        closeOnDocumentClick
                                        lockScroll
                                        >
                                        {close => (
                                            <div>
                                                <div className="close__wrapper">
                                                    <div className="popup__close" onClick={close}>
                                                        <label><img width="18px" src="./../images/Cross.svg" alt="Close Popup" /></label>
                                                    </div>
                                                </div>
                                                <InvestigationOverlay
                                                    investigationList={this.props.investigationList} onClose={close}
                                                    investigationType={"Update"} investigationId={recordObj.Dealer_Id}
                                                    investigationObj={recordObj}
                                                    locationsDropDownList={this.props.locationsDropDownList}
                                                    isInvestigationAccessForPowerUser={this.props.isInvestigationAccessForPowerUser}
                                                    isInvestigationAccessForRM={this.props.isInvestigationAccessForRM}
                                                    isUpdateDisabled = {isUpdateDisabled}
                                                    />
                                            </div>
                                        ) }
                                    </Popup>
                                    )
                                }) }
                            </div>
                            <div className="legend-wrapper">
                                <div className="row grid__columns-wrapper">
                                    <div className="col-sm-12">
                                        <p className="records-count">
                                            {(this.props.paginationData.activePage * 20 + 1) - 20}
                                            -
                                            {(this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) >= 20 ? this.props.paginationData.activePage * 20 : this.props.paginationData.totalCount}
                                            {' '}
                                            of
                                            {' '}
                                            {this.props.paginationData.totalCount}
                                            {' '}
                                            records
                                        </p>
                                        {(this.props.paginationData.totalCount > 20 &&
                                            <InvestigationPagination paginationData={this.props.paginationData} onSelect={() => this.focusDiv() } />
                                        ) }
                                    </div>

                                </div>

                            </div>

                        </div>
                        <Filter
                            reviewType="investigation"
                            filtersToDisplay={{
                                dealer_list: true,
                                assessment_template_lookup: false,
                                status_lookup: true,
                                last_modified_by: false,
                                start_date: false,
                                end_date: false,
                                program_list: false,
                                sap_dealer_code: false,
                                year: false,
                                investigation_title: true,
                                stage: true,
                                closed_on_date: false,

                            }}
                            stageList={this.props.lookUpStageObj && this.props.lookUpStageObj.data ? this.props.lookUpStageObj.data : null}
                            />
                        {this.props.paginationData.totalCount > 20 && ((this.props.paginationData.totalCount - ((this.props.paginationData.activePage * 20 + 1) - 20)) > 20) && (
                            <div className="text-center d-block d-sm-block d-md-none">
                                <button id="page-change-btn" onClick={() => this.props.onPageChange((this.props.paginationData.activePage + 1)) } className="btn btn-gray">
                                    View
                                    more
                                </button>
                            </div>
                        ) }
                    </div>
                )

        );
    }
}

const mapStateToProps = (state) => ({
    errorState: getInvestigationListError(state),
    investigationList: getInvestigationRecordsData(state),
    paginationData: getPaginationDetails(state),
    lookUpStageObj: getLookUpStageData(state),
    investigationListCount: getInvestigationRecordsCount(state),
    locationsDropDownList: getLocationsList(state),
});
const mapDispatchToProps = (dispatch) => ({
    //onResetSortAndFilters: (reviewType) => dispatch(doResetSortAndFilter(reviewType)),
    onFetchProgramsList: query => dispatch(doFetchProgramsList(query)),
    //onFetchInvestigationRecords: (query, payload) => dispatch(doFetchInvestigationRecords(query, payload)),
    onFetchLookUpStage: () => dispatch(doFetchLookUpStage()),
    onSort: sortBy => dispatch(doSortStories(sortBy, 'investigation')),
    onFetchLocationList: query => dispatch(fetchLocationList(query)),
});
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Investigation);

import {FETCH_LOCATIONS_LIST, FETCH_LOCATIONS_LIST_SUCCESS, FETCH_LOCATIONS_LIST_ERROR,FILTER_ACTIVE_LOCATION} from '../constants/actionTypes';

const INITIAL_STATE = {
    locationsList: [],
    count: 0,
    error: null,
    status: null
}
function locationsListReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case FETCH_LOCATIONS_LIST : {
            return {...state};
        }
        case FETCH_LOCATIONS_LIST_SUCCESS: {
            let locationsList = action.payload.result.data.data;
            const count = action.payload.result.data.Count;
            if (action.payload.status.is_active === 0) {
                locationsList = locationsList.map((location) => {
                    location.Location_Name_Invest = location.Location_Name;
                    location.Location_Name = location.Location_Name + ' - ' + location.Location_Type;
                    return location;
                });
            } else {
                
                locationsList = locationsList.map((location) => {
                    location.Location_Name_Invest = location.Location_Name;
                    location.Location_Name = location.Location_Name + ' - ' + location.Location_Type;
                    return location;
                });
                
            }
            return { ...state, locationsList, count };
        }
        case FETCH_LOCATIONS_LIST_ERROR : {
            const error = action.payload.error
            return {...state, error};
        }


        default :
            return state;
    }
}
export default locationsListReducer;


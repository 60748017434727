import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Select from "react-select";
import {connect} from "react-redux";
import { setUserType } from '../../actions/loggedInUser';

export class Login extends Component {
    state = {
        users: [
            { value: 'TMCA Use', label: 'TMCA User' },
            { value: 'Dealer Use', label: 'Dealer User' }
        ],
       selectedOption :  { value: 'TMCA Use', label: 'TMCA User' }
    };

    handleChange = selectedOption => {
        this.setState({ selectedOption });
        this.props.setUserType(selectedOption.value);
    };
    redirectToRoute = () => {
        localStorage.setItem('userType', this.state.selectedOption.value);
        let nextPage = window.location.pathname;
        if (nextPage === '/login' || nextPage.includes('/implicit/callback')) {
            nextPage = '/';
        }
        window.open(nextPage, '_self');
    };
    render() {
        const { selectedOption } = this.state;
        
        return (
           <div className="login-page">
                <div className="row  h-100 ">
                    <div className="col-12 col-lg-5 col-md-12 align-self-center">
                    <div className="login-page__head text-center">PREP</div>
                        <p className="text-center text-f18"><b>P</b>rogram <b>R</b>eview <b>E</b>valuation <b>P</b>ortal</p>
                    </div>
                    <div className="col-12 col-lg-7 col-md-12 align-self-center">
                        <div className="login-page__form">
                            <div className="login-page__container">
                                <figure className="text-center">
                                    <img src="images/ac-login.svg"/>
                                </figure>
                                <div className="login-page__form-control">
                                    <label>Select User Type</label>
                                    {/*<select class="custom-select ">*/}
                                        {/*<option value="TMCA User">TMCA User</option>*/}
                                        {/*<option value="TMCA User">TMCA User</option>*/}
                                    {/*</select>*/}
                                    <Select
                                        placeholderText = {'TMCA User'}
                                        className="form-group dropdown-with-search"
                                        value={selectedOption}
                                        onChange={this.handleChange}
                                        options={this.state.users}
                                    />
                                    <button  className="m-t-40 m-b-30 btn btn-primary" onClick={() => this.redirectToRoute()}>LOGIN</button>
                                    </div>
                                    <p className="login-page__msg text-center"> If you are having difficulties logging in, contact the ISD Service Desk via 1800 505 350 or toyota.servicedesk@toyota.com.au.  </p>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    setUserType: (userType) => dispatch(setUserType(userType))
});
export default withRouter(connect(
    null, mapDispatchToProps)
    (Login));

import {
  ADD_ASSIGNTO_LIST,
  FETCH_ASSIGNTO_LIST,
  FETCH_ASSIGNTO_LIST_ERROR
} from '../constants/actionTypes';

const doAddAssignToList = assignToList => ({
  type: ADD_ASSIGNTO_LIST,
  assignToList,
});

const doFetchAssignToList = assignToList => ({
  type: FETCH_ASSIGNTO_LIST,
  assignToList,
});

const doFetchErrorAssignToList = error => ({
  type: FETCH_ASSIGNTO_LIST_ERROR,
  error,
});

export {
  doAddAssignToList,
  doFetchAssignToList,
  doFetchErrorAssignToList
};

import React from "react";
import * as mocklocations from "../constants/dropdowns";
import _ from 'lodash';
import { getTokenEmail } from "../utils/utils";
import { getUserDetails } from "../constants/utilityfunctions";

const getLocationList = () => {
    return mocklocations.INIT_LOCALES_LIST;
};

const getAssessmentDropdownOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);

    const optionsList = optionsData.map((dataOption, index) => {
        return (
            <option value={dataOption.Assessment_Template_ID}>{dataOption.Assessment_Name}</option>
        );
    });

    return {
        "data": optionsList,
        "default_option": <option value="">{defaultOption.Assessment_Name}</option>
    }
}

const getStatusDropdownOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);

    const optionsList = optionsData.map((dataOption, index) => {
        return (
            <option id={`StatusLookup__${index}`} value={dataOption.Name}>{dataOption.Description}</option>
        );
    });

    return {
        "data": optionsList,
        "default_option": <option id='StatusLookup__' value="">{defaultOption.Description}</option>
    }
}

const getLastUpdateByOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);
    /*    let newarr =[];
        for(let i=0;i < optionsData.length;i++) {
            if(optionsData[i] && optionsData[i].data) {
                newarr = newarr.concat(optionsData[i].data);
            }
        }
    
        newarr = _.uniqBy(newarr,'profile.email');*/
    if (optionsData && optionsData.length > 0) {
        const optionsList = optionsData.map((dataOption, index) => {
            const currentUser = getUserDetails(dataOption);
            return (
                <option id={`LastUpdateByStatusLookup__${index}`} value={`${currentUser}`}>{`${currentUser}`}</option>
            );
        });

        return {
            "data": optionsList,
            "default_option": <option id='LastUpdateByStatusLookup__' value="">{defaultOption.Description}</option>
        }
    }
}

const getTitleOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);

    const optionsList = optionsData.map((dataOption, index) => {
        return (
            <option id={`TitleLookup__${index}`} value={dataOption.Name}>{dataOption.Description}</option>
        );
    });

    return {
        "data": optionsList,
        "default_option": <option id="TitleLookup__" value="">{defaultOption.Description}</option>
    }
}

const getPriorityOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);

    const optionsList = optionsData.map((dataOption, index) => {
        return (
            <option id={`PriorityLookup__${index}`} value={dataOption.Name}>{dataOption.Description}</option>
        );
    });

    return {
        "data": optionsList,
        "default_option": <option id="PriorityLookup__" value="">{defaultOption.Description}</option>
    }
}

const getAssigneeOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);

    const optionsList = optionsData.map((dataOption, index) => {
        const currentUser = getUserDetails(dataOption);
        return (
            <option id={`AssigneeLookup__${index}`} value={`${currentUser}`}>{`${currentUser}`}</option>
        );
    });
    return {
        "data": optionsList,
        "default_option": <option id="AssigneeLookup__" value="">{defaultOption.Description}</option>
    }
}

const getCreatorOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);

    const optionsList = optionsData.map((dataOption, index) => {
        const currentUser = getUserDetails(dataOption);
        return (
            <option id={`CreatorLookup__${index}`} value={`${currentUser}`}>{`${currentUser}`}</option>
        );
    });

    return {
        "data": optionsList,
        "default_option": <option id="CreatorLookup__" value="">{defaultOption.Description}</option>
    }
}

const getLastConductedByOptions = (optionsData, defaultOption) => {
    // optionsData.unshift(defaultOption);
    /* let newarr =[];
     for(let i=0;i < optionsData.length;i++) {
         if(optionsData[i] && optionsData[i].data) {
             newarr = newarr.concat(optionsData[i].data);
         }
     }
 
     newarr = _.uniqBy(newarr,'profile.email');*/
    if (optionsData && optionsData.length > 0) {
        const optionsList = optionsData.map((dataOption, index) => {
            const currentUser = getUserDetails(dataOption);
            return (
                <option id={`LastConductedByStatusLookup__${index}`} value={`${currentUser}`}>{`${currentUser}`}</option>
            );
        });
        return {
            "data": optionsList,
            "default_option": <option id='LastConductedByStatusLookup__' value="">{defaultOption.Description}</option>
        }
    }
}

const getDropdownOptions = (state, type) => {
    return state[type]
}
const getCurrentUser = (state) => {
    const sub = getTokenEmail();
    //return _.find(state.oktaUsers, ['email', sub]);
    if (state) {
        return _.find(state.oktaUsers, function (obj) {
            if (obj && obj.email && sub !== null) {
                return obj.email.toLowerCase() === sub.toLowerCase();
            }
        });
    }
}

export {
    getCurrentUser, getCreatorOptions, getTitleOptions, getAssigneeOptions, getPriorityOptions, getLocationList, getAssessmentDropdownOptions, getStatusDropdownOptions, getLastUpdateByOptions, getDropdownOptions, getLastConductedByOptions
};
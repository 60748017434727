import React from 'react';
import moment from 'moment';
import { connect } from "react-redux";
class Recommendation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: false,
            value:this.props.qDetails.Recommendations.length > 0 ? this.props.qDetails.Recommendations[0].Recommendation :''
        }
    }
   
    showLoader = () => {
        return this.props.isLoading  === this.state.value? (
          <div className="lds-ring-loader ">
            <div className="lds-ring" />
            <div className="lds-ring" />
            <div className="lds-ring" />
            <div className="lds-ring" />
            <div className="lds-overlay" />
          </div>
        ) : null;
    }

    getRecommendationsList() {
        return (
              <div className="questions__recomendation-content" >
                {this.state.value}
              </div>
        )

    }
    onPastePress = (e) => {
        e.preventDefault();
    }

    onKeyPress=(e) => {
        var inputValue = e.target.value;        
        var keyValue = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (inputValue.length > 0) {                 
            var regvalue = inputValue.substr((inputValue.length - 1), 1) + keyValue;
            var SpecialCharExists = false;
            var setOfStrings = ['\\*', '--', '?=', ';', '%%', '[^'];            
            for (var i = 0; i < setOfStrings.length; i++) {                
                if (regvalue.indexOf(setOfStrings[i]) != -1)
                    SpecialCharExists = true;
            }
            if (SpecialCharExists) {
                e.preventDefault();
                return false;
            }

            if (e.target.selectionStart == 0) {                
                var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
                if (Specialcharacters.test(keyValue) || e.which === 32) {
                    e.preventDefault();
                    return false;
                }
            }

            return true;
        }
        
        if (inputValue.length == 0) {            
            var Specialcharacters = /^[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
            if (Specialcharacters.test(keyValue)) {
                e.preventDefault();
                return false;
            }
            if (e.which === 32) {
                e.preventDefault();
                return false;
            }
        }
        return true;
    }
    
    setEditMode = (e) => {
        this.setState(prevState => ({
            isEdit: !prevState.isEdit
          }));
        e.preventDefault();
    }

    handleChange = (e) => {
        this.setState({
            value: e.target.value
        });
        e.preventDefault();
    }

    handleFocusOut = (e) => {

        if (this.state.value.length > 0) {

            const Recommendations = this.props.qDetails.Recommendations.length > 0 ? this.props.qDetails.Recommendations : [{
                "Question_Recommendation_ID": 0,
                "Question_Template_ID": this.props.qDetails.Question_Template_ID,
                "Recommendation": this.state.value,
                "User_ID": "default",
                "LastModifiedBy": this.props.review.LastModifiedBy,
                "LastModifiedOn": new Date(),
                "CreatedBy": this.props.review.CreatedBy,
                "CreatedOn": new Date(),
                "Assessment_Response_ID": this.props.qDetails.Assessment_Response_ID,
                "Recommendation_Date": new Date()
            }];

            this.props.qDetails.Recommendations = Recommendations;
            //console.log(this.props.qDetails.Recommendations);
            if (Recommendations.length === 0) {
                //this.props.qDetails.isLoading = this.state.value;
                //this.props.qDetails.Question_Recommendation_ID = 0;
            }
            else {
                this.props.qDetails.Question_Recommendation_ID = Recommendations[0].Question_Recommendation_ID;
                this.props.qDetails.Recommendations[0].Recommendation = this.state.value;
            }
            this.setState({ isEdit: true });
            e.preventDefault();
        }
        else {
            this.setState({ isEdit: false });
        }
        var targetId= e.target.id;
        var getPastedata= e.target.value;
        var splChars = "*|,\":<>[]{}`\';()@&$#%";
        if(getPastedata.length>0){
        if(getPastedata.includes('\\*') || getPastedata.includes('--') || getPastedata.includes('?=') || getPastedata.includes(';') || getPastedata.includes('%%') || getPastedata.includes('[^') || (splChars.indexOf(getPastedata.charAt(0)) != -1)){
            window.confirm("1. First character in the text box cannot be any of the special characters.\n2. Following combined characters (strings) i.e. [^ , -- , \\* , ?= , %% and ; cannot be used in the text box.");
           setTimeout(function() { document.getElementById(targetId).focus();}, 100);
        } 
    }   
    }


    handleSave = (e) => {
        const Recommendations = this.props.qDetails.Recommendations ? this.props.qDetails.Recommendations : [];

        let payload = {
            "Question_Template_ID": this.props.qDetails.Question_Template_ID,
            "Recommendation": this.state.value,
            "Assessment_ID":this.props.review.Assessment_Id,
            "User_ID": "default",
            "LastModifiedBy": this.props.review.LastModifiedBy,
            "LastModifiedOn": new Date(),
            "CreatedBy": this.props.review.CreatedBy,
            "CreatedOn": new Date(),
            "Assessment_Response_ID": this.props.qDetails.Assessment_Response_ID,
            "Recommendation_Date": new Date()
        }

        if(Recommendations.length === 0){
            this.props.postRecommendation(payload);

        }else {
            payload.Question_Recommendation_ID = Recommendations[0].Question_Recommendation_ID;
            this.props.updateRecommendation(payload);

        }
        this.setState({ isEdit: false });
        /** Audit Log */
        let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
            + '(' + this.props.currentUser.employeeNumber + ')' : "";

        let msg = currentUser + ' added Observation for ' + this.props.qDetails.Question_Template_ID + ' for ' + this.props.review.Assessment_Id  + ' on ' +  moment(new Date()).format('HH:MM DD MMM YYYY') + '.';
        const reqPayload = {
            LogMessage: this.props.auditLogMsg + msg,
            AuditLogModule: "Conduct Reviews And Approvals",
            AcionAPISource: "Add Observation",
            CreatedBy: currentUser,
            CreatedOn: moment(new Date()).format('HH:MM DD MMM YYYY')
        }
        this.props.doPostAuditLogData(reqPayload);
        e.preventDefault();
    }

    render() {

        return [
          <div className= "col-xl-3 col-md-6 questions__documents-wrapper questions__recomendation">
            <div className="docs-header recomendation-header">
            <a className={`edit-icon ${this.props.elHide}`} href="#"><img src="../images/icons-observation.svg" width="22px" className="toggleText" alt="Edit" onClick={this.setEditMode} /></a>
              {' '}
              <span className="docs-header-text"> Observation</span>
             
            </div>
            <div className="docs-content">

              {this.state.isEdit ? (
                <div className="questions__recomendation-form text-right">
                            <textarea disabled ={this.props.qAssessment_Response_ID} className="form-control recommendation-textarea m-b-10" id={this.props.qDetails.Question_Template_ID} maxlength="1000"  placeholder='Enter observation test' value={this.state.value}  onKeyPress={this.onKeyPress} onChange={this.handleChange} onBlur={this.handleFocusOut} />
                  <button disabled ={this.props.qAssessment_Response_ID} className="btn btn-primary" onClick={this.handleSave}>Save</button>
                </div>
) : this.getRecommendationsList()}
            </div>
            {this.showLoader()}
          </div>
        ]
    }
}

export default Recommendation;

import React from 'react';
import is from 'is_js';
import Moment from 'moment';
import { doUpdateQuestionRecommendation, doAddQuestionRecommendation } from "../../actions/review";
import { connect } from 'react-redux';

class SupportingSop extends React.Component {
    constructor(props) {
        super(props);

    }


    addDocument = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        const queryParams = {
            Dealer_ID: this.props.review.Dealer_ID,
            Sop_Name: this.props.qDetails.Sop_Name,
            Document_Name: file.name,
            Expiry_Date: new Date(),
            Program_Id: this.props.review.Program_ID

        }
        
        formData.append('data', file);
        this.props.postQuestionSopDocument(formData, queryParams, this.props.qDetails.Question_Id);

        const Recommendations = this.props.qDetails.Recommendations.length > 0 ? this.props.qDetails.Recommendations : [];

        if (Recommendations.length > 0) {
            let Recommendationpayload = {
                "Question_Template_ID": this.props.qDetails.Question_Template_ID,
                "Recommendation": this.props.qDetails.Recommendations[0].Recommendation,
                "Assessment_ID": this.props.review.Assessment_Id,
                "User_ID": "default",
                "LastModifiedBy": this.props.review.LastModifiedBy,
                "LastModifiedOn": "2019-01-07T16:32:13.277",
                "CreatedBy": this.props.review.CreatedBy,
                "CreatedOn": "2019-01-07T16:32:13.277",
                "Assessment_Response_ID": this.props.qDetails.Assessment_Response_ID,
                "Recommendation_Date": new Date()
            }
            if (this.props.qDetails.Recommendations[0].Recommendation.length > 0 && this.props.qDetails.Recommendations[0].Question_Recommendation_ID == 0) {
                this.props.doAddQuestionRecommendation(Recommendationpayload);
            }
            else {
                Recommendationpayload.Question_Recommendation_ID = this.props.qDetails.Question_Recommendation_ID;
                this.props.doUpdateQuestionRecommendation(Recommendationpayload);

            }
        }
        //end 

        /** Audit Log */
        let currentUser = this.props.currentUser !== undefined ? this.props.currentUser.firstName + ' ' + this.props.currentUser.lastName
            + '(' + this.props.currentUser.employeeNumber + ')' : "";
      
      let msg = currentUser + ' added SOP ' + this.props.qDetails.Sop_Name + ' for ' + this.props.qDetails.Question_Id + ' for ' + this.props.review.Program_ID + ' on ' +  Moment(new Date()).format('HH:MM DD MMM YYYY');
      const reqPayload = {
        LogMessage: msg,
        AuditLogModule: "Conduct Reviews And Approvals",
        AcionAPISource: "Upload SOP",
        CreatedBy: currentUser,
        CreatedOn: Moment(new Date()).format('HH:MM DD MMM YYYY')
    }
        this.props.doPostAuditLogData(reqPayload);
        /** Audit Log */
        e.preventDefault();
    }



    showLoader() {
        return this.props.isLoading === this.props.qDetails.Question_Id ? (
          <div className="lds-ring-loader">
            <div className="lds-ring" />
            <div className="lds-ring" />
            <div className="lds-ring" />
            <div className="lds-ring" />
            <div className="lds-overlay" />
          </div>
        ) : null;
    }



    render() {

        const Sops = this.props.qDetails.Sops ? this.props.qDetails.Sops : [];
        const elHide = this.props.canAddSOP ? '' : this.props.elHide;
        const disabledDiv = !this.props.qDetails.Is_Sop_Required ?  <div className="questions__disabled-column"></div>  : null;
        return [
          <div className="col-xl-4 col-md-6 questions__documents-wrapper questions__supporting-sop">
              {disabledDiv}
              <div className="docs-header supporting-sop-header clearfix">
              <img
                className="docs-icon"
                src="../images/icons-upload-sop.svg"
                alt="Supporting SOP" width="22px"
              />
              <span className="docs-header-text">Supporting SOP</span>
              {   this.props.qDetails.Is_Sop_Required  ? (
                  <React.Fragment>
                  <label htmlFor={`upload-photo-supporting${this.props.qDetails.Question_Template_ID}`} className={`add-more-link${this.props.qAssessment_Response_ID?"-disabled":""} add-more-link_photo ${elHide}`} href="">+ Add </label>
                  <input disabled ={this.props.qAssessment_Response_ID} id={`upload-photo-supporting${this.props.qDetails.Question_Template_ID}`} type="file" name="myFilesPh" className="addMore-doc" onChange={(e) => this.addDocument(e)} />
                  </React.Fragment>
                  ) : null
              }

            </div>
            <div className="docs-content">
              {this.props.qDetails.Is_Sop_Required && Sops && Sops.map((el, index) => {
                        return (
                          <div className="sop-row uploaded" key={index}>
                            <div className="sop-icon">
                              <img
                                className="uploaded-icon"
                                src="../images/success-icon.svg"
                                alt="Success"
                              />
                              <img src="../images/document-icon.svg" alt="Document" />
                            </div>
                            <div className="sop-name">
                              <span>{el.Sop_Name}</span>
                            </div>
                            <div className="sop-action-icons">
                              {/*<a className="remove-sop enabled-review" href={el.BlobURI} download  target = {is.safari()?`_blank`:``}> */}
                              <a className="remove-sop enabled-review" href={el.BlobURI} download  target='_blank'> 
                                <img
                                  src="../images/download-icon.svg"
                                  alt="Download"
                                />
                              </a>
                            </div>

                          </div>
)
                    })}
            </div>
            {this.showLoader()}

          </div>
        ]
    }
}

const mapDispatchToProps = dispatch => ({
    doAddQuestionRecommendation: (payload) => dispatch(doAddQuestionRecommendation(payload)),
    doUpdateQuestionRecommendation: (payload) => dispatch(doUpdateQuestionRecommendation(payload)),

});

export default connect(undefined, mapDispatchToProps)(SupportingSop);


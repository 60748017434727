import { Skeleton } from '@mui/material';
import * as React from 'react';
import propTypes from 'react-jss/lib/propTypes';

export default function Skeleton_ReviewQuestion(props) {
  return (



    <>
      <div className="position-relative long-title">
        <div className="h4 m-t-15 m-b-15 " >
          <Skeleton variant="text" height={28} id="SkeletonCriteria_Name" />
        </div>

      </div>
      <div className="questions">
        <div className="questions__row">
          <div className="questions__header">
            <div className="row">
              <div className="col-md-12 col-lg-5 col-xl-5 questions__left-col">
                <div className="skeleton_questions__count">
                  <Skeleton variant="rectangle" height={30} width={30} id="" />
                </div>
                <div className="questions__question">

                  <Skeleton variant="text" height={28} id="" />
                  <Skeleton variant="text" height={28} id="" />
                  <Skeleton variant="text" height={28} id="" />
                  <Skeleton variant="text" height={28} id="" />






                </div>
              </div>
              <div className="col-md-12 col-lg-2">
              </div>
              <div className={`col-md-12 col-lg-5 col-xl-5 questions__right-col text-right`}>
                <div className="Skeleton_button_container text-right">
                  {props.programName === "TFL" && (
                    <>
                      <Skeleton variant="rectangular" width={39} height={40} id='Skeleton_Standard' className='btn-skeleton' />
                      <Skeleton variant="rectangular" width={39} height={40} id='Skeleton_notStandard' className='btn-skeleton' />
                    </>)}
                    {props.programName === "Lexus" && (
                    <>
                      <Skeleton variant="rectangular" height={40} id='Skeleton_Pass' className='btn-skeleton' />
                      <Skeleton variant="rectangular" height={40} id='Skeleton_Fail' className='btn-skeleton' />
                    </>)}
                    {props.programName === "DPOK" && (
                    <>
                      <Skeleton variant="rectangular" height={38} id='Skeleton_Response_DPOK' className='btn-skeleton' />
                    </>)}
                </div>
              </div>

            </div>
          </div>

        </div>

        <div className="questions__row">
          <div className="questions__header">
            <div className="row">
              <div className="col-md-12 col-lg-5 col-xl-5 questions__left-col">
                <div className="skeleton_questions__count">
                  <Skeleton variant="rectangle" height={30} width={30} id="" />
                </div>
                <div className="questions__question">

                  <Skeleton variant="text" height={28} id="" />
                  <Skeleton variant="text" height={28} id="" />
                  <Skeleton variant="text" height={28} id="" />
                  <Skeleton variant="text" height={28} id="" />






                </div>
              </div>
              <div className="col-md-12 col-lg-2">
              </div>
              <div className={`col-md-12 col-lg-5 col-xl-5 questions__right-col text-right`}>
                <div className="Skeleton_button_container text-right">
                {props.programName === "TFL" && (
                    <>
                      <Skeleton variant="rectangular" width={39} height={40} id='Skeleton_Standard' className='btn-skeleton' />
                       <Skeleton variant="rectangular" width={39} height={40} id='Skeleton_notStandard' className='btn-skeleton' />
                    </>)}
                    {props.programName === "Lexus" && (
                    <>
                      <Skeleton variant="rectangular" height={40} id='Skeleton_Pass' className='btn-skeleton' />
                      <Skeleton variant="rectangular" height={40} id='Skeleton_Fail' className='btn-skeleton' />
                    </>)}
                    {props.programName === "DPOK" && (
                    <>
                      <Skeleton variant="rectangular" height={38} id='Skeleton_Response_DPOK' className='btn-skeleton' />
                    </>)}
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>



  );
}


import axios from 'axios';

let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}
const FETCH_DEALERSOP_BASE_URL = `${BASE_URL}/DealerSopsList`;
const ADD_SOPDOCS_BASE_URL = `${BASE_URL}/DealerSopsUpload`;

const fetchSOPDocsList = query =>
     axios.get(`${FETCH_DEALERSOP_BASE_URL}?${query.query}`, {
    "FilterBy": query.postParams
});

const fetchSortSOPDocsList = query =>
    axios.get(`${FETCH_DEALERSOP_BASE_URL}?${query.query}`, {
        "FilterBy": query.postParams
    });

const fetchSOPNamesList = query =>
         axios.get(`${BASE_URL}/LookupSops?dealer_id=`+query.location+`&program_id=`+query.program);

export const fetchLocationList = (payload)  =>
    axios.get(`${BASE_URL}/DealerList?is_active=${payload.is_active}&program_id=${payload.program_id}`);

export  const fetchProgramList = () =>
    axios.get(`${BASE_URL}/LookupProgram`);

export {
  ADD_SOPDOCS_BASE_URL, fetchSOPDocsList,fetchSortSOPDocsList, fetchSOPNamesList
};

import {
  ADD_SOPNAMES_LIST,
  FETCH_SOPNAMES_LIST,
  FETCH_SOPNAMES_LIST_ERROR,
  UN_AUTHORIZED_UPLOAD_SOP
} from '../constants/actionTypes';

const doAddSOPNamesList = sopNamesList => ({
  type: ADD_SOPNAMES_LIST,
  sopNamesList,
});

const doFetchSOPNamesList = sopNamesList => ({
  type: FETCH_SOPNAMES_LIST,
  sopNamesList,
});

const doFetchErrorSOPNamesList = error => ({
  type: FETCH_SOPNAMES_LIST_ERROR,
  error,
});

const unAuthorisedSOP = (payload) => ({
  type: UN_AUTHORIZED_UPLOAD_SOP,
  payload
})

export {
  doAddSOPNamesList,
  doFetchSOPNamesList,
  doFetchErrorSOPNamesList,
  unAuthorisedSOP
};

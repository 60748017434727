import React from "react";
import ReactTooltip from 'react-tooltip';
export default function Status(props) {
  console.log(props.Reviewstatus,'props.Reviewstatus')
   let status=(props.Reviewstatus==="In-progress" ||props.Reviewstatus=== "Completed" ||props.Reviewstatus==="Deleted")?props.Reviewstatus:'Other'
    return(
        <>
<p  className={`reviewStatusText ${status}Status`} >{"  " +props.Reviewstatus.toUpperCase()}</p>
<ReactTooltip />
</>
    )
    
}
import axios from 'axios';
let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}

//Cancel subsequent get requests. Currently, reviews fetch are triggered for sharing via item menu; Cancelling the pending requests.
const makeRequestCreator = () => {
    let call;
    return url => {
      if (call) {
        call.cancel(".Only one request allowed at a time.");
      }
      call = axios.CancelToken.source();
      return axios.get(url, {
        cancelToken: call.token
      });
    };
};
const get = makeRequestCreator();
//Cancel subsequent get request code ends

const fetchStories = query =>
     axios.post(`${BASE_URL}/SchedulesList?${query.query}`, {
    "FilterBy": query.postParams
});

const fetchTfl18Stories = query =>
    axios.post(`${BASE_URL}/TFL18List?${query.query}`, {
        "FilterBy": query.postParams
    });

const fetchActionStories = query =>
    axios.post(`${BASE_URL}/QuestionActionList?${query.query}`, {
            "FilterBy": query.postParams
        });
const fetchSortedStories = query =>
    axios.post(`${BASE_URL}?${query.query}`, {
        "FilterBy": query.postParams
    });

const fetchFilteredStories = query =>
    axios.post(`${BASE_URL}?${query.query}`, {
        "FilterBy": query.postParams
    });

const fetchTflNineReview = reviewId =>
    get(`${BASE_URL}/AssessmentById?assessment_id=${reviewId}`);


const fetchAssessmentStatusLookup = (query) => {
    let program_id, dealer_id;
    program_id = (query !== undefined && query.statusType.program_id !== undefined && query.statusType.program_id !== null ) ? query.statusType.program_id : "";
    dealer_id = (query !== undefined && query.statusType.dealer_id !== undefined && query.statusType.dealer_id !== null ) ? query.statusType.dealer_id : "";
        return  axios.get(
                `${BASE_URL}/LookupAssessmentTemplates?dealer_id=${dealer_id}&program_id=${program_id}`
            );
    } 

const fetchStatusLookup = (statusType) =>
    axios.get(
        `${BASE_URL}/LookupStatus?type=${statusType}`
    );

const fetchLastUpdateByLookup = () => {
    //const grp = getTokenGrp();
    return axios.get(`${BASE_URL}/OktaGroupUsers`);
}

const fetchTitleLookup = () =>
    axios.get(
        `${BASE_URL}/LookupTitle`
    );

const fetchAssigneeLookup = () =>
    axios.get(
        `${BASE_URL}/LookupAssignee`
        // `https://tmca-dart-qa-api-fnc.azurewebsites.net/LookupAssignee`
    );

const fetchPriorityLookup = () =>
    axios.get(
        `${BASE_URL}/LookupPriority`
    );

const fetchCreatorLookup = () =>
    axios.get(
        `${BASE_URL}/LookupCreator`
    );

const postReview =(reviewState, isSubmit) =>{
    let issubmit =  isSubmit ? isSubmit: 0;
    return axios.post(`${BASE_URL}/CaptureAssessmentResponse?issubmit=${issubmit}`, reviewState.review);
    }

const completeReview = reviewState => 
    axios.post(`${BASE_URL}/CaptureAssessmentResponse?issubmit=1`, reviewState.review);
    
const submitScore = payload =>{
    return axios.get(`${BASE_URL}/Score?dealer_id=`+payload.review.Dealer_ID+`&program_id=`+payload.review.Program_ID+`&review_id=`+payload.review.Review_ID+`&review_type=`+payload.review.Review_Type);
}

const shareReview = reviewData =>
    axios.post(`${BASE_URL}/SendMail?`, reviewData);
  
const fetchTfl18StoryFromDelearId = (query,userDetails) =>
    axios.get(
        `${BASE_URL}/StartTFL18?Dealer_id=${query}&user_id=${userDetails}`
    );
export {
    fetchCreatorLookup, postReview,fetchTflNineReview, fetchAssigneeLookup,fetchPriorityLookup,fetchTitleLookup, fetchStories,fetchSortedStories, fetchFilteredStories, fetchTfl18Stories,fetchAssessmentStatusLookup, fetchStatusLookup, fetchLastUpdateByLookup,fetchActionStories,completeReview, shareReview , fetchTfl18StoryFromDelearId,
    submitScore
};
import React from 'react';
import axios from 'axios';

axios.defaults.headers.common = {
    "Ocp-Apim-Subscription-Key": `${process.env[`REACT_APP_APIM_KEY_${window.location.hostname}`]}`
};
let BASE_URL;
if (localStorage.getItem('userType') === 'TMCA Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_${window.location.hostname}`]}`;
} else if (localStorage.getItem('userType') === 'Dealer Use') {
    BASE_URL = `${process.env[`REACT_APP_HN_BASE_URL_Dealer_${window.location.hostname}`]}`;
}

const FETCH_ACTION_DATA_BY_ID_BASE_URL = `${BASE_URL}/ActionListById`;

const config = { headers: { 'Content-Type': 'multipart/form-data' } };
function fetchActionDataById(query){
     return axios.get(`${BASE_URL}/QuestionActionById?${query}`)
}

/* API call to create an action */
const postCreateAction = formData => {
    return axios.post(`${BASE_URL}/AddQuestionAction`, formData, config);
}

/* API call to Update an action */
const postUpdateAction = (formData,query) => {
    return axios.post(`${BASE_URL}/UpdateQuestionAction${query}`, formData,config);
}


export {
    FETCH_ACTION_DATA_BY_ID_BASE_URL,
    fetchActionDataById,
    postCreateAction as postAction,
    postUpdateAction
};
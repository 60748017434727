import {
  CREATE_ACTION,
  UPDATE_ACTION,
  FETCH_ACTIONDATA,
  ACTION_ERRORS,
  FETCH_ERROR_ACTIONDATA,
  CREATE_ACTION_DATA,
  FETCH_ACTIONDATA_SUC,
  CLEAR_DATA
}
from '../constants/actionTypes';

const doCreateAction = (formData) => ({
  type: CREATE_ACTION,
    formData
});

const doCreateActionWithData = (actionData) => ({
  type: CREATE_ACTION_DATA,
  actionData
});

const doUpdateAction = (actionId, formData ) => ({
  type: UPDATE_ACTION,
  actionId,
  formData
});

const doCreateUpdateActionError = error => ({
  type: ACTION_ERRORS,
  error,
});

const doFetchActionDataById = (actionId) => ({
  type: FETCH_ACTIONDATA,
  actionId,
});

const doFetchActionDataByIdSuc = (actionData) => ({
  type: FETCH_ACTIONDATA_SUC,
  actionData,
});

const doFetchErrorActionDataById = (actionId) => ({
  type: FETCH_ERROR_ACTIONDATA,
  actionId,
});

const doClearData = (dataList) =>({
  type: CLEAR_DATA,
  dataList
 
});

export {
  doCreateAction,
  doUpdateAction,
  doCreateUpdateActionError,
  doFetchActionDataById,
  doFetchErrorActionDataById,
  doCreateActionWithData,
  doFetchActionDataByIdSuc,
  doClearData
};

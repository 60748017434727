import React, {Component} from 'react';
import {connect} from "react-redux";
import {doFetchLocationsList} from "../../actions/locationsList";
import DisplaySOPDocument from "./DisplaySOPDocument";
import DealerSOPListHeader from "./DealerSOPListHeader";
import {getDealerSOPListForDisplay, getDealerLocationId} from '../../selectors/uploadSOP';


export class DisplaySOPDocs extends Component {
constructor(props) {
    super(props);
        //this.headerRef = React.createRef();
}

    render() {
        return (
            <React.Fragment>
                <div className="grid">
                    <DealerSOPListHeader/>
                    {(this.props.dealerSOPListForDisplay || []).map((sopDoc,index) =>
                        <DisplaySOPDocument sopDoc={sopDoc} rowIndex={index} />
                    )}
                </div>

                {this.props.dealerLocationId !== null && this.props.dealerSOPListForDisplay.length === 0 && this.props.loader !== 'show' && (
                    <div className="grid">
                        <div className="grid__row">
                            <div className="grid__columns-wrapper">
                                No Results Found.
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dealerSOPListForDisplay: getDealerSOPListForDisplay(state),
    dealerLocationId: getDealerLocationId(state),
});

export default connect(
    mapStateToProps,
    null
)(DisplaySOPDocs);

import React, { Component } from 'react';
import {connect} from "react-redux";
import {doFetchProgramsList} from "../../actions/programsList";
import UploadSOPForm from "./UploadSOPForm";
import DisplaySOPDocs from "./DisplaySOPDocs";
import {getUnAuthSOP} from "../../selectors/uploadSOP";
import  UnauthorizePage from "../Common/UnauthorizePage"


export class ManageDealerSOP extends Component {

    componentDidMount() {
        document.title = "Upload SOP";

        this.initialLoadUploadSOPForm ();
        document.body.classList.remove('nav-open');
    }

    initialLoadUploadSOPForm () {
        this.props.onFetchProgramsList();

    }

    render() {
        return (
            <div>
              {this.props.unauthSOP && this.props.unauthSOP.StatusCode===401 ? (
                    <UnauthorizePage msg={this.props.unauthSOP.Message} />
                ) : (
                    <div className="main-content">
                        <UploadSOPForm />
                        <DisplaySOPDocs />
                    </div>
                )}
            </div>

            );
        }
    }

const mapStateToProps = state => ({
    unauthSOP:getUnAuthSOP(state)
 });

const mapDispatchToProps = (dispatch) => ({
    onFetchProgramsList : query => dispatch(doFetchProgramsList(query)),
});




export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageDealerSOP);

import { SET_USER_TYPE } from "../constants/actionTypes";


const INITIAL_STATE = {
  userType: ''
};

export function userTypeReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case SET_USER_TYPE: {
      return {
          userType: action.userType
      }
    }
   
    default : return state;
  }
}

export default userTypeReducer;

/**
 * Created by mohdshaban on 18/01/19.
 *
 * * */

import React from 'react';
import {withRouter} from 'react-router-dom';


class UserGuid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentScreen: 'start-image'
        }
    }

    skipScreen(e) {
        if (this.props.location.state && this.props.location.state.isComingFromFaq) {
            this.props.history.push({
                    pathname: '/Faqs',
                    state: {isComingFromFaq: false}

                }
            )

        } else {
            this.props.history.push('/');
            //This code is commented on purpose, if user shall be redirected to the typed url then use below snippet.
            //this.props.history.push(window.location.pathname);
        }
        localStorage.setItem('isUserFirstVisit', false)

        e.preventDefault();
    }

    nextScreen(screenNO) {
        this.setState({currentScreen: screenNO})

    }

    finishGuid(e) {
        if (this.props.location.state && this.props.location.state.isComingFromFaq) {
            this.props.history.push({
                    pathname: '/Faqs',
                    state: {isComingFromFaq: false}

                }
            )
        } else {
            this.props.history.push('/');
            //This code is commented on purpose, if user shall be redirected to the typed url then use below snippet.
            //this.props.history.push(window.location.pathname);
        }
        localStorage.setItem('isUserFirstVisit', false)
        e.preventDefault()
    }

    render() {


        return [
            <div className="container overflow-x-hidden">
                <div
                    className="start-image"
                    style={(this.state.currentScreen === 'start-image' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">
                        <button className="btn button-2" id="btn1" onClick={() => this.nextScreen('1')}>Start</button>
                        <a href="JavaScript:Void(0)" id="btn-close" className="btn btn-guide-close"
                           onClick={(e) => this.skipScreen(e)}>Close</a>

                    </div>
                </div>


                <div
                    className="comm-image image-1"
                    style={(this.state.currentScreen === '1' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">
                        <button id="btn2" className="btn button-2" onClick={() => this.nextScreen('2')}>Next</button>
                        <a href="" id="anchor2" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-2"
                    style={(this.state.currentScreen === '2' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn3" className="btn button-3" onClick={() => this.nextScreen('3')}>Next</button>
                        <a href="" id="anchor3" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-3"
                    style={(this.state.currentScreen === '3' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn4" className="btn button-4" onClick={() => this.nextScreen('4')}>Next</button>
                        <a href="" id="anchor4" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-4"
                    style={(this.state.currentScreen === '4' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn5" className="btn button-5" onClick={() => this.nextScreen('5')}>Next</button>
                        <a href="" id="anchor5" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-5"
                    style={(this.state.currentScreen === '5' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn6" className="btn button-6" onClick={() => this.nextScreen('6')}>Next</button>
                        <a href="" id="anchor6" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-6"
                    style={(this.state.currentScreen === '6' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn7" className="btn button-7" onClick={() => this.nextScreen('7')}>Next</button>
                        <a href="" id="anchor7" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>

                <div
                    className="comm-image image-7"
                    style={(this.state.currentScreen === '7' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn8" className="btn button-8" onClick={() => this.nextScreen('8')}>Next</button>
                        <a href="" id="anchor8" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-8"
                    style={(this.state.currentScreen === '8' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn9" className="btn button-9" onClick={() => this.nextScreen('9')}>Next</button>
                        <a href="" id="anchor9" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-9"
                    style={(this.state.currentScreen === '9' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn10" className="btn button-10" onClick={() => this.nextScreen('10')}>Next</button>
                        <a href="" id="anchor10" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-10"
                    style={(this.state.currentScreen === '10' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn11" className="btn button-11" onClick={() => this.nextScreen('11')}>Next</button>
                        <a href="" id="anchor11" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-11"
                    style={(this.state.currentScreen === '11' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn12" className="btn button-12" onClick={() => this.nextScreen('12')}>Next</button>
                        <a href="" id="anchor12" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-12"
                    style={(this.state.currentScreen === '12' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn13" className="btn button-13" onClick={() => this.nextScreen('13')}>Next</button>
                        <a href="" id="anchor13" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-13"
                    style={(this.state.currentScreen === '13' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">
                        <button id="btn14" className="btn button-14" onClick={() => this.nextScreen('14')}>Next</button>
                        <a href="" id="anchor14" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>


                    </div>

                </div>


                <div
                    className="comm-image image-14"
                    style={(this.state.currentScreen === '14' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">
                        <button id="btn15" className="btn button-15" onClick={() => this.nextScreen('15')}>Next</button>
                        <a href="" id="anchor15" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>


                    </div>

                </div>


                <div
                    className="comm-image image-15"
                    style={(this.state.currentScreen === '15' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">
                        <button id="btn16" className="btn button-16" onClick={() => this.nextScreen('16')}>Next</button>
                        <a href="" id="anchor16" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>


                    </div>

                </div>


                <div
                    className="comm-image image-16"
                    style={(this.state.currentScreen === '16' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn17" className="btn button-17" onClick={() => this.nextScreen('17')}>Next</button>
                        <a href="" id="anchor17" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>

                </div>


                <div
                    className="comm-image image-17"
                    style={(this.state.currentScreen === '17' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn18" className="btn button-18" onClick={() => this.nextScreen('18')}>Next</button>
                        <a href="" id="anchor18" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>

                 <div
                    className="comm-image image-18"
                    style={(this.state.currentScreen === '18' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn19" className="btn button-19" onClick={() => this.nextScreen('19')}>Next</button>
                        <a href="" id="anchor19" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>
                <div
                    className="comm-image image-19"
                    style={(this.state.currentScreen === '19' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn20" className="btn button-20" onClick={() => this.nextScreen('20')}>Next</button>
                        <a href="" id="anchor20" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>
                <div
                    className="comm-image image-20"
                    style={(this.state.currentScreen === '20' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn21" className="btn button-21" onClick={() => this.nextScreen('21')}>Next</button>
                        <a href="" id="anchor21" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>

                <div
                    className="comm-image image-21"
                    style={(this.state.currentScreen === '21' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn22" className="btn button-22" onClick={() => this.nextScreen('22')}>Next</button>
                        <a href="" id="anchor22" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>
                <div
                    className="comm-image image-22"
                    style={(this.state.currentScreen === '22' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn23" className="btn button-23" onClick={() => this.nextScreen('23')}>Next</button>
                        <a href="" id="anchor23" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>
                <div
                    className="comm-image image-23"
                    style={(this.state.currentScreen === '23' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">

                        <button id="btn25" className="btn button-25" onClick={() => this.nextScreen('25')}>Next</button>
                        <a href="" id="anchor25" className="btn btn-guide-close" onClick={(e) => this.skipScreen(e)}>Close</a>
                    </div>
                </div>



                <div
                    className="start-image last"
                    style={(this.state.currentScreen === '25' ? {display: 'block'} : {display: 'none'})}
                >
                    <div className="popover-bottom">
                            <button id="finish-btn" className="btn button-25" onClick={(e) => this.finishGuid(e)}>OK, got
                                it!
                            </button>
                        </div>
                </div>

            
               



            </div>
        ]


    }
}
export default withRouter(UserGuid);

